import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Modal, Typography, Grid, DialogActions } from '@mui/material';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { postAddress, updateAddress, deleteAddress } from '../store/actions/authorizations.actions';
import { showToast } from 'modules/layout/layout.actions';
import { compose } from 'recompose';
import { DELETE_ADDRESS_FAILURE, POST_ADDRESS_FAILURE, UPDATE_ADDRESS_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import countries from 'modules/common/countries.json';
import states from 'modules/common/states.json';
import { memoize } from 'lodash';
import Spinner from 'modules/common/spinner.component';

const AddressManagementModal = ({ authorizationId, addresses, postAddress, updateAddress, showToast, deleteAddress }) => {
  const addressStatusOptions = ['Rentership', 'Ownership'];

  const defaultAddress = () => {
    return {
      authorizationId: authorizationId,
      addressId: 0,
      addressType: addressStatusOptions[0],
      addressLineOne: '',
      addressLineTwo: '',
      addressLineThree: '',
      city: '',
      country: 'USA',
      state: '',
      zipcode: '',
      county: '',
      postalCode: ''
    };
  };
  const [open, setOpen] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(-1);
  const [addressForm, setAddressForm] = useState(defaultAddress());

  const renderCountryOptions = memoize(() =>
    countries.map(({ countryCode, description }) => (
      <MenuItem key={countryCode} value={countryCode}>
        {description}
      </MenuItem>
    ))
  );

  const renderStateOptions = memoize(() =>
    states
      .filter(state => state.countryCode === addressForm.country)
      .map(({ code, description }) => (
        <MenuItem key={code} value={code}>
          {description}
        </MenuItem>
      ))
  );

  const [isLoadingState, setIsLoadingState] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedAddressId(-1);
    setAddressForm(defaultAddress());
    setOpen(false);
  };

  const handleAddressChange = event => {
    const selectedId = event.target.value;
    setSelectedAddressId(selectedId);
    if (selectedId > 0) {
      const selectedAddress = addresses.find(addr => addr.addressId === selectedId);
      setAddressForm({ ...selectedAddress });
    } else {
      setAddressForm(defaultAddress());
    }
  };

  const handleInputChange = event => {
    setAddressForm({ ...addressForm, [event.target.name]: event.target.value });
  };

  const handleSave = async () => {
    setIsLoadingState(true);
    const response =
      selectedAddressId > 0 ? await updateAddress(authorizationId, addressForm) : await postAddress(authorizationId, addressForm);

    if (response.type === POST_ADDRESS_FAILURE || response.type === UPDATE_ADDRESS_FAILURE) {
      showToast('Failed to save address, please try again later', TOAST_MESSAGE_SEVERITY_ERROR);
    } else {
      setAddressForm(response.response);
      setSelectedAddressId(response.response.addressId);
    }
    setOpen(false);
    setIsLoadingState(false);
  };

  const handleDelete = async () => {
    setIsLoadingState(true);
    const response = selectedAddressId > 0 ? await deleteAddress(authorizationId, selectedAddressId) : null;

    if (response && response.type === DELETE_ADDRESS_FAILURE) {
      showToast('Failed to delete address, please try again later', TOAST_MESSAGE_SEVERITY_ERROR);
    } else {
      setSelectedAddressId(-1);
      setAddressForm(defaultAddress());
      setOpen(false);
    }
    setIsLoadingState(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
        <Button
          color="secondary"
          type="submit"
          variant="contained"
          onClick={handleOpen}
          sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
        >
          Manage Address
        </Button>
      </Box>

      <Modal open={open} onClose={handleClose} keepMounted aria-labelledby="address-modal">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 750,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px'
          }}
        >
          <Typography variant="h6">Manage Address</Typography>
          <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
            <InputLabel>Select Address</InputLabel>
            <Select value={selectedAddressId} onChange={handleAddressChange} label="Select Address">
              <MenuItem key={-1} value={-1}>
                <em>New Address</em>
              </MenuItem>
              {addresses.map(address => {
                const addressParts = [address.addressLineOne, address.city, address.state].filter(Boolean);
                return (
                  addressParts.length > 0 && (
                    <MenuItem key={address.addressId} value={address.addressId}>
                      {addressParts.join(', ')}
                    </MenuItem>
                  )
                );
              })}
            </Select>
          </FormControl>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={4}>
              <FormControl fullWidth sx={{ mt: 0 }} variant="outlined">
                <InputLabel>Rent/Own</InputLabel>
                <Select name="addressType" value={addressForm.addressType} onChange={handleInputChange} label="Rent/Own">
                  {addressStatusOptions.map(opt => (
                    <MenuItem key={opt} value={opt}>
                      {opt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="addressLineOne"
                label="Street Address"
                value={addressForm.addressLineOne}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="addressLineTwo"
                label="Apartment/Suite/Floor"
                value={addressForm.addressLineTwo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="addressLineThree"
                label="Additional Address Info"
                value={addressForm.addressLineThree}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField fullWidth name="city" label="City" value={addressForm.city} onChange={handleInputChange} />
            </Grid>
            <Grid item xs={4}>
              <TextField fullWidth name="county" label="County" value={addressForm.county} onChange={handleInputChange} />
            </Grid>
            {addressForm && addressForm?.country === 'USA' && (
              <Grid item xs={4}>
                <TextField fullWidth name="zipcode" label="Zip Code" value={addressForm.zipcode} onChange={handleInputChange} />
              </Grid>
            )}
          </Grid>
          <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
            <InputLabel>Country</InputLabel>
            <Select name="country" value={addressForm.country} onChange={handleInputChange} label="Country">
              {renderCountryOptions()}
            </Select>
          </FormControl>
          {addressForm && addressForm?.country === 'USA' && (
            <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
              <InputLabel>State</InputLabel>
              <Select name="state" value={addressForm.state} onChange={handleInputChange} label="State">
                {renderStateOptions()}
              </Select>
            </FormControl>
          )}
          {isLoadingState ? (
            <DialogActions sx={{ p: '1.25rem' }}>
              <Spinner />
            </DialogActions>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
              {selectedAddressId > 0 && (
                <Button onClick={handleDelete} sx={{ marginRight: '8px' }} disabled={isLoadingState || selectedAddressId <= 0}>
                  <Typography sx={{ color: '#868686', '&:hover': { color: '#474747' } }}>Delete</Typography>
                </Button>
              )}
              <Button onClick={handleClose} sx={{ marginRight: '8px' }} disabled={isLoadingState}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleSave} disabled={isLoadingState}>
                {selectedAddressId > 0 ? 'Update' : 'Create'}
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default compose(connect(null, { postAddress, updateAddress, deleteAddress, showToast }))(AddressManagementModal);
