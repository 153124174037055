import {
  DELETE_CALENDAR_FAILURE, DELETE_CALENDAR_REQUEST, DELETE_CALENDAR_SUCCESS,
  GET_CALENDAR_PERIODS_FAILURE, GET_CALENDAR_PERIODS_REQUEST, GET_CALENDAR_PERIODS_SUCCESS, 
  GET_CLIENTS_FAILURE, GET_CLIENTS_REQUEST, GET_CLIENTS_SUCCESS,
  GET_CLIENT_CALENDARS_FAILURE, GET_CLIENT_CALENDARS_REQUEST, GET_CLIENT_CALENDARS_SUCCESS,
  GET_CLIENT_CONTACTS_FAILURE, GET_CLIENT_CONTACTS_REQUEST, GET_CLIENT_CONTACTS_SUCCESS,
  GET_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE, GET_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST, GET_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS,
  GET_CLIENT_CONTRACT_FAILURE, GET_CLIENT_CONTRACT_FEE_FAILURE, GET_CLIENT_CONTRACT_FEE_REQUEST,
  GET_CLIENT_CONTRACT_FEE_SUCCESS, GET_CLIENT_CONTRACT_REQUEST, GET_CLIENT_CONTRACT_SLA_FAILURE,
  GET_CLIENT_CONTRACT_SLA_REQUEST, GET_CLIENT_CONTRACT_SLA_SUCCESS, GET_CLIENT_CONTRACT_SUCCESS,
  GET_CLIENT_DETAILS_FAILURE,
  GET_CLIENT_DETAILS_REQUEST,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_GENERAL_NOTES_FAILURE,
  GET_CLIENT_GENERAL_NOTES_REQUEST,
  GET_CLIENT_GENERAL_NOTES_SUCCESS,
  GET_CLIENT_HOMESALE_INFO_FAILURE,
  GET_CLIENT_HOMESALE_INFO_REQUEST,
  GET_CLIENT_HOMESALE_INFO_SUCCESS,
  GET_CLIENT_ISSUES_CHALLENGES_FAILURE, GET_CLIENT_ISSUES_CHALLENGES_REQUEST, GET_CLIENT_ISSUES_CHALLENGES_SUCCESS,
  GET_CLIENT_METADATA_SUCCESS, GET_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST, GET_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS,
  GET_CLIENT_POLICY_NOTES_FAILURE, GET_CLIENT_POLICY_NOTES_REQUEST, GET_CLIENT_POLICY_NOTES_SUCCESS, 
  GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_SUCCESS, 
  GET_CLIENT_SPECIAL_PROCESS_SUCCESS, GET_HOMESALE_CLOSING_COSTS_FAILURE, GET_HOMESALE_CLOSING_COSTS_REQUEST,
  GET_HOMESALE_CLOSING_COSTS_SUCCESS, GET_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE, GET_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST, 
  GET_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS, GET_HOMESALE_GBO_INSPECTIONS_FAILURE, GET_HOMESALE_GBO_INSPECTIONS_REQUEST, 
  GET_HOMESALE_GBO_INSPECTIONS_SUCCESS, GET_HOMESALE_HHG_PROCESS_FAILURE, GET_HOMESALE_HHG_PROCESS_REQUEST, 
  GET_HOMESALE_HHG_PROCESS_SUCCESS, GET_INTERNAL_USERS_FAILURE, GET_INTERNAL_USERS_REQUEST, 
  GET_INTERNAL_USERS_SUCCESS, GET_POLICIES_GENERAL_INFO_REQUEST, GET_POLICIES_GENERAL_INFO__FAILURE,
  GET_POLICIES_GENERAL_INFO__SUCCESS, POST_CLIENT_CALENDAR_FAILURE, 
  POST_CLIENT_CALENDAR_REQUEST, POST_CLIENT_CALENDAR_SUCCESS, POST_GBO_INSPECTIONS_INITIALIZE_FAILURE, POST_GBO_INSPECTIONS_INITIALIZE_REQUEST,
  POST_GBO_INSPECTIONS_INITIALIZE_SUCCESS,
  SET_CURRENT_CLIENT_ID, SET_LOADING_COMPLETE, UPDATE_CLIENT_CALENDAR_FAILURE,
  UPDATE_CLIENT_CALENDAR_REQUEST, UPDATE_CLIENT_CALENDAR_SUCCESS,
  UPDATE_PERIOD_FAILURE, UPDATE_PERIOD_REQUEST, UPDATE_PERIOD_SUCCESS,
} from './types';
import produce from 'immer';

import { SET_FOCUSED_TAB } from 'modules/intake/types';

import { cleanClientDetails, extractManagers, remapClients, sortById } from '../common/utils';

const initialState = {
  isLoading: false,
  isLoadingCalendars: false,
  isPublishingCalendar: false,
  isDeletingCalendar: false,
  clientPolicyNotesLoading: false,
  clientHomesaleGeneralInfoLoading: false,
  clients: null,
  currentClientId: null,
  clientsInfo: {},
  clientContactsLoading: false,
  clientIssuesAndChallengesLoading: false,
  clientGeneralNotesLoading: false,
  clientContactLoading: false,
  clientContractsSLALoading: false,
  clientContractsFeesLoading: false,
  clientContractsCreditsAndRebatesLoading: false,
  clientSpecialProcessesLoading: false,
  clientPoliciesImportantNotesIsLoading: false,
  clientHomesaleEligiblePropertiesLoading: false,
  homesaleClosingCostsLoading: false,
  homesaleGboInspectionsLoading: false,
  homesaleHHGProcessesLoading: false,
  focusedClientTab: null,
  unmappedClients: null,
  managerOptions: null,
  internalUsers: null,
  metadata: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {

    case SET_CURRENT_CLIENT_ID:
      draft.currentClientId = action.response;
      break;

    case GET_INTERNAL_USERS_REQUEST:
    case GET_CLIENTS_REQUEST:
    case GET_CLIENT_DETAILS_REQUEST:
      draft.isLoading = true;
      break;

    case GET_CLIENT_CONTACTS_REQUEST:
      draft.clientContactsLoading = true;
      break;

    case GET_CLIENT_ISSUES_CHALLENGES_REQUEST:
      draft.clientIssuesAndChallengesLoading = true;
      break;

    case GET_CLIENT_GENERAL_NOTES_REQUEST:
      draft.clientGeneralNotesLoading = true;
      break;
    
    case GET_CLIENT_CONTRACT_REQUEST:
      draft.clientContractLoading = true;
      break;

    case GET_CLIENT_CONTRACT_SLA_REQUEST:
      draft.clientContractsSLALoading = true;
      break;

    case GET_CLIENT_CONTRACT_FEE_REQUEST:
      draft.clientContractsFeesLoading = true;
      break;

    case GET_CLIENT_CONTRACT_CREDITS_REBATES_REQUEST:
      draft.clientContractsCreditsAndRebatesLoading = true;
      break;

    case GET_INTERNAL_USERS_FAILURE:
    case GET_CLIENTS_FAILURE:
    case GET_CLIENT_DETAILS_FAILURE:
      draft.isLoading = false;
      break;

    case GET_CLIENT_CONTACTS_FAILURE:
      draft.clientContactsLoading = false;
      break;

    case GET_CLIENT_ISSUES_CHALLENGES_FAILURE:
      draft.clientIssuesAndChallengesLoading = false;
      break;

    case GET_CLIENT_GENERAL_NOTES_FAILURE:
      draft.clientGeneralNotesLoading = false;
      break;

    case GET_CLIENT_CONTRACT_FAILURE:
      draft.clientContractLoading = false;
      break;

    case GET_CLIENT_CONTRACT_SLA_FAILURE:
      draft.clientContractsSLALoading = false;
      break;

    case GET_CLIENT_CONTRACT_FEE_FAILURE:
      draft.clientContractsFeesLoading = false;
      break;

    case GET_CLIENT_CONTRACT_CREDITS_REBATES_FAILURE:
      draft.clientContractsCreditsAndRebatesLoading = false;
      break;

    case GET_CLIENTS_SUCCESS:
      draft.clients = remapClients(action.response);
      draft.unmappedClients = sortById(action.response);
      draft.managerOptions = extractManagers(action.response);
      draft.isLoading = false;
      break;

    case GET_CLIENT_DETAILS_SUCCESS:
      if (!draft.clientsInfo[draft.currentClientId]) draft.clientsInfo[draft.currentClientId] = {};
      draft.clientsInfo[draft.currentClientId].clientDetails = { ...cleanClientDetails(action.response), clientContract: draft.clientContract };
      break;

    case GET_CLIENT_CONTACTS_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientContacts = action.response;
      draft.clientContactsLoading = false;
      break;

    case GET_CLIENT_ISSUES_CHALLENGES_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientIssuesAndChallenges = action.response;
      draft.clientIssuesAndChallengesLoading = false;
      break;

    case GET_CLIENT_GENERAL_NOTES_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientGeneralNotes = action.response;
      draft.clientGeneralNotesLoading = false;
      break;
      
    case GET_CLIENT_CONTRACT_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientContract = action.response;
      draft.clientContractLoading = false;
      break;

    case GET_CLIENT_CONTRACT_SLA_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientContractsSLA = action.response;
      draft.clientContractsSLALoading = false;
      break;

    case GET_CLIENT_CONTRACT_FEE_SUCCESS:
      if (!draft.clientsInfo[action.context.clientId]) draft.clientsInfo[action.context.clientId] = {};
      draft.clientsInfo[action.context.clientId].clientContractsFees = action.response;
      draft.clientContractsFeesLoading = false;
      break;

    case GET_CLIENT_CONTRACT_CREDITS_REBATES_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientContractsCreditsAndRebates = action.response;
      draft.clientContractsCreditsAndRebatesLoading = false;
      break;
    
    case SET_FOCUSED_TAB:
      draft.focusedClientTab = action.response;
      break;
    
    case GET_INTERNAL_USERS_SUCCESS:
      draft.internalUsers = action.response;
      draft.isLoading = false;
      break;

    case GET_CLIENT_SPECIAL_PROCESS_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientSpecialProcesses = action.response;
      draft.clientSpecialProcessesLoading = false;
      break;    
      
    case GET_CLIENT_SPECIAL_PROCESS_CATEGORIES_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientSpecialProcessCategories = action.response;
      break;

    case GET_CLIENT_POLICIES_IMPORTANT_NOTES_REQUEST:
      draft.clientPoliciesImportantNotesIsLoading = true;
      break;  

    case GET_CLIENT_POLICIES_IMPORTANT_NOTES_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientPoliciesImportantNotes = action.response;
      draft.clientPoliciesImportantNotesIsLoading = false;
      break;

    case DELETE_CALENDAR_REQUEST:
      draft.isDeletingCalendar = true;
      break;

    case DELETE_CALENDAR_SUCCESS:
    case DELETE_CALENDAR_FAILURE:
      draft.isDeletingCalendar = false;
      break;

    case POST_CLIENT_CALENDAR_REQUEST:
      draft.isPublishingCalendar = true;
      break;

    case POST_CLIENT_CALENDAR_SUCCESS:
    case POST_CLIENT_CALENDAR_FAILURE:
      draft.isPublishingCalendar = false;
      break;

    case UPDATE_PERIOD_REQUEST:
    case UPDATE_CLIENT_CALENDAR_REQUEST:
    case GET_CLIENT_CALENDARS_REQUEST:
    case GET_CALENDAR_PERIODS_REQUEST:
      draft.isLoadingCalendars = true;
      break;
    
    case UPDATE_PERIOD_FAILURE:
    case UPDATE_PERIOD_SUCCESS:
    case UPDATE_CLIENT_CALENDAR_FAILURE:
    case UPDATE_CLIENT_CALENDAR_SUCCESS:
    case GET_CLIENT_CALENDARS_FAILURE:
    case GET_CALENDAR_PERIODS_FAILURE:
      draft.isLoadingCalendars = false;
      break;

    case GET_CLIENT_CALENDARS_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientCalendars = action.response;
      draft.isLoadingCalendars = false;
      break;

    case GET_CLIENT_POLICY_NOTES_REQUEST:
      draft.clientPolicyNotesLoading = true;
      break;

    case GET_CLIENT_POLICY_NOTES_FAILURE:
      draft.clientPolicyNotesLoading = false;
      break;

    case GET_CLIENT_POLICY_NOTES_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientPolicyNotes = action.response;
      draft.clientPolicyNotesLoading = false;
      break;
    
    case GET_CALENDAR_PERIODS_SUCCESS:
      draft.clientsInfo[draft.currentClientId].calendarPeriods = action.response.clientPayrollPeriods;
      draft.isLoadingCalendars = false;
      break;

    case GET_CLIENT_HOMESALE_INFO_REQUEST:
      draft.clientHomesaleGeneralInfoLoading = true;
      break;

    case GET_CLIENT_HOMESALE_INFO_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientHomesaleGeneralInfo = action.response;
      draft.clientHomesaleGeneralInfoLoading = false;
      break;

    case GET_CLIENT_HOMESALE_INFO_FAILURE:
      draft.clientHomesaleGeneralInfoLoading = false;
      break;

    case GET_HOMESALE_ELIGIBLE_PROPERTIES_REQUEST:
      draft.clientHomesaleEligiblePropertiesLoading = true;
      break;

    case GET_HOMESALE_ELIGIBLE_PROPERTIES_FAILURE:
      draft.clientHomesaleEligiblePropertiesLoading = false;
      break;

    case GET_HOMESALE_ELIGIBLE_PROPERTIES_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientHomesaleEligibleProperties = action.response;
      draft.clientHomesaleEligiblePropertiesLoading = false;
      break;

    case GET_HOMESALE_CLOSING_COSTS_REQUEST:
      draft.homesaleClosingCostsLoading = true;
      break;

    case GET_HOMESALE_CLOSING_COSTS_FAILURE:
      draft.homesaleClosingCostsLoading = false;
      break;

    case GET_HOMESALE_CLOSING_COSTS_SUCCESS:
      draft.clientsInfo[draft.currentClientId].homesaleClosingCosts = action.response;
      draft.homesaleClosingCostsLoading = false;
      break;

    case GET_HOMESALE_GBO_INSPECTIONS_REQUEST:
      draft.homesaleGboInspectionsLoading = true;
      break;

    case GET_HOMESALE_GBO_INSPECTIONS_FAILURE:
      draft.homesaleGboInspectionsLoading = false;
      break;

    case GET_HOMESALE_GBO_INSPECTIONS_SUCCESS:
      draft.clientsInfo[draft.currentClientId].clientHomesaleGboInspections = action.response;
      draft.homesaleGboInspectionsLoading = false;
      break;

    case GET_POLICIES_GENERAL_INFO_REQUEST:
      draft.policiesGeneralInfoLoading = true;
      break;

    case GET_POLICIES_GENERAL_INFO__FAILURE:
      draft.policiesGeneralInfoLoading = false;
      break;

    case GET_POLICIES_GENERAL_INFO__SUCCESS:
      draft.clientsInfo[draft.currentClientId].policiesGeneralInfo = action.response;
      draft.policiesGeneralInfoLoading = false;
      break;

    case GET_CLIENT_METADATA_SUCCESS:
      draft.metadata = action.response;
      break;
      
    case POST_GBO_INSPECTIONS_INITIALIZE_REQUEST:
      draft.homesaleGboInspectionsLoading = true;
      break;

    case POST_GBO_INSPECTIONS_INITIALIZE_SUCCESS: 
      draft.homesaleGboInspectionsLoading = false;
      break;

    case POST_GBO_INSPECTIONS_INITIALIZE_FAILURE:
      draft.homesaleGboInspectionsLoading = false;
      break;

    case GET_HOMESALE_HHG_PROCESS_REQUEST:
      draft.homesaleHHGProcessesLoading = true;
      break;

    case GET_HOMESALE_HHG_PROCESS_FAILURE:
      draft.homesaleHHGProcessesLoading = false;
      break;

    case GET_HOMESALE_HHG_PROCESS_SUCCESS:
      draft.clientsInfo[draft.currentClientId].homesaleHHGProcesses = action.response;
      draft.homesaleHHGProcessesLoading = false;
      break;

    case SET_LOADING_COMPLETE:
      draft.isLoading = false;
      break;

    default:
      break;
  }

  return draft;
}, initialState);