/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { Button, Link, MenuItem, TextField, makeStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Dialog from 'modules/common/dialog.component';
import Spinner from 'modules/common/spinner.component';
import { formatDateForInput } from 'utilities/common';


const useStyles = makeStyles((theme) => ({
  mobileNum: {
    marginRight: 10,
    minWidth: 150,
  },
  email: {
    minWidth: 175,
    marginRight: 10,
  },
  countryCode: {
    maxWidth: 145,
    marginRight: 10,
  }
}));

function SendSurveyDialog(props) {
  const classes = useStyles(props);

  const {
    onSubmit,
    onCancel,
    maxLength,
    disabled,
    authorization,
    ...rest
  } = props;

  const [surveyStatus, setSurveyStatus] = useState('');
  const [surveyStatusDate, setSurveyStatusDate] = useState(null);
  const [surveyTrigger, setSurveyTrigger] = useState('');
  const [sendingSurvey, setSendingSurvey] = useState(false);

  const surveyStatusOptions = ['Not Sent', 'Sent', 'Reminder 1 Sent', 'Reminder 2 Sent', 'No Response - Please Follow Up', 'Response Received']

    const handleSubmit = async () => {
      const record = props.authorization;
  
      setSendingSurvey(true);
      if (record) {
        setSendingSurvey(false);
        onCancel();
        }
    };

  return (
    <Dialog
      {...rest}
      title="Send Survey"
      content={
        <div>
          {props.authorization && (
            <div>
              <div>
                <TextField
                    type="text"
                    name="surveyTrigger"
                    className={classes.email}
                    label="Survey Trigger"
                    variant="outlined"
                    value={surveyTrigger}
                    onChange={(e) => setSurveyTrigger(e.target.value)}
                    />
                <TextField
                    select
                    label="Survey Status"
                    className={classes.email}
                    value={surveyStatus}
                    onChange={(e) => { setSurveyStatus(e.target.value); }}
                    SelectProps={{
                    autoWidth: true,
                    }}
                    variant="outlined"
                >
                    {surveyStatusOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                    ))}
                </TextField>
                { surveyStatus === 'Sent' &&
                <>
                    <TextField
                    type="date"
                    name="surveyStatusDate"
                    className={classes.mobileNum}
                    label="Sent Date"
                    variant="outlined"
                    value={formatDateForInput(surveyStatusDate)}
                    onChange={(e) => setSurveyStatusDate(e.target.value)}
                    />
                </>
                }
                { surveyStatus === 'Reminder 1 Sent' &&
                <>
                    <TextField
                    type="date"
                    name="surveyStatusDate"
                    className={classes.mobileNum}
                    label="Reminder 1 Sent Date"
                    variant="outlined"
                    value={formatDateForInput(surveyStatusDate)}
                    onChange={(e) => setSurveyStatusDate(e.target.value)}
                    />
                </>
                }
                { surveyStatus === 'Reminder 2 Sent' &&
                <>
                    <TextField
                    type="date"
                    name="surveyStatusDate"
                    className={classes.mobileNum}
                    label="Reminder 2 Sent Date"
                    variant="outlined"
                    value={formatDateForInput(surveyStatusDate)}
                    onChange={(e) => setSurveyStatusDate(e.target.value)}
                    />
                </>
                }
                { surveyStatus === 'Response Received' &&
                <>
                <div>
                    <TextField
                    type="date"
                    name="surveyStatusDate"
                    className={classes.mobileNum}
                    label="Response Received Date"
                    variant="outlined"
                    value={formatDateForInput(surveyStatusDate)}
                    onChange={(e) => setSurveyStatusDate(e.target.value)}
                    />
                    <Link href="#" color="secondary">
                        View Survey Results
                    </Link>
                </div>
                </>
                }
              </div>
            </div>
          )}
          {(sendingSurvey) && (
            <Spinner />
          )}
        </div>
      }
      actions={
        <>
          <Button disabled={disabled} onClick={onCancel} variant="outlined" color="primary">Cancel</Button>
          <Button disabled={sendingSurvey} onClick={handleSubmit} variant="contained" color="primary">Send Survey</Button>
        </>
      }
    />
  );
}

SendSurveyDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  authorization: PropTypes.object,
};

export default compose(
  connect(null, {
    showToast,
  }),
)(SendSurveyDialog);