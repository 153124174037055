/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, Paper, Typography } from '@mui/material';
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS } from 'styles/theme';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ReportDetailsCard from './reportDetailsCard.component';
import FilePreviewCard from './filePreview.component';
import { getVoucherSummariesByAuth } from 'modules/expenses/expenses.actions';
import { GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE } from 'modules/expenses/types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';


const styles = theme => ({
  container: {
    height: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
    },
  },
  headerFixed: {
    zIndex: 4,
  },
  estimateHeaderFixed: {
    paddingTop: '115px',
    zIndex: 2,
  },
});

const ExpenseDetails = props => {
  const { details, clientInfo, location, vendors, inspectors, titleReps, brokerAgents } = props;
  const { submittedExpenses, summaryExpenseLines } = props.details?.authExpenses
  const { employee, authClientPolicies } = props.details?.authDetails;

  const expenseInfo = submittedExpenses ? submittedExpenses.find((s) => s.expenseId === parseInt(props.expenseId)) : null;
  
  const isReadOnly = expenseInfo?.isExpenseApproved ?? false;

  const [voucherSummaries, setVoucherSummaries] = useState(null);
  const [voucher, setVoucher] = useState(null);
  
  useEffect(() => {
  
      async function fetchData() {          
            const resp = await props.getVoucherSummariesByAuth(expenseInfo.authorizationId);
            if(resp.type === GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE) {
              props.showToast('Failed to get voucher summary.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
            }
            else{
              setVoucherSummaries(resp.response);
            }
           
          }

    if(summaryExpenseLines && summaryExpenseLines.length > 0 && summaryExpenseLines[0].voucherId)
    {
      fetchData();
    }
    if(voucherSummaries && voucherSummaries.length > 0)
      {
        setVoucher(voucherSummaries.filter(sum => sum.voucherId === summaryExpenseLines[0].voucherId));
      }
    }, [voucherSummaries, summaryExpenseLines]);

  return (
    <Box>
      <Paper square className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', width: '100%' }}>
          <Box sx={{ paddingLeft: '100px' }}>&nbsp;</Box>
          { voucher && voucher.length > 0 &&
            <>
              <Box>
                <Button onClick={() => props.history.push(`/authorizations/${expenseInfo.authorizationId}/expenses/${expenseInfo.expenseId}/vouchers/${voucher.voucherId}`)}>View Voucher</Button>
              </Box>
            </>
          }
          <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '10px' }}>
                <InfoBox label="Authorization Name" value={`${employee?.firstName} ${employee?.lastName}`} />
                <InfoBox label="Policy" value={authClientPolicies?.clientPolicyCode} />
                <InfoBox label="Status" value="Active" />
                <InfoBox label="Move Cap" value="$5,000,000,000" />
                <InfoBox label="Budget Remaining" value="$4,999,998,001" />
              </Box>
            </Box>
          </Box>
          <Box sx={{ color: 'green', padding: '10px' }}>Repay Agreement Received</Box>
        </Box>
      </Paper>
      <Box sx={{ paddingTop: '110px', margin: '16px', marginTop: '0px' }}>
        <ReportDetailsCard expenseInfo={expenseInfo} expenseLines={summaryExpenseLines} authId={props.authId} expenseCodes={props.expenseCodes} history={props.history} isReadOnly={isReadOnly}/>
      </Box>
      <Box sx={{ paddingTop: '10px', margin: '16px', marginTop: '0px' }}>
        <FilePreviewCard 
          details={details} 
          authId={props.authId} 
          clientInfo={clientInfo} 
          history={props.history} 
          location={location} 
          match={props.match}
          brokerAgents={brokerAgents}
          vendors={vendors}
          inspectors={inspectors}
          titleReps={titleReps}
        />
      </Box>
    </Box>
  );
};

const InfoBox = ({ label, value }) => (
  <Box>
    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
        {label}
      </Typography>
    </Box>
    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
        {value}
      </Typography>
    </Box>
  </Box>
);

const mapStateToProps = state => ({});

export default compose(withStyles(styles), connect(mapStateToProps, { getVoucherSummariesByAuth }))(ExpenseDetails);
