import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';

import {
  Box, Button, Card, CardContent, Checkbox, Dialog, DialogActions, Fab, FormControl, FormControlLabel, Grid, Stack, TextField, Tooltip, Typography 
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { formatDateForInput } from 'utilities/common';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';
import { postInternServices, updateInternServices, getInternServices } from 'modules/authorizations/store/actions/canidateTrip.actions';
import { POST_INTERN_SERVICES_FAILURE, UPDATE_INTERN_SERVICES_FAILURE, GET_INTERN_SERVICES_FAILURE } from 'modules/authorizations/store/types/canidateTripTypes';
import AdvancedSearch from 'modules/common/advancedSearch.component';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));


const EditCardModal = (props) => {
  const { open, onSubmit, isLoading, onClose, internServices, brokerAgentSearchInfo } = props;
  const classes = useStyles();

    const [initiationDate, setInitiationDate] = useState(internServices ? internServices.initiationDate : null)
    const [interviewStartDate, setInterviewStartDate] = useState(internServices ? internServices.interviewStartDate : null);
    const [interviewEndDate, setInterviewEndDate] = useState(internServices ? internServices.interviewEndDate : null);
    const [areaTour, setAreaTour] = useState(internServices ? internServices.areaTour : false);
    const [areaTourInitiationDate, setAreaTourInitiationDate] = useState(internServices ? internServices.areaTourInitiatedDate : null);
    const [areaTourCanceledDate, setAreaTourCanceledDate] = useState(internServices ? internServices.areaTourCanceledDate : null);
    const [areaTourCompletedDate, setAreaTourCompletedDate] = useState(internServices ? internServices.areaTourCompletedDate : null);
    const [areaTourAgentId, setAreaTourAgentId] = useState(internServices ? internServices.areaTourAgentId : 0);
    const [areaTourAgentName, setAreaTourAgentName] = useState(internServices ? internServices.areaTourAgentName : '');
    const [notes, setNotes] = useState(internServices ? internServices.notes : '');


  const onSubmitModal = (e) => {
    e.preventDefault();

    const values = {
        initiationDate,
        interviewStartDate,
        interviewEndDate,
        areaTour,
        areaTourInitiationDate,
        areaTourCanceledDate,
        areaTourCompletedDate,
        areaTourAgentId,
        areaTourAgentName,
        notes
    };
    onSubmit(values);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={onSubmitModal} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Tooltip title="Intern Summary" key="InternSummary" placement="right">
              <FontAwesomeIcon icon={['fas', 'fa-id-card']} size="3x" />
            </Tooltip>
            <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
              Intern Services Overview
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem',
            }}
          >
            <Grid container spacing={{ xs: 2, md: 3, lg: 5 }} sx={{ padding: '30px', paddingTop: '0px' }}>
                  <Grid item >
                    <div>
                      <TextField
                        key={'initiationDate'}
                        label={'Initiation Date'}
                        name={'initiationDate'}
                        required={false}
                        disabled={false}
                        value={formatDateForInput(initiationDate)}
                        sx={{ maxWidth: '360px' }}
                        margin="dense"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setInitiationDate(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item >
                    <div>
                      <TextField
                        key={'interviewStartDate'}
                        label={'Internship Start Date'}
                        name={'interviewStartDate'}
                        required={false}
                        disabled={false}
                        value={formatDateForInput(interviewStartDate)}
                        sx={{ maxWidth: '360px' }}
                        margin="dense"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setInterviewStartDate(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item >
                    <div>
                      <TextField
                        key={'interviewEndDate'}
                        label={'Internship End Date'}
                        name={'interviewEndDate'}
                        required={false}
                        disabled={false}
                        value={formatDateForInput(interviewEndDate)}
                        sx={{ maxWidth: '360px' }}
                        margin="dense"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setInterviewEndDate(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                        <FormControlLabel
                          key={'areaTour'}
                          name={'areaTour'}
                          label={'Area Tour'}
                          labelPlacement="start"
                          control={<Checkbox />}
                          required={false}
                          disabled={false}
                          checked={areaTour}
                          variant="standard"
                          margin="dense"
                          sx={{ marginTop: '6px', minWidth: '120px', maxWidth: '360px' }}
                          onClick={(e) => setAreaTour(e.target.checked ? true : false)}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  { areaTour &&
                    <>
                      <Grid item >
                        <div>
                          <TextField
                            key={'areaTourInitiationDate'}
                            label={'Area Tour Initiation Date'}
                            name={'areaTourInitiationDate'}
                            required={false}
                            disabled={false}
                            value={formatDateForInput(areaTourInitiationDate)}
                            sx={{ maxWidth: '360px' }}
                            margin="dense"
                            variant="standard"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setAreaTourInitiationDate(e.target.value)}
                          />
                        </div>
                      </Grid>
                      <Grid item >
                        <div>
                          <TextField
                            key={'areaTourCanceledDate'}
                            label={'Area Tour Canceled Date'}
                            name={'areaTourCanceledDate'}
                            required={false}
                            disabled={false}
                            value={formatDateForInput(areaTourCanceledDate)}
                            sx={{ maxWidth: '360px' }}
                            margin="dense"
                            variant="standard"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setAreaTourCanceledDate(e.target.value)}
                          />
                        </div>
                      </Grid>
                      <Grid item >
                        <div>
                          <TextField
                            key={'areaTourCompletedDate'}
                            label={'Area Tour Completed Date'}
                            name={'areaTourCompletedDate'}
                            required={false}
                            disabled={false}
                            value={formatDateForInput(areaTourCompletedDate)}
                            sx={{ maxWidth: '360px' }}
                            margin="dense"
                            variant="standard"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setAreaTourCompletedDate(e.target.value)}
                          />
                        </div>
                      </Grid>
                      <Grid item >
                        <div>
                          <AdvancedSearch 
                            labelText={'Area Tour Agent'} 
                            displayValueAccessorKey={'agentName'} 
                            saveIdValueAccessorKey={'brokerId'} 
                            searchingCriteria={brokerAgentSearchInfo.searchingColumns} 
                            searchingCriteriaExtensions={brokerAgentSearchInfo.searchingColumnExtensions} 
                            searchingRows={brokerAgentSearchInfo.searchingRows} 
                            value={areaTourAgentName} 
                            id={areaTourAgentId}
                            setIdValue={(id, val) => { setAreaTourAgentName(val); setAreaTourAgentId(id); }} 
                            />
                        </div>
                      </Grid>
                    </>
                  }
                <Grid item >
                    <div>
                        <TextField
                        key={'notes'}
                        label={'Notes'}
                        name={'notes'}
                        required={false}
                        disabled={false}
                        value={notes}
                        sx={{ width: '1000px' }}
                        margin="dense"
                        variant="standard"
                        rows={4}
                        multiline
                        type="text"
                        onChange={(e) => setNotes(e.target.value)}
                        />
                    </div>
                </Grid>
            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained">Save</Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

const InternSummaryCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [editingModalOpen, setEditingModalOpen] = useState(false);

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const { authId, internServices, brokerAgentSearchInfo, hideEdit, status } = props;

  const updateInternOverview = async (tab, values) => {
      setIsLoading(true);
      let newVals = { ...internServices };
      newVals.status = status;
      newVals.authorizationId = authId;
      newVals.initiationDate = values.initiationDate;
      newVals.interviewStartDate = values.interviewStartDate;
      newVals.interviewEndDate = values.interviewEndDate;
      newVals.areaTour = values.areaTour;
      newVals.areaTourInitiatedDate = values.areaTourInitiationDate;
      newVals.areaTourCanceledDate = values.areaTourCanceledDate;
      newVals.areaTourCompletedDate = values.areaTourCompletedDate;
      newVals.areaTourAgentId = values.areaTourAgentId;
      newVals.areaTourAgentName = values.areaTourAgentName;
      newVals.notes = values.notes;
          
      if (internServices && internServices.length !== 0) {
        const resp = await props.updateInternServices(newVals);
        if (resp.type === UPDATE_INTERN_SERVICES_FAILURE) {
          props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else { 
          const getResp = await props.getInternServices(authId);
          if (getResp.type === GET_INTERN_SERVICES_FAILURE) {
            return false;
          } else {
            setIsLoading(false);
            return true; 
          }
        }
      } else {
        values.authorizationId = authId;
  
        const resp = await props.postInternServices(values);
        if (resp.type === POST_INTERN_SERVICES_FAILURE) {
          props.showToast('Failed to update intern services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else { 
          setIsLoading(false);
          return true; 
        }
      }
    };
  
  const handleSaveEdits = async (values) => {
    // Send update call to parent to update and refresh values.
    const resp = await updateInternOverview(1, values);
    if (!resp) {
      showToast('Failed to update card data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    } 
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };

  return (
    <ThemeProvider theme={theme}>
      { internServices &&
        <>
        <Card className={classes.card} sx={{ marginTop: '16px' }}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Estimates" key="Estimates" placement="right">
                  <FontAwesomeIcon icon={['fas', 'fa-id-card']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Intern Services Overview
                </Typography>
              </div>
              {!hideEdit &&
              <>
                <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                  <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                </Fab>
              </>
              }

            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={2} columns={{ xs: 4, sm: 4, md: 4, xl: 4 }}>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Initiation Date
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { internServices && internServices.initiationDate ? new Date(internServices.initiationDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Internship Start Date
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { internServices && internServices.interviewStartDate ? new Date(internServices.interviewStartDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Internship End Date
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { internServices && internServices.interviewEndDate ? new Date(internServices.interviewEndDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Area Tour
                        </Typography>
                      </div>
                      <div>
                        <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                          <FormControlLabel
                            key={'internServices.areaTour'}
                            name={'internServices.areaTour'}
                            control={<Checkbox />}
                            checked={internServices.areaTour ? internServices.areaTour : false}
                            disabled={true}
                            variant="standard"
                            margin="dense"
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </Grid>
                  { internServices.areaTour &&
                    <>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Area Tour Initiation Date
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { internServices && internServices.areaTourInitiatedDate ? new Date(internServices.areaTourInitiatedDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Area Tour Canceled Date
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { internServices && internServices.areaTourCanceledDate ? new Date(internServices.areaTourCanceledDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Area Tour Completed Date
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { internServices && internServices.areaTourCompletedDate ? new Date(internServices.areaTourCompletedDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Area Tour Agent
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { internServices && internServices.areaTourAgentName ? internServices.areaTourAgentName : <div>&mdash;</div>}
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                    </>}
                    <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Notes
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { internServices && internServices.notes ? internServices.notes : <div>&mdash;</div>}
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
        </>
      }
      {editingModalOpen && (
        <EditCardModal
          classes={classes}
          tab={0}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={() => setEditingModalOpen(false)}
          onSubmit={handleSaveEdits}
          internServices={internServices}
          brokerAgentSearchInfo={brokerAgentSearchInfo}
        />
      )}
    </ThemeProvider>
  );

};

export default compose(
  connect(null, {
    updateInternServices,
    postInternServices,
    getInternServices,
    showToast,
  }),
)(InternSummaryCard);