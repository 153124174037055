import { GET_SURVEY_QUESTIONS_FAILURE, GET_SURVEY_QUESTIONS_REQUEST, GET_SURVEY_QUESTIONS_SUCCESS,
  UPDATE_SURVEY_QUESTION_FAILURE, UPDATE_SURVEY_QUESTION_REQUEST, UPDATE_SURVEY_QUESTION_SUCCESS,
  GET_SURVEY_QUESTION_TRIGGERS_FAILURE, GET_SURVEY_QUESTION_TRIGGERS_REQUEST, GET_SURVEY_QUESTION_TRIGGERS_SUCCESS,
  POST_SURVEY_QUESTION_TRIGGER_FAILURE, POST_SURVEY_QUESTION_TRIGGER_REQUEST, POST_SURVEY_QUESTION_TRIGGER_SUCCESS,
  UPDATE_SURVEY_QUESTION_TRIGGER_FAILURE, UPDATE_SURVEY_QUESTION_TRIGGER_REQUEST, UPDATE_SURVEY_QUESTION_TRIGGER_SUCCESS,
  DELETE_SURVEY_QUESTION_TRIGGER_FAILURE, DELETE_SURVEY_QUESTION_TRIGGER_REQUEST, DELETE_SURVEY_QUESTION_TRIGGER_SUCCESS,
  GET_SURVEY_QUESTION_VARIATIONS_FAILURE, GET_SURVEY_QUESTION_VARIATIONS_REQUEST, GET_SURVEY_QUESTION_VARIATIONS_SUCCESS,
  POST_SURVEY_QUESTION_VARIATION_FAILURE, POST_SURVEY_QUESTION_VARIATION_REQUEST, POST_SURVEY_QUESTION_VARIATION_SUCCESS,
  UPDATE_SURVEY_QUESTION_VARIATION_FAILURE, UPDATE_SURVEY_QUESTION_VARIATION_REQUEST, UPDATE_SURVEY_QUESTION_VARIATION_SUCCESS,
  DELETE_SURVEY_QUESTION_VARIATION_FAILURE, DELETE_SURVEY_QUESTION_VARIATION_REQUEST, DELETE_SURVEY_QUESTION_VARIATION_SUCCESS,
  SET_SURVEY_LOADING_COMPLETE, GET_SURVEY_QUESTION_BY_ID_FAILURE, GET_SURVEY_QUESTION_BY_ID_REQUEST, GET_SURVEY_QUESTION_BY_ID_SUCCESS, SET_QUESTION_ID,
  GET_SURVEY_QUESTION_RESPONSES_REQUEST, GET_SURVEY_QUESTION_RESPONSES_SUCCESS, GET_SURVEY_QUESTION_RESPONSES_FAILURE,
  GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_FAILURE, GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_REQUEST, GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_SUCCESS
 } from './surveys.types';
import { CALL_INTAKE_V2_API } from 'middleware/intakeV2Api';

export const setQuestionId = id => ({
  type: SET_QUESTION_ID,
  response: id
});

export const setSurveyLoadingComplete = () => ({
  type: SET_SURVEY_LOADING_COMPLETE
});

export const getSurveyQuestions = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_SURVEY_QUESTIONS_REQUEST, GET_SURVEY_QUESTIONS_SUCCESS, GET_SURVEY_QUESTIONS_FAILURE],
      authenticated: true,
      endpoint: 'api/surveys',
      method: 'GET',
    },
  };
};

export const getSurveyQuestionById = (surveyQuestionId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_SURVEY_QUESTION_BY_ID_REQUEST, GET_SURVEY_QUESTION_BY_ID_SUCCESS, GET_SURVEY_QUESTION_BY_ID_FAILURE],
      authenticated: true,
      endpoint: `api/surveys/GetById/${surveyQuestionId}`,
      method: 'GET',
    },
  };
};

export const updateSurveyQuestion = values => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_SURVEY_QUESTION_REQUEST, UPDATE_SURVEY_QUESTION_SUCCESS, UPDATE_SURVEY_QUESTION_FAILURE],
      authenticated: true,
      endpoint: `api/surveys`,
      method: 'PUT',
      payload: values
    }
  };
};

export const getSurveyQuestionTriggers = (surveyQuestionId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_SURVEY_QUESTION_TRIGGERS_REQUEST, GET_SURVEY_QUESTION_TRIGGERS_SUCCESS, GET_SURVEY_QUESTION_TRIGGERS_FAILURE],
      authenticated: true,
      endpoint: `api/surveys/GetTriggers/${surveyQuestionId}`,
      method: 'GET',
    },
  };
};

export const postSurveyQuestionTrigger = values => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_SURVEY_QUESTION_TRIGGER_REQUEST, POST_SURVEY_QUESTION_TRIGGER_SUCCESS, POST_SURVEY_QUESTION_TRIGGER_FAILURE],
      authenticated: true,
      endpoint: 'api/surveys/triggers',
      method: 'POST',
      payload: values
    }
  };
};

export const updateSurveyQuestionTrigger = values => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_SURVEY_QUESTION_TRIGGER_REQUEST, UPDATE_SURVEY_QUESTION_TRIGGER_SUCCESS, UPDATE_SURVEY_QUESTION_TRIGGER_FAILURE],
      authenticated: true,
      endpoint: `api/surveys/triggers`,
      method: 'PUT',
      payload: values
    }
  };
};

export const deleteSurveyQuestionTrigger = surveyQuestionTriggerId => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_SURVEY_QUESTION_TRIGGER_REQUEST, DELETE_SURVEY_QUESTION_TRIGGER_SUCCESS, DELETE_SURVEY_QUESTION_TRIGGER_FAILURE],
      authenticated: true,
      endpoint: `api/surveys/triggers/${surveyQuestionTriggerId}`,
      method: 'DELETE'
    }
  };
};

export const getSurveyQuestionVariations = (surveyQuestionId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_SURVEY_QUESTION_VARIATIONS_REQUEST, GET_SURVEY_QUESTION_VARIATIONS_SUCCESS, GET_SURVEY_QUESTION_VARIATIONS_FAILURE],
      authenticated: true,
      endpoint: `api/surveys/GetVariations/${surveyQuestionId}`,
      method: 'GET',
    },
  };
};

export const postSurveyQuestionVariation = values => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_SURVEY_QUESTION_VARIATION_REQUEST, POST_SURVEY_QUESTION_VARIATION_SUCCESS, POST_SURVEY_QUESTION_VARIATION_FAILURE],
      authenticated: true,
      endpoint: 'api/surveys/variations',
      method: 'POST',
      payload: values
    }
  };
};

export const updateSurveyQuestionVariation = values => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_SURVEY_QUESTION_VARIATION_REQUEST, UPDATE_SURVEY_QUESTION_VARIATION_SUCCESS, UPDATE_SURVEY_QUESTION_VARIATION_FAILURE],
      authenticated: true,
      endpoint: `api/surveys/variations`,
      method: 'PUT',
      payload: values
    }
  };
};

export const deleteSurveyQuestionVariation = surveyQuestionVariationId => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_SURVEY_QUESTION_VARIATION_REQUEST, DELETE_SURVEY_QUESTION_VARIATION_SUCCESS, DELETE_SURVEY_QUESTION_VARIATION_FAILURE],
      authenticated: true,
      endpoint: `api/surveys/variations/${surveyQuestionVariationId}`,
      method: 'DELETE'
    }
  };
};

// Survey responses
export const getSurveyQuestionResponses = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_SURVEY_QUESTION_RESPONSES_REQUEST, GET_SURVEY_QUESTION_RESPONSES_SUCCESS, GET_SURVEY_QUESTION_RESPONSES_FAILURE],
      authenticated: true,
      endpoint: 'api/surveys/responses',
      method: 'GET',
    },
  };
};

export const getSurveyQuestionResponsesByAuth = authorizationId => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_REQUEST, GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_SUCCESS, GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_FAILURE],
      authenticated: true,
      endpoint: `api/surveys/responses/GetByAuth/${authorizationId}`,
      method: 'GET',
    },
  };
};
