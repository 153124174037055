import { combineReducers } from 'redux';

import apiError from 'middleware/apiError.reducer';
import auth from 'modules/auth/auth.reducer';
import authorizations from 'modules/authorizations/store/reducers/authorizationRoot.reducer';
import benefit from 'modules/benefit/benefit.reducer';
import clients from 'modules/clients/clients.reducer';
import common from 'modules/common/common.reducer';
import expenses from 'modules/expenses/expenses.reducer';
import intake from 'modules/intake/intake.reducer';
import layout from 'modules/layout/layout.reducer';
import selfService from 'modules/selfService/selfService.reducer';
import recurringPayables from 'modules/recurringPayables/store/recurringPayables.reducer';
import surveys from 'modules/surveys/surveys.reducer';

const rootReducer = combineReducers({
  auth,
  authorizations,
  intake,
  common,
  layout,
  benefit,
  selfService,
  apiError,
  clients,
  expenses,
  recurringPayables,
  surveys,
});

export default rootReducer;
