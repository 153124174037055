import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postGlobalInfo, updateGlobalInfo } from 'modules/authorizations/store/actions/global.actions';
import { showToast } from 'modules/layout/layout.actions';
import React, { useEffect, useState } from 'react';

import Spinner from 'modules/common/spinner.component';
import {
  deleteHhgVehicles,
  getHhgVehicles,
  postHhgVehicles,
  updateHhgSegment,
  updateHhgVehicles
} from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import MultiRowForm from 'modules/common/multiRowForm.component';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { UPDATE_HHG_ESTIMATE_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';

const CarShipmentVehiclesCrudTable = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { hhgSegmentVehicles, segmentId, authorizationId, isReadOnly, segmentInfo, updateHhgSegment } = props;

  const formFields = [
    {
      name: 'hhgSegmentVehicleId',
      type: 'hidden'
    },
    {
      name: 'hhgSegmentId',
      type: 'hidden'
    },
    {
      name: 'authorizationId',
      type: 'hidden'
    },
    { name: 'makeOfCar', type: 'text', label: 'Make of Car' },
    { name: 'modelOfCar', type: 'text', label: 'Model of Car' }
  ];
  const formLabel = 'Vehicles';
  const addRowLabel = 'Add Vehicle';
  const header = { title: 'Vehicles', iconPath: ['fas', 'car'] };

  const createNewRowFunc = () => {
    return {
      hhgSegmentVehicleId: 0,
      hhgSegmentId: segmentId,
      authorizationId: authorizationId,
      makeOfCar: '',
      modelOfCar: ''
    };
  };

  const saveForm = async formValues => {
    setIsLoading(true);

    formValues = formValues ?? [];

    const deletedVehicles = hhgSegmentVehicles.filter(
      vehicle => !formValues.some(formValue => formValue.hhgSegmentVehicleId === vehicle.hhgSegmentVehicleId)
    );

    const newVehicles = formValues.filter(formValue => formValue.hhgSegmentVehicleId === 0);

    const updatedVehicles = formValues.filter(
      formValue =>
        formValue.hhgSegmentVehicleId !== 0 &&
        hhgSegmentVehicles.some(
          vehicle =>
            vehicle.hhgSegmentVehicleId === formValue.hhgSegmentVehicleId &&
            (vehicle.makeOfCar !== formValue.makeOfCar || vehicle.modelOfCar !== formValue.modelOfCar)
        )
    );

    try {
      for (const vehicle of deletedVehicles) {
        await props.deleteHhgVehicles(vehicle.hhgSegmentVehicleId);
      }

      for (const vehicle of newVehicles) {
        await props.postHhgVehicles(vehicle);
      }

      for (const vehicle of updatedVehicles) {
        await props.updateHhgVehicles(vehicle);
      }

      await props.getHhgVehicles(authorizationId);
    } catch (error) {
      console.error('Error saving form:', error);
      props.showToast('Failed to update Vehicles, please try again later', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } finally {
      setIsLoading(false);
    }
  };

  const [hasVehicles, setHasVehicles] = useState(segmentInfo?.hasVehicles || false);
  const [isLoadingCheckbox, setIsLoadingCheckbox] = useState(false);
  const handleHasVehicles = async () => {
    setIsLoadingCheckbox(true);
    setHasVehicles(!hasVehicles);
    let request = { ...segmentInfo };
    request.hasVehicles = !request.hasVehicles;

    const response = await updateHhgSegment(request);
    if (response.type === UPDATE_HHG_ESTIMATE_FAILURE) {
      showToast('Failed to update hhg estimate, please try again later', TOAST_MESSAGE_SEVERITY_ERROR);
    }
    setIsLoadingCheckbox(false);
  };

  useEffect(() => {
    setHasVehicles(segmentInfo?.hasVehicles || false);
  }, [segmentInfo]);

  const hasVehiclesCheckBox = () => {
    return (
      <FormControlLabel
        sx={{ marginLeft: '10px' }}
        control={<Checkbox checked={hasVehicles} onChange={handleHasVehicles} color="primary" disabled={isLoadingCheckbox} />}
        label={<Typography sx={{ fontSize: '1.1rem' }}>Does EE have cars to move?</Typography>}
      />
    );
  };

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <MultiRowForm
          formValues={hhgSegmentVehicles.map(item => ({ ...item })) || []}
          saveFunction={saveForm}
          formFields={formFields}
          createNewRowFunc={createNewRowFunc}
          formLabel={formLabel}
          addRowLabel={addRowLabel}
          isDisabled={isReadOnly || !hasVehicles}
          header={header}
          headerCheckbox={() => hasVehiclesCheckBox()}
        />
      )}
    </>
  );
};

export default compose(
  connect(null, {
    updateGlobalInfo,
    postGlobalInfo,
    showToast,
    deleteHhgVehicles,
    postHhgVehicles,
    updateHhgVehicles,
    getHhgVehicles,
    updateHhgSegment
  })
)(CarShipmentVehiclesCrudTable);
