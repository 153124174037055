/* eslint-disable react-hooks/exhaustive-deps */
import { POST_FINANCE_AND_TAX_FAILURE, UPDATE_FINANCE_AND_TAX_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postFinanceAndTax, updateFinanceAndTax } from '../store/actions/authorizations.actions';
import { getVoucherSummariesByAuth } from 'modules/expenses/expenses.actions';
import { GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE } from 'modules/expenses/types';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState, useEffect } from 'react';
import { Box, Button, createTheme, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ThemeProvider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { currencyFormatter } from 'utilities/currencyFormatter';
import { getClientContractFees, setClientId } from 'modules/clients/clients.actions';
import CrudTableComponent from 'modules/clients/details/crudTable.component';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';

const FinanceAndTaxCard = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [voucherSummaries, setVoucherSummaries] = useState([]);
  const [feeBillModal, setFeeBillModal] = useState(false);

  const { details, clientInfo, fullClientsInfo, clientTaxPolicies } = props;

  const theme = createTheme({
      palette: {
        primary: COLOR_PRIMARY,
        secondary: COLOR_SECONDARY,
        warning: COLOR_WARNING,
      },
  });

  const adjustedColumns = [
    { accessorKey: 'feeId', header: 'Fee Id', type: 'text', required: true, size: 75 },
    { accessorKey: 'feeType', header: 'Fee Type', type: 'text', required: true, size: 75 },
    { accessorKey: 'amount', header: 'Amount', type: 'money', required: true, size: 75 },
    {
        header: 'Effective Date',
        accessorKey: 'effectiveDate',
        type: 'date',
        required: false,
        tab: 1,
        disabled: true,
        Cell: ({ cell }) => cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-', 
        size: 65
      },
      {
        header: 'End Date',
        accessorKey: 'endDate',
        type: 'date',
        required: false,
        tab: 1,
        Cell: ({ cell }) => cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-', 
        size: 65
      },
      { accessorKey: 'notes', header: 'Notes', type: 'text', required: true, size: 75 },
  ];

  let clientBillingPolicy = Array.isArray(details?.authDetails?.clientPoliciesByClient)
    ? details?.authDetails?.clientPoliciesByClient.find(po => po.id === details?.authDetails?.summary?.clientPolicyId)
        ?.clientBillingPolicy || ''
    : '';

  const summaryTabs = [{ header: 'Finance and Tax', order: 1, iconPath: ['fa', 'dollar'] }];

    useEffect(() => {
  
      async function fetchData() {          
            const resp = await props.getVoucherSummariesByAuth(props.id);
            if(resp.type === GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE) {
              props.showToast('Failed to get voucher summaries.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
            }
            else{
              setVoucherSummaries(resp.response);
            }
           
          }
  
      if(voucherSummaries && voucherSummaries.length === 0)
      {
        fetchData();
      }
    }, [voucherSummaries, props]);

    const fetchData = async (moveTrackClientId) => {
      props.setClientId(moveTrackClientId);
      await props.getClientContractFees(moveTrackClientId);
    }

    useEffect(() => {
      if (clientInfo && clientInfo.moveTrackClientId) {
        fetchData(clientInfo.moveTrackClientId);
      }
    }, [clientInfo])

    let recurringPayments = voucherSummaries.filter(sum => sum.isRecurringPayment === true);

  const taxAssistancePolicySearchInfo = {
    searchingColumns: [
      { name: 'id', title: 'Id' },
      { name: 'policyCode', title: 'Policy Code' },
      { name: 'description', title: 'Description' }
    ],
    searchingColumnExtensions: [
      { columnName: 'id', width: 150 },
      { columnName: 'policyCode', width: 200 },
      { columnName: 'description', width: 300 }
    ],
    searchingRows: clientTaxPolicies ? clientTaxPolicies : {},
    idKey: 'id',
    nameKey: 'policyCode'
  };

  const fields = [
    {
      header: 'Billing Policy',
      accessorKey: 'clientBillingPolicy',
      type: 'text',
      value: clientBillingPolicy ? clientBillingPolicy : '',
      hideInModal: true,
      tab: 1
    },
    {
      header: 'Tax Assistance Policy',
      accessorKey: 'id',
      nameKey: 'policyCode',
      nameValue: details && details.financeAndTax ? details.financeAndTax.taxPolicyCode : '',
      type: 'advanced-search',
      searchInfo: taxAssistancePolicySearchInfo,
      required: false,
      value: details && details.financeAndTax ? details.financeAndTax.taxPolicyId : '',
      hideInCard: false,
      tab: 1
    },
    {
      header: 'Division',
      accessorKey: 'division',
      type: 'text',
      required: false,
      value: details && details.financeAndTax ? details.financeAndTax.division : '',
      tab: 1
    },
    {
      header: 'Short Term Assignment',
      accessorKey: 'shortTermAssignment',
      type: 'checkbox',
      required: false,
      value: details && details.financeAndTax ? details.financeAndTax.shortTermAssignment : '',
      tab: 1
    }
  ];

  const buttonComponent = () => {
    const processedExpensesTotal = details.authExpenses && details.authExpenses.expenseLines ? details.authExpenses.expenseLines.filter((el) => (el.approvalStatus === 'Partial Approved' || el.approvalStatus === 'Approved') && el.apPosted != null).reduce((total, el) => { return total + parseFloat(el.amount); }, 0) : 0.00;
    const unprocessedExpensesTotal = details.authExpenses ? details.authExpenses.submittedExpenses.filter((se) => se.isExpenseApproved !== true).reduce((total, se) => { return total + se.total; }, 0) : 0;
    const unapprovedExpensesTotal = details.authExpenses && details.authExpenses.expenseLines ? details.authExpenses.expenseLines.filter((el) => el.approvalStatus === 'Partial Approved' || el.approvalStatus === 'Denied').reduce((total, el) => { return total + parseFloat(el.denied); }, 0) : 0.00;
    const unpaidExpensesTotal = details.authExpenses && details.authExpenses.expenseLines ? details.authExpenses.expenseLines.filter((el) => (el.approvalStatus === 'Partial Approved' || el.approvalStatus === 'Approved') && el.apPosted == null).reduce((total, el) => { return total + parseFloat(el.amount); }, 0) : 0.00;

    return (
      <Grid container>
        <Grid item md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column'}}>
            <Box>
              <Typography variant="body2">
                <Link to={`/authorizations/${props.id}/recurringPayments`}>
                  Recurring Payments: {recurringPayments.length}
                </Link>
              </Typography>
            </Box>
            <Box sx={{paddingTop: '10px'}}>
              <Typography variant="body2">
                <Link onClick={() => setFeeBillModal(true)}>
                  Fees Billed: {clientInfo && fullClientsInfo[clientInfo.moveTrackClientId] && fullClientsInfo[clientInfo.moveTrackClientId].clientContractsFees ? fullClientsInfo[clientInfo.moveTrackClientId].clientContractsFees.length : 0}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column'}}>
            <Box>
              <Typography variant="body2">
                <Link to={`/authorizations/${props.id}/expenses`}>
                  Expenses
                </Link>
              </Typography>
            </Box>
            <Box sx={{paddingTop: '5px', display: 'flex', flexDirection: 'row'}} >
              <Box sx={{marginRight: '5px'}}>
                <Typography variant="body2">Processed:</Typography>
              </Box>
              <Typography variant="body2" color="secondary">{currencyFormatter({ value: processedExpensesTotal })}</Typography>
            </Box>
            <Box sx={{paddingTop: '5px', display: 'flex', flexDirection: 'row'}} >
              <Box sx={{marginRight: '5px'}}>
                <Typography variant="body2">Unprocessed:</Typography>
              </Box>
              <Typography variant="body2" color="secondary">{currencyFormatter({ value: unprocessedExpensesTotal })}</Typography>
            </Box>
            <Box sx={{paddingTop: '5px', display: 'flex', flexDirection: 'row'}} >
              <Box sx={{marginRight: '5px'}}>
                <Typography variant="body2">Unapproved:</Typography>
              </Box>
              <Typography variant="body2" color="secondary">{currencyFormatter({ value: unapprovedExpensesTotal })}</Typography>
            </Box>
            <Box sx={{paddingTop: '5px', display: 'flex', flexDirection: 'row'}} >
              <Box sx={{marginRight: '5px'}}>
                <Typography variant="body2">Unpaid:</Typography>
              </Box>
              <Typography variant="body2" color="secondary">{currencyFormatter({ value: unpaidExpensesTotal })}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    values.authorizationId = props.id;

    if (details.financeAndTax && details.financeAndTax.length !== 0) {
      const resp = await props.updateFinanceAndTax(values);
      if (resp.type === UPDATE_FINANCE_AND_TAX_FAILURE) {
        props.showToast('Failed to update the employee finance and tax info, please try again.', {
          severity: TOAST_MESSAGE_SEVERITY_ERROR
        });
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    } else {
      const resp = await props.postFinanceAndTax(values);
      if (resp.type === POST_FINANCE_AND_TAX_FAILURE) {
        props.showToast('Failed to update employee finance and tax info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <EditableCard tabs={summaryTabs} summaries={fields} numColumns={2} updateFields={updateFields} isLoading={isLoading} ButtonComponent={buttonComponent} />
      <Dialog open={feeBillModal} onClose={() => setFeeBillModal(false)} fullWidth maxWidth="lg">
        <DialogTitle>Fees Billed</DialogTitle>
        <DialogContent>
          <CrudTableComponent 
            showBasicTabl={true}
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={adjustedColumns} 
            rows={clientInfo && fullClientsInfo[clientInfo.moveTrackClientId] && fullClientsInfo[clientInfo.moveTrackClientId].clientContractsFees ? fullClientsInfo[clientInfo.moveTrackClientId].clientContractsFees : []} 
            customAddText={'Add Fee'}
            hideEdit={true}
          />
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button color="primary" onClick={() => setFeeBillModal(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )

};

const mapStateToProps = (state) => {
  return {
    fullClientsInfo: state.clients.clientsInfo,
  };
};

export default compose(
  connect(mapStateToProps, {
    postFinanceAndTax,
    updateFinanceAndTax,
    getVoucherSummariesByAuth,
    showToast,
    getClientContractFees,
    setClientId
  })
)(FinanceAndTaxCard);
