/* eslint-disable react-hooks/exhaustive-deps */

import { withStyles } from '@material-ui/core';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  getHhgEstimates,
  getHhgItems,
  getHhgMovingDates,
  getHhgSegments,
  postHhgEstimate,
  postHhgSegment,
  updateHhgEstimate
} from 'modules/authorizations/store/actions/houseHoldGoods.actions';

import PrimaryHhgInfo from './primaryHhgInfo.component';

import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS, DRAWER_WIDTH_OPEN } from 'styles/theme';
import classNames from 'classnames';

import {
  GET_HHG_ESTIMATES_FAILURE,
  GET_HHG_ITEMS_FAILURE,
  GET_HHG_MOVING_DATE_FAILURE,
  GET_HHG_SEGMENTS_FAILURE,
  POST_HHG_ESTIMATE_FAILURE,
  POST_HHG_SEGMENT_FAILURE,
  UPDATE_HHG_ESTIMATE_FAILURE
} from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import EstimatesActuals from './estimatesActuals.component';
import NeedsAnalysis from './needsAnalysis.component';
import Shipment from './shipment.component';
import Spinner from 'modules/common/spinner.component';
import Storage from './storage.component';
import queryString from 'query-string';
import ServiceTriggeredComment from '../commentsAndNotes/serviceTriggeredComment.component';
import SlidingCommentsAndNotes from '../commentsAndNotes/commentsAndNotesSlidingPanel.component';
import { formatDateForInput } from 'utilities/common';

const styles = theme => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  container: {
    height: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`
    }
  },
  drawerOpen: {
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`
  },
  headerFixed: {
    zIndex: 4
  },
  tabFixed: {
    paddingTop: '100px',
    zIndex: 3
  },
  previewTabFixed: {
    paddingTop: '10px',
    zIndex: 3
  },
  estimateHeaderFixed: {
    paddingTop: '150px',
    zIndex: 2
  },
  previewEstimateHeaderFixed: {
    paddingTop: '15px',
    zIndex: 3
  }
});

export const hhgMoveTypes = [
  { id: 0, name: 'Self Move' },
  { id: 1, name: 'Intrastate' },
  { id: 2, name: 'Interstate' },
  { id: 3, name: 'International' }
];

const HouseholdGoods = props => {
  const { details, location, shouldShowSideDrawer, isReadOnly } = props;

  let segments = details?.authHouseHoldGoods?.hhgSegments || [];
  let selectedSegment = segments.length > 0 ? segments[segments.length - 1] : null;
  const [segmentId, setSegmentId] = useState(selectedSegment?.segmentId);

  const [currentTab, setCurrentTab] = useState(queryString.parse(location.search).tab ? queryString.parse(location.search).tab : 'PRIMARY');
  const [showAddSegment, setShowAddSegment] = useState(false);
  const [newInitDate, setNewInitDate] = useState(new Date().toISOString().split('T')[0]);
  const [newDescription, setNewDescription] = useState('');
  const [newType, setNewType] = useState(0);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isLoadingMovingDates, setIsLoadingMovingDates] = useState(false);
  const [isLoadingItemsToBeMoved, setIsLoadingItemsToBeMoved] = useState(false);

  const [estimateId, setEstimateId] = useState(
    details?.authHouseHoldGoods?.hhgEstimates && details?.authHouseHoldGoods?.hhgEstimates.filter(hge => hge.estimateType === currentTab)[0]
      ? details?.authHouseHoldGoods?.hhgEstimates.filter(hge => hge.estimateType === currentTab)[0].estimateId
      : null
  );
  const [newEstimateSegmentId, setNewEstimateSegmentId] = useState(0);
  const [showAddEstimate, setShowAddEstimate] = useState(false);
  const [newEstimateDescription, setNewEstimateDescription] = useState('');
  const [newEstimateSegment, setNewEstimateSegment] = useState(
    newEstimateSegmentId ? details?.authHouseHoldGoods.hhgSegments.find(s => s.segmentId === newEstimateSegmentId) : []
  );
  const [isLoadingEstimates, setIsLoadingEstimates] = useState(false);

  const [showTriggeredComment, setShowTriggeredComment] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [estimateAccepted, setEstimateAccepted] = useState(
    details?.authHouseHoldGoods?.hhgEstimates && details?.authHouseHoldGoods?.hhgEstimates.filter(hge => hge.estimateType === currentTab)[0]
      ? details?.authHouseHoldGoods?.hhgEstimates.filter(hge => hge.estimateType === currentTab)[0].estimateAccepted
      : false
  );

  const completeService = () => {
    setShowTriggeredComment(true);
  };

  const createSegment = async () => {
    const authId = details?.authDetails?.summary?.authorizationId;

    setIsLoadingModal(true);

    const resp = await props.postHhgSegment({
      authorizationId: authId,
      type: newType,
      name: newDescription,
      initiationDate: newInitDate
    });

    if (resp.type === POST_HHG_SEGMENT_FAILURE) {
      props.showToast('Failed to update the hhg segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingModal(false);
    } else {
      const allSegmentsResp = await props.getHhgSegments(authId);
      if (allSegmentsResp.type === GET_HHG_SEGMENTS_FAILURE) {
        props.showToast('Failed to fetch the hhg segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Segment created successfully.', { userMustDismiss: false, severity: 'success' });
        setSegmentId(resp.response.segmentId);
        segments = allSegmentsResp.response;
        setShowAddSegment(false);
      }
      setIsLoadingModal(false);
    }
  };

  const createEstimate = async () => {
    setIsLoadingModal(true);

    const resp = await props.postHhgEstimate({
      authorizationId: details?.authDetails?.summary.authorizationId,
      segmentId: newEstimateSegmentId,
      description: newEstimateDescription,
      estimateType: currentTab,
      vendorId: newEstimateSegment && newEstimateSegment.storageVendorId !== undefined ? newEstimateSegment.storageVendorId : null,
      vendorCompanyName:
        newEstimateSegment && newEstimateSegment.storageVendorCompanyName ? newEstimateSegment.storageVendorCompanyName : '',
      vendorContactId:
        newEstimateSegment && newEstimateSegment.storageVendorContactId !== undefined ? newEstimateSegment.storageVendorContactId : null,
      vendorContactName: newEstimateSegment && newEstimateSegment.storageVendorContactName ? newEstimateSegment.storageVendorContactId : '',
      vendorPhone: newEstimateSegment && newEstimateSegment.storageVendorContactPhone ? newEstimateSegment.storageVendorContactPhone : '',
      vendorContactEmail:
        newEstimateSegment && newEstimateSegment.storageVendorContactEmail ? newEstimateSegment.storageVendorContactEmail : ''
    });

    if (resp.type === POST_HHG_ESTIMATE_FAILURE) {
      props.showToast('Failed to create the new hhg estimate, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingModal(false);
    } else {
      const allEstimatesResp = await props.getHhgEstimates(segmentId);
      if (allEstimatesResp.type === GET_HHG_ESTIMATES_FAILURE) {
        props.showToast('Failed to fetch the hhg estimates, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Estimate created successfully.', { userMustDismiss: false, severity: 'success' });
        setEstimateId(resp.response.estimateId);
        setShowAddEstimate(false);
        setNewEstimateDescription('');
      }
      setIsLoadingModal(false);
    }
  };

  const updateEstimateAccepted = async () => {
    setIsLoadingModal(true);
    let newVals = { ...details?.authHouseHoldGoods?.hhgEstimates.filter(hge => hge.estimateType === currentTab)[0] };
    newVals.estimateAccepted = !estimateAccepted;
    newVals.estimateAcceptedDate = new Date();

    const resp = await props.updateHhgEstimate(newVals);

    if (resp.type === UPDATE_HHG_ESTIMATE_FAILURE) {
      props.showToast('Failed to update the estimate accepted data, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingModal(false);
    } else {
      const allEstimatesResp = await props.getHhgEstimates(segmentId);
      if (allEstimatesResp.type === GET_HHG_ESTIMATES_FAILURE) {
        props.showToast('Failed to fetch the hhg estimates, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Estimate updated successfully.', { userMustDismiss: false, severity: 'success' });
        setEstimateId(resp.response.estimateId);
        setEstimateAccepted(resp.response.estimateAccepted);
      }
      setIsLoadingModal(false);
    }
  };

  const changeTab = tab => {
    setCurrentTab(tab);
    const values = queryString.parse(location.search);
    values.tab = tab;
    props.history.replace(`${location.pathname}?${queryString.stringify(values)}`);
  };

  const getSegmentData = async () => {
    setIsLoadingMovingDates(true);
    const movingDatesResp = await props.getHhgMovingDates(segmentId);
    setIsLoadingMovingDates(false);
    if (movingDatesResp.type === GET_HHG_MOVING_DATE_FAILURE) {
      props.showToast('Failed to fetch the hhg moving dates, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }

    setIsLoadingItemsToBeMoved(true);
    const itemsToBeMovedResp = await props.getHhgItems(segmentId);
    setIsLoadingItemsToBeMoved(false);
    if (itemsToBeMovedResp.type === GET_HHG_ITEMS_FAILURE) {
      props.showToast('Failed to fetch the hhg items to be moved, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }

    setIsLoadingEstimates(true);
    const estimatesResp = await props.getHhgEstimates(segmentId);
    setIsLoadingEstimates(false);
    if (estimatesResp.type === GET_HHG_ESTIMATES_FAILURE) {
      props.showToast('Failed to fetch the hhg estimates, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      setEstimateId(
        estimatesResp.response && estimatesResp.response.filter(hge => hge.estimateType === currentTab)[0]
          ? estimatesResp.response.filter(hge => hge.estimateType === currentTab)[0].estimateId
          : null
      );
    }
  };

  useEffect(() => {
    if (segmentId) {
      getSegmentData();
    }
    if (newEstimateSegmentId && newEstimateSegmentId > 0) {
      setNewEstimateSegment(details?.authHouseHoldGoods.hhgSegments.find(s => s.segmentId === newEstimateSegmentId));
    }
  }, [segmentId, newEstimateSegmentId]);

  useEffect(() => {
    if (
      (currentTab === 'GOODS_SHIPMENT' || currentTab === 'CAR_SHIPMENT' || currentTab === 'LONG_TERM_STORAGE') &&
      details &&
      details.authHouseHoldGoods &&
      details.authHouseHoldGoods.hhgEstimates
    ) {
      setEstimateId(
        details.authHouseHoldGoods.hhgEstimates.filter(hge => hge.estimateType === currentTab)[0]
          ? details.authHouseHoldGoods.hhgEstimates.filter(hge => hge.estimateType === currentTab)[0].estimateId
          : null
      );
    }
  }, [currentTab]);


  return (
    <>
      <Box>
        {showComment && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1200,
              pointerEvents: 'none'
            }}
          />
        )}
        {location && (location.pathname.includes('expenses') || isReadOnly) && (
          <>
            <Paper
              square
              className={classNames(props.classes.previewHeaderFixed, props.classes.container, {
                [props.classes.drawerOpen]: shouldShowSideDrawer
              })}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                <Box>
                  <div className={props.classes.mb2}>
                    <Typography variant="subtitle2">Segment</Typography>
                  </div>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Select
                      labelId={'segmentSelect'}
                      key={'segmentSelect'}
                      label={''}
                      name={'segmentSelect'}
                      value={segmentId}
                      sx={{ maxWidth: '360px', minWidth: '180px' }}
                      onChange={e => setSegmentId(e.target.value)}
                    >
                      {segments &&
                        segments.map(opt => (
                          <MenuItem key={opt.segmentId} value={opt.segmentId} sx={{ margin: 'dense' }}>
                            {opt.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                  <Box>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                        Move Type
                      </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                        {details?.authDetails?.authServices.householdGoods}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                        HHG Status
                      </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                        {'Initiated'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                        Policy
                      </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                        {details?.authDetails?.authClientPolicies?.clientPolicyCode}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}></Box>
              </Box>
            </Paper>
            <Paper
              square
              className={classNames(props.classes.previewTabFixed, props.classes.container, {
                [props.classes.drawerOpen]: shouldShowSideDrawer
              })}
            >
              <Tabs variant="fullWidth" value={currentTab} onChange={(event, tab) => changeTab(tab)} sx={{}}>
                <Tab value={'PRIMARY'} label="Primary Information" />
                <Tab value={'NEEDS_ANALYSIS'} label="Needs Analysis" disabled={!segmentId} />
                <Tab value={'ESTIMATES'} label="Estimates/Actuals" disabled={!segmentId} />
                <Tab value={'SHIPMENT'} label="Shipment of Goods" disabled={!segmentId} />
                <Tab value={'STORAGE'} label="Storage" disabled={!segmentId} />
              </Tabs>
            </Paper>

            {currentTab === 'ESTIMATES' && segmentId != null && (
              <Paper
                square
                className={classNames(props.classes.previewEstimateHeaderFixed, props.classes.container, {
                  [props.classes.drawerOpen]: shouldShowSideDrawer
                })}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px' }}>
                    <Select
                      labelId={'estimateId'}
                      key={'estimateId'}
                      label={''}
                      name={'estimateId'}
                      value={estimateId}
                      sx={{ maxWidth: '360px', minWidth: '180px' }}
                      onChange={e => {
                        setEstimateId(e.target.value);
                        setEstimateAccepted(
                          details?.authHouseHoldGoods?.hhgEstimates.filter(hge => hge.estimateType === currentTab)[0].estimateAccepted
                            ? details?.authHouseHoldGoods?.hhgEstimates(hge => hge.estimateType === currentTab)[0].estimateAccepted
                            : false
                        );
                      }}
                    >
                      {details &&
                        details?.authHouseHoldGoods?.hhgEstimates &&
                        details?.authHouseHoldGoods?.hhgEstimates.map(opt => (
                          <MenuItem key={opt.estimateId} value={opt.estimateId} sx={{ margin: 'dense' }}>
                            {opt.description}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                </Box>
              </Paper>
            )}
            {segmentId == null && (
              <Box sx={{ padding: '20px', paddingTop: '165px', fontSize: '20px' }}>
                <b>No segment selected. Create a new segment above to get started.</b>
              </Box>
            )}
            {segmentId != null && (
              <Box sx={{ padding: '20px', paddingTop: currentTab === 'ESTIMATES' ? '20px' : '15px' }}>
                {currentTab === 'PRIMARY' && (
                  <PrimaryHhgInfo
                    details={details}
                    segmentId={segmentId}
                    location={location}
                    isLoadingMovingDates={isLoadingMovingDates}
                    id={props.id}
                    isReadOnly={isReadOnly}
                  />
                )}
                {currentTab === 'NEEDS_ANALYSIS' && (
                  <NeedsAnalysis
                    details={details}
                    segmentId={segmentId}
                    location={location}
                    isLoadingItemsToBeMoved={isLoadingItemsToBeMoved}
                    id={props.id}
                    isReadOnly={isReadOnly}
                  />
                )}
                {currentTab === 'ESTIMATES' && (
                  <EstimatesActuals
                    estimateId={estimateId}
                    details={details}
                    location={location}
                    segmentId={segmentId}
                    isLoadingEstimates={isLoadingEstimates}
                    id={props.id}
                    isReadOnly={isReadOnly}
                  />
                )}
                {currentTab === 'SHIPMENT' && (
                  <Shipment details={details} segmentId={segmentId} location={location} id={props.id} isReadOnly={isReadOnly} />
                )}
                {currentTab === 'STORAGE' && (
                  <Storage details={details} segmentId={segmentId} location={location} id={props.id} isReadOnly={isReadOnly} />
                )}
              </Box>
            )}
          </>
        )}
        {location && !location.pathname.includes('expenses') && !isReadOnly && (
          <>
            <Paper
              square
              className={classNames('position-fixed', props.classes.headerFixed, props.classes.container, {
                [props.classes.drawerOpen]: shouldShowSideDrawer
              })}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                <Box>
                  <div className={props.classes.mb2}>
                    <Typography variant="subtitle2">Segment</Typography>
                  </div>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Select
                      labelId={'segmentSelect'}
                      key={'segmentSelect'}
                      label={''}
                      name={'segmentSelect'}
                      value={segmentId}
                      sx={{ maxWidth: '360px', minWidth: '180px' }}
                      onChange={e => setSegmentId(e.target.value)}
                    >
                      {segments &&
                        segments.map(opt => (
                          <MenuItem key={opt.segmentId} value={opt.segmentId} sx={{ margin: 'dense' }}>
                            {opt.name}
                          </MenuItem>
                        ))}
                    </Select>
                    <Button
                      color="secondary"
                      type="submit"
                      variant="contained"
                      sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                      onClick={() => setShowAddSegment(true)}
                    >
                      New Segment
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                  <Box>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                        Move Type
                      </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                        {details?.authDetails?.authServices.householdGoods}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                        HHG Status
                      </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                        {'Initiated'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                        Policy
                      </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                        {details?.authDetails?.authClientPolicies?.clientPolicyCode}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      maxHeight: '40px',
                      marginLeft: '12px',
                      marginTop: '8px',
                      backgroundColor: '#868686',
                      '&:hover': { backgroundColor: '#474747' }
                    }}
                  >
                    Cancel Service
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      maxHeight: '40px',
                      marginLeft: '12px',
                      marginTop: '8px',
                      backgroundColor: '#f0b11d',
                      '&:hover': { backgroundColor: '#ba8a00' }
                    }}
                  >
                    {' '}
                    Set On Hold{' '}
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                    onClick={completeService}
                  >
                    {' '}
                    Complete Service{' '}
                  </Button>
                  <Button
                    onClick={() => setShowComment(true)}
                    variant="contained"
                    sx={{
                      maxHeight: '40px',
                      marginLeft: '12px',
                      marginTop: '8px',
                      backgroundColor: '#41B7C0',
                      color: 'white',
                      '&:hover': { backgroundColor: '#3d959c' }
                    }}
                  >
                    Comments
                  </Button>
                </Box>
              </Box>
            </Paper>
            <Paper
              square
              className={classNames('position-fixed', props.classes.tabFixed, props.classes.container, {
                [props.classes.drawerOpen]: shouldShowSideDrawer
              })}
            >
              <Tabs variant="fullWidth" value={currentTab} onChange={(event, tab) => changeTab(tab)} sx={{}}>
                <Tab value={'PRIMARY'} label="Primary Information" />
                {/* <Tab value={'NEEDS_ANALYSIS'} label="Needs Analysis" disabled={!segmentId} /> */}
                <Tab value={'GOODS_SHIPMENT'} label="Estimates" disabled={!segmentId} />
                {/* <Tab value={'CAR_SHIPMENT'} label="Car Shipment" disabled={!segmentId} />
          <Tab value={'LONG_TERM_STORAGE'} label="Long Term Storage" disabled={!segmentId} />
          <Tab value={'SHIPMENT'} label="Shipment of Goods" disabled={!segmentId} />
          <Tab value={'STORAGE'} label="Storage" disabled={!segmentId} /> */}
              </Tabs>
            </Paper>

            {(currentTab === 'GOODS_SHIPMENT' || currentTab === 'CAR_SHIPMENT' || currentTab === 'LONG_TERM_STORAGE') && segmentId != null && (
              <Paper
                square
                className={classNames('position-fixed', props.classes.estimateHeaderFixed, props.classes.container, {
                  [props.classes.drawerOpen]: shouldShowSideDrawer
                })}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px' }}>
                    <Select
                      labelId={'estimateId'}
                      key={'estimateId'}
                      label={''}
                      name={'estimateId'}
                      value={estimateId}
                      sx={{ maxWidth: '360px', minWidth: '180px', maxHeight: '65px' }}
                      onChange={e => setEstimateId(e.target.value)}
                    >
                      {details &&
                        details?.authHouseHoldGoods?.hhgEstimates &&
                        details?.authHouseHoldGoods?.hhgEstimates
                          .filter(hge => hge.estimateType === currentTab)
                          .map(opt => (
                            <MenuItem key={opt.estimateId} value={opt.estimateId} sx={{ margin: 'dense' }}>
                              {opt.description}
                            </MenuItem>
                          ))}
                    </Select>
                    <Button
                      color="secondary"
                      type="submit"
                      variant="contained"
                      sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '10px' }}
                      onClick={() => setShowAddEstimate(true)}
                    >
                      {' '}
                      New Estimate{' '}
                    </Button>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px' }}>
                    <div>
                      <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                        <FormControlLabel
                          key={'estimateAccepted'}
                          name={'estimateAccepted'}
                          label={'Estimate Accepted'}
                          labelPlacement="start"
                          control={<Checkbox />}
                          required={false}
                          disabled={false}
                          checked={estimateAccepted}
                          variant="standard"
                          margin="dense"
                          sx={{ minWidth: '20px', maxWidth: '60px', maxHeight: '20px', marginRight: '10px' }}
                          onClick={e => {
                            setEstimateAccepted(e.target.checked ? true : false);
                            updateEstimateAccepted();
                          }}
                        />
                      </FormControl>
                    </div>
                    {estimateAccepted && (
                      <>
                        <Box>
                          <TextField
                            key={'estimateAcceptedDate'}
                            label={'Estimate Accepted Date'}
                            name={'estimateAcceptedDate'}
                            disabled={true}
                            value={
                              details?.authHouseHoldGoods?.hhgEstimates &&
                              details?.authHouseHoldGoods?.hhgEstimates.filter(hge => hge.estimateType === currentTab)[0]
                                ? formatDateForInput(
                                    details?.authHouseHoldGoods?.hhgEstimates.filter(hge => hge.estimateType === currentTab)[0]
                                      .estimateAcceptedDate
                                  )
                                : null
                            } // formatDateForInput(values[summary.accessorKey])
                            sx={{ maxWidth: '360px' }}
                            margin="dense"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            onChange={e => setNewDescription(e.target.value)}
                          />
                        </Box>
                        <Box>
                          <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '10px' }}>
                            {' '}
                            Send Acceptance Letter{' '}
                          </Button>
                        </Box>
                      </>
                    )}
                    <Box>
                      <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '10px' }}>
                        {' '}
                        Send Estimate Request{' '}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            )}

            {segmentId == null && (
              <Box sx={{ padding: '20px', paddingTop: '165px', fontSize: '20px' }}>
                <b>No segment selected. Create a new segment above to get started.</b>
              </Box>
            )}
            {segmentId != null && (
              <Box
                sx={{
                  padding: '20px',
                  paddingTop:
                    currentTab === 'GOODS_SHIPMENT' || currentTab === 'CAR_SHIPMENT' || currentTab === 'LONG_TERM_STORAGE'
                      ? '245px'
                      : '165px'
                }}
              >
                {currentTab === 'PRIMARY' && (
                  <PrimaryHhgInfo
                    details={details}
                    segmentId={segmentId}
                    location={location}
                    isLoadingMovingDates={isLoadingMovingDates}
                    id={props.id}
                  />
                )}
                {currentTab === 'NEEDS_ANALYSIS' && (
                  <NeedsAnalysis
                    details={details}
                    segmentId={segmentId}
                    location={location}
                    isLoadingItemsToBeMoved={isLoadingItemsToBeMoved}
                    id={props.id}
                  />
                )}
                {currentTab === 'GOODS_SHIPMENT' && (
                  <EstimatesActuals
                    estimateId={estimateId}
                    currentTab={currentTab}
                    details={details}
                    location={location}
                    segmentId={segmentId}
                    isLoadingEstimates={isLoadingEstimates}
                    id={props.id}
                  />
                )}
                {currentTab === 'CAR_SHIPMENT' && (
                  <EstimatesActuals
                    estimateId={estimateId}
                    currentTab={currentTab}
                    details={details}
                    location={location}
                    segmentId={segmentId}
                    isLoadingEstimates={isLoadingEstimates}
                    id={props.id}
                  />
                )}
                {currentTab === 'LONG_TERM_STORAGE' && (
                  <EstimatesActuals
                    estimateId={estimateId}
                    currentTab={currentTab}
                    details={details}
                    location={location}
                    segmentId={segmentId}
                    isLoadingEstimates={isLoadingEstimates}
                    id={props.id}
                  />
                )}
                {currentTab === 'SHIPMENT' && <Shipment details={details} segmentId={segmentId} location={location} id={props.id} />}
                {currentTab === 'STORAGE' && <Storage details={details} segmentId={segmentId} location={location} id={props.id} />}
              </Box>
            )}
          </>
        )}

        <Dialog open={showAddSegment} fullWidth={false} maxWidth={'lg'}>
          <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
            <Box sx={{ marginRight: '20px' }}>
              <TextField
                key={'newDescription'}
                label={'Name'}
                name={'newDescription'}
                required={true}
                disabled={false}
                value={newDescription} // formatDateForInput(values[summary.accessorKey])
                sx={{ maxWidth: '360px' }}
                margin="dense"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                onChange={e => setNewDescription(e.target.value)}
              />
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </Box>
            <Box sx={{ marginRight: '20px' }}>
              <TextField
                key={'newInitDate'}
                label={'Initiation Date'}
                name={'newInitDate'}
                required={true}
                disabled={false}
                value={newInitDate} // formatDateForInput(values[summary.accessorKey])
                sx={{ maxWidth: '360px' }}
                margin="dense"
                variant="standard"
                type="date"
                InputLabelProps={{ shrink: true }}
                onChange={e => setNewInitDate(e.target.value)}
              />
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </Box>
            <Box>
              <FormControl sx={{ marginTop: '6px' }}>
                <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'hhgType'}>
                  HHG Type
                </InputLabel>
                <Select
                  labelId={'hhgType'}
                  key={'hhgType'}
                  label={'hhgType'}
                  name={'hhgType'}
                  required={true}
                  disabled={false}
                  value={newType}
                  margin="dense"
                  variant="standard"
                  sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                  onChange={e => setNewType(e.target.value)}
                >
                  {hhgMoveTypes.map(opt => (
                    <MenuItem key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                      {opt.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </Box>
          </Box>
          <DialogActions sx={{ p: '1.25rem' }}>
            {isLoadingModal && <Spinner />}
            {!isLoadingModal && (
              <>
                <Button onClick={() => setShowAddSegment(false)}>Cancel</Button>
                <Button
                  onClick={createSegment}
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={!newInitDate || !(newDescription && newDescription.length > 0)}
                >
                  Create Segment
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>

        <Dialog open={showAddEstimate} fullWidth={false} maxWidth={'lg'}>
          <DialogTitle>Create New Estimate</DialogTitle>
          <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
            <Box>
              <TextField
                key={'newEstimateDescription'}
                label={'Name of Estimate'}
                name={'newEstimateDescription'}
                required={true}
                disabled={false}
                value={newEstimateDescription} // formatDateForInput(values[summary.accessorKey])
                sx={{ maxWidth: '360px', minWidth: '275px', marginRight: '10px' }}
                margin="dense"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                onChange={e => setNewEstimateDescription(e.target.value)}
              />
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </Box>
            <Box>
              <FormControl sx={{ marginTop: '6px' }}>
                <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'hhgType'}>
                  Segment
                </InputLabel>
                <Select
                  labelId={'segment'}
                  key={'segment'}
                  label={'Segment'}
                  name={'segment'}
                  required={true}
                  disabled={false}
                  value={newEstimateSegmentId}
                  margin="dense"
                  variant="standard"
                  sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                  onChange={e => setNewEstimateSegmentId(e.target.value)}
                >
                  {details?.authHouseHoldGoods?.hhgSegments !== undefined &&
                    details?.authHouseHoldGoods.hhgSegments.map(opt => (
                      <MenuItem key={opt.segmentId} value={opt.segmentId} sx={{ margin: 'dense' }}>
                        {opt.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
            <Box>
              <TextField
                key={'startLocation'}
                label={'Start Location'}
                name={'startLocation'}
                required={false}
                disabled={true}
                sx={{ maxWidth: '360px', minWidth: '275px', marginRight: '10px' }}
                margin="dense"
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box>
              <TextField
                key={'endLocation'}
                label={'End Location'}
                name={'endLocation'}
                required={false}
                disabled={true}
                sx={{ maxWidth: '360px', minWidth: '275px', marginRight: '10px' }}
                margin="dense"
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box>
              <TextField
                key={'vendor'}
                label={'Vendor'}
                name={'vendor'}
                required={false}
                disabled={true}
                value={newEstimateSegment && newEstimateSegment.storageVendorCompanyName ? newEstimateSegment.storageVendorCompanyName : ''}
                sx={{ maxWidth: '360px', minWidth: '275px', marginRight: '10px' }}
                margin="dense"
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>
          <DialogActions sx={{ p: '1.25rem' }}>
            {isLoadingModal && <Spinner />}
            {!isLoadingModal && (
              <>
                <Button onClick={() => setShowAddEstimate(false)}>Cancel</Button>
                <Button
                  onClick={createEstimate}
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={!(newEstimateDescription && newEstimateDescription.length > 0)}
                >
                  Create Estimate
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </Box>
      {showTriggeredComment && (
        <ServiceTriggeredComment
          authorizationId={details?.authDetails?.summary?.authorizationId}
          serviceOptionId={19}
          displayText={'Household Goods - Service Completed Comment'}
          setShowTriggeredComment={setShowTriggeredComment}
        />
      )}
      {showComment && (
        <SlidingCommentsAndNotes
          authorizationId={details?.authDetails?.summary?.authorizationId}
          serviceOptionId={19}
          setShowComment={setShowComment}
          isOpen={true}
        />
      )}
    </>
  );
};

HouseholdGoods.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    shouldShowSideDrawer: state.layout.shouldShowSideDrawer
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getHhgSegments,
    postHhgSegment,
    showToast,
    getHhgMovingDates,
    getHhgItems,
    getHhgEstimates,
    postHhgEstimate,
    updateHhgEstimate
  })
)(HouseholdGoods);
