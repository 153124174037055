/* eslint-disable no-useless-computed-key */
import { Box, MenuItem, TextField, withStyles } from '@material-ui/core';

import { chain } from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Fragment, PureComponent } from 'react';
import memoize from 'memoize-one';

//import { TOAST_MESSAGE_SEVERITY_ERROR } from '../layout/types';
import { getTestApiV2, setPageTitle, showToast } from 'modules/layout/layout.actions';
import Log from 'utilities/log';
import auth from 'auth/auth';

import { TOAST_MESSAGE_SEVERITY_ERROR } from '../layout/types';
import { getAllPreferredExpenseCodes, getExpenseLines, getVoucherSummaries, postVoucherSummary } from 'modules/expenses/expenses.actions';
import {
  GET_CLIENT_PREFERRED_EXPENSE_CODES_FAILURE,
  GET_EXPENSE_LINES_FAILURE,
  GET_VOUCHER_SUMMARIES_FAILURE,
  POST_VOUCHER_SUMMARY_FAILURE
} from 'modules/expenses/types';
import VouchersGrid from './vouchersGrid.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import { Button, Dialog, DialogActions, Typography } from '@mui/material';
import AdvancedSearch from 'modules/common/advancedSearch.component';
import Spinner from 'modules/common/spinner.component';
import { getAuthorizationSummaries } from 'modules/authorizations/store/actions/authorizations.actions';
import { GET_AUTHORIZATIONS_FAILURE } from 'modules/authorizations/store/types/authorizationTypes';
import { getVendors } from 'modules/authorizations/store/actions/dataManagement.actions';
import { GET_VENDORS_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';

const styles = theme => ({
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 100
  }
});

class VoucherSummariesView extends PureComponent {
  state = {
    clientId: -1,
    expenseAnalystId: -1,
    reassigningRecord: null,
    deletingRecord: null,
    isReassigningRecord: false,
    isDeletingRecord: false,
    webAccessRecord: null,
    showWebAccessModal: false,
    showAlterWebAccessModal: false,
    isLoading: false,
    selectedStatuses: [1],
    showCreateVoucher: false,
    selectedClientId: null,
    selectedClientName: null,
    payToVendor: false,
    selectedVendorId: null,
    selectedVendorName: null,
    selectedAuthId: null,
    selectedAuthName: null,
    isLoadingModal: false
  };

  clientSearchInfo = clients => {
    return {
      searchingColumns: [
        { name: 'clientId', title: 'Client Id' },
        { name: 'clientName', title: 'Company Name' },
        { name: 'moveTrackClientId', title: 'MT Client Id' },
        { name: 'clientCode', title: 'Client Code' },
        { name: 'clientStatus', title: 'Status' },
        { name: 'serviceRep', title: 'Service Rep' }
      ],
      searchingColumnExtensions: [
        { columnName: 'clientId', width: 150 },
        { columnName: 'clientName', width: 250 },
        { name: 'moveTrackClientId', width: 175 },
        { name: 'clientCode', width: 175 },
        { columnName: 'clientStatus', width: 175 },
        { columnName: 'serviceRep', width: 175 }
      ],
      searchingRows: clients,
      idKey: 'clientId',
      nameKey: 'clientName'
    };
  };

  async componentDidMount() {
    this.props.setPageTitle('Vouchers');

    this.setState({ isLoading: true });

    const queryParams = new URLSearchParams(this.props.location.search);
    const statusParam = queryParams.get('status');

    if (statusParam) {
      const status = parseInt(statusParam, 10);
      if (!isNaN(status)) {
        this.setState(prevState => ({
          selectedStatuses: [status]
        }));
      }
    }

    if (!this.props.expenseSummaries) {
      let { type } = await this.props.getVoucherSummaries();
      if (type === GET_VOUCHER_SUMMARIES_FAILURE) {
        this.props.showToast('Failed to retrieve vouchers, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.expenseLines) {
      let { type } = await this.props.getExpenseLines();
      if (type === GET_EXPENSE_LINES_FAILURE) {
        this.props.showToast('Failed to retrieve expense lines, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.vendors) {
      let { type } = await this.props.getVendors();
      if (type === GET_VENDORS_FAILURE) {
        this.props.showToast('Failed to retrieve vednors, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.authorizationSummaries) {
      let { type } = await this.props.getAuthorizationSummaries();
      if (type === GET_AUTHORIZATIONS_FAILURE) {
        this.props.showToast('Failed to retrieve authorizations, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.clientPreferredExpenseCodes) {
      let { type } = await this.props.getAllPreferredExpenseCodes();
      if (type === GET_CLIENT_PREFERRED_EXPENSE_CODES_FAILURE) {
        this.props.showToast('Failed to retrieve client preferred expense codes, please try again', {
          severity: TOAST_MESSAGE_SEVERITY_ERROR
        });
      }
    }

    this.setState({ isLoading: false });
  }

  createVoucher = async () => {
    this.setState({ isLoadingModal: true });

    let {
      payToVendor,
      selectedClientId,
      selectedClientName,
      selectedVendorId,
      selectedVendorName,
      selectedAuthId,
      selectedAuthName
    } = this.state;

    const resp = await this.props.postVoucherSummary({
      status: 1,
      payToVendor: payToVendor,
      clientId: selectedClientId,
      clientName: selectedClientName,
      vendorId: selectedVendorId,
      vendorName: selectedVendorName,
      authorizationId: selectedAuthId,
      authName: selectedAuthName
    });

    if (resp.type === POST_VOUCHER_SUMMARY_FAILURE) {
      this.props.showToast('Failed to create new voucher, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }

    this.props.history.push(`/vouchers/${resp.response.voucherId}`);
  };

  getClientList = memoize(clients => {
    if (!clients) {
      return [];
    }
    return chain(clients)
      .values()
      .uniqWith(clients, (a, b) => a.description === b.description)
      .value()
      .sort((a, b) => String(a.description).localeCompare(b.description))
      .filter(instance => instance.description);
  });

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSummarySelect = id => {
    this.props.history.push(`/expenses/${id}`);
  };

  changeStatusFilters = statusNum => {
    if (this.state.selectedStatuses.includes(statusNum)) {
      this.setState({ selectedStatuses: this.state.selectedStatuses.filter(s => s !== statusNum) });
    } else {
      let tempStatuses = [...this.state.selectedStatuses];
      tempStatuses.push(statusNum);
      this.setState({ selectedStatuses: tempStatuses });
    }
  };

  filterSummaries = memoize((voucherSummaries, clientId, expenseAnalystId, selectedStatuses) => {
    return voucherSummaries.filter(summary => {
      return (
        (clientId === -1 || summary.clientId === clientId) &&
        (expenseAnalystId === -1 || summary.expenseAnalyst === expenseAnalystId) &&
        (selectedStatuses.length === 0 || selectedStatuses.includes(summary.status))
      );
    });
  });

  render() {
    Log.trace('RENDER', 'ClientsView');
    const {
      clientId,
      showCreateVoucher,
      expenseAnalystId,
      selectedStatuses,
      selectedClientId,
      selectedClientName,
      selectedVendorId,
      selectedVendorName,
      payToVendor,
      selectedAuthId,
      selectedAuthName,
      isLoadingModal
    } = this.state;
    const { classes, clients, history, isLoading, unmappedClients, vendors, authorizationSummaries } = this.props;
    const sortedClients = this.getClientList(clients);
    let sortedExpenseAnalysts = ['JLBECKI', 'IHOLLIS', 'ASEITZ'];

    let voucherSummaries = this.filterSummaries(this.props.voucherSummaries || [], clientId, expenseAnalystId, selectedStatuses || []);
    let expenseLines = this.props.expenseLines;

    let unmappedClientsAdjusted = [];
    if (unmappedClients) {
      unmappedClientsAdjusted = unmappedClients.map(c => {
        let newCopy = { ...c };
        newCopy.clientName = c.description;
        newCopy.clientId = c.id;
        return newCopy;
      });
    }
    const clientAdvInfo = this.clientSearchInfo(unmappedClientsAdjusted);

    return (
      <Fragment>
        <div className={classes.headerContainer}>
          <TextField
            select
            label="Clients"
            className={classes.textField}
            value={clientId}
            onChange={this.handleChange('clientId')}
            SelectProps={{
              autoWidth: true
            }}
            variant="outlined"
          >
            <MenuItem value={-1}>
              <em>All Clients</em>
            </MenuItem>
            {sortedClients.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.description}
              </MenuItem>
            ))}
          </TextField>
          {auth.userHasRole('admin') && (
            <TextField
              select
              label="Expense Analyst"
              className={classes.textField}
              value={expenseAnalystId}
              onChange={this.handleChange('expenseAnalystId')}
              SelectProps={{
                autoWidth: true
              }}
              variant="outlined"
            >
              <MenuItem value={-1}>
                <em>All Expense Analysts</em>
              </MenuItem>
              {sortedExpenseAnalysts.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box sx={{ padding: '10px', display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ paddingTop: '15px' }}>
              <Typography>Voucher Status: </Typography>
            </Box>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                maxHeight: '40px',
                marginLeft: '12px',
                marginTop: '8px',
                backgroundColor: selectedStatuses.includes(1) ? '#36939b' : '#ffffff',
                '&:hover': { backgroundColor: selectedStatuses.includes(1) ? '#15585e' : '#faf8f2' },
                color: selectedStatuses.includes(1) ? 'ffffff' : '#080600'
              }}
              onClick={() => this.changeStatusFilters(1)}
            >
              {' '}
              Draft{' '}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                maxHeight: '40px',
                marginLeft: '12px',
                marginTop: '8px',
                backgroundColor: selectedStatuses.includes(2) ? '#36939b' : '#ffffff',
                '&:hover': { backgroundColor: selectedStatuses.includes(2) ? '#15585e' : '#faf8f2' },
                color: selectedStatuses.includes(2) ? 'ffffff' : '#080600'
              }}
              onClick={() => this.changeStatusFilters(2)}
            >
              {' '}
              Pending{' '}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                maxHeight: '40px',
                marginLeft: '12px',
                marginTop: '8px',
                backgroundColor: selectedStatuses.includes(3) ? '#36939b' : '#ffffff',
                '&:hover': { backgroundColor: selectedStatuses.includes(3) ? '#15585e' : '#faf8f2' },
                color: selectedStatuses.includes(3) ? 'ffffff' : '#080600'
              }}
              onClick={() => this.changeStatusFilters(3)}
            >
              {' '}
              On Hold{' '}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                maxHeight: '40px',
                marginLeft: '12px',
                marginTop: '8px',
                backgroundColor: selectedStatuses.includes(4) ? '#36939b' : '#ffffff',
                '&:hover': { backgroundColor: selectedStatuses.includes(4) ? '#15585e' : '#faf8f2' },
                color: selectedStatuses.includes(4) ? 'ffffff' : '#080600'
              }}
              onClick={() => this.changeStatusFilters(4)}
            >
              {' '}
              Approved{' '}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                maxHeight: '40px',
                marginLeft: '12px',
                marginTop: '8px',
                backgroundColor: selectedStatuses.includes(5) ? '#36939b' : '#ffffff',
                '&:hover': { backgroundColor: selectedStatuses.includes(5) ? '#15585e' : '#faf8f2' },
                color: selectedStatuses.includes(5) ? 'ffffff' : '#080600'
              }}
              onClick={() => this.changeStatusFilters(5)}
            >
              {' '}
              Denied{' '}
            </Button>
          </Box>
          <Button
            color="secondary"
            variant="contained"
            sx={{
              maxHeight: '40px',
              marginRight: '10px',
              marginTop: '8px',
              backgroundColor: '#5D9878',
              '&:hover': { backgroundColor: '#528569' }
            }}
            onClick={() => this.setState({ showCreateVoucher: true })}
          >
            {' '}
            Create Voucher{' '}
          </Button>
        </Box>
        {expenseLines && (
          <>
            {this.state.isLoading && <FullscreenSpinner />}
            {!this.state.isLoading && (
              <div className="px-2">
                <VouchersGrid
                  vouchers={voucherSummaries}
                  expenseLines={expenseLines ?? []}
                  onSelect={this.handleSummarySelect}
                  isLoading={isLoading}
                  history={history}
                />
              </div>
            )}

            <Dialog open={showCreateVoucher} fullWidth={false} maxWidth={'lg'}>
              <Box sx={{ padding: '25px' }}>
                <Typography variant="h5">New Voucher</Typography>
              </Box>
              <Box sx={{ padding: '25px', paddingTop: '0px' }}>
                <Typography variant="body2">Select from the base options below to get started with voucher creation.</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', padding: '10px 20px 10px 20px' }}>
                <Box sx={{ marginRight: '20px' }}>
                  <AdvancedSearch
                    labelText={'Client'}
                    displayValueAccessorKey={'clientName'}
                    saveIdValueAccessorKey={'clientId'}
                    searchingCriteria={clientAdvInfo.searchingColumns}
                    searchingCriteriaExtensions={clientAdvInfo.searchingColumnExtensions}
                    searchingRows={unmappedClientsAdjusted}
                    id={selectedClientId}
                    value={selectedClientName}
                    setIdValue={(id, value) => {
                      this.setState({ selectedAuthId: null, selectedAuthName: null, selectedClientId: id, selectedClientName: value });
                    }}
                    isReadOnly={false}
                  />
                  <Typography variant="caption" display="block" color="primary">
                    Required
                  </Typography>
                </Box>

                <Box>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      maxHeight: '40px',
                      marginLeft: '12px',
                      marginTop: '8px',
                      backgroundColor: payToVendor ? '#36939b' : '#ffffff',
                      '&:hover': { backgroundColor: payToVendor ? '#15585e' : '#faf8f2' },
                      color: payToVendor ? 'ffffff' : '#080600'
                    }}
                    onClick={() => this.setState({ payToVendor: !payToVendor })}
                  >
                    {' '}
                    Pay to Vendor{' '}
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      maxHeight: '40px',
                      marginLeft: '12px',
                      marginTop: '8px',
                      backgroundColor: !payToVendor ? '#36939b' : '#ffffff',
                      '&:hover': { backgroundColor: !payToVendor ? '#15585e' : '#faf8f2' },
                      color: !payToVendor ? 'ffffff' : '#080600'
                    }}
                    onClick={() => this.setState({ payToVendor: !payToVendor })}
                  >
                    {' '}
                    Pay to EE{' '}
                  </Button>
                </Box>

                {payToVendor && (
                  <Box sx={{ marginLeft: '25px' }}>
                    <AdvancedSearch
                      labelText={'Vendor'}
                      displayValueAccessorKey={'companyName'}
                      saveIdValueAccessorKey={'vendorId'}
                      searchingCriteria={[
                        { name: 'vendorId', title: 'Vendor Id' },
                        { name: 'companyName', title: 'Company Name' },
                        { name: 'city', title: 'City' },
                        { name: 'state', title: 'State' },
                        { name: 'phone', title: 'Company Phone' },
                        { name: 'email', title: 'Company Email' }
                      ]}
                      searchingCriteriaExtensions={[
                        { columnName: 'vendorId', width: 150 },
                        { columnName: 'companyName', width: 250 },
                        { name: 'city', width: 175 },
                        { name: 'state', width: 175 },
                        { columnName: 'phone', width: 175 },
                        { columnName: 'email', width: 175 }
                      ]}
                      searchingRows={vendors}
                      id={selectedVendorId}
                      value={selectedVendorName}
                      setIdValue={(id, value) => {
                        this.setState({ selectedVendorId: id, selectedVendorName: value });
                      }}
                      isReadOnly={false}
                    />
                    <Typography variant="caption" display="block" color="primary">
                      Required
                    </Typography>
                  </Box>
                )}

                {!payToVendor && (
                  <Box sx={{ marginLeft: '25px' }}>
                    <AdvancedSearch
                      labelText={'Authorization'}
                      displayValueAccessorKey={'transfereeName'}
                      saveIdValueAccessorKey={'authorizationId'}
                      searchingCriteria={[
                        { name: 'authorizationId', title: 'Auth Id' },
                        { name: 'transfereeName', title: 'Full Name' },
                        { name: 'departure', title: 'Departure Location' },
                        { name: 'destination', title: 'Destination Location' },
                        { name: 'clientName', title: 'Client' }
                      ]}
                      searchingCriteriaExtensions={[
                        { columnName: 'authorizationId', width: 150 },
                        { columnName: 'transfereeName', width: 250 },
                        { name: 'departure', width: 175 },
                        { name: 'destination', width: 175 },
                        { columnName: 'clientName', width: 175 }
                      ]}
                      searchingRows={
                        selectedClientName
                          ? authorizationSummaries.filter(a => a.clientName === selectedClientName)
                          : authorizationSummaries
                      }
                      id={selectedAuthId}
                      value={selectedAuthName}
                      setIdValue={(id, value) => {
                        if (id && value) {
                          let relevantAuthInfo = authorizationSummaries.find(a => a.authorizationId === id);
                          let relevantClientInfo = unmappedClientsAdjusted.find(c => c.clientName === relevantAuthInfo.clientName);
                          this.setState({
                            selectedAuthId: id,
                            selectedAuthName: value,
                            selectedClientId: relevantClientInfo.clientId,
                            selectedClientName: relevantAuthInfo.clientName
                          });
                        } else {
                          this.setState({ selectedAuthId: id, selectedAuthName: value });
                        }
                      }}
                      isReadOnly={false}
                    />
                    <Typography variant="caption" display="block" color="primary">
                      Required
                    </Typography>
                  </Box>
                )}
              </Box>
              <DialogActions sx={{ p: '1.25rem' }}>
                {isLoadingModal && <Spinner />}
                {!isLoadingModal && (
                  <>
                    <Button
                      onClick={() => this.setState({ showCreateVoucher: false })}
                      sx={{ color: '#41B7C0', '&:hover': { color: '#3d959c' } }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={this.createVoucher}
                      color="primary"
                      sx={{ backgroundColor: '#41B7C0', '&:hover': { backgroundColor: '#3d959c' } }}
                      type="submit"
                      variant="contained"
                      disabled={
                        selectedClientId == null ||
                        (payToVendor && (selectedVendorId == null || selectedVendorId === '')) ||
                        (!payToVendor && (selectedAuthId == null || selectedAuthId === ''))
                      }
                    >
                      Create Voucher
                    </Button>
                  </>
                )}
              </DialogActions>
            </Dialog>
          </>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    authorizations: { authorizationSummaries, vendors },
    expenses: { voucherSummaries, expenseLines, isLoading, clientPreferredExpenseCodes },
    clients: { clients, unmappedClients }
  } = state;
  return {
    voucherSummaries,
    expenseLines,
    clients,
    unmappedClients,
    isLoading,
    vendors,
    authorizationSummaries,
    clientPreferredExpenseCodes
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
    showToast,
    getTestApiV2,
    getVoucherSummaries,
    getExpenseLines,
    getAuthorizationSummaries,
    getVendors,
    postVoucherSummary,
    getAllPreferredExpenseCodes
  })
)(VoucherSummariesView);
