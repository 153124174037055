import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';
import MultiRowForm from 'modules/common/multiRowForm.component';
import Spinner from 'modules/common/spinner.component';
import { getSurveyQuestionTriggers, postSurveyQuestionTrigger, updateSurveyQuestionTrigger, deleteSurveyQuestionTrigger } from '../surveys.actions';
import { ServiceTypes, FieldTypes, ValueTypes } from './surveyQuestionConstants';

const SurveyQuestionTriggerCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { surveyQuestionTriggers, surveyQuestionId, isReadOnly } = props;

  const formFields = [
    {
      name: 'surveyQuestionTriggerId',
      type: 'hidden'
    },
    {
      name: 'surveyQuestionId',
      type: 'hidden'
    },
    { name: 'triggerServiceId', type: 'select', options: ServiceTypes, label: 'Service' },
    { name: 'triggerFieldName', type: 'select', options: FieldTypes, label: 'Field' },
    { name: 'triggerValue', type: 'select', options: ValueTypes, label: 'Value' }

  ];
  const formLabel = 'triggers';
  const addRowLabel = 'Add New Trigger';
  const header = { title: 'Question-Enabling Trigger Setup', iconPath: ['fas', 'flag'] };

  const createNewRowFunc = () => {
    return {
      surveyQuestionTriggerId: 0,
      surveyQuestionId: surveyQuestionId,
      triggerServiceId: -1,
      triggerFieldName: '',
      triggerValue: '',
    };
  };

  const saveForm = async formValues => {
    setIsLoading(true);

    formValues = formValues ?? [];
    const newTriggers = formValues.filter(formValue => formValue.surveyQuestionTriggerId === 0);
    const deletedTriggers = surveyQuestionTriggers.filter(
        trigger => !formValues.some(formValue => formValue.surveyQuestionTriggerId === trigger.surveyQuestionTriggerId)
      );
    const updatedTriggers = formValues.filter(
        formValue =>
          formValue.surveyQuestionTriggerId !== 0 &&
        surveyQuestionTriggers.some(
            trigger =>
              trigger.surveyQuestionTriggerId === formValue.surveyQuestionTriggerId &&
              (trigger.service !== formValue.service || trigger.field !== formValue.field)
          )
      );

    try {
        if(deletedTriggers.length > 0)
        {
            for (const trigger of deletedTriggers) {
                await props.deleteSurveyQuestionTrigger(trigger.surveyQuestionTriggerId);
            }
        }
        if(newTriggers.length > 0)
        {
            for (const trigger of newTriggers) {
                await props.postSurveyQuestionTrigger(trigger);
            }
        }
        if(updatedTriggers.length > 0)
        {
            for (const trigger of updatedTriggers) {
                await props.updateSurveyQuestionTrigger(trigger);
            }
        }
        await props.getSurveyQuestionTriggers(surveyQuestionId);
    } catch (error) {
        console.error('Error saving form:', error);
        props.showToast('Failed to update triggers, please try again later', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } finally {
        setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <MultiRowForm
          formValues={surveyQuestionTriggers && surveyQuestionTriggers.length > 0 ? surveyQuestionTriggers.map(item => ({ ...item })) : []}
          saveFunction={saveForm}
          formFields={formFields}
          createNewRowFunc={createNewRowFunc}
          formLabel={formLabel}
          addRowLabel={addRowLabel}
          isDisabled={isReadOnly}
          header={header}
        />
      )}
    </>
  );
};

export default compose(
  connect(null, {
    showToast,
    getSurveyQuestionTriggers,
    postSurveyQuestionTrigger,
    updateSurveyQuestionTrigger,
    deleteSurveyQuestionTrigger,
  })
)(SurveyQuestionTriggerCard);
