/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, Typography, Paper } from '@mui/material';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ApprovedExpenses from './approvedExpenses.component';
import React, { useEffect, useState } from 'react';
import SubmittedExpenses from './submittedExpenses.component';
import classNames from 'classnames';
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS } from 'styles/theme';
import { postVoucherSummary } from 'modules/expenses/expenses.actions';
import { POST_VOUCHER_SUMMARY_FAILURE } from 'modules/expenses/types';
import { getVoucherSummariesByAuth } from 'modules/expenses/expenses.actions';
import { GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE } from 'modules/expenses/types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { showToast } from 'modules/layout/layout.actions';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';

const styles = theme => ({
  container: {
    height: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`
    }
  },
  headerFixed: {
    zIndex: 4
  },
  estimateHeaderFixed: {
    paddingTop: '115px',
    zIndex: 2
  }
});

const Expenses = props => {
  const {authorizationSummaries, unmappedClients } = props;
  const { id, history } = props;
  const { expenseLines } = props.details?.authExpenses ?? [];
  const { employee, authClientPolicies  } = props.details?.authDetails

  const [selectedScreen, setSelectedScreen] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [voucherSummaries, setVoucherSummaries] = useState([]);

  useEffect(() => {

    async function fetchData() {          
          const resp = await props.getVoucherSummariesByAuth(id);
          if(resp.type === GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE) {
            props.showToast('Failed to get voucher summaries.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          }
          else{
            setVoucherSummaries(resp.response);
          }
         
        }


    const url = window.location.href;
    if (url.includes('approved')) {
      setSelectedScreen(2);
    } else {
      setSelectedScreen(1);
    }

    if(voucherSummaries && voucherSummaries.length === 0)
    {
      fetchData();
    }
  }, [voucherSummaries]);

  const handleToggle = screen => {
    setSelectedScreen(screen);
  };

  const createVoucher = async () => {
    setIsLoading(true);

    const resp = await props.postVoucherSummary({
      status: 1,
      payToVendor: false,
      clientId: authClientPolicies.clientId,
      clientName: unmappedClients?.find(s => s.moveTrackClientId === authClientPolicies.clientId)?.description || '',
      vendorId: null,
      vendorName: null,
      authorizationId: employee.authorizationId,
      authName: authorizationSummaries?.find(s => s.authorizationId === id)?.transfereeName || ''
    });

    if (resp.type === POST_VOUCHER_SUMMARY_FAILURE) {
      props.showToast('Failed to create new voucher, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
    else{
      history.push(`/vouchers/${resp.response.voucherId}`);
    }
  };


  return (
    <Box>
      {isLoading && <FullscreenSpinner />}
      {!isLoading &&
      <>
      <Paper square className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', width: '100%' }}>
          <Box sx={{ paddingLeft: '100px' }}>&nbsp;</Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                <InfoBox label="Authorization Name" value={`${employee?.firstName} ${employee?.lastName}`} />
                <InfoBox label="Policy" value={authClientPolicies?.clientPolicyCode} />
                <InfoBox label="Status" value="Active" />
                <InfoBox label="Move Cap" value="$5,000,000,000" />
                <InfoBox label="Budget Remaining" value="$4,999,998,001" />
              </Box>
            </Box>
          </Box>
          <Box sx={{ color: 'green', padding: '10px' }}>Repay Agreement Received</Box>
        </Box>
      </Paper>
      <Paper square className={classNames('position-fixed', props.classes.estimateHeaderFixed, props.classes.container)}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px', paddingBottom: '5px' }}>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  maxHeight: '40px',
                  marginLeft: '12px',
                  marginTop: '8px',
                  backgroundColor: selectedScreen === 1 ? '#36939b' : '#ffffff',
                  '&:hover': { backgroundColor: selectedScreen === 1 ? '#15585e' : '#faf8f2' },
                  color: selectedScreen === 1 ? 'ffffff' : '#080600'
                }}
                onClick={() => handleToggle(1)}
              >
                Submitted Expenses
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  maxHeight: '40px',
                  marginLeft: '12px',
                  marginTop: '8px',
                  backgroundColor: selectedScreen === 2 ? '#36939b' : '#ffffff',
                  '&:hover': { backgroundColor: selectedScreen === 2 ? '#15585e' : '#faf8f2' },
                  color: selectedScreen === 2 ? 'ffffff' : '#080600'
                }}
                onClick={() => handleToggle(2)}
              >
                Approved Expenses
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 2, paddingRight: '10px', paddingBottom: '5px' }}>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                maxHeight: '40px',
                marginLeft: '12px',
                marginTop: '8px'
              }}
              onClick={createVoucher}
            >
              Create Voucher
            </Button>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                maxHeight: '40px',
                marginLeft: '12px',
                marginTop: '8px'
              }}
              onClick={() => console.log('Create Vendor Invoice clicked')}
            >
              Create Vendor Invoice
            </Button>
          </Box>
        </Box>
      </Paper>
      <Box sx={{ paddingTop: '185px' }}>
        {selectedScreen === 1 ? (
          <SubmittedExpenses submittedExpenses={props.details?.authExpenses?.submittedExpenses} id={id} history={history} expenseLines={expenseLines} voucherSummaries={voucherSummaries} />
        ) : (
          <ApprovedExpenses expenses={props.details?.authExpenses?.submittedExpenses} expenseLines={expenseLines} id={id} history={history} />
        )}
      </Box>
      </>
      }
   </Box>
  );
};

const InfoBox = ({ label, value }) => (
  <Box>
    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
        {label}
      </Typography>
    </Box>
    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
        {value}
      </Typography>
    </Box>
  </Box>
);

const mapStateToProps = state => ({});

export default compose(withStyles(styles), connect(mapStateToProps, { postVoucherSummary, getVoucherSummariesByAuth, showToast }))(Expenses);
