import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';

const HhgAddressCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { segmentInfo, hideEdit, addresses } = props;
  const tabs = [{ header: 'Start/End Locations', order: 1, iconPath: ['fas', 'truck-moving'] }];

  const calculateMoveMiles = () => {
    if (segmentInfo.departureAddressId && segmentInfo.destinationAddressId) return Math.floor(Math.random() * 100);

    return '-';
  };

  const createAddressName = address => {
    const addressParts = [address.addressLineOne, address.city, address.state].filter(Boolean);
    return addressParts.length > 0 ? addressParts.join(', ') : '';
  };

  let addressesOptions = Array.isArray(addresses)
    ? addresses
        .map(s => ({
          id: s.addressId,
          title: createAddressName(s)
        }))
        .filter(option => option.title)
    : [];

  let moveMiles = calculateMoveMiles();

  const summaries = [
    {
      header: 'Start Location',
      accessorKey: 'departureAddressId',
      type: 'dropdown-v2',
      options: addressesOptions,
      required: false,
      value: segmentInfo ? segmentInfo.departureAddressId : '',
      tab: 1
    },
    {
      header: 'End Location',
      accessorKey: 'destinationAddressId',
      type: 'dropdown-v2',
      options: addressesOptions,
      required: false,
      value: segmentInfo ? segmentInfo.destinationAddressId : '',
      tab: 1
    },
    {
      header: 'Move Miles',
      type: 'number',
      required: false,
      tab: 1,
      hideInModal: true,
      value: moveMiles
    }
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    var request = { ...segmentInfo };
    request.destinationAddressId = values.destinationAddressId;
    request.departureAddressId = values.departureAddressId;

    var response = await props.updateHhgSegment(request);
    if (response.type === UPDATE_HHG_SEGMENT_FAILURE) {
      props.showToast('Failed to update destination/departure address, please try again later', TOAST_MESSAGE_SEVERITY_ERROR);
    }
    setIsLoading(false);
    return true;
  };

  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={2}
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={hideEdit}
      authorizationId={props.id}
      addresses={addresses || []}
      showAddressCard={true}
    />
  );
};

export default compose(
  connect(null, {
    showToast,
    updateHhgSegment
  })
)(HhgAddressCard);
