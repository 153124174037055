
export const GET_CANDIDATE_SERVICES_FAILURE = 'GET_CANDIDATE_SERVICES_FAILURE';
export const GET_CANDIDATE_SERVICES_REQUEST = 'GET_CANDIDATE_SERVICES_REQUEST';
export const GET_CANDIDATE_SERVICES_SUCCESS = 'GET_CANDIDATE_SERVICES_SUCCESS';

export const POST_CANDIDATE_SERVICES_FAILURE = 'POST_CANDIDATE_SERVICES_FAILURE';
export const POST_CANDIDATE_SERVICES_REQUEST = 'POST_CANDIDATE_SERVICES_REQUEST';
export const POST_CANDIDATE_SERVICES_SUCCESS = 'POST_CANDIDATE_SERVICES_SUCCESS';

export const UPDATE_CANDIDATE_SERVICES_FAILURE = 'UPDATE_CANDIDATE_SERVICES_FAILURE';
export const UPDATE_CANDIDATE_SERVICES_REQUEST = 'UPDATE_CANDIDATE_SERVICES_REQUEST';
export const UPDATE_CANDIDATE_SERVICES_SUCCESS = 'UPDATE_CANDIDATE_SERVICES_SUCCESS';

export const GET_INTERN_SERVICES_FAILURE = 'GET_INTERN_SERVICES_FAILURE';
export const GET_INTERN_SERVICES_REQUEST = 'GET_INTERN_SERVICES_REQUEST';
export const GET_INTERN_SERVICES_SUCCESS = 'GET_INTERN_SERVICES_SUCCESS';

export const POST_INTERN_SERVICES_FAILURE = 'POST_INTERN_SERVICES_FAILURE';
export const POST_INTERN_SERVICES_REQUEST = 'POST_INTERN_SERVICES_REQUEST'; 
export const POST_INTERN_SERVICES_SUCCESS = 'POST_INTERN_SERVICES_SUCCESS';

export const UPDATE_INTERN_SERVICES_FAILURE = 'UPDATE_INTERN_SERVICES_FAILURE';
export const UPDATE_INTERN_SERVICES_REQUEST = 'UPDATE_INTERN_SERVICES_REQUEST';
export const UPDATE_INTERN_SERVICES_SUCCESS = 'UPDATE_INTERN_SERVICES_SUCCESS';

export const GET_ALL_CANDIDATE_TRIPS_FAILURE = 'GET_ALL_CANDIDATE_TRIPS_FAILURE';
export const GET_ALL_CANDIDATE_TRIPS_REQUEST = 'GET_ALL_CANDIDATE_TRIPS_REQUEST';
export const GET_ALL_CANDIDATE_TRIPS_SUCCESS = 'GET_ALL_CANDIDATE_TRIPS_SUCCESS';

export const GET_CANDIDATE_TRIP_FAILURE = 'GET_CANDIDATE_TRIP_FAILURE';
export const GET_CANDIDATE_TRIP_REQUEST = 'GET_CANDIDATE_TRIP_REQUEST';
export const GET_CANDIDATE_TRIP_SUCCESS = 'GET_CANDIDATE_TRIP_SUCCESS';

export const POST_CANDIDATE_TRIP_FAILURE = 'POST_CANDIDATE_TRIP_FAILURE';
export const POST_CANDIDATE_TRIP_REQUEST = 'POST_CANDIDATE_TRIP_REQUEST';
export const POST_CANDIDATE_TRIP_SUCCESS = 'POST_CANDIDATE_TRIP_SUCCESS';

export const UPDATE_CANDIDATE_TRIP_FAILURE = 'UPDATE_CANDIDATE_TRIP_FAILURE';
export const UPDATE_CANDIDATE_TRIP_REQUEST = 'UPDATE_CANDIDATE_TRIP_REQUEST';
export const UPDATE_CANDIDATE_TRIP_SUCCESS = 'UPDATE_CANDIDATE_TRIP_SUCCESS';

export const DELETE_CANDIDATE_TRIP_FAILURE = 'DELETE_CANDIDATE_TRIP_FAILURE';
export const DELETE_CANDIDATE_TRIP_REQUEST = 'DELETE_CANDIDATE_TRIP_REQUEST';
export const DELETE_CANDIDATE_TRIP_SUCCESS = 'DELETE_CANDIDATE_TRIP_SUCCESS';

export const GET_COST_ESTIMATES_FAILURE = 'GET_COST_ESTIMATES_FAILURE';
export const GET_COST_ESTIMATES_REQUEST = 'GET_COST_ESTIMATES_REQUEST';
export const GET_COST_ESTIMATES_SUCCESS = 'GET_COST_ESTIMATES_SUCCESS';

export const POST_COST_ESTIMATES_FAILURE = 'POST_COST_ESTIMATES_FAILURE';
export const POST_COST_ESTIMATES_REQUEST = 'POST_COST_ESTIMATES_REQUEST';
export const POST_COST_ESTIMATES_SUCCESS = 'POST_COST_ESTIMATES_SUCCESS';

export const UPDATE_COST_ESTIMATES_FAILURE = 'UPDATE_COST_ESTIMATES_FAILURE';
export const UPDATE_COST_ESTIMATES_REQUEST = 'UPDATE_COST_ESTIMATES_REQUEST';
export const UPDATE_COST_ESTIMATES_SUCCESS = 'UPDATE_COST_ESTIMATES_SUCCESS';

// Travel Booking Request types added 2/26/2025 via AB#1303
export const GET_TRAVEL_BOOKING_REQUESTS_BY_AUTH_FAILURE = 'GET_TRAVEL_BOOKING_REQUESTS_BY_AUTH_FAILURE';
export const GET_TRAVEL_BOOKING_REQUESTS_BY_AUTH_REQUEST = 'GET_TRAVEL_BOOKING_REQUESTS_BY_AUTH_REQUEST';
export const GET_TRAVEL_BOOKING_REQUESTS_BY_AUTH_SUCCESS = 'GET_TRAVEL_BOOKING_REQUESTS_BY_AUTH_SUCCESS';

export const GET_TRAVEL_BOOKING_REQUESTS_BY_TRIP_FAILURE = 'GET_TRAVEL_BOOKING_REQUESTS_BY_TRIP_FAILURE';
export const GET_TRAVEL_BOOKING_REQUESTS_BY_TRIP_REQUEST = 'GET_TRAVEL_BOOKING_REQUESTS_BY_TRIP_REQUEST';
export const GET_TRAVEL_BOOKING_REQUESTS_BY_TRIP_SUCCESS = 'GET_TRAVEL_BOOKING_REQUESTS_BY_TRIP_SUCCESS';

export const GET_TRAVEL_BOOKING_REQUESTS_FAILURE = 'GET_TRAVEL_BOOKING_REQUESTS_FAILURE';
export const GET_TRAVEL_BOOKING_REQUESTS_REQUEST = 'GET_TRAVEL_BOOKING_REQUESTS_REQUEST';
export const GET_TRAVEL_BOOKING_REQUESTS_SUCCESS = 'GET_TRAVEL_BOOKING_REQUESTS_SUCCESS';

export const POST_TRAVEL_BOOKING_REQUESTS_FAILURE = 'POST_TRAVEL_BOOKING_REQUESTS_FAILURE';
export const POST_TRAVEL_BOOKING_REQUESTS_REQUEST = 'POST_TRAVEL_BOOKING_REQUESTS_REQUEST';
export const POST_TRAVEL_BOOKING_REQUESTS_SUCCESS = 'POST_TRAVEL_BOOKING_REQUESTS_SUCCESS';

export const UPDATE_TRAVEL_BOOKING_REQUESTS_FAILURE = 'UPDATE_TRAVEL_BOOKING_REQUESTS_FAILURE';
export const UPDATE_TRAVEL_BOOKING_REQUESTS_REQUEST = 'UPDATE_TRAVEL_BOOKING_REQUESTS_REQUEST';
export const UPDATE_TRAVEL_BOOKING_REQUESTS_SUCCESS = 'UPDATE_TRAVEL_BOOKING_REQUESTS_SUCCESS';

export const DELETE_TRAVEL_BOOKING_REQUESTS_FAILURE = 'DELETE_TRAVEL_BOOKING_REQUESTS_FAILURE';
export const DELETE_TRAVEL_BOOKING_REQUESTS_REQUEST = 'DELETE_TRAVEL_BOOKING_REQUESTS_REQUEST';
export const DELETE_TRAVEL_BOOKING_REQUESTS_SUCCESS = 'DELETE_TRAVEL_BOOKING_REQUESTS_SUCCESS';
