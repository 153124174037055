import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_ESTIMATE_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgEstimate } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  Fab,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chipClickable: {
    cursor: 'pointer'
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important'
  },
  spacingX: {
    marginRight: theme.spacing(1)
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2)
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px'
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important'
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important'
  },
  editModalInput: {
    minWidth: '332px'
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px'
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0
  }
}));

const EditCardModal = props => {
  const { open, onSubmit, isLoading, onClose, estimateInfo } = props;
  const classes = useStyles();

  const [discountRateEstimate, setDiscountRateEstimate] = useState(estimateInfo ? estimateInfo.discountRateEstimate : 0);
  const [discountRateActual] = useState(estimateInfo ? estimateInfo.discountRateActual : 0);
  const [storageDiscountRateEstimate, setStorageDiscountRateEstimate] = useState(
    estimateInfo ? estimateInfo.storageDiscountRateEstimate : 0
  );
  const [storageDiscountRateActual] = useState(estimateInfo ? estimateInfo.storageDiscountRateActual : 0);
  const [landWeightEstimate, setLandWeightEstimate] = useState(estimateInfo ? estimateInfo.landWeightEstimate : 0);
  const [airWeightEstimate, setAirWeightEstimate] = useState(estimateInfo ? estimateInfo.airWeightEstimate : 0);
  const [seaWeightEstimate, setSeaWeightEstimate] = useState(estimateInfo ? estimateInfo.seaWeightEstimate : 0);

  const [weightActual] = useState(estimateInfo ? estimateInfo.weightActual : 0);
  const [storageEstimate, setStorageEstimate] = useState(estimateInfo ? estimateInfo.storageEstimate : 0);
  const [storageActual] = useState(estimateInfo ? estimateInfo.storageActual : 0);
  const [doorToDoorEstimate, setDoorToDoorEstimate] = useState(estimateInfo ? estimateInfo.doorToDoorEstimate : 0);
  const [doorToDoorActual] = useState(estimateInfo ? estimateInfo.doorToDoorActual : 0);
  const [exitTransitProtectionEstimate, setExitTransitProtectionEstimate] = useState(
    estimateInfo ? estimateInfo.exitTransitProtectionEstimate : 0
  );
  const [exitTransitProtectionActual] = useState(estimateInfo ? estimateInfo.exitTransitProtectionActual : 0);
  const [thirdPartyEstimates, setThirdPartyEstimates] = useState(estimateInfo ? estimateInfo.thirdPartyEstimates : 0);
  const [thirdPartyActuals] = useState(estimateInfo ? estimateInfo.thirdPartyActuals : 0);

  const [isDirty, setIsDirty] = useState(false);

  const onSubmitModal = e => {
    e.preventDefault();

    const values = {
      discountRateEstimate,
      discountRateActual,
      storageDiscountRateEstimate,
      storageDiscountRateActual,
      landWeightEstimate,
      airWeightEstimate,
      seaWeightEstimate,
      weightActual,
      storageEstimate,
      storageActual,
      doorToDoorEstimate,
      doorToDoorActual,
      exitTransitProtectionEstimate,
      exitTransitProtectionActual,
      thirdPartyEstimates,
      thirdPartyActuals
    };

    onSubmit(values);
  };

  useEffect(() => {
    if (estimateInfo) {
      if (
        (!(discountRateEstimate === 0 && estimateInfo.discountRateEstimate == null) &&
          discountRateEstimate !== estimateInfo.discountRateEstimate) ||
        (!(discountRateActual === 0 && estimateInfo.discountRateActual == null) &&
          discountRateActual !== estimateInfo.discountRateActual) ||
        (!(storageDiscountRateEstimate === 0 && estimateInfo.storageDiscountRateEstimate == null) &&
          storageDiscountRateEstimate !== estimateInfo.storageDiscountRateEstimate) ||
        (!(storageDiscountRateActual === 0 && estimateInfo.storageDiscountRateActual == null) &&
          storageDiscountRateActual !== estimateInfo.storageDiscountRateActual) ||
        (!(landWeightEstimate === 0 && estimateInfo.landWeightEstimate == null) && landWeightEstimate !== estimateInfo.landWeightEstimate) ||
        (!(airWeightEstimate === 0 && estimateInfo.airWeightEstimate == null) && airWeightEstimate !== estimateInfo.airWeightEstimate) ||
        (!(seaWeightEstimate === 0 && estimateInfo.seaWeightEstimate == null) && seaWeightEstimate !== estimateInfo.seaWeightEstimate) ||
        (!(weightActual === 0 && estimateInfo.weightActual == null) && weightActual !== estimateInfo.weightActual) ||
        (!(storageEstimate === 0 && estimateInfo.storageEstimate == null) && storageEstimate !== estimateInfo.storageEstimate) ||
        (!(storageActual === 0 && estimateInfo.storageActual == null) && storageActual !== estimateInfo.storageActual) ||
        (!(doorToDoorEstimate === 0 && estimateInfo.doorToDoorEstimate == null) &&
          doorToDoorEstimate !== estimateInfo.doorToDoorEstimate) ||
        (!(doorToDoorActual === 0 && estimateInfo.doorToDoorActual == null) && doorToDoorActual !== estimateInfo.doorToDoorActual) ||
        (!(exitTransitProtectionEstimate === 0 && estimateInfo.exitTransitProtectionEstimate == null) &&
          exitTransitProtectionEstimate !== estimateInfo.exitTransitProtectionEstimate) ||
        (!(exitTransitProtectionActual === 0 && estimateInfo.exitTransitProtectionActual == null) &&
          exitTransitProtectionActual !== estimateInfo.exitTransitProtectionActual) ||
        (!(thirdPartyEstimates === 0 && estimateInfo.thirdPartyEstimates == null) &&
          thirdPartyEstimates !== estimateInfo.thirdPartyEstimates) ||
        (!(thirdPartyActuals === 0 && estimateInfo.thirdPartyActuals == null) && thirdPartyActuals !== estimateInfo.thirdPartyActuals)
      ) {
        setIsDirty(true);
      }
    }
  }, [
    discountRateEstimate,
    discountRateActual,
    storageDiscountRateEstimate,
    storageDiscountRateActual,
    landWeightEstimate,
    airWeightEstimate,
    seaWeightEstimate,
    weightActual,
    storageEstimate,
    storageActual,
    doorToDoorEstimate,
    doorToDoorActual,
    exitTransitProtectionEstimate,
    exitTransitProtectionActual,
    thirdPartyEstimates,
    thirdPartyActuals,
    estimateInfo
  ]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={onSubmitModal} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Tooltip title="Estimates" key="Estimates" placement="right">
              <FontAwesomeIcon icon={['fas', 'money-check-dollar']} size="3x" />
            </Tooltip>
            <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
              Estimates
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem'
            }}
          >
            <Grid container spacing={2}>
              <Grid item md="4" />
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Estimates
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4" />
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Discount Rate
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <TextField
                    key={'discountRateEstimate'}
                    label={''}
                    name={'discountRateEstimate'}
                    value={discountRateEstimate}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">%</InputAdornment>
                    }}
                    allowDecimal={true}
                    onChange={e => setDiscountRateEstimate(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="4" />
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Storage Discount Rate
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <TextField
                    key={'storageDiscountRateEstimate'}
                    label={''}
                    name={'storageDiscountRateEstimate'}
                    value={storageDiscountRateEstimate}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">%</InputAdornment>
                    }}
                    allowDecimal={true}
                    onChange={e => setStorageDiscountRateEstimate(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="4" />
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Weight
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <TextField
                    key={'landWeightEstimate'}
                    label={''}
                    name={'landWeightEstimate'}
                    value={landWeightEstimate}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">lb</InputAdornment>
                    }}
                    allowDecimal={true}
                    onChange={e => setLandWeightEstimate(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="4" />
              <Grid item md="4">
                <Box>
                  <TextField
                    key={'airWeightEstimate'}
                    label={''}
                    name={'airWeightEstimate'}
                    value={airWeightEstimate}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">lb</InputAdornment>
                    }}
                    allowDecimal={true}
                    onChange={e => setAirWeightEstimate(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="4" />
              <Grid item md="4">
                <Box>
                  <TextField
                    key={'seaWeightEstimate'}
                    label={''}
                    name={'seaWeightEstimate'}
                    value={seaWeightEstimate}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">lb</InputAdornment>
                    }}
                    allowDecimal={true}
                    onChange={e => setSeaWeightEstimate(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="4" />
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Door to Door
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <TextField
                    key={'doorToDoorEstimate'}
                    label={''}
                    name={'doorToDoorEstimate'}
                    value={doorToDoorEstimate}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    allowDecimal={true}
                    onChange={e => setDoorToDoorEstimate(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="4" />

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Storage
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <TextField
                    key={'storageEstimate'}
                    label={''}
                    name={'storageEstimate'}
                    value={storageEstimate}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    allowDecimal={true}
                    onChange={e => setStorageEstimate(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="4" />
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Exit Transit Protection
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <TextField
                    key={'exitTransitProtectionEstimate'}
                    label={''}
                    name={'exitTransitProtectionEstimate'}
                    value={exitTransitProtectionEstimate}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    allowDecimal={true}
                    onChange={e => setExitTransitProtectionEstimate(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="4" />
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Third Party Estimates
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <TextField
                    key={'thirdPartyEstimates'}
                    label={''}
                    name={'thirdPartyEstimates'}
                    value={thirdPartyEstimates}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    allowDecimal={true}
                    onChange={e => setThirdPartyEstimates(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="4" />
            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained" disabled={!isDirty}>
              Save
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

const GoodsEstimatesCard = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [editingModalOpen, setEditingModalOpen] = useState(false);

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const { estimateInfo, itemsTotal } = props;

  const updateFields = async values => {
    setIsLoading(true);

    let newVals = { ...estimateInfo };
    newVals.discountRateEstimate = values.discountRateEstimate;
    newVals.discountRateActual = values.discountRateActual;
    newVals.storageDiscountRateEstimate = values.storageDiscountRateEstimate;
    newVals.storageDiscountRateActual = values.storageDiscountRateActual;
    newVals.weightEstimate = values.weightEstimate;
    newVals.weightActual = values.weightActual;
    newVals.storageEstimate = values.storageEstimate;
    newVals.storageActual = values.storageActual;
    newVals.doorToDoorEstimate = values.doorToDoorEstimate;
    newVals.doorToDoorActual = values.doorToDoorActual;
    newVals.exitTransitProtectionEstimate = values.exitTransitProtectionEstimate;
    newVals.exitTransitProtectionActual = values.exitTransitProtectionActual;
    newVals.thirdPartyEstimates = values.thirdPartyEstimates;
    newVals.thirdPartyActuals = values.thirdPartyActuals;

    const resp = await props.updateHhgEstimate(newVals);
    if (resp.type === UPDATE_HHG_ESTIMATE_FAILURE) {
      props.showToast('Failed to update the estimate info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else {
      setIsLoading(false);
      return true;
    }
  };

  const handleSaveEdits = async values => {
    // Send update call to parent to update and refresh values.
    const resp = await updateFields(values);
    if (!resp) {
      showToast('Failed to update card data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    }
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };

  useEffect(() => {}, [estimateInfo]);

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Estimates" key="Estimates" placement="right">
                  <FontAwesomeIcon icon={['fas', 'money-check-dollar']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Estimates
                </Typography>
              </div>
              {!props.hideEdit && (
                <>
                  <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                    <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                  </Fab>
                </>
              )}
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={2}>
              <Grid item md="6" />
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Estimates
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="6">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Discount Rate
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {estimateInfo ? estimateInfo.discountRateEstimate : 0.0}%
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="6">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Storage Discount Rate
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {estimateInfo ? estimateInfo.storageDiscountRateEstimate : 0.0}%
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="6">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Land Weight
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {estimateInfo ? estimateInfo.landWeightEstimate : 0.0} lb
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="6">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Air Weight
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {estimateInfo ? estimateInfo.airWeightEstimate : 0.0} lb
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="6">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Sea Weight
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {estimateInfo ? estimateInfo.seaWeightEstimate : 0.0} lb
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="6">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Door to Door
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      ${estimateInfo ? estimateInfo.doorToDoorEstimate : 0.0}
                    </Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item md="6">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Storage
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      ${estimateInfo ? estimateInfo.storageEstimate : 0.0}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="6">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Exit Transit Protection
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      ${estimateInfo ? estimateInfo.exitTransitProtectionEstimate : 0.0}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="6">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Third Party Estimates
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      ${estimateInfo ? estimateInfo.thirdPartyEstimates : 0.0}
                    </Typography>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
      {editingModalOpen && (
        <EditCardModal
          classes={classes}
          tab={0}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={() => setEditingModalOpen(false)}
          onSubmit={handleSaveEdits}
          estimateInfo={estimateInfo}
          itemsTotal={itemsTotal}
        />
      )}
    </ThemeProvider>
  );
};

export default compose(
  connect(null, {
    updateHhgEstimate,
    showToast
  })
)(GoodsEstimatesCard);
