import { CALL_INTAKE_V2_API } from 'middleware/intakeV2Api';
import {
  DELETE_RECURRING_PAYABLES_FAILURE,
  DELETE_RECURRING_PAYABLES_REQUEST,
  DELETE_RECURRING_PAYABLES_SUCCESS,
  GET_RECURRING_PAYABLES_FAILURE,
  GET_RECURRING_PAYABLES_REQUEST,
  GET_RECURRING_PAYABLES_SUCCESS,
  POST_RECURRING_PAYABLES_FAILURE,
  POST_RECURRING_PAYABLES_REQUEST,
  POST_RECURRING_PAYABLES_SUCCESS,
  UPDATE_RECURRING_PAYABLES_FAILURE,
  UPDATE_RECURRING_PAYABLES_REQUEST,
  UPDATE_RECURRING_PAYABLES_SUCCESS
} from './recurringPayables.types';

export const postVoucherRecurringPayable = values => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_RECURRING_PAYABLES_REQUEST, POST_RECURRING_PAYABLES_SUCCESS, POST_RECURRING_PAYABLES_FAILURE],
      authenticated: true,
      endpoint: 'api/vouchers/recurringPayables',
      method: 'POST',
      payload: values
    }
  };
};

export const updateVoucherRecurringPayable = (values, id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_RECURRING_PAYABLES_REQUEST, UPDATE_RECURRING_PAYABLES_SUCCESS, UPDATE_RECURRING_PAYABLES_FAILURE],
      authenticated: true,
      endpoint: `api/vouchers/recurringPayables/${id}`,
      method: 'PUT',
      payload: values
    }
  };
};

export const deleteVoucherRecurringPayable = id => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_RECURRING_PAYABLES_REQUEST, DELETE_RECURRING_PAYABLES_SUCCESS, DELETE_RECURRING_PAYABLES_FAILURE],
      authenticated: true,
      endpoint: `api/vouchers/recurringPayables/${id}`,
      method: 'DELETE'
    }
  };
};

export const getAllVoucherRecurringPayable = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_RECURRING_PAYABLES_REQUEST, GET_RECURRING_PAYABLES_SUCCESS, GET_RECURRING_PAYABLES_FAILURE],
      authenticated: true,
      endpoint: `api/vouchers/recurringPayables`,
      method: 'GET'
    }
  };
};
