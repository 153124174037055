/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { postCommentsAndNotes, updateCommentsAndNotes } from 'modules/authorizations/store/actions/commentsAndNotes.actions';
import { POST_COMMENT_AND_NOTES_FAILURE } from 'modules/authorizations/store/types/commentsAndNotesTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { showToast } from 'modules/layout/layout.actions';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import { Close, Delete } from '@mui/icons-material';
import { formatDate, formatDateForInput } from 'utilities/common';
import Spinner from 'modules/common/spinner.component';
import {
  ExceptionApprovedByOptions,
  ExceptionCategoryOptions,
  ExceptionCodeOptions,
  ExceptionRequestTypeOptions,
  ExceptionStatusOptions,
  RegardingOptions,
  ServiceOptions
} from './commentAndNotesConstants';
import { createDefaultComment } from './commentsAndNotes.component';

const ServiceTriggeredComment = props => {
  const { authorizationId, serviceOptionId, displayText, setShowTriggeredComment } = props;

  const [open, setOpen] = useState(true);
  const [openAttachments, setOpenAttachments] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [temporaryAttachments, setTemporaryAttachments] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isCancelButtonDisabled, setIsCancelButtonDisabled] = useState(true);
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);
  const [commentFormData, setCommentFormData] = useState(createDefaultComment(authorizationId, serviceOptionId, localStorage, null, 'System'));
  const [showExpenseForm, setShowExpenseForm] = useState(commentFormData?.regardingId === 23);

  const checkRequiredValues = () => {
    if (
      commentFormData &&
      commentFormData?.regardingId &&
      commentFormData?.serviceId &&
      commentFormData?.subject &&
      commentFormData?.subject !== '' &&
      commentFormData?.commentContent &&
      commentFormData?.commentContent !== ''
    ) {
      if (commentFormData?.regardingId !== 23) {
        return true;
      }
      if (commentFormData?.exceptionReason && commentFormData?.exceptionReason !== '' && commentFormData?.exceptionRequestedMoney) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isFormDirty()) {
      setIsCancelButtonDisabled(false);

      if (checkRequiredValues()) {
        setIsSubmitDisable(false);
      } else {
        setIsSubmitDisable(true);
      }
    } else {
      if (checkRequiredValues()) {
        setIsSubmitDisable(false);
      } else {
        setIsSubmitDisable(true);
      }
      setIsCancelButtonDisabled(true);
    }
  }, [commentFormData]);

  useEffect(() => {
    if (commentFormData?.regardingId === 23) {
      setShowExpenseForm(true);
    } else {
      setShowExpenseForm(false);
    }
  }, [commentFormData?.regardingId]);

  const handleOpenAttachments = () => setOpenAttachments(true);

  const handleCloseAttachments = () => {
    setTemporaryAttachments([]);
    setOpenAttachments(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setShowTriggeredComment(false);
  };

  const onDrop = acceptedFiles => {
    setTemporaryAttachments([...temporaryAttachments, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleRemoveAttachment = index => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  const isFormDirty = () => {
    const relevantFields = [
      'serviceId',
      'regardingId',
      'transfereeViewable',
      'clientViewable',
      'pinnedComment',
      'subject',
      'commentContent',
      'exceptionReason',
      'exceptionRequestedMoney',
      'exceptionCategoryId',
      'exceptionCodeId',
      'exceptionRequestTypeId',
      'exceptionApprovedDate',
      'exceptionActualAmount',
      'exceptionApprovedAmount',
      'exceptionApprovedBy'
    ];

    const filteredCommentFormData = _.pick(commentFormData, relevantFields);

    const defaultFormData = {
      serviceId: null,
      regardingId: null,
      transfereeViewable: false,
      clientViewable: false,
      pinnedComment: false,
      subject: null,
      commentContent: null,
      exceptionReason: null,
      exceptionRequestedMoney: null,
      exceptionCategoryId: null,
      exceptionCodeId: null,
      exceptionRequestTypeId: null,
      exceptionApprovedDate: null,
      exceptionActualAmount: null,
      exceptionApprovedAmount: null,
      exceptionApprovedBy: null
    };

    return !_.isEqual(filteredCommentFormData, defaultFormData);
  };

  const handleSaveFile = () => {
    setAttachments([...attachments, ...temporaryAttachments]);
    handleCloseAttachments();
  };

  const cancelComment = () => {
    setCommentFormData(createDefaultComment(authorizationId, serviceOptionId, localStorage));
  };

  const saveComment = async () => {
    setIsLoading(true);
    const resp = await props.postCommentsAndNotes(commentFormData);
    if (resp.type === POST_COMMENT_AND_NOTES_FAILURE) {
      props.showToast('Failed to create comment, please try again later', TOAST_MESSAGE_SEVERITY_ERROR);
    } else {
      setOpen(false);
      setShowTriggeredComment(false);
    }
    setIsLoading(false);
  };

  const handleRegardingValueChange = value => {
    if (value === 23) {
      setShowExpenseForm(true);
    } else {
      setShowExpenseForm(false);
    }
    setCommentFormData({ ...commentFormData, regardingId: value });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth="md">
        <DialogTitle>
          {displayText}
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              pointerEvents: 'auto',
              opacity: 1
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Paper
                  square
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    padding: '16px',
                    border: 'none',
                    borderBottom: '1px solid #ccc',
                    boxShadow: 'none'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '20px'
                    }}
                  >
                    <Box textAlign="center">
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                        Added By
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {commentFormData?.addedBy}
                      </Typography>
                    </Box>
                    <Box textAlign="center">
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                        Created Party
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {commentFormData?.createdParty}
                      </Typography>
                    </Box>
                    <Box textAlign="center">
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                        Modified By
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {commentFormData?.modifiedBy}
                      </Typography>
                    </Box>
                    <Box textAlign="center">
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                        Created Date
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {formatDate(commentFormData?.createdDate)}
                      </Typography>
                    </Box>
                  </Box>
                  <Link
                    href="#"
                    sx={{
                      marginLeft: 'auto',
                      color: '#0000EE',
                      textDecoration: 'underline',
                      fontWeight: 500,
                      fontSize: '1rem',
                      paddingRight: '10px'
                    }}
                  >
                    Letters
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs={12} sx={{ padding: '8px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                  }}
                >
                  <Box>
                    <FormControl sx={{ marginTop: '6px' }}>
                      <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'serviceSelector'}>
                        Service
                      </InputLabel>
                      <Select
                        labelId={'serviceSelector'}
                        key={'serviceSelector'}
                        label={'Service'}
                        name={'service'}
                        required={true}
                        value={commentFormData?.serviceId || ''}
                        margin="dense"
                        variant="standard"
                        sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                        onChange={e => setCommentFormData({ ...commentFormData, serviceId: e.target.value })}
                      >
                        {ServiceOptions.map(opt => (
                          <MenuItem key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                            {opt.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl sx={{ marginTop: '6px' }}>
                      <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'regardingSelector'}>
                        Regarding
                      </InputLabel>
                      <Select
                        labelId={'regardingSelector'}
                        key={'regardingSelector'}
                        label={'Regarding'}
                        name={'regarding'}
                        required={true}
                        value={commentFormData?.regardingId || ''}
                        margin="dense"
                        variant="standard"
                        sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                        onChange={e => handleRegardingValueChange(e.target.value)}
                      >
                        {RegardingOptions.map(opt => (
                          <MenuItem key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                            {opt.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                      <FormControlLabel
                        key="transfereeViewable"
                        name="transfereeViewable"
                        labelPlacement="start"
                        label="EE Viewable"
                        control={
                          <Checkbox
                            checked={commentFormData?.transfereeViewable || false}
                            onChange={e => setCommentFormData({ ...commentFormData, transfereeViewable: e.target.checked })}
                          />
                        }
                        sx={{ marginTop: '6px', minWidth: '90px' }}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                      <FormControlLabel
                        key="clientViewable"
                        name="clientViewable"
                        labelPlacement="start"
                        label="Client Viewable"
                        control={
                          <Checkbox
                            checked={commentFormData?.clientViewable || false}
                            onChange={e => setCommentFormData({ ...commentFormData, clientViewable: e.target.checked })}
                          />
                        }
                        sx={{ marginTop: '6px', minWidth: '90px' }}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                      <FormControlLabel
                        key="pinnedComment"
                        name="pinnedComment"
                        labelPlacement="start"
                        label="Pinned"
                        control={
                          <Checkbox
                            checked={commentFormData?.pinnedComment || false}
                            onChange={e => setCommentFormData({ ...commentFormData, pinnedComment: e.target.checked })}
                          />
                        }
                        sx={{ marginTop: '6px', minWidth: '90px' }}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ padding: '8px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <TextField
                      key={'subject'}
                      label={'Subject'}
                      name={'subject'}
                      required={true}
                      value={commentFormData?.subject || ''}
                      margin="dense"
                      multiline
                      variant="standard"
                      rows={1}
                      sx={{
                        width: '100%',
                        maxWidth: '600px',
                        minWidth: '200px'
                      }}
                      onChange={e => setCommentFormData({ ...commentFormData, subject: e.target.value })}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ padding: '8px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <TextField
                      label={'Comment'}
                      name={'commentContent'}
                      required={true}
                      value={commentFormData?.commentContent || ''}
                      margin="dense"
                      multiline
                      variant="standard"
                      rows={3}
                      fullWidth
                      sx={{
                        width: '100%',
                        maxWidth: '600px',
                        minWidth: '200px'
                      }}
                      onChange={e => setCommentFormData({ ...commentFormData, commentContent: e.target.value })}
                    />
                  </Box>
                </Box>
              </Grid>
              {!showExpenseForm && (
                <Grid item xs={12} sx={{ padding: '8px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '16px',
                      marginTop: '16px'
                    }}
                  >
                    {attachments.map((attachment, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          backgroundColor: '#f9f9f9',
                          padding: '8px',
                          borderRadius: '4px',
                          maxWidth: 'calc(100% - 16px)'
                        }}
                      >
                        <Tooltip arrow placement="top" title="Delete">
                          <IconButton color="error" onClick={() => handleRemoveAttachment(index)}>
                            <Delete />
                          </IconButton>
                        </Tooltip>
                        <Link
                          href={attachment.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            color: '#007bff',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                          }}
                        >
                          {attachment.name}
                        </Link>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              )}
              {showExpenseForm && (
                <>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      alignSelf: 'flex-end',
                      paddingLeft: '16px'
                    }}
                  >
                    <Paper
                      sx={{
                        padding: 2,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        backgroundColor: 'transparent',
                        width: '100%'
                      }}
                    >
                      <Typography variant="h6" sx={{ marginBottom: 2 }}>
                        Exception
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            label="Exception Reason"
                            name="exceptionReason"
                            required={true}
                            value={commentFormData?.exceptionReason}
                            margin="dense"
                            multiline
                            rows={2}
                            variant="standard"
                            fullWidth
                            onChange={e => setCommentFormData({ ...commentFormData, exceptionReason: e.target.value })}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Requested $"
                            name="exceptionRequestedMoney"
                            required={true}
                            value={commentFormData?.exceptionRequestedMoney}
                            margin="dense"
                            variant="standard"
                            type="number"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            onChange={e => setCommentFormData({ ...commentFormData, exceptionRequestedMoney: e.target.value })}
                            sx={{ width: '100%' }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth margin="dense">
                            <InputLabel id="exceptionStatus">Status</InputLabel>
                            <Select
                              labelId="exceptionStatus"
                              value={commentFormData?.exceptionStatusId || ''}
                              onChange={e => setCommentFormData({ ...commentFormData, exceptionStatusId: e.target.value })}
                              variant="standard"
                            >
                              {ExceptionStatusOptions.map(opt => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth margin="dense">
                            <InputLabel id="approvedBy">Approved By</InputLabel>
                            <Select
                              labelId="approvedBy"
                              value={commentFormData?.exceptionApprovedBy || ''}
                              onChange={e => setCommentFormData({ ...commentFormData, exceptionApprovedBy: e.target.value })}
                              variant="standard"
                            >
                              {ExceptionApprovedByOptions.map((opt, index) => (
                                <MenuItem key={index} value={opt}>
                                  {opt}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth margin="dense">
                            <InputLabel id="exceptionCategory">Category</InputLabel>
                            <Select
                              labelId="exceptionCategory"
                              value={commentFormData?.exceptionCategoryId || ''}
                              onChange={e => setCommentFormData({ ...commentFormData, exceptionCategoryId: e.target.value })}
                              variant="standard"
                            >
                              {ExceptionCategoryOptions.map(opt => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Approved Date"
                            name="exceptionApprovedDate"
                            type="date"
                            value={formatDateForInput(commentFormData?.exceptionApprovedDate)}
                            margin="dense"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            onChange={e => setCommentFormData({ ...commentFormData, exceptionApprovedDate: e.target.value })}
                            sx={{ width: '100%' }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth margin="dense">
                            <InputLabel id="exceptionCode">Code</InputLabel>
                            <Select
                              labelId="exceptionCategory"
                              value={commentFormData?.exceptionCodeId || ''}
                              onChange={e => setCommentFormData({ ...commentFormData, exceptionCodeId: e.target.value })}
                              variant="standard"
                            >
                              {ExceptionCodeOptions.map(opt => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Approved Amount"
                            name="exceptionApprovedAmount"
                            required
                            value={commentFormData?.exceptionApprovedAmount}
                            margin="dense"
                            variant="standard"
                            type="number"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            onChange={e => setCommentFormData({ ...commentFormData, exceptionApprovedAmount: e.target.value })}
                            sx={{ width: '100%' }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth margin="dense">
                            <InputLabel id="requestType">Request Type</InputLabel>
                            <Select
                              labelId="requestType"
                              value={commentFormData?.exceptionRequestTypeId || ''}
                              onChange={e => setCommentFormData({ ...commentFormData, exceptionRequestTypeId: e.target.value })}
                              variant="standard"
                            >
                              {ExceptionRequestTypeOptions.map(opt => (
                                <MenuItem key={opt.id} value={opt.id}>
                                  {opt.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={4} sx={{ padding: '8px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '16px',
                        marginTop: '16px'
                      }}
                    >
                      {attachments.map((attachment, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            backgroundColor: '#f9f9f9',
                            padding: '8px',
                            borderRadius: '4px',
                            maxWidth: 'calc(100% - 16px)'
                          }}
                        >
                          <Tooltip arrow placement="top" title="Delete">
                            <IconButton color="error" onClick={() => handleRemoveAttachment(index)}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                          <Link
                            href={attachment.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                              color: '#007bff',
                              textDecoration: 'underline',
                              cursor: 'pointer'
                            }}
                          >
                            {attachment.name}
                          </Link>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
            <Dialog open={openAttachments} onClose={handleCloseAttachments} fullWidth maxWidth="sm">
              <DialogTitle>Upload Attachments</DialogTitle>
              <DialogContent>
                <Box
                  {...getRootProps()}
                  sx={{
                    border: '2px dashed gray',
                    borderRadius: '8px',
                    padding: '16px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    marginBottom: '16px'
                  }}
                >
                  <input {...getInputProps()} />
                  <Typography>Drag & drop files here, or click to select files</Typography>
                </Box>
                <Box>
                  {temporaryAttachments.length > 0 ? (
                    temporaryAttachments.map((file, index) => <Typography key={index}>{file.name}</Typography>)
                  ) : (
                    <Typography>No files uploaded</Typography>
                  )}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseAttachments}
                  color="secondary"
                  variant="text"
                  sx={{
                    color: '#868686',
                    '&:hover': {
                      color: '#474747',
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={handleSaveFile} color="primary">
                  Submit File
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </DialogContent>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}

        {!isLoading && (
          <DialogActions>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', padding: '16px' }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleOpenAttachments}
                sx={{
                  maxHeight: '40px',
                  marginLeft: '12px',
                  marginTop: '8px',
                  backgroundColor: '#41B7C0',
                  '&:hover': { backgroundColor: '#474747' }
                }}
              >
                Add Attachments
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  maxHeight: '40px',
                  marginLeft: '12px',
                  marginTop: '8px',
                  backgroundColor: '#868686',
                  '&:hover': { backgroundColor: '#474747' }
                }}
                disabled={isCancelButtonDisabled}
                onClick={cancelComment}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  maxHeight: '40px',
                  marginLeft: '12px',
                  marginTop: '8px',
                  backgroundColor: '#67A783',
                  '&:hover': {
                    backgroundColor: '#53936F'
                  },
                  color: '#FFFFFF'
                }}
                disabled={isSubmitDisable}
                onClick={saveComment}
              >
                {'Create Comment'}
              </Button>
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => ({});

export default compose(connect(mapStateToProps, { postCommentsAndNotes, updateCommentsAndNotes, showToast }))(ServiceTriggeredComment);
