export const ServiceOptions = [
  { id: 1, title: 'Homefinding' },
  { id: 2, title: 'Lender/New Home Purchase' },
  { id: 3, title: 'Closed File' },
  { id: 4, title: 'Tasks' },
  { id: 5, title: 'Global' },
  { id: 6, title: 'Client' },
  { id: 7, title: 'Destination Renter' },
  { id: 8, title: 'Banking' },
  { id: 9, title: 'MEA/LS' },
  { id: 10, title: 'Repay' },
  { id: 11, title: 'HomeSale' },
  { id: 12, title: 'Final Move' },
  { id: 13, title: 'Temp Living' },
  { id: 14, title: 'General' },
  { id: 15, title: 'Initial call/First contact' },
  { id: 16, title: 'DSP' },
  { id: 17, title: 'Destination Buyer' },
  { id: 18, title: 'Survey' },
  { id: 19, title: 'Household Goods' },
  { id: 20, title: 'Property Management' },
  { id: 21, title: 'Assignment' },
  { id: 22, title: 'OnlineAuth' },
  { id: 23, title: 'LS Referrals' },
  { id: 24, title: 'Travel' },
  { id: 25, title: 'QA' },
  { id: 26, title: 'Lender/Homesale' },
  { id: 27, title: 'Exceptions' },
  { id: 28, title: 'Tax Assistance' },
  { id: 29, title: 'Policy' },
  { id: 30, title: 'Budget Tracker' },
  { id: 31, title: 'Accounting' }
];

export const RegardingOptions = [
  { id: 1, title: 'Decision Source' },
  { id: 2, title: 'LanguageTraining' },
  { id: 3, title: 'HS Mktg' },
  { id: 4, title: 'Portal' },
  { id: 5, title: 'HS-INSPECT' },
  { id: 6, title: 'Destination' },
  { id: 7, title: 'No Survey - MSD Approved' },
  { id: 8, title: 'HS TRC Contract' },
  { id: 9, title: 'HHG Air' },
  { id: 10, title: 'Final Eval' },
  { id: 11, title: 'Global' },
  { id: 12, title: 'HS-BUYOUT' },
  { id: 13, title: 'HF Homebuyer' },
  { id: 14, title: 'HS Title/Deed' },
  { id: 15, title: 'HS Equity Statement' },
  { id: 16, title: 'HS/RS: Closing' },
  { id: 17, title: 'NOTES' },
  { id: 18, title: 'Client' },
  { id: 19, title: 'Fee' },
  { id: 20, title: 'HS/RS: Offer/Purchase agreement w/buyer' },
  { id: 21, title: 'Approval' },
  { id: 22, title: 'HHG Perm Storage' },
  { id: 23, title: 'Exception' },
  { id: 24, title: 'OneSource' },
  { id: 25, title: 'HS Guaranteed Offer' },
  { id: 26, title: 'Status Update' },
  { id: 27, title: 'HHG SIT' },
  { id: 28, title: 'PO#/BP Call' },
  { id: 29, title: 'Initiation Call' },
  { id: 30, title: 'LOU/Offer Letter' },
  { id: 31, title: 'Capped Move' },
  { id: 32, title: 'Pulse Survey Follow Up' },
  { id: 33, title: 'HF Agent Referral' },
  { id: 34, title: 'Post File Close Action' },
  { id: 35, title: 'HHG Sea' },
  { id: 36, title: 'HHG Car Ship' },
  { id: 37, title: 'HS Agent Referral' },
  { id: 38, title: 'Dates' },
  { id: 39, title: 'Household Goods' },
  { id: 40, title: 'Property Management' },
  { id: 41, title: 'HS Marketing Review' },
  { id: 42, title: 'Cross Cultural/Settling In' },
  { id: 43, title: 'Lease' },
  { id: 44, title: 'HHG Claims' },
  { id: 45, title: 'Receivable' },
  { id: 46, title: 'HHG Self Move' },
  { id: 47, title: 'RS-CLOSING' },
  { id: 48, title: 'HF Renter' },
  { id: 49, title: 'PO #' },
  { id: 50, title: 'Travel' },
  { id: 51, title: 'Pulse Check' },
  { id: 52, title: 'HS BMA' },
  { id: 53, title: 'RS-OFFERS' },
  { id: 54, title: 'Escalation' },
  { id: 55, title: 'HS-LISTING' },
  { id: 56, title: 'Expense' },
  { id: 57, title: 'COLA' },
  { id: 58, title: 'OTHER' },
  { id: 59, title: 'VISA/Work Permit' },
  { id: 60, title: 'HS-APPRAISAL' },
  { id: 61, title: 'Refund' },
  { id: 62, title: 'CONV_SE' },
  { id: 63, title: 'Internal' },
  { id: 64, title: 'HHG Discard/Donate' },
  { id: 65, title: 'HHG Self Pay' },
  { id: 66, title: 'Accounting' },
  { id: 67, title: 'Final Eval Follow Up' },
  { id: 68, title: 'HF NHCC' }
];

export const ExceptionCategoryOptions = [
  { id: 1, title: 'Service Fee' },
  { id: 2, title: 'Storage' },
  { id: 3, title: 'HHG' },
  { id: 4, title: 'Destination' },
  { id: 5, title: 'Misc Expense' },
  { id: 6, title: 'Homesale' },
  { id: 7, title: 'Allowances & Lump Sums' },
  { id: 8, title: 'Lease Cancel/Dup Housing' },
  { id: 9, title: 'House Hunting' },
  { id: 10, title: 'Loss On Sale' },
  { id: 11, title: 'Final Trip' },
  { id: 12, title: 'Global Assign Cost' },
  { id: 13, title: 'TempLiving' },
  { id: 14, title: 'Tax Assistance' },
  { id: 15, title: 'Candidate' }
];

export const ExceptionStatusOptions = [
  { id: 1, title: 'Approved' },
  { id: 2, title: 'Not Approved' },
  { id: 3, title: 'Pending' }
];
export const ExceptionCodeOptions = [
  { id: 1, title: 'Code 1' },
  { id: 2, title: 'Code 2' },
  { id: 3, title: 'Code 3' }
];
export const ExceptionRequestTypeOptions = [
  { id: 1, title: 'Company Request' },
  { id: 2, title: 'Employee Request' },
  { id: 3, title: 'Covid19' }
];
export const ExceptionApprovedByOptions = ['User 1', 'User 2', 'User 3'];

export const SearchableProperties = new Set([
  'addedBy',
  'createdParty',
  'serviceId',
  'regardingId',
  'subject',
  'commentContent',
  'exceptionReason',
  'modifiedBy',
  'createdDate',
  'createdParty',
  'exceptionRequestedMoney',
  'exceptionCategoryId',
  'exceptionCodeId',
  'exceptionRequestTypeId',
  'exceptionStatusId',
  'exceptionApprovedDate',
  'exceptionApprovedBy',
  'exceptionActualAmount',
  'exceptionApprovedAmount',
  'exceptionApprovedDate'
]);
