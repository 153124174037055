/* eslint-disable react-hooks/exhaustive-deps */
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  InputAdornment
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';
import {
  deleteExpenseLine,
  getExpenseLines,
  getExpenseLinesBySummaryId,
  postExpenseLine,
  postMultipleExpenseLines,
  updateExpenseLine,
  updateExpenseSummary,
  getExpenseSummaryById
} from 'modules/expenses/expenses.actions';
import { Paper } from '@material-ui/core';
import {
  DELETE_EXPENSE_LINE_FAILURE,
  GET_EXPENSE_SUMMARY_BY_ID_FAILURE,
  POST_EXPENSE_LINE_FAILURE,
  POST_EXPENSE_SUMMARY_FAILURE
} from 'modules/expenses/types';
import VoucherReportLineItem from './voucherReportLineItem.component';
import { SERVICE_FILTER_TYPES } from 'modules/authorizations/details/expenses/expenseConstants';
import AttachmentModal from 'modules/common/AttatchmentModal.component';
import VoucherDetailsButtonsComponent from './voucherDetailsButtons.component';
import AdvancedSearchComponent from 'modules/common/advancedSearch.component';
import { formatDateForInput } from 'utilities/common';
import { CreateExpenseCodeSearchInfo } from 'modules/common/expenseCodeHelper';
import { postExpenseSummary } from 'modules/expenses/expenses.actions';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chipClickable: {
    cursor: 'pointer'
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important'
  },
  spacingX: {
    marginRight: theme.spacing(1)
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2)
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px'
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important'
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important'
  },
  editModalInput: {
    minWidth: '332px'
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px'
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0
  }
}));

const calculateBalance = (lineItems, voucherAmount) => {
  const lineItemTotal = lineItems.reduce((total, item) => {
    return total + (parseFloat(item?.amount) || 0);
  }, 0);
  return (voucherAmount ?? 0) - lineItemTotal;
};

const VoucherLineItemsCard = props => {
  const {
    voucherInfo,
    expenseLineItems,
    isReadOnly,
    setIsFormValidForApproval,
    isFormValidForApproval,
    voucherStatus,
    setVoucherStatus,
    updateVoucher,
    voucherInfoForm,
    setVoucherInfoForm,
    history,
    updateAllLineItems,
    authorizationSummaries,
    isDirty,
    clientPreferredExpenseCodes,
    voucherAmount,
    expenseSummary,
    recurringPayableId
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [expandedId, setExpandedId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(calculateBalance(expenseLineItems, voucherAmount));
  const [formExpenseLineItems, setFormExpenseLineItems] = useState([...expenseLineItems]);
  const [newItem, setNewItem] = useState({});
  const [openNewItem, setOpenNewItem] = useState(false);
  const [newItemOnHold, setNewItemOnHold] = useState(false);
  const [lineItemsExpenseSummary, setLineItemsExpenseSummary] = useState(
    expenseSummary && expenseSummary.length > 0 ? expenseSummary : null
  );

  useEffect(() => {
    let total = calculateBalance(formExpenseLineItems, voucherAmount);
    setTotalAmount(total);
    if (total === 0) {
      setIsFormValidForApproval(true);
    } else {
      setIsFormValidForApproval(false);
    }
  }, [formExpenseLineItems, voucherAmount]);

  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(false);
  const toggleShowDetails = () => {
    setShowDetails(prevShowDetails => !prevShowDetails);
  };

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const addLine = async () => {
    setIsLoading(true);

    let newVals = {
      ...newItem,
      voucherId: voucherInfo.voucherId,
      authorizationId: newItem.authorizationId,
      recurringPayableId: recurringPayableId || 0
    };

    if (lineItemsExpenseSummary) {
      // lineItemsExpenseSummary could be an object or a list of object. Figure out if list:
      if (lineItemsExpenseSummary.length > 0) {
        // is list
        newVals.expenseId = lineItemsExpenseSummary[0].expenseId;
      } else {
        // is not a list
        newVals.expenseId = lineItemsExpenseSummary.expenseId;
      }
      const resp = await props.postExpenseLine(newVals);
      if (resp.type === POST_EXPENSE_LINE_FAILURE) {
        props.showToast('Failed to add the voucher line, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        setOpenNewItem(false);
        return false;
      } else {
        props.showToast(`Successfully added voucher line ${resp.response.expenseLineId}.`, { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
        setFormExpenseLineItems(prevItems => [...prevItems, resp.response]);
        setIsLoading(false);
        setOpenNewItem(false);
        setShowDetails(false);
        setNewItem({});
        return true;
      }
    } else {
      // create expense summary for line items
      let checkVals = {
        authorizationId: voucherInfo.authorizationId,
        authName: voucherInfo.authName
      };
      const expenseResp = await props.postExpenseSummary(checkVals);
      if (expenseResp.type === POST_EXPENSE_SUMMARY_FAILURE) {
        props.showToast('Failed to create new expense summary, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      } else {
        // add line item
        newVals.expenseId = expenseResp.response.expenseId;
        const resp = await props.postExpenseLine(newVals);
        if (resp.type === POST_EXPENSE_LINE_FAILURE) {
          props.showToast('Failed to add the voucher line, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          setOpenNewItem(false);
          return false;
        } else {
          props.showToast(`Successfully added voucher line ${resp.response.expenseLineId}.`, { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });

          // NOW get the expense and set it
          const expResp = await props.getExpenseSummaryById(newVals.expenseId);
          if (resp.type === GET_EXPENSE_SUMMARY_BY_ID_FAILURE) {
            props.showToast('Failed to add the voucher line, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
            setIsLoading(false);
            setOpenNewItem(false);
            return false;
          } else {
            setLineItemsExpenseSummary(expResp.response);
            setFormExpenseLineItems(prevItems => [...prevItems, resp.response]);
            setIsLoading(false);
            setOpenNewItem(false);
            setShowDetails(false);
            setNewItem({});
            return true;
          }
        }
      }
    }
  };

  const handleAddLine = () => {
    if (!voucherInfo.payToVendor) {
      setNewItem({ authorizationId: voucherInfo.authorizationId });
    }
    setOpenNewItem(true);
  };

  const cancelAddLine = () => {
    setOpenNewItem(false);
    setShowDetails(false);
    setNewItem({});
  };

  const deleteLine = async id => {
    setIsLoading(true);
    const resp = await props.deleteExpenseLine(id);
    if (resp.type === DELETE_EXPENSE_LINE_FAILURE) {
      props.showToast('Failed to delete the voucher line, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else {
      await props.getExpenseLines();
      props.showToast(`Successfully deleted voucher line ${id}.`, { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      setFormExpenseLineItems(prevItems => prevItems.filter(item => item.expenseLineId !== id));
      setIsLoading(false);
      return true;
    }
  };

  const expenseCodeSearchInfo = CreateExpenseCodeSearchInfo(props.expenseCodes, clientPreferredExpenseCodes, voucherInfoForm.clientId);
  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ marginTop: '10px' }}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center" style={{ display: 'flex', alignItems: 'center' }}>
              <div className={classes.iconRow}>
                <Tooltip title="Estimates" key="Estimates" placement="right">
                  <FontAwesomeIcon icon={['fas', 'file-contract']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Line Items
                </Typography>
              </div>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                {totalAmount === 0 && (
                  <>
                    <Typography>
                      Balance: <span style={{ fontWeight: 'bold' }}>${totalAmount.toFixed(2)}</span>
                    </Typography>
                  </>
                )}
                {totalAmount !== 0 && (
                  <>
                    <Typography>
                      Balance: <span style={{ fontWeight: 'bold', color: 'red' }}>${totalAmount.toFixed(2)}</span>
                    </Typography>
                  </>
                )}
              </div>
              {/* Ghost Element */}
              <div style={{ visibility: 'hidden', width: 'auto', paddingRight: '250px' }}>
                <div className={classes.iconRow}>
                  <Tooltip title="Estimates" key="Estimates" placement="right">
                    <FontAwesomeIcon icon={['fas', 'file-contract']} size="2x" />
                  </Tooltip>
                  <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                    Line Items
                  </Typography>
                </div>
              </div>
            </div>
          </CardContent>

          <CardContent sx={{ padding: '10px', marginLeft: '10px', paddingTop: '0px' }}>
            <Box sx={{ marginTop: '16px' }}>
              <Paper>
                <Box sx={{ padding: '16px', paddingTop: '8px' }}>
                  <Box sx={{ paddingBottom: '10px', borderBottom: '2px solid' }}>
                    <Grid container spacing={1} columns={{ xs: 20, sm: 20, md: 20, xl: 20 }}>
                      <Grid item md={3}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Auth Id
                        </Typography>
                      </Grid>
                      <Grid item md={1}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          CAT
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Code
                        </Typography>
                      </Grid>
                      <Grid item md={2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Amount
                        </Typography>
                      </Grid>
                      <Grid item md={5}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Line Desc
                        </Typography>
                      </Grid>
                      <Grid item md={2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          On Hold
                        </Typography>
                      </Grid>
                      <Grid item md={2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Attachment
                        </Typography>
                      </Grid>
                      <Grid item md={1}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Edit
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {(!formExpenseLineItems || formExpenseLineItems.length === 0) && (
                    <>
                      <Box sx={{ paddingTop: '20px', display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="subtitle2">
                          No line items. Please enter at least one (1) line item to submit voucher.
                        </Typography>
                      </Box>
                    </>
                  )}

                  {formExpenseLineItems &&
                    formExpenseLineItems.map(row => (
                      <Box sx={{ paddingBottom: '10px', paddingTop: '10px', borderBottom: '2px solid' }}>
                        {expandedId !== row.expenseLineId && (
                          <Grid container spacing={1} columns={{ xs: 20, sm: 20, md: 20, xl: 20 }}>
                            <Grid item md={3}>
                              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px' }}>
                                {row.authorizationId}
                              </Typography>
                            </Grid>
                            <Grid item md={1}>
                              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px' }}>
                                {row.cat}
                              </Typography>
                            </Grid>
                            <Grid item md={3}>
                              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px' }}>
                                {row.expenseCode}
                              </Typography>
                            </Grid>
                            <Grid item md={2}>
                              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px' }}>
                                {row.amount}
                              </Typography>
                            </Grid>
                            <Grid item md={5}>
                              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px' }}>
                                {row.description}
                              </Typography>
                            </Grid>
                            <Grid item md={2}>
                              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', paddingTop: '10px' }}>
                                <Checkbox
                                  checked={row.onHoldDate !== null}
                                  color="primary"
                                  inputProps={{ 'aria-label': 'status checkbox' }}
                                  disabled={true}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2}>
                              <Box>
                                <AttachmentModal
                                  displayText={isReadOnly ? 'View Attatchments' : 'Add Attachment'}
                                  isReadOnly={isReadOnly}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={1}>
                              <Fab
                                className={classes.iconOverlayImg}
                                color="primary"
                                size="small"
                                onClick={() => setExpandedId(row.expenseLineId)}
                              >
                                <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                              </Fab>
                            </Grid>
                          </Grid>
                        )}
                        {expandedId === row.expenseLineId && (
                          <VoucherReportLineItem
                            isReadOnly={isReadOnly}
                            lineItem={row}
                            closeLineItem={() => setExpandedId(null)}
                            expenseCodes={props.expenseCodes}
                            setFormExpenseLineItems={setFormExpenseLineItems}
                            authorizationSummaries={authorizationSummaries}
                            voucherInfoForm={voucherInfoForm}
                          />
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '10px'
                          }}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            sx={{
                              backgroundColor: '#36939b',
                              '&:hover': { backgroundColor: '#15585e' },
                              color: 'ffffff',
                              minWidth: '120px'
                            }}
                            onClick={async () => await deleteLine(row.expenseLineId)}
                            disabled={isReadOnly || isLoading}
                          >
                            Delete Line
                          </Button>
                        </Box>
                      </Box>
                    ))}
                  {openNewItem && (
                    <Box sx={{ paddingBottom: '10px', paddingTop: '10px', borderBottom: '2px solid' }}>
                      <Grid container spacing={1} columns={{ xs: 20, sm: 20, md: 20, xl: 20 }}>
                        <Grid item md={3}>
                          {voucherInfoForm.payToVendor ? (
                            <AdvancedSearchComponent
                              labelText={'Authorization'}
                              displayValueAccessorKey={'transfereeName'}
                              saveIdValueAccessorKey={'authorizationId'}
                              searchingCriteria={[
                                { name: 'authorizationId', title: 'Auth Id' },
                                { name: 'transfereeName', title: 'Full Name' },
                                { name: 'departure', title: 'Departure Location' },
                                { name: 'destination', title: 'Destination Location' },
                                { name: 'clientName', title: 'Client' }
                              ]}
                              searchingCriteriaExtensions={[
                                { columnName: 'authorizationId', width: 150 },
                                { columnName: 'transfereeName', width: 250 },
                                { name: 'departure', width: 175 },
                                { name: 'destination', width: 175 },
                                { columnName: 'clientName', width: 175 }
                              ]}
                              searchingRows={authorizationSummaries.filter(a => a.clientName === voucherInfoForm.clientName)}
                              id={newItem.authorizationId}
                              value={newItem.authName}
                              setIdValue={(id, value) => {
                                setNewItem({ ...newItem, authorizationId: id, authName: value });
                              }}
                              isReadOnly={isReadOnly}
                            />
                          ) : (
                            <TextField
                              key={'authorizationId'}
                              label={''}
                              name={'authorizationId'}
                              required={false}
                              disabled={true}
                              value={voucherInfo.authorizationId}
                              sx={{ maxWidth: '500px' }}
                              fullWidth={true}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        </Grid>
                        <Grid item md={1}>
                          <Box
                            sx={{
                              paddingTop: '10px',
                              display: 'flex',
                              height: '100%'
                            }}
                          >
                            <div className={classes.mb2}>
                              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                {newItem.cat ? newItem.cat : <div>&mdash;</div>}
                              </Typography>
                            </div>
                          </Box>
                        </Grid>

                        <Grid item md={3}>
                          <Box>
                            <FormControl key={''}>
                              <AdvancedSearchComponent
                                key={'expenseCode'}
                                labelText={''}
                                displayValueAccessorKey={expenseCodeSearchInfo.nameKey}
                                saveIdValueAccessorKey={expenseCodeSearchInfo.idKey}
                                searchingCriteria={expenseCodeSearchInfo.searchingColumns}
                                searchingCriteriaExtensions={expenseCodeSearchInfo.searchingColumnExtensions}
                                searchingRows={expenseCodeSearchInfo.searchingRows}
                                id={newItem.expenseCodeId}
                                value={newItem.expenseCode}
                                setIdValue={(id, value) => {
                                  const expenseCodeInfo = props.expenseCodes.find(e => e.expenseCodeId === id);
                                  setNewItem({
                                    ...newItem,
                                    expenseCode: value,
                                    expenseCodeId: id,
                                    expenseCodeDescription: expenseCodeInfo.expenseCodeDescription,
                                    cat: expenseCodeInfo.category
                                  });
                                }}
                                maxWidthsx={'360px'}
                                isReadOnly={isReadOnly}
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item md={2}>
                          <TextField
                            key={'amount'}
                            label={''}
                            name={'amount'}
                            required={false}
                            disabled={isReadOnly}
                            value={newItem.amount}
                            sx={{ maxWidth: '500px' }}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            onChange={e => setNewItem({ ...newItem, amount: e.target.value })}
                          />
                        </Grid>
                        <Grid item md={5}>
                          <TextField
                            key={'description'}
                            label={''}
                            name={'description'}
                            required={false}
                            disabled={isReadOnly}
                            value={newItem.description}
                            sx={{ maxWidth: '500px' }}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={e => setNewItem({ ...newItem, description: e.target.value })}
                          />
                        </Grid>
                        <Grid item md={2} sx={{ marginLeft: '10px' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                key={'onHold'}
                                name={'On Hold'}
                                checked={newItemOnHold}
                                disabled={isReadOnly}
                                onChange={e => {
                                  if (!newItemOnHold) {
                                    setNewItem({ ...newItem, onHoldDate: new Date() });
                                  } else {
                                    setNewItem({ ...newItem, onHoldDate: null });
                                  }
                                  setNewItemOnHold(!newItemOnHold);
                                }}
                              />
                            }
                            sx={{ maxWidth: '500px' }}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <AttachmentModal displayText={'View Attachment'} />
                        </Grid>
                        <Grid item md={3}>
                          <Box sx={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px', paddingRight: '10px' }}>
                            <span onClick={toggleShowDetails} style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}>
                              Show Additional Details
                            </span>
                          </Box>
                        </Grid>
                        <Grid item md={17}>
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px', paddingRight: '10px' }}>
                            {!showDetails && !isReadOnly && !isLoading && (
                              <Box sx={{ padding: '10px', display: 'flex', gap: '10px' }}>
                                <Button color="primary" variant="contained" onClick={cancelAddLine} sx={{ minWidth: '120px' }}>
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={addLine}
                                  sx={{ minWidth: '120px' }}
                                  disabled={!newItem?.authorizationId}
                                >
                                  Save Line
                                </Button>
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                      {showDetails && (
                        <Box>
                          <Grid container spacing={1} columns={{ xs: 20, sm: 20, md: 20, xl: 20 }}>
                            <Grid item md={2}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Debit Amount
                                  </Typography>
                                </div>
                                <TextField
                                  key={'debitAmount'}
                                  label={''}
                                  name={'debitAmount'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={newItem.debitAmount}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  type={'number'}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                  }}
                                  onChange={e => setNewItem({ ...newItem, debitAmount: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={2}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Credit Amount
                                  </Typography>
                                </div>
                                <TextField
                                  key={'creditAmount'}
                                  label={''}
                                  name={'creditAmount'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={newItem.creditAmount}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  type={'number'}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                  }}
                                  allowDecimal={true}
                                  onChange={e => setNewItem({ ...newItem, creditAmount: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={2}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Far Code
                                  </Typography>
                                </div>
                                <TextField
                                  key={'farCode'}
                                  label={''}
                                  name={'farCode'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={newItem.farCode}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={e => setNewItem({ ...newItem, farCode: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={2}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Ex. Amount
                                  </Typography>
                                </div>
                                <TextField
                                  key={'exceptionAmount'}
                                  label={''}
                                  name={'exceptionAmount'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={newItem.exceptionAmount}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  type={'number'}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                  }}
                                  onChange={e => setNewItem({ ...newItem, exceptionAmount: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={2}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Ex. Reason
                                  </Typography>
                                </div>
                                <TextField
                                  key={'exceptionReason'}
                                  label={''}
                                  name={'exceptionReason'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={newItem.exceptionReason}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={e => setNewItem({ ...newItem, exceptionReason: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={4}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Needs Approval Amount
                                  </Typography>
                                </div>
                                <TextField
                                  key={'needsApprovalAmount'}
                                  label={''}
                                  name={'needsApprovalAmount'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={newItem.needsApprovalAmount}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  type={'number'}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                  }}
                                  onChange={e => setNewItem({ ...newItem, needsApprovalAmount: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={4}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Needs Approval By
                                  </Typography>
                                </div>
                                <TextField
                                  key={'needsApprovalBy'}
                                  label={''}
                                  name={'needsApprovalBy'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={newItem.needsApprovalBy}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={e => setNewItem({ ...newItem, needsApprovalBy: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={2}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Service
                                  </Typography>
                                </div>
                                <Select
                                  labelId={'service'}
                                  key={'service'}
                                  label={''}
                                  name={'service'}
                                  value={newItem.service}
                                  sx={{ maxWidth: '140px', minWidth: '120px' }}
                                  disabled={isReadOnly}
                                  onChange={e => {
                                    setNewItem({ ...newItem, service: e.target.value });
                                  }}
                                >
                                  {SERVICE_FILTER_TYPES.map(opt => (
                                    <MenuItem disabled={isReadOnly} key={opt} value={opt} sx={{ margin: 'dense' }}>
                                      {opt}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Box>
                            </Grid>
                            <Grid item md={2}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Expense Key
                                  </Typography>
                                </div>
                                <TextField
                                  key={'expenseKey'}
                                  label={''}
                                  name={'expenseKey'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={newItem.expenseKey}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={e => setNewItem({ ...newItem, expenseKey: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={2}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Ap Date
                                  </Typography>
                                </div>
                                <TextField
                                  key={'apDate'}
                                  label={''}
                                  name={'apDate'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={formatDateForInput(newItem.apDate)}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  type="date"
                                  onChange={e => setNewItem({ ...newItem, apDate: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={2}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Payroll Date
                                  </Typography>
                                </div>
                                <TextField
                                  key={'payrollDate'}
                                  label={''}
                                  name={'payrollDate'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={formatDateForInput(newItem.payrollDate)}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  type="date"
                                  onChange={e => setNewItem({ ...newItem, payrollDate: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={2}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Payroll Type
                                  </Typography>
                                </div>
                                <TextField
                                  key={'payrollType'}
                                  label={''}
                                  name={'payrollType'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={newItem.payrollType}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={e => setNewItem({ ...newItem, payrollType: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={2}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Expense Date
                                  </Typography>
                                </div>
                                <TextField
                                  key={'expenseDate'}
                                  label={''}
                                  name={'expenseDate'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={formatDateForInput(newItem.expenseDate)}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  type="date"
                                  onChange={e => setNewItem({ ...newItem, expenseDate: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={2}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    Route To
                                  </Typography>
                                </div>
                                <TextField
                                  key={'apRouteTo'}
                                  label={''}
                                  name={'apRouteTo'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={newItem.apRouteTo}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={e => setNewItem({ ...newItem, apRouteTo: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={2}>
                              <Box>
                                <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                    GL Number
                                  </Typography>
                                </div>
                                <TextField
                                  key={'glNumber'}
                                  label={''}
                                  name={'glNumber'}
                                  required={false}
                                  disabled={isReadOnly}
                                  value={newItem.glNumber}
                                  sx={{ maxWidth: '500px' }}
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={e => setNewItem({ ...newItem, glNumber: e.target.value })}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  paddingTop: '24px',
                                  width: '100%'
                                }}
                              >
                                {isLoading && <Spinner />}
                                {!isReadOnly && !isLoading && (
                                  <Box sx={{ padding: '10px', display: 'flex', gap: '10px' }}>
                                    <Button color="primary" variant="contained" onClick={cancelAddLine} sx={{ minWidth: '120px' }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={addLine}
                                      sx={{ minWidth: '120px' }}
                                      disabled={!newItem?.authorizationId}
                                    >
                                      Save Line
                                    </Button>
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      padding: '10px'
                    }}
                  >
                    {
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{
                          backgroundColor: '#36939b',
                          '&:hover': { backgroundColor: '#15585e' },
                          color: '#ffffff',
                          minWidth: '120px'
                        }}
                        onClick={handleAddLine}
                        disabled={isReadOnly || isLoading || openNewItem}
                      >
                        Add Line
                      </Button>
                    }
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: '16px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingX: '24px',
                    paddingBottom: '10px'
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <VoucherDetailsButtonsComponent
                      isFormValidForApproval={isFormValidForApproval}
                      voucherStatus={voucherStatus}
                      setVoucherStatus={setVoucherStatus}
                      updateVoucher={updateVoucher}
                      voucherInfoForm={voucherInfoForm}
                      setVoucherInfoForm={setVoucherInfoForm}
                      history={history}
                      updateAllLineItems={updateAllLineItems}
                      expenseLineItems={expenseLineItems}
                      isLoading={isLoading}
                      isDirty={isDirty}
                      voucherInfo={voucherInfo}
                    />
                  </Box>
                </Box>
              </Paper>
            </Box>
          </CardContent>
        </Box>
      </Card>
    </ThemeProvider>
  );
};

export default compose(
  connect(null, {
    updateExpenseSummary,
    postExpenseLine,
    updateExpenseLine,
    getExpenseLinesBySummaryId,
    postMultipleExpenseLines,
    showToast,
    deleteExpenseLine,
    getExpenseLines,
    postExpenseSummary,
    getExpenseSummaryById
  })
)(VoucherLineItemsCard);
