import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  Grid,
  Paper,
  Button,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  FormControlLabel,
  Box,
  Tooltip,
  Typography
} from '@mui/material';
import isEqual from 'lodash/isEqual';

const MultiRowForm = ({
  formValues = [],
  saveFunction,
  formFields,
  createNewRowFunc,
  addRowLabel,
  isDisabled = false,
  header,
  headerCheckbox
}) => {
  const [formValuesState, setFormValuesState] = useState(formValues);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setFormValuesState([...formValues]);
    setHasChanges(false);
  }, [formValues]);

  const checkForChanges = updatedValues => {
    setHasChanges(!isEqual(updatedValues, formValues));
  };

  const addFormRow = () => {
    const updatedValues = [...formValuesState, createNewRowFunc()];
    setFormValuesState(updatedValues);
    checkForChanges(updatedValues);
  };

  const removeFormRow = index => {
    const updatedValues = formValuesState.filter((_, i) => i !== index);
    setFormValuesState(updatedValues);
    checkForChanges(updatedValues);
  };

  const handleFieldChange = (value, index, field) => {
    const updatedValues = [...formValuesState];
    updatedValues[index] = { ...updatedValues[index], [field]: value };
    setFormValuesState(updatedValues);
    checkForChanges(updatedValues);
  };

  const saveForm = async () => {
    if (saveFunction) {
      setIsSubmitting(true);
      await saveFunction(formValuesState);
      setIsSubmitting(false);
      setHasChanges(false);
    }
  };

  const renderHeader = () => {
    if (!header) return null;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {header.iconPath && (
          <Tooltip title={header.title} placement="right">
            <FontAwesomeIcon icon={header.iconPath} size="2x" />
          </Tooltip>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            paddingLeft: header.iconPath ? '20px' : '0px'
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: '1.3125rem',
              fontWeight: 'bold'
            }}
          >
            {header.title}
          </Typography>
        </Box>
        {headerCheckbox && headerCheckbox()}
      </Box>
    );
  };

  return (
    <Grid item xs={12}>
      <Paper className="card-root" style={{ padding: '16px' }}>
        <Grid container item alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }}>
          {renderHeader()}
          <Button disabled={isSubmitting || isDisabled} onClick={addFormRow}>
            {addRowLabel}
          </Button>
        </Grid>
        {formValuesState.map((formValue, index) => {
          const visibleFields = formFields.filter(field => field.type !== 'hidden');
          const fieldCount = visibleFields.length;
          const xsValue = Math.floor(12 / fieldCount) - 1; // Leave space for trashcan icon

          return (
            <Grid container key={index} alignItems="center" spacing={2} style={{ marginBottom: '8px' }}>
              {visibleFields.map(field => {
                if (field.type === 'select') {
                  return (
                    <Grid item xs={xsValue} key={field.name}>
                      <FormControl fullWidth margin="dense">
                        <InputLabel>{field.label}</InputLabel>
                        <Select
                          value={formValue[field.name]}
                          onChange={e => handleFieldChange(e.target.value, index, field.name)}
                          disabled={isSubmitting || isDisabled}
                        >
                          {field.options.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  );
                }
                if (field.type === 'text') {
                  return (
                    <Grid item xs={xsValue} key={field.name}>
                      <TextField
                        label={field.label}
                        value={formValue[field.name]}
                        onChange={e => handleFieldChange(e.target.value, index, field.name)}
                        disabled={isSubmitting || isDisabled}
                        fullWidth
                        margin="dense"
                      />
                    </Grid>
                  );
                }
                if (field.type === 'checkbox') {
                  return (
                    <Grid item xs={xsValue} key={field.name}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formValue[field.name]}
                            onChange={() => handleFieldChange(!formValue[field.name], index, field.name)}
                            disabled={isSubmitting || isDisabled}
                          />
                        }
                        label={field.label}
                      />
                    </Grid>
                  );
                }
                return null;
              })}
              <Grid item xs="auto">
                <IconButton onClick={() => removeFormRow(index)} disabled={isSubmitting || isDisabled}>
                  <FontAwesomeIcon icon={['far', 'trash-alt']} size="lg" />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
        <Grid container justifyContent="flex-end" style={{ marginTop: '1rem' }}>
          <Button
            color="secondary"
            variant="contained"
            sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
            onClick={saveForm}
            disabled={!hasChanges || isSubmitting || isDisabled}
          >
            Save
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default MultiRowForm;
