/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */

import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { showToast } from 'modules/layout/layout.actions';
import AdvancedSearch from 'modules/common/advancedSearch.component';

import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS, DRAWER_WIDTH_OPEN } from 'styles/theme';
import VoucherInfoCard from './voucherInfoCard.component';
import { checkDirty } from 'modules/authorizations/utils';
import { postMultipleExpenseLines, updateVoucherSummary } from 'modules/expenses/expenses.actions';
import { POST_EXPENSE_LINE_MULTIPLE_FAILURE, UPDATE_VOUCHER_SUMMARY_FAILURE } from 'modules/expenses/types';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import PaymentInfoCard from './paymentInfoCard.component';
import { Prompt } from 'react-router-dom/cjs/react-router-dom';
import VoucherLineItems from './voucherLineItems.component';
import DynamicFilePreviewCard from './dynamicFilePreviewCard.component';
import RecurringPaymentCard from './recurringPaymentCard.component';
import { updateVoucherRecurringPayable } from 'modules/recurringPayables/store/recurringPayables.action';
import { UPDATE_RECURRING_PAYABLES_FAILURE } from 'modules/recurringPayables/store/recurringPayables.types';

const styles = theme => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  container: {
    height: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`
    }
  },
  drawerOpen: {
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`
  },
  headerFixed: {
    zIndex: 4
  },
  tabFixed: {
    paddingTop: '100px',
    zIndex: 3
  },
  estimateHeaderFixed: {
    paddingTop: '150px',
    zIndex: 2
  }
});

const voucherStatusOptions = [
  { title: 'DRAFT', id: 1 },
  { title: 'PENDING', id: 2 },
  { title: 'ON HOLD', id: 3 },
  { title: 'APPROVED', id: 4 },
  { title: 'DENIED', id: 5 }
];

const recurringPayableDetailsStatus = [
  { title: 'Cancel', id: 0 },
  { title: 'Active', id: 1 },
  { title: 'Complete', id: 2 }
];

const calcAreLineItemsEditable = status => {
  return status === 4 || status === 5;
};

const VoucherDetails = props => {
  const {
    unmappedClients,
    authorizationSummaries,
    shouldShowSideDrawer,
    vendors,
    voucherInfo,
    history,
    location,
    match,
    expenseLines,
    expenseCodes,
    clientPreferredExpenseCodes,
    expenses,
    isRecurringPayable = false
  } = props;

  const [voucherState, setVoucherState] = useState({ ...voucherInfo }); //Maintain the state of the form after a save has been preformed.
  const [voucherInfoForm, setVoucherInfoForm] = useState({ ...voucherInfo });
  const [expenseLineItems, setExpenseLineItems] = useState(
    [...expenseLines].filter(s =>
      !isRecurringPayable ? s.voucherId === voucherInfo.voucherId : s.recurringPayableId === voucherInfo.recurringPayableId
    )
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [isFormValidForApproval, setIsFormValidForApproval] = useState(false);
  const [voucherStatus, setVoucherStatus] = useState(voucherInfoForm?.status);
  const [isReadOnly, setIsReadOnly] = useState(calcAreLineItemsEditable(voucherInfoForm?.status));

  let expenseSummary =
    expenses && expenses.length > 0 && expenseLineItems && expenseLineItems.length > 0
      ? expenses.filter(expense => expense.expenseId === expenseLineItems[0].expenseId)
      : [];

  useEffect(() => {
    setIsReadOnly(calcAreLineItemsEditable(voucherStatus));
  }, [voucherStatus]);

  let unmappedClientsAdjusted = [];
  if (unmappedClients) {
    unmappedClientsAdjusted = unmappedClients.map(c => {
      let newCopy = { ...c };
      newCopy.clientName = c.description;
      newCopy.clientId = c.id;
      return newCopy;
    });
  }

  const saveDraft = async updatedForm => {
    setIsLoading(true);

    let newVals = { ...updatedForm };

    if (newVals.amount != null && newVals.amount.length === 0) newVals.amount = 0;

    const resp = isRecurringPayable
      ? await props.updateVoucherRecurringPayable(newVals, voucherInfoForm.recurringPayableId)
      : props.updateVoucherSummary(newVals);

    if (resp.type === UPDATE_VOUCHER_SUMMARY_FAILURE || resp.type === UPDATE_RECURRING_PAYABLES_FAILURE) {
      props.showToast('Failed to update the voucher summary, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else {
      let successMessage = isRecurringPayable
        ? `Successfully updated recurring payable ${voucherInfoForm.recurringPayableId}.`
        : `Successfully updated voucher ${voucherInfoForm.voucherId}.`;
      props.showToast(successMessage, { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      setVoucherInfoForm(updatedForm);
      setVoucherState(updatedForm);
      setVoucherStatus(isRecurringPayable ? updatedForm.status : updatedForm.recurringPayableStatus);
      setIsLoading(false);
      setIsDirty(false);
      return true;
    }
  };

  const updateAllLineItems = async updatedExpenseLineItemsList => {
    setIsLoading(true);

    let newVals = [...updatedExpenseLineItemsList];

    if (isRecurringPayable) {
      newVals = newVals.map(item => ({
        ...item,
        recurringPayableId: voucherInfoForm.recurringPayableId
      }));
    }

    const resp = await props.postMultipleExpenseLines(newVals);
    if (resp.type === POST_EXPENSE_LINE_MULTIPLE_FAILURE) {
      props.showToast('Failed to update the expense lines, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else {
      props.showToast('Successfully updated voucher summary', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      setIsLoading(false);
      setExpenseLineItems(updatedExpenseLineItemsList);
      return true;
    }
  };

  const grabVoucherAuthIds = vLineItems => {
    let authList = [];
    vLineItems.map(vli => {
      if (!authList.includes(vli.authorizationId)) {
        authList.push(vli.authorizationId);
      }
    });
    return authList;
  };

  useEffect(() => {
    setIsDirty(checkDirty(voucherState, voucherInfoForm));
  }, [voucherInfoForm]);

  return (
    <Box>
      <Prompt
        when={true}
        message={({ pathname }) => {
          return !isDirty ? true : 'Make sure to save any unsaved changes. Are you sure you would like to leave?';
        }}
      />
      <Paper
        square
        className={classNames({
          'position-fixed': true,
          [props.classes.headerFixed]: true,
          [props.classes.container]: true,
          [props.classes.drawerOpen]: shouldShowSideDrawer
        })}
        sx={{ paddingTop: '5px', zIndex: 4, position: 'relative', width: '100%' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
            <Box sx={{ marginRight: '20px' }}>
              <AdvancedSearch
                labelText={'Client'}
                displayValueAccessorKey={'clientName'}
                saveIdValueAccessorKey={'clientId'}
                searchingCriteria={[
                  { name: 'clientId', title: 'Client Id' },
                  { name: 'clientName', title: 'Company Name' },
                  { name: 'moveTrackClientId', title: 'MT Client Id' },
                  { name: 'clientCode', title: 'Client Code' },
                  { name: 'clientStatus', title: 'Status' },
                  { name: 'serviceRep', title: 'Service Rep' }
                ]}
                searchingCriteriaExtensions={[
                  { columnName: 'clientId', width: 150 },
                  { columnName: 'clientName', width: 250 },
                  { name: 'moveTrackClientId', width: 175 },
                  { name: 'clientCode', width: 175 },
                  { columnName: 'clientStatus', width: 175 },
                  { columnName: 'serviceRep', width: 175 }
                ]}
                searchingRows={unmappedClientsAdjusted}
                id={voucherInfoForm.clientId}
                value={voucherInfoForm.clientName}
                setIdValue={(id, value) => {
                  setVoucherInfoForm({ ...voucherInfoForm, authorizationId: null, authName: null, clientId: id, clientName: value });
                }}
                isReadOnly={isReadOnly}
              />
            </Box>
            <Box>
              <Button
                color="secondary"
                variant="contained"
                disabled={isReadOnly}
                sx={{
                  maxHeight: '40px',
                  marginLeft: '12px',
                  marginTop: '8px',
                  backgroundColor: voucherInfoForm.payToVendor ? '#36939b' : '#ffffff',
                  '&:hover': { backgroundColor: voucherInfoForm.payToVendor ? '#15585e' : '#faf8f2' },
                  color: voucherInfoForm.payToVendor ? 'ffffff' : '#080600'
                }}
                onClick={() => setVoucherInfoForm({ ...voucherInfoForm, payToVendor: !voucherInfoForm.payToVendor })}
              >
                {' '}
                Pay to Vendor{' '}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                disabled={isReadOnly}
                sx={{
                  maxHeight: '40px',
                  marginLeft: '12px',
                  marginTop: '8px',
                  backgroundColor: !voucherInfoForm.payToVendor ? '#36939b' : '#ffffff',
                  '&:hover': { backgroundColor: !voucherInfoForm.payToVendor ? '#15585e' : '#faf8f2' },
                  color: !voucherInfoForm.payToVendor ? 'ffffff' : '#080600'
                }}
                onClick={() => setVoucherInfoForm({ ...voucherInfoForm, payToVendor: !voucherInfoForm.payToVendor })}
              >
                Pay to EE
              </Button>
            </Box>
            {voucherInfoForm.payToVendor && (
              <Box sx={{ marginLeft: '25px' }}>
                <AdvancedSearch
                  labelText={'Vendor'}
                  displayValueAccessorKey={'companyName'}
                  saveIdValueAccessorKey={'vendorId'}
                  searchingCriteria={[
                    { name: 'vendorId', title: 'Vendor Id' },
                    { name: 'companyName', title: 'Company Name' },
                    { name: 'city', title: 'City' },
                    { name: 'state', title: 'State' },
                    { name: 'phone', title: 'Company Phone' },
                    { name: 'email', title: 'Company Email' }
                  ]}
                  searchingCriteriaExtensions={[
                    { columnName: 'vendorId', width: 150 },
                    { columnName: 'companyName', width: 250 },
                    { name: 'city', width: 175 },
                    { name: 'state', width: 175 },
                    { columnName: 'phone', width: 175 },
                    { columnName: 'email', width: 175 }
                  ]}
                  searchingRows={vendors}
                  id={voucherInfoForm.vendorId}
                  value={voucherInfoForm.vendorName}
                  setIdValue={(id, value) => {
                    setVoucherInfoForm({ ...voucherInfoForm, vendorId: id, vendorName: value });
                  }}
                  isReadOnly={isReadOnly}
                />
              </Box>
            )}
            {!voucherInfoForm.payToVendor && (
              <Box sx={{ marginLeft: '25px' }}>
                <AdvancedSearch
                  labelText={'Authorization'}
                  displayValueAccessorKey={'transfereeName'}
                  saveIdValueAccessorKey={'authorizationId'}
                  searchingCriteria={[
                    { name: 'authorizationId', title: 'Auth Id' },
                    { name: 'transfereeName', title: 'Full Name' },
                    { name: 'departure', title: 'Departure Location' },
                    { name: 'destination', title: 'Destination Location' },
                    { name: 'clientName', title: 'Client' }
                  ]}
                  searchingCriteriaExtensions={[
                    { columnName: 'authorizationId', width: 150 },
                    { columnName: 'transfereeName', width: 250 },
                    { name: 'departure', width: 175 },
                    { name: 'destination', width: 175 },
                    { columnName: 'clientName', width: 175 }
                  ]}
                  searchingRows={
                    voucherInfoForm.clientName
                      ? authorizationSummaries.filter(a => a.clientName === voucherInfoForm.clientName)
                      : authorizationSummaries
                  }
                  id={voucherInfoForm.authorizationId}
                  value={voucherInfoForm.authName}
                  setIdValue={(id, value) => {
                    if (id && value) {
                      let relevantAuthInfo = authorizationSummaries.find(a => a.authorizationId === id);
                      let relevantClientInfo = unmappedClientsAdjusted.find(c => c.clientName === relevantAuthInfo.clientName);
                      setVoucherInfoForm({
                        ...voucherInfoForm,
                        authorizationId: id,
                        authName: value,
                        clientId: relevantClientInfo.clientId,
                        clientName: relevantAuthInfo.clientName
                      });
                    } else {
                      setVoucherInfoForm({ ...voucherInfoForm, authorizationId: id, authName: value });
                    }
                  }}
                  isReadOnly={isReadOnly}
                />
              </Box>
            )}
            {!voucherInfoForm.payToVendor && (
              <Box sx={{ marginLeft: '25px' }}>
                <TextField
                  key={'voucherInfoForm.authorizationId'}
                  label={'Authorization Id'}
                  name={'voucherInfoForm.authorizationId'}
                  required={false}
                  disabled={true}
                  value={voucherInfoForm.authorizationId}
                  sx={{ maxWidth: '360px' }}
                  type="text"
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            )}
          </Box>

          <Box sx={{ color: 'green', padding: '10px', marginTop: '15px' }}>Repay Agreement Received</Box>
          {!voucherInfoForm.isRecurringPayable && (voucherInfoForm?.status === 3 || voucherInfoForm?.status === 5) && (
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
              <Box>
                <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                    Reason
                  </Typography>
                </Box>
                <Box sx={{ marginBottom: '4px', marginRight: '20px', maxWidth: '100%', wordBreak: 'break-word' }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#43423a',
                      fontWeight: 500,
                      fontSize: '1rem',
                      textAlign: 'center',
                      wordBreak: 'break-word',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    {voucherInfoForm?.status === 3 ? voucherInfoForm?.onHoldReason : voucherInfoForm?.denyReason}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Status
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {isRecurringPayable
                    ? recurringPayableDetailsStatus.find(s => s.id === voucherInfoForm.recurringPayableStatus)?.title
                    : voucherStatusOptions.find(s => s.id === voucherInfoForm.status)?.title}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Box sx={{ paddingLeft: '16px', paddingTop: '10px' }}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          {isRecurringPayable && (
            <Grid item xs={12} xl={12} rowSpacing={2}>
              <Box sx={{ paddingRight: '16px' }}>
                <RecurringPaymentCard
                  isReadOnly={isReadOnly}
                  voucherInfo={voucherInfoForm}
                  updateVoucherInfo={newInfo => setVoucherInfoForm(newInfo)}
                  vendors={vendors}
                  history={history}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} xl={8} rowSpacing={2}>
            <VoucherInfoCard
              isReadOnly={isReadOnly}
              voucherInfo={voucherInfoForm}
              updateVoucherInfo={newInfo => setVoucherInfoForm(newInfo)}
              vendors={vendors}
            />
          </Grid>
          <Grid item xs={12} xl={4} rowSpacing={2}>
            <PaymentInfoCard
              isReadOnly={isReadOnly}
              voucherInfo={voucherInfoForm}
              updateVoucherInfo={newInfo => setVoucherInfoForm(newInfo)}
              setVoucherStatus={setVoucherStatus}
              updateVoucher={saveDraft}
            />
          </Grid>
          <Grid item xs={12} rowSpacing={2}>
            <VoucherLineItems
              isFormValidForApproval={isFormValidForApproval}
              voucherInfo={voucherState}
              expenseLineItems={expenseLineItems}
              isReadOnly={isReadOnly}
              setIsFormValidForApproval={setIsFormValidForApproval}
              expenseCodes={expenseCodes}
              voucherStatus={voucherStatus}
              setVoucherStatus={setVoucherStatus}
              updateVoucher={saveDraft}
              voucherInfoForm={voucherInfoForm}
              setVoucherInfoForm={setVoucherInfoForm}
              history={history}
              updateAllLineItems={updateAllLineItems}
              isLoading={isLoading}
              isDirty={isDirty}
              authorizationSummaries={authorizationSummaries}
              clientPreferredExpenseCodes={clientPreferredExpenseCodes}
              voucherAmount={voucherInfoForm.amount}
              expenseSummary={expenseSummary}
              recurringPayableId={voucherInfoForm.recurringPayableId}
            />
          </Grid>
        </Grid>
        <Box sx={{ paddingTop: '10px', margin: '16px', marginTop: '0px' }}>
          {!isRecurringPayable && (
            <DynamicFilePreviewCard
              authOptions={voucherInfoForm.payToVendor ? grabVoucherAuthIds(expenseLineItems) : null}
              authId={!voucherInfoForm.payToVendor ? voucherInfoForm.authorizationId : null}
              summaryExpenseLines={expenseLineItems}
              history={history}
              location={location}
              match={match}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = state => {
  const {
    authorizations: { authorizationSummaries, vendors },
    expenses: { voucherSummaries, expenseLines, isLoading },
    clients: { clients, unmappedClients }
  } = state;
  return { voucherSummaries, expenseLines, clients, unmappedClients, isLoading, vendors, authorizationSummaries };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    updateVoucherSummary,
    showToast,
    postMultipleExpenseLines,
    updateVoucherRecurringPayable
  })
)(VoucherDetails);
