import { createNewFieldsForm } from '../Utils/tempLivingFormUtils';
import { createFormTabs, getSelectedTab } from '../Utils/tempLivingConstantsUtils';
import {
  createNewInitializationFormRequest,
  createUpdateInitializationFormRequest,
  createUpdateMovedOutAndMovedInFormRequest,
  createUpdateTempLivingPropertyRequest
} from '../Utils/tempLivingRequestUtils';
import { getCurrentStatusSequence } from '../Utils/tempLivingUtils';
import { useSelector } from 'react-redux';
import { useUpdateOrCreateFormHandler } from '../useFormHandler';
import EditableCard from 'modules/common/editableCard.component';
import React from 'react';

const TempLivingForms = ({
  authId,
  showToast,
  isNewForm,
  onCloseNewForm,
  isReadOnly,
  selectedTempLivingAssignment,
  setSelectedTempLivingAssignment,
  family,
  pets
}) => {
  const { isLoading } = useSelector(state => ({
    isLoading: state.authorizations.authorizationDetails[authId]?.tempLivingAssignmentIsLoading
  }));

  const formHandler = useUpdateOrCreateFormHandler(showToast, isNewForm);
  const currentStatusSequence = getCurrentStatusSequence(selectedTempLivingAssignment);
  const currentTab = getSelectedTab(isNewForm, currentStatusSequence);

  const handleFormSubmission = async (tab, values) => {
    let updatedValues;
    if (isNewForm) {
      updatedValues = createNewInitializationFormRequest(values, authId);
    } else if (tab === 1) {
      updatedValues = createUpdateInitializationFormRequest(selectedTempLivingAssignment, values);
    } else if (tab === 2) {
      updatedValues = createUpdateTempLivingPropertyRequest(selectedTempLivingAssignment, values);
    } else {
      updatedValues = createUpdateMovedOutAndMovedInFormRequest(selectedTempLivingAssignment, values);
    }

    const resp = await formHandler(updatedValues);

    if (isNewForm) {
      onCloseNewForm();
      if (resp.isSuccessful) {
        setSelectedTempLivingAssignment(resp.content);
      }
    }
    return resp.isSuccessful;
  };

  const onCancel = () => {
    if (isNewForm) {
      onCloseNewForm();
    }
  };

  return (
    <EditableCard
      tabs={createFormTabs(currentStatusSequence)}
      summaries={createNewFieldsForm()}
      numColumns={5}
      updateFields={handleFormSubmission}
      isLoading={isLoading}
      openModel={isNewForm}
      onCancelProp={onCancel}
      openCurrentTab={currentTab}
      hideEdit={isReadOnly}
    />
  );
};

export default TempLivingForms;
