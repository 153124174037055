import { PetTypesOptions } from '../../TemporaryLiving/TempLivingConstants';
import { ValueType, YesOrNo, parseFormValue } from './formFieldConstants';

export const createFamilyPetColumns = vendors => {
  return [
    {
      accessorKey: 'petName',
      header: 'Pet Name',
      type: 'text',
      required: true,
      hideInTable: false,
      size: 65
    },
    {
      accessorKey: 'petType',
      header: 'Type',
      type: 'dropdown',
      required: true,
      options: PetTypesOptions,
      hideInTable: false,
      size: 65
    },
    {
      accessorKey: 'breed',
      header: 'Breed',
      type: 'text',
      required: true,
      hideInTable: false,
      size: 65
    },
    {
      accessorKey: 'age',
      header: 'Age',
      type: 'number',
      required: false,
      hideInTable: false,
      size: 65
    },
    {
      accessorKey: 'weight',
      header: 'Weight (lbs)',
      type: 'number',
      required: true,
      hideInTable: false,
      size: 65
    },
    {
      accessorKey: 'specialNeeds',
      header: 'Special Needs',
      type: 'notes',
      required: false,
      hideInTable: false,
      size: 65
    },
    {
      accessorKey: 'comments',
      header: 'Comments',
      type: 'notes',
      required: false,
      hideInTable: true
    }
  ];
};

export const createFamilyPetRows = (formDataList, vendors) => {
  if (!formDataList || !Array.isArray(formDataList)) {
    return [];
  }

  return formDataList.map(data => mapPetRow(data));
};

export const mapPetRow = (data, vendors) => {
  return {
    ...data,
    requiredDocumentationOnAnimal: data?.requiredDocumentationOnAnimal ? YesOrNo[0] : YesOrNo[1],
    requiredVaccines: data?.requiredVaccines ? YesOrNo[0] : YesOrNo[1],

    vendorPhoneNumber: data?.vendorId && vendors ? vendors.find(v => v.vendorId === data?.vendorId)?.phone : '',
    vendorEmail: data?.vendorId && vendors ? vendors.find(v => v.vendorId === data?.vendorId)?.vendorEmail : '',

    departureDate: parseFormValue(data.departureDate, ValueType.DateTimeType),
    arrivalDate: parseFormValue(data.arrivalDate, ValueType.DateTimeType)
  };
};

export const getNameOfDeletePetRow = row => {
  return `${row.getValue('petName') ?? ''}`;
};

export const createFamilyPetPostRequest = (formData, authorizationId) => {
  const request = { ...formData };
  request.authorizationId = authorizationId;
  parseFamilyPetFormValues(request, formData);
  return request;
};

export const createFamilyPetPutRequest = (originalFormData, newFormData, authorizationId) => {
  const request = { ...newFormData };
  request.authorizationId = authorizationId;
  request.familyPetId = originalFormData.familyPetId;
  parseFamilyPetFormValues(request, newFormData);
  return request;
};

const parseFamilyPetFormValues = (currentRequest, newRequestValues) => {
  currentRequest.requiredDocumentationOnAnimal = newRequestValues.requiredDocumentationOnAnimal === YesOrNo[0];
  currentRequest.requiredVaccines = newRequestValues.requiredVaccines === YesOrNo[0];

  currentRequest.departureDate = parseFormValue(newRequestValues.departureDate, ValueType.DateTimeType);
  currentRequest.arrivalDate = parseFormValue(newRequestValues.arrivalDate, ValueType.DateTimeType);

  currentRequest.age = parseFormValue(newRequestValues.age, ValueType.IntType);
  currentRequest.weight = parseFormValue(newRequestValues.weight, ValueType.IntType);
  currentRequest.carrierWeight = parseFormValue(newRequestValues.carrierWeight, ValueType.IntType);
  currentRequest.vendorId = parseFormValue(newRequestValues.vendorId, ValueType.IntType);

  currentRequest.costOfQuarantine = parseFormValue(newRequestValues.costOfQuarantine, ValueType.FloatType);
};
