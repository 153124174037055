/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {getExpenseCodes, getVendors} from 'modules/authorizations/store/actions/dataManagement.actions';

import {GET_EXPENSE_CODES_FAILURE, GET_VENDORS_FAILURE} from 'modules/authorizations/store/types/dataManagementTypes';

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { getAllPreferredExpenseCodes, getExpenseLines, getVoucherSummaries } from 'modules/expenses/expenses.actions';
import { getAuthorizationSummaries } from 'modules/authorizations/store/actions/authorizations.actions';
import { getClients } from 'modules/clients/clients.actions';
import { GET_CLIENT_PREFERRED_EXPENSE_CODES_FAILURE, GET_EXPENSE_LINES_FAILURE, GET_EXPENSE_SUMMARIES_FAILURE, GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE } from 'modules/expenses/types';
import { GET_AUTHORIZATIONS_FAILURE } from 'modules/authorizations/store/types/authorizationTypes';
import { getExpenseSummaries } from 'modules/expenses/expenses.actions';
import VoucherDetails from './voucherDetails.component';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const HomesaleView = (props) => {

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const voucherInfo = (props.voucherSummaries && Array.isArray(props.voucherSummaries)) ? props.voucherSummaries.find((v) => v.voucherId === parseInt(props.match.params.id)) : null;
  const expenseLines = (props.expenseLines && Array.isArray(props.expenseLines)) ? props.expenseLines.find((v) => v.voucherId === parseInt(props.match.params.id)) : null;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData () {
      window.scrollTo(0, 0);

      setIsLoading(true);

      let { type } = await props.getVoucherSummaries();
      if (type === GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE) {
          props.showToast('Failed to retrieve vouchers, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
      
      if (!props.expenseLines) {
          let { type } = await props.getExpenseLines();
          if (type === GET_EXPENSE_LINES_FAILURE) {
              props.showToast('Failed to retrieve expense lines, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          }
      }
  
      if (!props.vendors) {
        let { type } = await props.getVendors();
        if (type === GET_VENDORS_FAILURE) {
          props.showToast('Failed to retrieve vednors, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }
  
      if (!props.authorizationSummaries) {
        let { type } = await props.getAuthorizationSummaries();
        if (type === GET_AUTHORIZATIONS_FAILURE) {
          props.showToast('Failed to retrieve authorizations, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }
  
      if(!props.expenseCodes){
        let { type } = await props.getExpenseCodes();
        if (type === GET_EXPENSE_CODES_FAILURE) {
          props.showToast('Failed to retrieve expense codes, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      if(!props.expenseSummaries){
        let { type } = await props.getExpenseSummaries();
        if (type === GET_EXPENSE_SUMMARIES_FAILURE) {
          props.showToast('Failed to retrieve expense summaries, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }
      
      if (!props.clientPreferredExpenseCodes) {
        let { type } = await props.getAllPreferredExpenseCodes();
        if (type === GET_CLIENT_PREFERRED_EXPENSE_CODES_FAILURE) {
          props.showToast('Failed to retrieve client preferred expense codes, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      setIsLoading(false);

    }

    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && voucherInfo &&
          <VoucherDetails voucherInfo={voucherInfo} history={props.history} location={props.location} match={props.match} expenseLines={expenseLines} expenseCodes={props.expenseCodes} clientPreferredExpenseCodes={props.clientPreferredExpenseCodes} expenses={props.expenseSummaries} />
        }
        {isLoading && <FullscreenSpinner />}

      </Box>
    </ThemeProvider>
  );

};

HomesaleView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    authorizations: { authorizationSummaries, vendors, expenseCodes },
    expenses: { voucherSummaries, expenseLines, isLoading, clientPreferredExpenseCodes, expenseSummaries },
    clients: { clients, unmappedClients },
  } = state;

  return { voucherSummaries, expenseLines, clients, unmappedClients, isLoading, vendors, authorizationSummaries, expenseCodes, clientPreferredExpenseCodes, expenseSummaries };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getVoucherSummaries,
    getVendors,
    getAuthorizationSummaries,
    getClients,
    getExpenseLines,
    getExpenseCodes,
    getAllPreferredExpenseCodes,
    getExpenseSummaries,
  }),
)(HomesaleView);