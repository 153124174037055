/* eslint-disable react-hooks/exhaustive-deps */

import { withStyles } from '@material-ui/core';

import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Spinner from 'modules/common/spinner.component';
import CarShipmentVehicles from './carShipmentVehicles.component';
import CarShipmentMoveDates from './carShipmentMoveDates.component';
import LongTermStorageMoveDates from './longTermStorageMoveDates.component';
import CarShipmentEstimates from './carShipmentEstimates.component';
import GoodsShipmentEstimates from './goodsShipmentEstimates.component';
import LongTermStorageEstimates from './longTermStorageEstimates.component';
import { updateHhgEstimate } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_ESTIMATE_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import GoodsShipmentMovingDatesCard from './goodsShipmentMovingDates.component';
import RequestDetailsEstimatesCard from './requestDetailsEstimatesCard.component';

const styles = theme => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  }
});

const EstimatesActuals = props => {
  const { currentTab, details, estimateId, location, isLoadingEstimates, segmentId, isReadOnly, id, showToast, updateHhgEstimate } = props;

  const segmentInfo = details?.authHouseHoldGoods?.hhgSegments
    ? details?.authHouseHoldGoods?.hhgSegments.find(s => s.segmentId === segmentId)
    : null;

  const estimateInfo =
    segmentInfo && estimateId != null ? details?.authHouseHoldGoods?.hhgEstimates.find(e => e.estimateId === estimateId) : null;

  let itemsTotal = 0;
  if (details?.authHouseHoldGoods?.hhgItems)
    details.authHouseHoldGoods.hhgItems.map(item => (itemsTotal = itemsTotal + Number(item.value)));

  let estimateInfoEmptyOrValid = estimateInfo == null || (estimateInfo != null && estimateInfo.estimateType !== currentTab);
  let vehicles = details?.authHouseHoldGoods?.hhgSegmentVehicles
    ? details?.authHouseHoldGoods?.hhgSegmentVehicles.filter(v => v.estimateId === estimateId)
    : [];

  const [isLoading, setIsLoading] = useState(false);
  const [hasVehicles, setHasVehicles] = useState(estimateInfo?.hasVehicles || false);
  const handleHasVehicles = async () => {
    setIsLoading(true);
    setHasVehicles(!hasVehicles);
    let request = { ...estimateInfo };
    request.hasVehicles = !request.hasVehicles;

    const response = await updateHhgEstimate(request);
    if (response.type === UPDATE_HHG_ESTIMATE_FAILURE) {
      showToast('Failed to update hhg estimate, please try again later', TOAST_MESSAGE_SEVERITY_ERROR);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setHasVehicles(estimateInfo?.hasVehicles || false);
  }, [estimateId]);

  const isReadOnlyMode = location.pathname.includes('expenses') || isReadOnly;

  const renderCarShipmentSection = () => (
    <>
      <FormControlLabel
        control={<Checkbox checked={hasVehicles} onChange={handleHasVehicles} color="primary" disabled={isLoading} />}
        label={<Typography sx={{ fontSize: '1.1rem' }}>Does EE have cars to move?</Typography>}
      />
      {hasVehicles && (
        <Box sx={{ paddingTop: '16px' }}>
          <CarShipmentVehicles hhgSegmentVehicles={vehicles} estimateId={estimateId} authorizationId={id} isReadOnly={isReadOnlyMode} />
        </Box>
      )}
      <Box sx={{ paddingTop: '16px' }}>
        <CarShipmentMoveDates estimateInfo={estimateInfo} hideEdit={isReadOnlyMode} />
      </Box>
    </>
  );

  const renderGoodsShipmentSection = () => (
    <Box sx={{ paddingTop: '16px' }}>
      <GoodsShipmentMovingDatesCard estimateId={estimateId} authId={id} hideEdit={isReadOnlyMode} />
    </Box>
  );

  const renderLongTermStorageSection = () => (
    <Box sx={{ paddingTop: '16px' }}>
      <LongTermStorageMoveDates estimateInfo={estimateInfo} hideEdit={isReadOnlyMode} />
    </Box>
  );

  const renderVendorInformation = EstimateComponent => (
    <Box>
      <EstimateComponent estimateInfo={estimateInfo} itemsTotal={itemsTotal} hideEdit={isReadOnlyMode} />
    </Box>
  );

  return (
    <Box>
      {estimateInfoEmptyOrValid && !isLoadingEstimates && (
        <Box sx={{ padding: '20px', fontSize: '20px' }}>
          <b>No estimate selected. Create a new estimate above to get started.</b>
        </Box>
      )}
      {!estimateInfoEmptyOrValid && !isLoadingEstimates && (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} xl={8} rowSpacing={2}>
            <RequestDetailsEstimatesCard estimateInfo={estimateInfo} hideEdit={isReadOnlyMode} />
            {currentTab === 'CAR_SHIPMENT' && renderCarShipmentSection()}
            {currentTab === 'GOODS_SHIPMENT' && renderGoodsShipmentSection()}
            {currentTab === 'LONG_TERM_STORAGE' && renderLongTermStorageSection()}
          </Grid>
          <Grid item xs={12} xl={4} rowSpacing={2}>
            {currentTab === 'CAR_SHIPMENT' && renderVendorInformation(CarShipmentEstimates)}
            {currentTab === 'GOODS_SHIPMENT' && renderVendorInformation(GoodsShipmentEstimates)}
            {currentTab === 'LONG_TERM_STORAGE' && renderVendorInformation(LongTermStorageEstimates)}
          </Grid>
        </Grid>
      )}
      {isLoadingEstimates && <Spinner />}
    </Box>
  );
};

EstimatesActuals.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default compose(withStyles(styles), connect(mapStateToProps, { updateHhgEstimate }))(EstimatesActuals);
