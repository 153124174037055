import { GET_SURVEY_QUESTION_TRIGGERS_FAILURE, GET_SURVEY_QUESTION_TRIGGERS_REQUEST, GET_SURVEY_QUESTION_VARIATIONS_FAILURE, GET_SURVEY_QUESTION_VARIATIONS_REQUEST, GET_SURVEY_QUESTIONS_FAILURE, GET_SURVEY_QUESTIONS_REQUEST, GET_SURVEY_QUESTIONS_SUCCESS, GET_SURVEY_QUESTION_TRIGGERS_SUCCESS, GET_SURVEY_QUESTION_VARIATIONS_SUCCESS, SET_SURVEY_LOADING_COMPLETE, UPDATE_SURVEY_QUESTION_SUCCESS, SET_QUESTION_ID, GET_SURVEY_QUESTION_BY_ID_SUCCESS, GET_SURVEY_QUESTION_BY_ID_FAILURE, GET_SURVEY_QUESTION_BY_ID_REQUEST, GET_SURVEY_QUESTION_RESPONSES_SUCCESS, GET_SURVEY_QUESTION_RESPONSES_REQUEST, GET_SURVEY_QUESTION_RESPONSES_FAILURE } from './surveys.types';
import produce from 'immer';

const initialState = {
  currentSurveyQuestionId: null,
  surveyQuestions: null,
  surveyQuestionTriggers: null,
  surveyQuestionVariations: null,
  isLoadingSurveyQuestions: false,
  surveyQuestionResponses: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_SURVEY_QUESTIONS_REQUEST:
    case GET_SURVEY_QUESTION_TRIGGERS_REQUEST:
    case GET_SURVEY_QUESTION_VARIATIONS_REQUEST:
    case GET_SURVEY_QUESTION_BY_ID_REQUEST:
    case GET_SURVEY_QUESTION_RESPONSES_REQUEST:
      draft.isLoadingSurveyQuestions = true;
      break;

    case SET_QUESTION_ID:
      draft.currentSurveyQuestionId = action.response;
      break;

    case SET_SURVEY_LOADING_COMPLETE:
      draft.isLoadingSurveyQuestions = false;
      break;

    case GET_SURVEY_QUESTIONS_SUCCESS:
      draft.isLoadingSurveyQuestions = false;
      draft.surveyQuestions = action.response;
      break;

    case GET_SURVEY_QUESTION_BY_ID_SUCCESS:
      draft.isLoadingSurveyQuestions = false;
      draft.surveyQuestions[draft.currentSurveyQuestionId] = action.response;
      break;

    case GET_SURVEY_QUESTION_TRIGGERS_SUCCESS:
      draft.isLoadingSurveyQuestions = false;
      draft.surveyQuestionTriggers = action.response;
      break;

    case GET_SURVEY_QUESTION_VARIATIONS_SUCCESS:
      draft.isLoadingSurveyQuestions = false;
      draft.surveyQuestionVariations = action.response;
      break;

    case UPDATE_SURVEY_QUESTION_SUCCESS:
      draft.surveyQuestions[draft.currentSurveyQuestionId] = action.response;
      break;

    case GET_SURVEY_QUESTIONS_FAILURE:
    case GET_SURVEY_QUESTION_TRIGGERS_FAILURE:
    case GET_SURVEY_QUESTION_VARIATIONS_FAILURE:
    case GET_SURVEY_QUESTION_BY_ID_FAILURE:
    case GET_SURVEY_QUESTION_RESPONSES_FAILURE:
      draft.isLoadingSurveyQuestions = false;
      break;

    case GET_SURVEY_QUESTION_RESPONSES_SUCCESS:
      draft.isLoadingSurveyQuestions = false;
      draft.surveyQuestionResponses = action.response;
      break;

    default:
      break;
  }

  return draft;
}, initialState);
