/* eslint-disable react-hooks/exhaustive-deps */

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { GET_HHG_ESTIMATE_MOVING_DATES_FAILURE, POST_HHG_ESTIMATE_MOVING_DATES_FAILURE, UPDATE_HHG_ESTIMATE_MOVING_DATES_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { getHhgEstimateMovingDates, postHhgEstimateMovingDates, updateHhgEstimateMovingDates } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import React, { useEffect, useState } from 'react';

import {
  Box, Button, Card, CardContent, Dialog, DialogActions, Divider, Fab, Grid, Stack, Tab, Tabs, TextField, Tooltip, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';

import { formatDateForInput } from 'utilities/common';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));


const EditCardModal = (props) => {
  const { open, onSubmit, isLoading, onClose, movingDates } = props;
  const classes = useStyles();

  const [estimatedPackStart, setEstimatedPackStart] = useState(movingDates ? movingDates.estimatedPackStart : null);
  const [estimatedPackEnd, setEstimatedPackEnd] = useState(movingDates ? movingDates.estimatedPackEnd : null);
  const [actualPackStart, setActualPackStart] = useState(movingDates ? movingDates.actualPackStart : null);
  const [actualPackEnd, setActualPackEnd] = useState(movingDates ? movingDates.actualPackEnd : null);
  const [estimatedLoadStart, setEstimatedLoadStart] = useState(movingDates ? movingDates.estimatedLoadStart : null);
  const [estimatedLoadEnd, setEstimatedLoadEnd] = useState(movingDates ? movingDates.estimatedLoadEnd : null);
  const [actualLoadStart, setActualLoadStart] = useState(movingDates ? movingDates.actualLoadStart : null);
  const [actualLoadEnd, setActualLoadEnd] = useState(movingDates ? movingDates.actualLoadEnd : null);

  const [estimatedDeliveryStart, setEstimatedDeliveryStart] = useState(movingDates ? movingDates.estimatedDeliveryStart : null);
  const [estimatedDeliveryEnd, setEstimatedDeliveryEnd] = useState(movingDates ? movingDates.estimatedDeliveryEnd : null);
  const [actualDeliveryStart, setActualDeliveryStart] = useState(movingDates ? movingDates.actualPackStart : null);
  const [actualDeliveryEnd, setActualDeliveryEnd] = useState(movingDates ? movingDates.actualDeliveryEnd : null);
  const [estimatedStorageStart, setEstimatedStorageStart] = useState(movingDates ? movingDates.estimatedStorageStart : null);
  const [estimatedStorageEnd, setEstimatedStorageEnd] = useState(movingDates ? movingDates.estimatedStorageEnd : null);
  const [actualStorageStart, setActualStorageStart] = useState(movingDates ? movingDates.actualStorageStart : null);
  const [actualStorageEnd, setActualStorageEnd] = useState(movingDates ? movingDates.actualStorageEnd : null);
  const [estimatedStorageDays, setEstimatedStorageDays] = useState(movingDates ? movingDates.estimatedStorageDays : 0);
  const [actualStorageDays, setActualStorageDays] = useState(movingDates ? movingDates.actualStorageDays : 0);


  const [estimatedCarLoadingStart, setEstimatedCarLoadingStart] = useState(movingDates ? movingDates.estimatedCarLoadingStart : null);
  const [estimatedCarLoadingEnd, setEstimatedCarLoadingEnd] = useState(movingDates ? movingDates.estimatedCarLoadingEnd : null);
  const [actualCarLoadingStart, setActualCarLoadingStart] = useState(movingDates ? movingDates.actualCarLoadingStart : null);
  const [actualCarLoadingEnd, setActualCarLoadingEnd] = useState(movingDates ? movingDates.actualCarLoadingEnd : null);
  const [estimatedCarDeliveryStart, setEstimatedCarDeliveryStart] = useState(movingDates ? movingDates.estimatedCarDeliveryStart : null);
  const [estimatedCarDeliveryEnd, setEstimatedCarDeliveryEnd] = useState(movingDates ? movingDates.estimatedCarDeliveryEnd : null);
  const [actualCarDeliveryStart, setActualCarDeliveryStart] = useState(movingDates ? movingDates.actualCarDeliveryStart : null);
  const [actualCarDeliveryEnd, setActualCarDeliveryEnd] = useState(movingDates ? movingDates.actualCarDeliveryEnd : null);

  const onSubmitModal = (e) => {
    e.preventDefault();

    const values = {
        estimatedPackStart,
        estimatedPackEnd,
        actualPackStart,
        actualPackEnd,
        estimatedLoadStart,
        estimatedLoadEnd,
        actualLoadStart,
        actualLoadEnd,
        estimatedDeliveryStart,
        estimatedDeliveryEnd,
        actualDeliveryStart,
        actualDeliveryEnd,
        estimatedStorageStart,
        estimatedStorageEnd,
        actualStorageStart,
        actualStorageEnd,
        estimatedStorageDays,
        actualStorageDays,
      estimatedCarLoadingStart,
      estimatedCarLoadingEnd,
      actualCarLoadingStart,
      actualCarLoadingEnd,
      estimatedCarDeliveryStart,
      estimatedCarDeliveryEnd,
      actualCarDeliveryStart,
      actualCarDeliveryEnd,
    };

    onSubmit(values);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={onSubmitModal} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Tooltip title="Move Dates" key="Move Dates" placement="right">
              <FontAwesomeIcon icon={['fas', 'money-check-dollar']} size="3x" />
            </Tooltip>
            <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
              Move Dates
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem',
            }}
          >
            <Grid container spacing={2}>
              <Grid item md="4" />
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Estimated Start</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Estimated End</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Actual Start</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Actual End</Typography>
                  </div>
                </Box>
              </Grid> 
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Pack</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedPackStart'}
                    label={''}
                    name={'estimatedPackStart'}
                    value={formatDateForInput(estimatedPackStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedPackStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedPackEnd'}
                    label={''}
                    name={'estimatedPackEnd'}
                    value={formatDateForInput(estimatedPackEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedPackEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualPackStart'}
                    label={''}
                    name={'actualPackStart'}
                    value={formatDateForInput(actualPackStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualPackStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualPackEnd'}
                    label={''}
                    name={'actualPackEnd'}
                    value={formatDateForInput(actualPackEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualPackEnd(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Load</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedLoadStart'}
                    label={''}
                    name={'estimatedLoadStart'}
                    value={formatDateForInput(estimatedLoadStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedLoadStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedLoadEnd'}
                    label={''}
                    name={'estimatedLoadEnd'}
                    value={formatDateForInput(estimatedLoadEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedLoadEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualLoadStart'}
                    label={''}
                    name={'actualLoadStart'}
                    value={formatDateForInput(actualLoadStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualLoadStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualLoadEnd'}
                    label={''}
                    name={'actualLoadEnd'}
                    value={formatDateForInput(actualLoadEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualLoadEnd(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Delivery</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedDeliveryStart'}
                    label={''}
                    name={'estimatedDeliveryStart'}
                    value={formatDateForInput(estimatedDeliveryStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedDeliveryStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedDeliveryEnd'}
                    label={''}
                    name={'estimatedDeliveryEnd'}
                    value={formatDateForInput(estimatedDeliveryEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedDeliveryEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualDeliveryStart'}
                    label={''}
                    name={'actualDeliveryStart'}
                    value={formatDateForInput(actualDeliveryStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualDeliveryStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualDeliveryEnd'}
                    label={''}
                    name={'actualDeliveryEnd'}
                    value={formatDateForInput(actualDeliveryEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualDeliveryEnd(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Car Loading</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedCarLoadingStart'}
                    label={''}
                    name={'estimatedCarLoadingStart'}
                    value={formatDateForInput(estimatedCarLoadingStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedCarLoadingStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedCarLoadingEnd'}
                    label={''}
                    name={'estimatedCarLoadingEnd'}
                    value={formatDateForInput(estimatedCarLoadingEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedCarLoadingEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualCarLoadingStart'}
                    label={''}
                    name={'actualCarLoadingStart'}
                    value={formatDateForInput(actualCarLoadingStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualCarLoadingStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualCarLoadingEnd'}
                    label={''}
                    name={'actualCarLoadingEnd'}
                    value={formatDateForInput(actualCarLoadingEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualCarLoadingEnd(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Car Delivery</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedCarDeliveryStart'}
                    label={''}
                    name={'estimatedCarDeliveryStart'}
                    value={formatDateForInput(estimatedCarDeliveryStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedCarDeliveryStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedCarDeliveryEnd'}
                    label={''}
                    name={'estimatedCarDeliveryEnd'}
                    value={formatDateForInput(estimatedCarDeliveryEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedCarDeliveryEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualCarDeliveryStart'}
                    label={''}
                    name={'actualCarDeliveryStart'}
                    value={formatDateForInput(actualCarDeliveryStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualCarDeliveryStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualCarDeliveryEnd'}
                    label={''}
                    name={'actualCarDeliveryEnd'}
                    value={formatDateForInput(actualCarDeliveryEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualCarDeliveryEnd(e.target.value)}
                  />
                </Box>
              </Grid>

              
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Storage</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedStorageStart'}
                    label={''}
                    name={'estimatedStorageStart'}
                    value={formatDateForInput(estimatedStorageStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedStorageStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedStorageEnd'}
                    label={''}
                    name={'estimatedStorageEnd'}
                    value={formatDateForInput(estimatedStorageEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedStorageEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualStorageStart'}
                    label={''}
                    name={'actualStorageStart'}
                    value={formatDateForInput(actualStorageStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualStorageStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualStorageEnd'}
                    label={''}
                    name={'actualStorageEnd'}
                    value={formatDateForInput(actualStorageEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualStorageEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ margin: '20px 0' }} />
              </Grid>
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Storage Days</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <TextField
                    key={'estimatedStorageDays'}
                    label={'Estimated Storage Days'}
                    name={'estimatedStorageDays'}
                    value={estimatedStorageDays}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="number"
                    onChange={(e) => setEstimatedStorageDays(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <TextField
                    key={'actualStorageDays'}
                    label={'Actual Storage Days'}
                    name={'actualStorageDays'}
                    value={actualStorageDays}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="number"
                    onChange={(e) => setActualStorageDays(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained" >Save</Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

const GoodsShipmentMovingDatesCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [editingModalOpen, setEditingModalOpen] = useState(false);
  const [currentType, setCurrentType] = useState(1);
  const [estimateMovingDates, setEstimateMovingDates] = useState([]);
  const [movingDates, setMovingDates] = useState(estimateMovingDates && estimateMovingDates.length > 0 && currentType ? estimateMovingDates.find(s => s.type === currentType) : []);

  const movingTypes = [
    { title: 'Air', id: 1},
    { title: 'Land', id: 2},
    { title: 'Sea', id: 3},
    { title: 'LTS', id: 4},
  ]

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const { estimateId, authId } = props;

    useEffect(() => {
        async function fetchData() {          
            const resp = await props.getHhgEstimateMovingDates(estimateId);
            if(resp.type === GET_HHG_ESTIMATE_MOVING_DATES_FAILURE) {
              props.showToast('Failed to get estimate moving dates.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
            }
            else{
              setEstimateMovingDates(resp.response);
              if(movingDates !== undefined && movingDates.estimateId !== estimateId)
              {
                setMovingDates([]);
              }
            }
           
          }
  
      if(estimateId)
      {
        fetchData();
      }
      if(currentType && estimateMovingDates && estimateMovingDates.length > 0)
      {
        setMovingDates(estimateMovingDates.find(s => s.type === currentType));
      }
    }, [estimateMovingDates, estimateId, currentType]);

  const updateFields = async (values) => {
    setIsLoading(true);

    let newVals = { ...movingDates };

    newVals.estimatedPackStart = values.estimatedPackStart;
    newVals.estimatedPackEnd = values.estimatedPackEnd;
    newVals.actualPackStart = values.actualPackStart;
    newVals.actualPackEnd = values.actualPackEnd;
    newVals.estimatedLoadStart = values.estimatedLoadStart;
    newVals.estimatedLoadEnd = values.estimatedLoadEnd;
    newVals.actualLoadStart = values.actualLoadStart;
    newVals.actualLoadEnd = values.actualLoadEnd;
    newVals.estimatedDeliveryStart = values.estimatedDeliveryStart;
    newVals.estimatedDeliveryEnd = values.estimatedDeliveryEnd;
    newVals.actualDeliveryStart = values.actualDeliveryStart;
    newVals.actualDeliveryEnd = values.actualDeliveryEnd;
    newVals.estimatedStorageStart = values.estimatedStorageStart;
    newVals.estimatedStorageEnd = values.estimatedStorageEnd;
    newVals.actualStorageStart = values.actualStorageStart;
    newVals.actualStorageEnd = values.actualStorageEnd;
    newVals.estimatedStorageDays = values.estimatedStorageDays;
    newVals.actualStorageDays = values.actualStorageDays;
    newVals.estimatedCarLoadingStart= values.estimatedCarLoadingStart;
    newVals.estimatedCarLoadingEnd= values.estimatedCarLoadingEnd;
    newVals.actualCarLoadingStart= values.actualCarLoadingStart;
    newVals.actualCarLoadingEnd= values.actualCarLoadingEnd;
    newVals.estimatedCarDeliveryStart= values.estimatedCarDeliveryStart;
    newVals.estimatedCarDeliveryEnd= values.estimatedCarDeliveryEnd;
    newVals.actualCarDeliveryStart= values.actualCarDeliveryStart;
    newVals.actualCarDeliveryEnd= values.actualCarDeliveryEnd;


    if(movingDates !== undefined && movingDates.length !== 0)
    {
        const resp = await props.updateHhgEstimateMovingDates(newVals);
        if (resp.type === UPDATE_HHG_ESTIMATE_MOVING_DATES_FAILURE) {
          props.showToast('Failed to update the estimate moving dates info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else {       
          setIsLoading(false);
          return true;
        }
    }
    else {
        newVals.type = currentType;
        newVals.authorizationId = authId;
        newVals.estimateId = estimateId;
        const resp = await props.postHhgEstimateMovingDates(newVals);
        if (resp.type === POST_HHG_ESTIMATE_MOVING_DATES_FAILURE) {
          props.showToast('Failed to create the estimate moving dates info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else { 
          setIsLoading(false);
          return true;
        }
    }

    
  };

  const handleSaveEdits = async (values) => {
    // Send update call to parent to update and refresh values.
    const resp = await updateFields(values);
    if (!resp) {
      showToast('Failed to update moving dates data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    } 
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };

  const renderTab = (tab) => {
    return (
      <Tab
        key={tab.id}
        iconPosition={'start'}
        value={tab.id}
        label={tab.title}
        sx={{
          zIndex: 1,
          minWidth: '265px',
          minHeight: '0px',
          textTransform: 'none',
          fontSize: '1.25rem',
          fontWeight: 'bold',
          color: 'black',
        }}
      />
    );
  };

  const handleChangeTab = (tab) => {
    setCurrentType(tab);
    setMovingDates(estimateMovingDates.find(s => s.type === currentType));
  };
    
  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Estimates" key="Estimates" placement="right">
                  <FontAwesomeIcon icon={['fas', 'calendar-days']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Moving Dates
                </Typography>
              </div>
              { !props.hideEdit && 
              <>
                <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                  <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                </Fab>
              </>
              }
            </div>
          </CardContent>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
                <Tabs variant="fullWidth" value={currentType} onChange={(event, tab) => handleChangeTab(tab)} style={{ flex: 0.9 }}>
                    {movingTypes.map(renderTab)}
                </Tabs>
            </div>
          </CardContent>
          { estimateMovingDates &&
          <>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={2}>
              <Grid item md="4" />
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Estimated Start</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Estimated End</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Actual Start</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Actual End</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Pack</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.estimatedPackStart) ? new Date(movingDates.estimatedPackStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.estimatedPackEnd) ? new Date(movingDates.estimatedPackEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.actualPackStart) ? new Date(movingDates.actualPackEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.actualPackEnd) ? new Date(movingDates.actualPackEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Load</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.estimatedLoadStart) ? new Date(movingDates.estimatedLoadStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.estimatedLoadEnd) ? new Date(movingDates.estimatedLoadEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.actualLoadStart) ? new Date(movingDates.actualLoadStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.actualLoadEnd) ? new Date(movingDates.actualLoadEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Delivery</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.estimatedDeliveryStart) ? new Date(movingDates.estimatedDeliveryStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.estimatedDeliveryEnd) ? new Date(movingDates.estimatedDeliveryEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.actualDeliveryStart) ? new Date(movingDates.actualDeliveryStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.actualDeliveryEnd) ? new Date(movingDates.actualDeliveryEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Car Loading</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.estimatedCarLoadingStart) ? new Date(movingDates.estimatedCarLoadingStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.estimatedCarLoadingEnd) ? new Date(movingDates.estimatedCarLoadingEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.actualCarLoadingStart) ? new Date(movingDates.actualCarLoadingStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.actualCarLoadingEnd) ? new Date(movingDates.actualCarLoadingEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Car Delivery</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.estimatedCarDeliveryStart) ? new Date(movingDates.estimatedCarDeliveryStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.estimatedCarDeliveryEnd) ? new Date(movingDates.estimatedCarDeliveryEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.actualCarDeliveryStart) ? new Date(movingDates.actualCarDeliveryStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.actualCarDeliveryEnd) ? new Date(movingDates.actualCarDeliveryEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Storage</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.estimatedStorageStart) ? new Date(movingDates.estimatedStorageStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.estimatedStorageEnd) ? new Date(movingDates.estimatedStorageEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.actualStorageStart) ? new Date(movingDates.actualStorageStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.actualStorageEnd) ? new Date(movingDates.actualStorageEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ margin: '20px 0' }} />
              </Grid>
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Storage Days</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.estimatedStorageDays) ? movingDates.estimatedStorageDays : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(movingDates && movingDates.actualStorageDays) ? movingDates.actualStorageDays : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          </>
          }
        </Box>
      </Card>
      {editingModalOpen && (
        <EditCardModal
          classes={classes}
          tab={0}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={() => setEditingModalOpen(false)}
          onSubmit={handleSaveEdits}
          movingDates={movingDates}
        />
      )}
    </ThemeProvider>
  );

};

export default compose(
  connect(null, {
    getHhgEstimateMovingDates,
    postHhgEstimateMovingDates,
    updateHhgEstimateMovingDates,
    showToast,
  }),
)(GoodsShipmentMovingDatesCard);