import React from 'react';
import {
  createInitializationFormFields,
  createMovedOutAndMovedInFormFields,
  createTempLivingPropertyFormFields
} from '../Utils/tempLivingFormUtils';
import {
  createUpdateInitializationFormRequest,
  createUpdateMovedOutAndMovedInFormRequest,
  createUpdateTempLivingPropertyRequest
} from '../Utils/tempLivingRequestUtils';
import { useSelector } from 'react-redux';
import { useUpdateOrCreateFormHandler } from '../useFormHandler';
import EditableCard from 'modules/common/editableCard.component';
import { TemporaryLivingFormIds } from '../TempLivingConstants';

const TempLivingEditableCardForms = ({ authId, showToast, isReadOnly, selectedTempLivingAssignment, formId, pets, family }) => {
  const { isLoading } = useSelector(state => ({
    isLoading: state.authorizations.authorizationDetails[authId]?.tempLivingAssignmentIsLoading
  }));

  let summaries =
    formId === TemporaryLivingFormIds.Initiated
      ? createInitializationFormFields(selectedTempLivingAssignment, pets, family)
      : formId === TemporaryLivingFormIds.Property
      ? createTempLivingPropertyFormFields(selectedTempLivingAssignment)
      : createMovedOutAndMovedInFormFields(selectedTempLivingAssignment);

  let headerTab =
    formId === TemporaryLivingFormIds.Initiated
      ? { header: 'Temp Living Preferences', order: 1, iconPath: ['fas', 'user'], isDisabled: false }
      : formId === TemporaryLivingFormIds.Property
      ? { header: 'Property', order: 1, iconPath: ['fas', 'house'], isDisabled: false }
      : { header: 'Moved In/Moved Out', order: 1, iconPath: ['fas', 'truck-moving'], isDisabled: true };

  const formHandler = useUpdateOrCreateFormHandler(showToast, false);

  const handleFormSubmission = async (tab, values) => {
    let updatedValues;
    if (formId === TemporaryLivingFormIds.Initiated) {
      updatedValues = createUpdateInitializationFormRequest(selectedTempLivingAssignment, values);
    } else if (formId === TemporaryLivingFormIds.Property) {
      updatedValues = createUpdateTempLivingPropertyRequest(selectedTempLivingAssignment, values);
    } else {
      updatedValues = createUpdateMovedOutAndMovedInFormRequest(selectedTempLivingAssignment, values);
    }

    const resp = await formHandler(updatedValues);
    return resp.isSuccessful;
  };

  return (
    <EditableCard
      tabs={[headerTab]}
      summaries={summaries}
      numColumns={formId === TemporaryLivingFormIds.Initiated ? 6 : 5}
      updateFields={handleFormSubmission}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );
};

export default TempLivingEditableCardForms;
