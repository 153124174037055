import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_ESTIMATE_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgEstimate } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  Divider,
  Fab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';
import { formatDateForInput } from 'utilities/common';


const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chipClickable: {
    cursor: 'pointer'
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important'
  },
  spacingX: {
    marginRight: theme.spacing(1)
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2)
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px'
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important'
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important'
  },
  editModalInput: {
    minWidth: '332px'
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px'
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0
  }
}));

const EditCardModal = props => {
  const { open, onSubmit, isLoading, onClose, estimateInfo } = props;
  const classes = useStyles();

  const [orderedDate, setOrderedDate] = useState(estimateInfo ? estimateInfo.orderedDate : null);
  const [vendorAccepted, setVendorAccepted] = useState(estimateInfo && estimateInfo.vendorAccepted && estimateInfo.vendorAccepted === true ? 'Yes' : 'No');
  const [vendorNotAcceptedReason, setVendorNotAcceptedReason] = useState(estimateInfo ? estimateInfo.vendorNotAcceptedReason : '');
  const [appointmentDate, setAppointmentDate] = useState(estimateInfo ? estimateInfo.appointmentDate : null);
  const [receivedDate, setReceivedDate] = useState(estimateInfo ? estimateInfo.receivedDate : null);
  const [inventoryFileName, setInventoryFileName] = useState(estimateInfo ? estimateInfo.inventoryFileName : '');
  const [notes, setNotes] = useState(estimateInfo ? estimateInfo.comment : '');
  const [vendorContactName, setVendorContactName] = useState(estimateInfo ? estimateInfo.vendorContactName : '');
  const [vendorContactEmail, setVendorContactEmail] = useState(estimateInfo ? estimateInfo.vendorContactEmail : '');
  const [vendorContactPhone, setVendorContactPhone] = useState(estimateInfo ? estimateInfo.vendorContactPhone : '');


  const [isDirty, setIsDirty] = useState(false);
  let acceptedOptions = ['Yes', 'No'];

  const onSubmitModal = e => {
    e.preventDefault();

    const values = {
      orderedDate,
      vendorAccepted,
      vendorNotAcceptedReason,
      appointmentDate,
      receivedDate,
      inventoryFileName,
      notes,
      vendorContactName,
      vendorContactPhone,
      vendorContactEmail,
    };

    onSubmit(values);
  };

  useEffect(() => {
    if (estimateInfo) {
      if (
        (!(orderedDate === 0 && estimateInfo.orderedDate == null) &&
          orderedDate !== estimateInfo.orderedDate) ||
        (!(vendorAccepted === 0 && estimateInfo.vendorAccepted == null) &&
          vendorAccepted !== estimateInfo.vendorAccepted) ||
        (!(appointmentDate === 0 && estimateInfo.appointmentDate == null) &&
          appointmentDate !== estimateInfo.appointmentDate) ||
        (!(receivedDate === 0 && estimateInfo.reasonNotAccepted == null) && receivedDate !== estimateInfo.receivedDate) ||
        (!(inventoryFileName === 0 && estimateInfo.inventoryFileName == null) && inventoryFileName !== estimateInfo.inventoryFileName)
      ) {
        setIsDirty(true);
      }
    }
  }, [
    orderedDate,
    vendorAccepted,
    appointmentDate,
    receivedDate,
    inventoryFileName,
    estimateInfo,
  ]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={onSubmitModal} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Tooltip title="Estimates" key="Estimates" placement="right">
              <FontAwesomeIcon icon={['fas', 'fa-truck-moving']} size="3x" />
            </Tooltip>
            <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
                Request Details
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem',
            }}
          >
            <Grid container spacing={{ xs: 2, md: 3, lg: 5 }} sx={{ padding: '30px', paddingTop: '0px' }}>
                  <Grid item >
                    <div>
                      <TextField
                        key={'orderedDate'}
                        label={'Ordered Date'}
                        name={'orderedDate'}
                        required={false}
                        disabled={false}
                        value={formatDateForInput(orderedDate)}
                        sx={{ maxWidth: '360px' }}
                        margin="dense"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setOrderedDate(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '6px' }}>
                        <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'Service Type'}>
                          Vendor Accepted
                        </InputLabel>
                        <Select
                          labelId={'vendorAccepted'}
                          key={'vendorAccepted'}
                          label={'Vendor Accepted'}
                          name={'vendorAccepted'}
                          required={false}
                          disabled={false}
                          value={vendorAccepted}
                          margin="dense"
                          variant="standard"
                          sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                          onChange={(e) => setVendorAccepted(e.target.value)}
                        >
                          {acceptedOptions.map((opt) => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                              {opt}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  { vendorAccepted && vendorAccepted === 'No' &&
                  <>
                  <Grid item >
                      <div>
                        <TextField
                          key={'vendorNotAcceptedReason'}
                          label={'Reason Not Accepted'}
                          name={'vendorNotAcceptedReason'}
                          required={false}
                          disabled={false}
                          value={vendorNotAcceptedReason}
                          sx={{ maxWidth: '360px' }}
                          margin="dense"
                          variant="standard"
                          type="text"
                          onChange={(e) => setVendorNotAcceptedReason(e.target.value)}
                        />
                      </div>
                    </Grid>
                  </>
                  }
                  <Grid item >
                    <div>
                      <TextField
                        key={'appointmentDate'}
                        label={'Appointment Date'}
                        name={'appointmentDate'}
                        required={false}
                        disabled={false}
                        value={formatDateForInput(appointmentDate)}
                        sx={{ maxWidth: '360px' }}
                        margin="dense"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setAppointmentDate(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item >
                    <div>
                      <TextField
                        key={'receivedDate'}
                        label={'Received Date'}
                        name={'receivedDate'}
                        required={false}
                        disabled={false}
                        value={formatDateForInput(receivedDate)}
                        sx={{ maxWidth: '360px' }}
                        margin="dense"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setReceivedDate(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <TextField
                        key={'inventoryFileName'}
                        name={'inventoryFileName'}
                        label={'Inventory File Name'}
                        margin="dense"
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="file"
                        onChange={e => {
                            const file = e.target.files?.[0];
                            setInventoryFileName({
                            [e.target.name]: {
                                id: null,
                                file: file || null
                            }
                            });
                        }}
                        helperText={inventoryFileName?.file?.name ? `${inventoryFileName?.file?.name} Selected` : ''}
                        InputLabelProps={{ shrink: true }}
                        />
                        <button
                        type="button"
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            color: 'red',
                            fontSize: '1.2rem'
                        }}
                        onClick={() =>
                            setInventoryFileName({
                            [inventoryFileName]: { id: null, file: null }
                            })
                        }
                        >
                        &#x2715;
                        </button>
                    </div>
                    </Grid>
                    <Grid item >
                      <div>
                        <TextField
                          key={'notes'}
                          label={'Comment'}
                          name={'notes'}
                          required={false}
                          disabled={false}
                          value={notes}
                          multiline
                          sx={{ width: '1000px' }}                          
                          margin="dense"
                          variant="standard"
                          type="text"
                          onChange={(e) => setNotes(e.target.value)}
                        />
                      </div>
                    </Grid>
                  <Box sx={{ width: '100%', marginTop: '20px' }}>
                    <hr />
                  </Box>
                  <Grid item >
                      <div>
                        <TextField
                          key={'vendorContactName'}
                          label={'Vendor Contact Name'}
                          name={'vendorContactName'}
                          required={false}
                          disabled={true}
                          value={vendorContactName}
                          sx={{ maxWidth: '360px' }}
                          margin="dense"
                          variant="standard"
                          type="text"
                          onChange={(e) => setVendorContactName(e.target.value)}
                        />
                      </div>
                    </Grid>
                    <Grid item >
                      <div>
                        <TextField
                          key={'vendorContactPhone'}
                          label={'Vendor Contact Phone'}
                          name={'vendorContactPhone'}
                          required={false}
                          disabled={true}
                          value={vendorContactPhone}
                          sx={{ maxWidth: '360px' }}
                          margin="dense"
                          variant="standard"
                          type="text"
                          onChange={(e) => setVendorContactPhone(e.target.value)}
                        />
                      </div>
                    </Grid>
                    <Grid item >
                      <div>
                        <TextField
                          key={'vendorContactEmail'}
                          label={'Vendor Contact Email'}
                          name={'vendorContactEmail'}
                          required={false}
                          disabled={true}
                          value={vendorContactEmail}
                          sx={{ maxWidth: '360px' }}
                          margin="dense"
                          variant="standard"
                          type="text"
                          onChange={(e) => setVendorContactEmail(e.target.value)}
                        />
                      </div>
                    </Grid>

            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained" disabled={!isDirty}>
              Save
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

const RequestDetailsEstimatesCard = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [editingModalOpen, setEditingModalOpen] = useState(false);

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const { estimateInfo, hideEdit } = props;

    const sendUpdate = async newVals => {
      setIsLoading(true);
      if (estimateInfo && estimateInfo.length !== 0) {
        const resp = await props.updateHhgEstimate(newVals);
        if (resp.type === UPDATE_HHG_ESTIMATE_FAILURE) {
          props.showToast('Failed to update the estimate info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else {
          setIsLoading(false);
          return true;
        }
      }
    };

  const updateFields = async values => {
    setIsLoading(true);

    let newVals = { ...estimateInfo };
    newVals.orderedDate = values.orderedDate;
    newVals.vendorNotAcceptedReason = values.vendorNotAcceptedReason;
    newVals.appointmentDate = values.appointmentDate;
    newVals.receivedDate = values.receivedDate;
    newVals.inventoryFileName = values.infentoryFileName;
    newVals.comment = values.notes;
    newVals.vendorContactName = values.vendorContactName;
    newVals.vendorContactPhone = values.vendorContactPhone;
    newVals.vendorContactEmail = values.vendorContactEmail;

    if (values.vendorAccepted === 'No') {
        newVals.vendorAccepted = false;
      }
      if (values.vendorAccepted === 'Yes') {
        newVals.vendorAccepted = true;
      }

    const resp = await props.updateHhgEstimate(newVals);
    if (resp.type === UPDATE_HHG_ESTIMATE_FAILURE) {
      props.showToast('Failed to update the estimate info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else {
      setIsLoading(false);
      return true;
    }
  };

  const handleSaveEdits = async values => {
    // Send update call to parent to update and refresh values.
    const resp = await updateFields(values);
    if (!resp) {
      showToast('Failed to update card data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    }
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };  

  const updateTimeStamp = async fieldName => {
    setIsLoadingButton(true);
    let newVals = { ...estimateInfo };
    newVals[fieldName] = new Date().toISOString();
    let resp = await sendUpdate(newVals);
    setIsLoadingButton(false);
    return resp;
  };

  const reorderEstimates = async () => {
    setIsLoadingButton(true);
    let newVals = { ...estimateInfo };
    newVals.orderedDate = null;
    newVals.acceptedDate = null;
    let resp = await sendUpdate(newVals);
    setIsLoadingButton(false);
    return resp;
  };

  let onClickEvent;
  let buttonText;
  let reorderEstimatesButtonExist = false;
  if (estimateInfo?.orderedDate === null || estimateInfo?.orderedDate === '') {
    //Assumed they havn't sent estimate
    buttonText = 'Send Estimate Request';
    onClickEvent = async () => await updateTimeStamp('orderedDate');
  } else if (estimateInfo?.acceptedDate === null || estimateInfo?.acceptedDate === '') {
    //Assumed they sent estimate but havn't accepted
    buttonText = 'Accept Estimate';
    onClickEvent = async () => await updateTimeStamp('acceptedDate');
    reorderEstimatesButtonExist = true;
  } else {
    //Assumed they sent estimate and accepted offer
    buttonText = 'Send Actual Reminder';
    onClickEvent = () => console.log('Send Actual Reminder was clicked');
    reorderEstimatesButtonExist = true;
  }

  useEffect(() => {}, [estimateInfo]);

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Estimates" key="Estimates" placement="right">
                  <FontAwesomeIcon icon={['fas', 'money-check-dollar']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Request Details
                </Typography>
              </div>
              {!hideEdit && (
                <>
                  <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                    <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                  </Fab>
                </>
              )}
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={2} columns={{ xs: 4, sm: 4, md: 4, xl: 4 }}>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Ordered Date
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { estimateInfo && estimateInfo.orderedDate ? new Date(estimateInfo.orderedDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Vendor Accepted
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { estimateInfo && estimateInfo.vendorAccepted && estimateInfo.vendorAccepted === true ? 'Yes' : 'No'}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  { estimateInfo && estimateInfo.vendorAccepted === false &&
                    <>
                    <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                            Reason Not Accepted
                            </Typography>
                        </div>
                        <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                            { estimateInfo && estimateInfo.reasonNotAccepted ? estimateInfo.reasonNotAccepted : <div>&mdash;</div>}
                            </Typography>
                        </div>
                        </Box>
                    </Grid>
                    </>
                  }
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div sx={{ marginLeft: '-50px', marginTop: '40px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Appointment Date
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { estimateInfo && estimateInfo.appointmentDate ? new Date(estimateInfo.appointmentDate).toLocaleDateString('en-us') : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Received Date
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { estimateInfo && estimateInfo.receivedDate ? new Date(estimateInfo.receivedDate).toLocaleDateString('en-us') : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          HHG Inventory
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { estimateInfo && estimateInfo.inventoryFileName ? estimateInfo.infentoryFileName : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Comment
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { estimateInfo && estimateInfo.comment ? estimateInfo.comment : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ margin: '20px 0' }} />
                  </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Vendor Contact Name
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { estimateInfo && estimateInfo.vendorContactName ? estimateInfo.vendorContactName : <div>&mdash;</div> }
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Vendor Contact Phone
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { estimateInfo && estimateInfo.vendorContactPhone ? estimateInfo.vendorContactPhone : <div>&mdash;</div>}
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Vendor Contact Email
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { estimateInfo && estimateInfo.vendorContactEmail ? estimateInfo.vendorContactEmail : <div>&mdash;</div>}
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      {isLoadingButton && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                            <DialogActions sx={{ p: '1.25rem' }}>
                            <Spinner />
                            </DialogActions>
                        </Box>
                        )}
                        {!isLoadingButton && !props.hideEdit && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                            <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '0 10px'
                            }}
                            >
                            {reorderEstimatesButtonExist && (
                                <Button
                                onClick={async () => await reorderEstimates()}
                                variant="contained"
                                sx={{
                                    maxHeight: '40px',
                                    marginLeft: '12px',
                                    marginTop: '8px',
                                    backgroundColor: '#868686',
                                    '&:hover': { backgroundColor: '#474747' }
                                }}
                                >
                                Reorder Estimates
                                </Button>
                            )}
                            <Button
                                onClick={onClickEvent}
                                color="secondary"
                                variant="contained"
                                sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                            >
                                {buttonText}
                            </Button>
                            </Box>
                        </Box>
                        )}
            </Grid>
          </CardContent>
        </Box>
      </Card>
      {editingModalOpen && (
        <EditCardModal
          classes={classes}
          tab={0}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={() => setEditingModalOpen(false)}
          onSubmit={handleSaveEdits}
          estimateInfo={estimateInfo}
        />
      )}
    </ThemeProvider>
  );
};

export default compose(
  connect(null, {
    updateHhgEstimate,
    showToast
  })
)(RequestDetailsEstimatesCard);
