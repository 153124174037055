/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  Button,
  Dialog,
  DialogActions,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { makeStyles } from '@material-ui/styles';
import { getSurveyQuestions } from 'modules/surveys/surveys.actions';
import { GET_SURVEY_QUESTIONS_FAILURE } from 'modules/surveys/surveys.types';

import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Spinner from 'modules/common/spinner.component';

import { formatDateForInput } from 'utilities/common';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import TagFacesIcon from '@mui/icons-material/TagFaces';


const useStyles = makeStyles(() => ({
  mobileNum: {
    marginRight: 10,
    minWidth: 150,
  },
  email: {
    minWidth: 175,
    marginRight: 10,
  },
  countryCode: {
    maxWidth: 145,
    marginRight: 10,
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  dialogPaper: {
    minHeight: '65vh',
    maxHeight: '65vh'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
}));

function SurveyResponseDialog(props) {
    const classes = useStyles();
    const [questions, setQuestions] = useState([]);
      
    const theme = createTheme({
      palette: {
        primary: COLOR_PRIMARY,
        secondary: COLOR_SECONDARY,
        warning: COLOR_WARNING,
      },
       
    });

  const {
    open,
    onCancel,
    maxLength,
    disabled,
    authorization,
    surveyQuestionResponses,
    isLoading,
    ...rest
  } = props;

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
  }));
  
  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" />,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" />,
      label: 'Dissatisfied',
    },
    3: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="warning" />,
      label: 'Mostly Satisfied',
    },
    5: {
      icon: <TagFacesIcon color="success" />,
      label: 'Satisfied',
    },
    6: {
      icon: <SentimentVerySatisfiedIcon color="success" />,
      label: 'Very Satisfied',
    },
  };
  
  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

    useEffect(() => {
      async function fetchData () {
        if(questions && questions.length === 0)
        {
          let resp = await props.getSurveyQuestions();
          if(resp.type === GET_SURVEY_QUESTIONS_FAILURE)
          {
            props.showToast('Failed to get survey questions.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
            }
          else{
            setQuestions(resp.response);
          }
        }
      }
  
      fetchData();
    }, [questions]);

  const renderQuestion = (question) => {
      let questionName = questions && questions.length > 0 ? questions.find(q => q.surveyQuestionId === question.surveyQuestionId).questionName : '';
      let questionValue = question && question.result ? question.result : 3;
    return (
    <ThemeProvider theme={theme}>
      <Grid item sx={{marginRight: '100px'}}>
          <div className={classes.mb2}>
            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', paddingBottom: '6px' }}>
                {questionName}
              </Typography>
          </div>
          <StyledRating
            name="highlight-selected-only"
            defaultValue={questionValue}
            readOnly={true}
            IconContainerComponent={IconContainer}
            getLabelText={(value) => customIcons[value].label}
            highlightSelectedOnly
            max={6}
          />
          <div className={classes.mb2}>
            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 'bold', fontSize: '1rem' }}>
                Comments
              </Typography>
          </div>
          <div className={classes.mb2}>
            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', paddingBottom: '16px' }}>
                {question.comment}
              </Typography>
          </div>
        </Grid>
        <Grid item>
          <div className={classes.mb2}>
            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 'bold', fontSize: '1rem' }}>
                Response Completion Date
              </Typography>
          </div>
          <div className={classes.mb2}>
            <Typography variant="body2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', paddingBottom: '6px' }}>
                {question ? formatDateForInput(question.completionDate) : <div>&mdash;</div> }
              </Typography>
          </div>
          <div className={classes.mb2}>
            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 'bold', fontSize: '1rem' }}>
                Variation
              </Typography>
          </div>
          <div className={classes.mb2}>
            <Typography variant="body2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', paddingBottom: '6px' }}>
                {question ? question.surveyQuestionVariationName : <div>&mdash;</div> }
              </Typography>
          </div>
          <div className={classes.mb2}>
            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 'bold', fontSize: '1rem' }}>
                Vendor
              </Typography>
          </div>
          <div className={classes.mb2}>
            <Typography variant="body2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', paddingBottom: '6px' }}>
                {question.linkedVendorId}
              </Typography>
          </div>
          <div className={classes.mb2}>
            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 'bold', fontSize: '1rem' }}>
                Counselor
              </Typography>
          </div>
          <div className={classes.mb2}>
            <Typography variant="body2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', paddingBottom: '6px' }}>
                {question.linkedCounselorId}
              </Typography>
          </div>
        </Grid>
        </ThemeProvider>
    );
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
          <form fullScreen>
            <div className="row justify-space-between align-center">
              <div className={classes.dialogIconRow}>
                <Typography variant="h5" >
                  Survey Results
                </Typography>
              </div>
            </div>
            <div className={classes.dialogContentContainer}>
              <Stack
                sx={{
                  width: '100%',
                  minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
                  gap: '1.5rem',
                }}
              >
              <Grid container spacing={{ xs: 2, md: 3 }} sx={{ padding: '30px', paddingTop: '0px', alignContent: 'center'}}>
                  <Grid item >
                  <div className={classes.mb2}>
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Sent Date
                        </Typography>
                    </div>
                    <div className={classes.mb2}>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        { <div>&mdash;</div> }
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item >
                  <div className={classes.mb2}>
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Survey Status
                        </Typography>
                    </div>
                    <div className={classes.mb2}>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        { authorization && authorization.surveyStatus ? authorization.surveyStatus : <div>&mdash;</div> }
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item >
                  <div className={classes.mb2}>
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Client
                        </Typography>
                    </div>
                    <div className={classes.mb2}>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        { authorization && authorization.clientName ? authorization.clientName : <div>&mdash;</div> }
                      </Typography>
                    </div>
                  </Grid>
                  </Grid>
                  <Grid container spacing={{ xs: 2, md: 2 }} sx={{ padding: '30px', paddingTop: '0px' }}>
                     {surveyQuestionResponses && surveyQuestionResponses.map(question => renderQuestion(question))}    
                </Grid>
              </Stack>
            </div>
            {isLoading && 
              <DialogActions sx={{ p: '1.25rem' }}>
                <Spinner />
              </DialogActions>
            }
            {!isLoading && 
              <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onCancel}>Close</Button>
              </DialogActions>
            }
          </form>
        </Dialog>
  );
}

SurveyResponseDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  authorization: PropTypes.object,
};

export default compose(
  connect(null, {
    getSurveyQuestions,
    showToast,
  }),
)(SurveyResponseDialog);