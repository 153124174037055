/* eslint-disable react-hooks/exhaustive-deps */

import { Box, } from '@mui/material';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React from 'react';
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS } from 'styles/theme';
import { showToast } from 'modules/layout/layout.actions';

const styles = theme => ({
  container: {
    height: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`
    }
  },
  headerFixed: {
    zIndex: 4
  },
  estimateHeaderFixed: {
    paddingTop: '115px',
    zIndex: 2
  }
});

const RecurringPayments = props => {

  return (
    <Box>
      
   </Box>
  );
};

const mapStateToProps = state => ({});

export default compose(withStyles(styles), connect(mapStateToProps, { showToast }))(RecurringPayments);
