/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, Grid, Paper, InputLabel, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import React, {useState} from 'react';
import classNames from 'classnames';
import { showToast } from 'modules/layout/layout.actions';
import SurveyQuestionTriggerCard from './surveyQuestionTriggerCard.component';
import SurveyQuestionAnswerCard from './surveyQuestionAnswerCard.component';
import SurveyQuestionSetupCard from './surveyQuestionSetupCard.component';
import { updateSurveyQuestion, getSurveyQuestionById } from '../surveys.actions';
import { UPDATE_SURVEY_QUESTION_FAILURE, GET_SURVEY_QUESTION_BY_ID_FAILURE } from '../surveys.types';

const styles = theme => ({
  container: {
    height: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`
    }
  },
  headerFixed: {
    zIndex: 4
  },
  estimateHeaderFixed: {
    paddingTop: '115px',
    zIndex: 2
  }
});

const SurveyQuestionDetails = props => {
  const { surveyQuestionDetails, surveyQuestionTriggers, surveyQuestionVariations } = props;
  
  const [isLoading, setIsLoading] = useState(false);
  const [newQuestionName, setNewQuestionName] = useState(surveyQuestionDetails ? surveyQuestionDetails?.questionName : '');
  const [newQuestionCategory, setNewQuestionCategory] = useState(surveyQuestionDetails ? surveyQuestionDetails?.category : '');
  const [newQuestionStatus, setNewQuestionStatus] = useState(surveyQuestionDetails ? surveyQuestionDetails?.status : '');

  let categories = [{label: 'Category 1', value: 1}, {label: 'Category 2', value: 2}];
  let statuses = [{label: 'Active', value: 1}, {label: 'InActive', value: 2}];

  const handleUpdate = async () => {
        setIsLoading(true);
        
        let newVals = { ...surveyQuestionDetails };
        newVals.questionName = newQuestionName;
        newVals.category = newQuestionCategory;
        newVals.status = newQuestionStatus;
  
        let resp = await props.updateSurveyQuestion(newVals);
        if (resp.type === UPDATE_SURVEY_QUESTION_FAILURE) {
          props.showToast('Failed to update survey question, please try again later', TOAST_MESSAGE_SEVERITY_ERROR);
          setIsLoading(false);
        }
        else {
          let getResp = await props.getSurveyQuestionById(newVals.surveyQuestionId);
          if(getResp.type === GET_SURVEY_QUESTION_BY_ID_FAILURE) {
              props.showToast('Failed to get survey question, please try again later', TOAST_MESSAGE_SEVERITY_ERROR);
              setIsLoading(false);
          }
          else {
              props.showToast(`Successfully updated survey Question ${newVals.surveyQuestionId}.`, {severity: TOAST_MESSAGE_SEVERITY_SUCCESS});
              setIsLoading(false);
          }
        }
      };

  return (
    <>
        <Box>
          <Paper square className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', width: '100%' }}>
              <Box sx={{ paddingLeft: '100px', paddingRight: '100px' }}>&nbsp;</Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '10px' }}>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                            Question Id
                        </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                            {surveyQuestionDetails?.surveyQuestionId}
                        </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                            <TextField
                                label={"Question Name"}
                                value={newQuestionName}
                                onChange={e => setNewQuestionName(e.target.value)}
                                fullWidth
                                margin="dense"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                          <FormControl fullWidth margin="dense">
                            <InputLabel>Question Category</InputLabel>
                            <Select
                                label={"Question Category"}
                                value={newQuestionCategory}
                                onChange={e => setNewQuestionCategory(e.target.value)}
                                sx={{minWidth: '150px'}}
                            >
                                {categories.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Box>
                    </Box>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Status</InputLabel>
                            <Select
                                label={"Status"}
                                value={newQuestionStatus}
                                onChange={e => setNewQuestionStatus(e.target.value)}
                                sx={{minWidth: '150px'}}
                            >
                                {statuses.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ padding: '10px'}}>
                    <Button
                        color="secondary"
                        variant="contained"
                        sx={{ maxHeight: '40px', marginRight: '20px', marginTop: '8px' }}
                        onClick={() => handleUpdate()}
                        disabled={isLoading}
                        >
                        Save
                    </Button>
                </Box>
            </Box>
          </Paper>
            <Box sx={{ padding: '16px', paddingTop: '120px' }}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={12} xl={8} rowSpacing={2}>
                    <SurveyQuestionTriggerCard surveyQuestionTriggers={surveyQuestionTriggers} surveyQuestionId={surveyQuestionDetails?.surveyQuestionId} isReadOnly={surveyQuestionDetails?.status === 'InActive'}  />
                    <Box sx={{ paddingTop: '16px' }}>
                        <SurveyQuestionAnswerCard surveyQuestionDetails={surveyQuestionDetails} isReadOnly={surveyQuestionDetails?.status === 'InActive'} />
                    </Box>
                    <Box sx={{ paddingTop: '16px' }}>
                    <SurveyQuestionSetupCard surveyQuestionVariations={surveyQuestionVariations} surveyQuestionId={surveyQuestionDetails?.surveyQuestionId} isReadOnly={surveyQuestionDetails?.status === 'InActive'} />
                    </Box>
                </Grid>
                <Grid item xs={12} xl={4} rowSpacing={2}>
                    <Box>

                    </Box>
                </Grid>
                </Grid>
            </Box>
      </Box>
    </>
  );
};

const mapStateToProps = state => ({});

export default compose(withStyles(styles), connect(mapStateToProps, { updateSurveyQuestion, getSurveyQuestionById, showToast}))(SurveyQuestionDetails);