import {
  Link,
  Switch,
  Typography,
  withStyles,
  withTheme,
} from '@material-ui/core';
    
import { FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { Grid, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import {
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import ReactRouterPropTypes from 'react-router-prop-types';
    
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import classNames from 'classnames';
    
import { formatDateGeneric } from 'utilities/common';
import Log from 'utilities/log';
import Spinner from 'modules/common/spinner.component';
    
const styles = (theme) => {
  const intakeRecordBorder = `1px solid ${theme.palette.grey[500]}`;
  return {
    table: {
      borderSpacing: theme.spacing(0, 2),
    },
    tableHead: {
      height: 32,
    },
    cell: {
      borderTop: intakeRecordBorder,
      borderBottom: intakeRecordBorder,
      borderLeft: 0,
      borderRight: 0,
      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderLeft: intakeRecordBorder,
      },
      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        borderRight: intakeRecordBorder,
      },
    },
    urgencyMenuItem: {
      justifyContent: 'center',
    },
    selectInput: {
      lineHeight: 1.75,
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(0.5),
    },
    row: {
      backgroundColor: theme.palette.common.white,
    },
    noDataCell: {
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(12),
      textAlign: 'center',
    },
  };
};
    
const FILTER_DISABLED_COLUMNS = ['organization'];
    
class AuthorizationsGrid extends PureComponent {
      static defaultProps = {
        isLoading: true,
      };
    
      constructor(props) {
        super(props);
    
        this.state = {
          columns: [
            { name: 'authorizationId', title: 'Auth-ID' },
            { name: 'details', title: 'Details', getCellValue: this.getDetailsCellValue },
            { name: 'status', title: 'Status' },
            { name: 'departure', title: 'Departure' },
            { name: 'destination', title: 'Destination' },
            { name: 'lastModified', title: 'Last Modified', getCellValue: this.formatDate('lastModified') },
            { name: 'surveySent', title: '', getCellValue: this.getSurveySentCellValue },
          ],
          filters: [],
          sorting: [{ columnName: 'details', direction: 'asc' }],
          integratedFilteringColumnExtensions: [
            { columnName: 'details', predicate: this.keyPredicate },
          ],
        };
  
        this.changeSorting = (sorting) => this.setState({ sorting });
  
      }

      keyPredicate = (value, filter) => {
        const valueLower = (value.key || '').toLowerCase();
        const filterLower = (filter.value || '').toLowerCase();
        return valueLower.indexOf(filterLower) > -1;
      };

      formatDate = (key) => (record) => {
        return formatDateGeneric(record[key]);
      };
  
      cellClicked = (id) => {
        this.props.history.push(`/authorizations/${id}`);
      }

      getDetailsCellValue = (record) => {
        const { transfereeName, clientName, moveType } = record;
        return (
          <Fragment key={transfereeName}>
            <Typography>{transfereeName}</Typography>
            <Typography>{clientName}</Typography>
            <Typography>{moveType}</Typography>
          </Fragment>
        );
      };
    
      changeFilters = (filters) => this.setState({ filters });
    
      filterCell = (props) => {
        return props.column.title && !FILTER_DISABLED_COLUMNS.includes(props.column.name) ?
          <TableFilterRow.Cell {...props} /> : null;
      };
    
      headerCell = (props) => {
        return props.column.title ? <TableHeaderRow.Cell {...props} /> : null;
      };
    
      headerRowComponent = (props) => {
        return <TableHeaderRow.Row className={this.props.classes.tableHead} {...props} />;
      };
    
      rowComponent = (props) => {
        return <Table.Row className={this.props.classes.row} {...props} />;
      };
    
      cellComponent = (props) => {
        let clickHandler = null;
        let pointerClass = null;
        if (!['surveySent'].includes(props.column.name)) {
          clickHandler = () => this.props.onSelect(props.row.authorizationId); // Add handler for when a client is clicked
          pointerClass = 'handles-click';
        }
        const className = classNames(this.props.classes.cell, pointerClass);
        return <Table.Cell onClick={clickHandler} className={className} {...props} />;
      };
    
      tableComponent = (props) => {
        return <Table.Table className={this.props.classes.table} {...props} />;
      };
    
      noDataCellComponent = (props) => {
        const content = this.props.isLoading ? <Spinner logo /> : 'No Records were returned.  Check the filters above.';
        // need to remove the getMessage function to avoid exception
        // eslint-disable-next-line no-unused-vars
        const { getMessage, ...rest } = props;
        return (
          <Table.Cell {...rest} className={this.props.classes.noDataCell}>
            <Typography>{content}</Typography>
          </Table.Cell>
        );
      };

      getSurveySentCellValue = (record) => {
        return (
          <div className={this.props.classes.centerContent}>
            {record.surveySent && (
              <Link href="#" onClick={() => this.props.onSendSurvey(record)} color="secondary">
                Survey Sent
              </Link>
            )}
            {!record.surveySent && (
              <>
                <Typography>Send Survey</Typography>
                <Switch
                  checked={record.surveySent}
                  disabled={record.surveySent}
                  onChange={() => this.props.onSendSurvey(record)}
                  value={record.surveySent}
                />
              </>
            )}
          </div>
        );
      }
    
      render() {
        Log.trace('RENDER', 'authorizationsGrid');
        const { authorizations } = this.props;
        const {
          columns,
          filters,
          tableColumnExtensions,
          integratedFilteringColumnExtensions,
          sorting,
        } = this.state;
    
        return (
          <Grid
            rows={authorizations}
            columns={columns}
          >
            <SortingState
              sorting={sorting}
              onSortingChange={this.changeSorting}
            />
              
            <FilteringState
              filters={filters}
              onFiltersChange={this.changeFilters}
            />
            <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
            <IntegratedSorting />
            <Table
              columnExtensions={tableColumnExtensions}
              rowComponent={this.rowComponent}
              tableComponent={this.tableComponent}
              cellComponent={this.cellComponent}
              noDataCellComponent={this.noDataCellComponent}
            />
            <TableHeaderRow rowComponent={this.headerRowComponent} cellComponent={this.headerCell} showSortingControls />
            <TableFilterRow cellComponent={this.filterCell} />
          </Grid>
        );
      }
}
    
AuthorizationsGrid.propTypes = {
  authorizations: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  history: ReactRouterPropTypes.history.isRequired,
  onSendSurvey: PropTypes.func.isRequired,
};
    
export default compose(
  withStyles(styles),
  withTheme,
)(AuthorizationsGrid);
    