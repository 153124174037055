export const RecurringPayableStatus = {
  Active: 1,
  Pending: 2,
  Expired: 3,
  Cancelled: 4
};

export const PayToChoiceOptions = {
  Vendor: 1,
  EE: 2
};

export const FrequencyOptions = [
  { title: 'Weekly', id: 1, periodsPerYear: 52 },
  { title: 'Bi-Weekly', id: 2, periodsPerYear: 26 },
  { title: 'Semi-Monthly', id: 3, periodsPerYear: 24 },
  { title: 'Monthly', id: 4, periodsPerYear: 12 },
  { title: 'Quarterly', id: 5, periodsPerYear: 4 },
  { title: 'Semi-Annually', id: 7, periodsPerYear: 2 },
  { title: 'Annually', id: 8, periodsPerYear: 1 }
];

export const IsLeapYear = year => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const VoucherPaymentStatus = {
  Failed: 0,
  Pending: 1,
  Paid: 2
};

export const VoucherStatusOptions = {
    Draft: 1,
    Pending: 2,
    Approved: 4,
    Denied: 5
}