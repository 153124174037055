/* eslint-disable react-hooks/exhaustive-deps */

import {
  Grid, Checkbox
} from '@mui/material';
import { POST_CANDIDATE_TRIP_FAILURE, UPDATE_CANDIDATE_TRIP_FAILURE } from 'modules/authorizations/store/types/canidateTripTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { calculateTripDuration } from 'modules/authorizations/utils';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getCandidateTripById, postCandidateTrip, updateCandidateTrip, postTravelBookingRequest, getTravelBookingRequestsByTrip } from 'modules/authorizations/store/actions/canidateTrip.actions';
import { showToast } from 'modules/layout/layout.actions';
import TripSummaryCard from 'modules/common/tripSummaryCard.component';
import React, { useState } from 'react';

const CandidateTripSummaryCard = (props) => {
  
  const [isLoading, setIsLoading] = useState(false);

  const { trip, familyMembers, id, hideEdit, addresses } = props;
  
  const tripTabs = [
    { header: 'Trip Summary', order: 1, iconPath: ['fas', 'fa-plane-departure'] },
  ];

  let family = familyMembers.map(f => {
    return f.firstName + " " + f.lastName;
  });

  const tripTypes = ['One Way', 'Round-Trip'];
  const tripCategories = ['Homefinding', 'Global', 'TL Home Visit', 'Candidate', 'Intern One Way', 'Intern Home Visit'];
  const tripBooking = ['Self Booked', 'TRC Booking'];
  const rentalCarClassOptions = ['Economy', 'Compact Sedan', 'Midsize Sedan', 'Full Size Sedan', 'Compact SUV', 'Full Size SUV', 'Other'];

  const parseValueToArray = (input) => {
    if (!input) return [];
    return Array.isArray(input) ? input : input.split(',');
  };
  
  const tripSummaries = [
    { header: 'Trip Title', accessorKey: 'tripName', type: 'text', required: false,  value: trip ? trip.tripName : '', categoryType: 'tripSummary', tab: 1 },
    { header: 'Trip Category', accessorKey: 'tripCategory', type: 'dropdown', options: tripCategories, value: trip?.tripCategory ? trip.tripCategory : tripCategories[3], required: true, categoryType: 'tripSummary', tab: 1 },
    { header: 'Family Attending', accessorKey: 'familyAttending', type: 'multi-select', options: family,  required: false, value: trip && trip.familyAttending && trip.familyAttending.length > 0 ? parseValueToArray(trip.familyAttending) : parseValueToArray(family[0]), categoryType: 'tripSummary', tab: 1 },
    { header: 'Journey', accessorKey: 'typeOfTrip', type: 'dropdown', options: tripTypes, value: trip?.typeOfTrip ? trip.typeOfTrip : tripTypes[1], required: true, categoryType: 'tripSummary', tab: 1 },
    {
        header: 'Origin',
        accessorKey: 'departureAddressId',
        type: 'dropdown-v2',
        options: addresses,
        required: false,
        categoryType: 'tripSummary',
        value: trip ? trip.departureAddressId : '',
        tab: 1,
      },
      {
        header: 'Destination',
        accessorKey: 'destinationAddressId',
        type: 'dropdown-v2',
        options: addresses,
        required: false,
        categoryType: 'tripSummary',
        value: trip ? trip.destinationAddressId : '',
        tab: 1,
      },
    { header: 'Trip Start Date', accessorKey: 'tripStartDate', type: 'date', required: false, value: trip ? trip.tripStartDate : null, categoryType: 'tripSummary', tab: 1 },
    { header: 'Trip End Date', accessorKey: 'tripEndDate', type: 'date', required: false, value: trip ? trip.tripEndDate : null, categoryType: 'tripSummary', tab: 1 },
    { header: 'Trip Duration', accessorKey: 'tripDuration', type: 'number', required: false, value: trip && (trip.tripStartDate && trip.tripEndDate) ? calculateTripDuration(trip.tripStartDate, trip.tripEndDate, trip.tripDuration) : 0, categoryType: 'tripSummary', tab: 1 },
    { header: 'Special Needs', accessorKey: 'specialNeeds', type: 'notes', required: false, value: trip ? trip.specialNeeds : '', categoryType: 'tripSummary', tab: 1 },
    { header: 'Notes', accessorKey: 'notes', type: 'notes', required: false, value: trip ? trip.notes : '', categoryType: 'tripSummary', tab: 1 },
    { header: 'Booking Type', accessorKey: 'hasBookingDetails', type: 'dropdown', options: tripBooking, value: trip?.hasBookingDetails ? tripBooking[1] : tripTypes[0], required: true, categoryType: 'tripSummary', tab: 1 },
    // Air Transport
    { header: 'Air Transport', accessorKey: 'airTransport', type: 'checkbox', required: false, value: trip ? trip.airTransport : false, tab: 1, Cell: ({ cell }) => <Checkbox name="airTransport" label="Active" checked={cell.row.original.airTransport} disabled={true} /> },
    { header: 'Departure Date', accessorKey: 'airTransportDepartureDate', type: 'date', required: false, value: trip ? trip.airTransportDepartureDate : null, categoryType: 'airTransport', tab: 1 },
    { header: 'Arrival Date', accessorKey: 'airTransportArrivalDate', type: 'date', required: false, value: trip ? trip.airTransportArrivalDate : null, categoryType: 'airTransport', tab: 1 },
    { header: 'Return Departure Date', accessorKey: 'airTransportReturnDepartureDate', type: 'date', required: false, value: trip ? trip.airTransportReturnDepartureDate : null, categoryType: 'airTransport', dependentOn: 'typeOfTrip', dependentValue: 'Round-Trip', tab: 1 },
    { header: 'Return Arrival Date', accessorKey: 'airTransportReturnArrivalDate', type: 'date', required: false, value: trip ? trip.airTransportReturnArrivalDate : null, dependentOn: 'typeOfTrip', dependentValue: 'Round-Trip', categoryType: 'airTransport', tab: 1 },
    // Hotel Booking
    { header: 'Hotel Booking', accessorKey: 'hotelBooking', type: 'checkbox', required: false, value: trip ? trip.hotelBooking : false, tab: 1, Cell: ({ cell }) => <Checkbox name="hotelBooking" label="Active" checked={cell.row.original.hotelBooking} disabled={true} /> },
    { header: 'Hotel Name', accessorKey: 'hotelName', type: 'text', required: false, value: trip ? trip.hotelName : '', categoryType: 'hotelBooking', tab: 1 },
    { header: 'Hotel Address', accessorKey: 'hotelAddress', type: 'text', required: false, value: trip ? trip.hotelAddress : '', categoryType: 'hotelBooking', tab: 1 },
    { header: 'Check In', accessorKey: 'hotelCheckIn', type: 'date', required: false, value: trip ? trip.hotelCheckIn : null, categoryType: 'hotelBooking', tab: 1 },
    { header: 'Check Out', accessorKey: 'hotelCheckOut', type: 'date', required: false, value: trip ? trip.hotelCheckOut : null, categoryType: 'hotelBooking', tab: 1 },
    { header: 'Duration of Stay', accessorKey: 'hotelDurationOfStay', type: 'number', required: false, value: trip && (trip.hotelCheckIn && trip.hotelCheckOut) ? calculateTripDuration(trip.hotelCheckIn, trip.hotelCheckOut, trip.hotelDurationOfStay) : 0, categoryType: 'hotelBooking', tab: 1 },
    // Rental Car
    { header: 'Rental Car', accessorKey: 'rentalCarBooking', type: 'checkbox', required: false, value: trip ? trip.rentalCarBooking : false, tab: 1, Cell: ({ cell }) => <Checkbox name="rentalCarBooking" label="Active" checked={cell.row.original.rentalCarBooking} disabled={true} />, },
    { header: 'Rental Car Class', accessorKey: 'rentalCarClass', type: 'dropdown', options: rentalCarClassOptions, value: trip?.rentalCarClass ? trip.rentalCarClass : rentalCarClassOptions[0], required: true, categoryType: 'rentalCar', tab: 1 },
    { header: 'Please Specify Rental Car Class', accessorKey: 'otherRentalCar', type: 'text', required: false,  value: trip ? trip.otherRentalCar : '', categoryType: 'rentalCar', dependentOn: 'rentalCarClass', dependentValue: 'Other', tab: 1 },
    { header: 'Rental Car Start Date', accessorKey: 'rentalCarStartDate', type: 'date', required: false, value: trip ? trip.rentalCarStartDate : null, categoryType: 'rentalCar', tab: 1 },
    { header: 'Rental Car End Date', accessorKey: 'rentalCarEndDate', type: 'date', required: false, value: trip ? trip.rentalCarEndDate : null, categoryType: 'rentalCar', tab: 1 },
    { header: 'Rental Duration', accessorKey: 'rentalCarDuration', type: 'number', required: false, value: trip && (trip.rentalCarStartDate && trip.rentalCarEndDate) ? calculateTripDuration(trip.rentalCarStartDate, trip.rentalCarEndDate, trip.rentalCarDuration) : 0, categoryType: 'rentalCar', tab: 1 },
  ];
  
  const updateTripFields = async (tab, values) => {
    setIsLoading(true);
    if (trip && trip.length !== 0) {
      let newValues = { ...trip };

        newValues.tripName = values.tripName;
        newValues.familyAttending = values.familyAttending ? values.familyAttending.join(',') : values.familyAttending;
        newValues.typeOfTrip = values.typeOfTrip;
        newValues.tripStartDate = values.tripStartDate;
        newValues.tripEndDate = values.tripEndDate;
        newValues.tripDuration = values.tripDuration;
        newValues.specialNeeds = values.specialNeeds;
        newValues.notes = values.notes;
        
        if(values.hasBookingDetails && values.hasBookingDetails === tripBooking[0])
        {
            newValues.hasBookingDetails = false;
        }
        else {
            newValues.hasBookingDetails = true;
        }

        newValues.airTransport = values.airTransport;
        newValues.airTransportDepartureDate = values.airTransportDepartureDate
        newValues.airTransportArrivalDate = values.airTransportArrivalDate;
        newValues.airTransportReturnDepartureDate = values.airTransportReturnDepartureDate;
        newValues.airTransportReturnArrivalDate = values.airTransportReturnArrivalDate;

        newValues.hotelBooking = values.hotelBooking;
        newValues.hotelName = values.hotelName;
        newValues.hotelAddress = values.hotelAddress;
        newValues.hotelCheckIn = values.hotelCheckIn;
        newValues.hotelCheckOut = values.hotelCheckOut;
        newValues.hotelDurationOfStay = values.hotelDurationOfStay;

        newValues.rentalCarBooking = values.rentalCarBooking;
        newValues.rentalCarClass = values.rentalCarClass;
        newValues.otherRentalCar = values.otherRentalCar;
        newValues.rentalCarStartDate = values.rentalCarStartDate;
        newValues.rentalCarEndDate = values.rentalCarEndDate;
        newValues.rentalCarDuration = values.rentalCarDuration;
  
      const resp = await props.updateCandidateTrip(newValues);
      if (resp.type === UPDATE_CANDIDATE_TRIP_FAILURE) {
        props.showToast('Failed to update homesale resale info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      values.authorizationId = id;
  
      const resp = await props.postCandidateTrip(values);
      if (resp.type === POST_CANDIDATE_TRIP_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  return (
    <div>
    <Grid item sx={{ marginBottom: '10px' }}>
      <TripSummaryCard
        tabs={tripTabs}
        summaries={tripSummaries}
        numColumns={5} 
        updateFields={updateTripFields}
        isLoading={isLoading}
        hideEdit={hideEdit}
        hasAttachments={true}
      />
    </Grid>
    </div>
  );
  
};
  
const mapStateToProps = (state) => {
  return {
  };
};
  
export default compose(
  connect(mapStateToProps, {
    postCandidateTrip,
    updateCandidateTrip,
    getCandidateTripById,
    postTravelBookingRequest,
    getTravelBookingRequestsByTrip,
    showToast,
  }),
)(CandidateTripSummaryCard);