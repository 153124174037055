import { Box, Button, FormControlLabel, Modal, Switch, TextField, Typography } from '@mui/material';
import { POST_COMMUNICATION_INFO_FAILURE, UPDATE_COMMUNICATION_INFO_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postCommunicationInfo, updateCommunicationInfo } from '../store/actions/authorizations.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const CommunicationCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { clientInfo, communication } = props;

  const communicationTabs = [{ header: 'Communication', order: 1, iconPath: ['fas', 'phone'] }];

  const surveyStatusOptions = ['Active', 'On Hold'];
  const surveyTypeOptions = [
    'BVO',
    'GBO',
    'MLSUM',
    'Renter',
    'Candidate',
    'Lump Sum',
    'Global',
    'Property Management',
    'Southwire Intern',
    'Sandia Intern'
  ];

  const communicationSummaries = [
    {
      header: 'Preferred Contact',
      accessorKey: 'preferredContact',
      type: 'dropdown',
      required: true,
      options: ['Mobile', 'Email'],
      value: communication ? communication.preferredContact : '',
      tab: 1
    },
    {
      header: 'Primary Number',
      accessorKey: 'primaryNumber',
      type: 'text',
      required: false,
      value: communication ? communication.primaryNumber : '',
      tab: 1
    },
    {
      header: 'Primary Email',
      accessorKey: 'primaryEmail',
      type: 'text',
      required: false,
      value: communication ? communication.primaryEmail : '',
      tab: 1
    },
    {
      header: 'Secondary Number',
      accessorKey: 'secondaryPhoneNumnber',
      type: 'text',
      required: false,
      value: communication ? communication.secondaryPhoneNumnber : '',
      tab: 1,
      hideInTable: true
    },
    {
      header: 'Secondary Email',
      accessorKey: 'secondaryEmailAddress',
      type: 'text',
      required: false,
      value: communication ? communication.secondaryEmailAddress : '',
      tab: 1,
      hideInTable: true
    },
    {
      header: 'Work Email',
      accessorKey: 'workEmail',
      type: 'text',
      required: false,
      value: communication ? communication.workEmail : '',
      tab: 1
    },
    {
      header: 'SO Primary Email',
      accessorKey: 'soPersonalEmail',
      type: 'text',
      required: false,
      value: communication ? communication.soPersonalEmail : '',
      tab: 1
    },
    {
      header: 'SO Primary Mobile',
      accessorKey: 'soMobile',
      type: 'text',
      required: false,
      value: communication ? communication.soMobile : '',
      tab: 1
    },
    {
      header: 'SO Secondary Email',
      accessorKey: 'secondarySOPhoneNumnber',
      type: 'text',
      required: false,
      value: communication ? communication.secondarySOPhoneNumnber : '',
      tab: 1,
      hideInTable: true
    },
    {
      header: 'SO Secondary Mobile',
      accessorKey: 'secondarySOEmailAddress',
      type: 'text',
      required: false,
      value: communication ? communication.secondarySOEmailAddress : '',
      tab: 1,
      hideInTable: true
    },
    {
      header: 'Survey Type',
      accessorKey: 'surveyType',
      type: 'dropdown',
      options: surveyTypeOptions,
      required: false,
      value: communication ? communication.surveyType ?? surveyTypeOptions[0] : '',
      tab: 1
    },
    {
      header: 'Survey Status',
      accessorKey: 'surveyStatus',
      type: 'dropdown',
      options: surveyStatusOptions,
      required: false,
      value: communication ? communication.surveyStatus : '',
      tab: 1
    }
  ];

  const topLink = clientInfo ? { title: clientInfo.description, link: `/clients/${clientInfo.moveTrackClientId}` } : null;

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    values.authorizationId = props.id;

    if (communication && communication.length !== 0) {
      const resp = await props.updateCommunicationInfo(values);
      if (resp.type === UPDATE_COMMUNICATION_INFO_FAILURE) {
        props.showToast('Failed to update the employee communication info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    } else {
      const resp = await props.postCommunicationInfo(values);
      if (resp.type === POST_COMMUNICATION_INFO_FAILURE) {
        props.showToast('Failed to update employee communication info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  const SendEeEmailButton = ({ ...rest }) => {
    const [open, setOpen] = useState(false);
    const [isPrimaryOnly, setIsPrimaryOnly] = useState(true);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleToggle = () => setIsPrimaryOnly(prev => !prev);

    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{
                maxHeight: '40px',
                marginLeft: '12px',
                marginTop: '8px',
                backgroundColor: '#868686',
                '&:hover': { backgroundColor: '#474747' }
              }}
            >
              Send EE Email
            </Button>
          </Box>
        </Box>
        <Modal open={open} onClose={handleClose} aria-labelledby="email-modal">
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: '8px'
            }}
          >
            <Typography variant="h6" id="email-modal" gutterBottom>
              Select Email Recipients
            </Typography>
            <FormControlLabel
              control={<Switch checked={!isPrimaryOnly} onChange={handleToggle} color="primary" />}
              label={isPrimaryOnly ? 'Primary Contact Only' : 'Primary and Secondary Contact'}
              sx={{ marginBottom: '16px' }}
            />
            <TextField fullWidth label="Primary Contact Email" value={communication?.primaryEmail ?? ''} margin="dense" />
            {!isPrimaryOnly && (
              <TextField
                fullWidth
                label="Secondary Contact Email"
                value={communication?.soPersonalEmail ?? ''}
                margin="dense"
                sx={{ marginTop: '8px' }}
              />
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
              <Button onClick={handleClose} sx={{ marginRight: '8px' }}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Send Email
              </Button>
            </Box>
          </Box>
        </Modal>
      </>
    );
  };

  return (
    <EditableCard
      tabs={communicationTabs}
      summaries={communicationSummaries}
      numColumns={2}
      updateFields={updateFields}
      isLoading={isLoading}
      ButtonComponent={SendEeEmailButton}
      topLink={topLink}
    />
  );
};

export default compose(
  connect(null, {
    postCommunicationInfo,
    updateCommunicationInfo,
    showToast
  })
)(CommunicationCard);
