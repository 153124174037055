export const GET_RECURRING_PAYABLES_REQUEST = 'GET_RECURRING_PAYABLES_REQUEST';
export const GET_RECURRING_PAYABLES_SUCCESS = 'GET_RECURRING_PAYABLES_SUCCESS';
export const GET_RECURRING_PAYABLES_FAILURE = 'GET_RECURRING_PAYABLES_FAILURE';

export const POST_RECURRING_PAYABLES_REQUEST = 'POST_RECURRING_PAYABLES_REQUEST';
export const POST_RECURRING_PAYABLES_SUCCESS = 'POST_RECURRING_PAYABLES_SUCCESS';
export const POST_RECURRING_PAYABLES_FAILURE = 'POST_RECURRING_PAYABLES_FAILURE';

export const UPDATE_RECURRING_PAYABLES_REQUEST = 'UPDATE_RECURRING_PAYABLES_REQUEST';
export const UPDATE_RECURRING_PAYABLES_SUCCESS = 'UPDATE_RECURRING_PAYABLES_SUCCESS';
export const UPDATE_RECURRING_PAYABLES_FAILURE = 'UPDATE_RECURRING_PAYABLES_FAILURE';

export const DELETE_RECURRING_PAYABLES_REQUEST = 'DELETE_RECURRING_PAYABLES_REQUEST';
export const DELETE_RECURRING_PAYABLES_SUCCESS = 'DELETE_RECURRING_PAYABLES_SUCCESS';
export const DELETE_RECURRING_PAYABLES_FAILURE = 'DELETE_RECURRING_PAYABLES_FAILURE';