/* eslint-disable no-case-declarations */
import {
  DELETE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS,
  GET_EXPENSE_LINE_BY_SUMMARY_ID_SUCCESS,
  GET_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS,
  GET_EXPENSE_LINES_BY_AUTH_SUCCESS,
  GET_EXPENSE_SUMMARIES_BY_AUTH_SUCCESS,
  POST_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS,
  POST_EXPENSE_LINE_SUCCESS,
  UPDATE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS,
  UPDATE_EXPENSE_LINE_SUCCESS,
  UPDATE_EXPENSE_SUMMARY_SUCCESS
} from 'modules/expenses/types';

const handleAuthExpensesReducer = (draft, action) => {
  if (draft.currentAuthId && !draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId] = {};
  if (draft.currentAuthId && !draft.authorizationDetails[draft.currentAuthId].authExpenses)
    draft.authorizationDetails[draft.currentAuthId].authExpenses = {};

  switch (action.type) {
    case GET_EXPENSE_SUMMARIES_BY_AUTH_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authExpenses.submittedExpenses = action.response;
      break;

    case GET_EXPENSE_LINES_BY_AUTH_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authExpenses.expenseLines = action.response;
      break;

    case GET_EXPENSE_LINE_BY_SUMMARY_ID_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authExpenses.summaryExpenseLines = action.response;
      break;

    case UPDATE_EXPENSE_LINE_SUCCESS: {
      const expenseLineItems = draft.authorizationDetails[draft.currentAuthId]?.authExpenses.expenseLines;
      if (expenseLineItems) {
        const indexToUpdate = expenseLineItems.findIndex(s => s.expenseLineId === action.response?.expenseLineId);
        if (indexToUpdate !== -1) {
          expenseLineItems[indexToUpdate] = action.response;
          draft.authorizationDetails[draft.currentAuthId].authExpenses.expenseLines = [...expenseLineItems];
        }

        if (draft.authorizationDetails[draft.currentAuthId]?.authExpenses.submittedExpenses.find(es => es.expenseId === action.response.expenseId)) {
          const summaryLineItems = draft.authorizationDetails[draft.currentAuthId]?.authExpenses.summaryExpenseLines;
          const matchingSubmittedExpense = draft.authorizationDetails[draft.currentAuthId]?.authExpenses.submittedExpenses.find(
            es => es.expenseId === action.response.expenseId
          );
          if (summaryLineItems && matchingSubmittedExpense) {
            const indexToUpdate = summaryLineItems.findIndex(s => s.expenseLineId === action.response?.expenseLineId);
            summaryLineItems[indexToUpdate] = action.response;
            draft.authorizationDetails[draft.currentAuthId].authExpenses.summaryExpenseLines = [...summaryLineItems];
          }
        }
      }
      break;
    }

    case UPDATE_EXPENSE_SUMMARY_SUCCESS:
      let tempSummaries = draft.authorizationDetails[draft.currentAuthId].authExpenses.submittedExpenses;
      const tempSummaryIndex = tempSummaries.findIndex(s => s.expenseId === action.response.expenseId);
      tempSummaries[tempSummaryIndex] = action.response;
      draft.authorizationDetails[draft.currentAuthId].authExpenses.submittedExpenses = tempSummaries;
      break;

    case POST_EXPENSE_LINE_SUCCESS:
      if (draft.authorizationDetails[draft.currentAuthId]?.authExpenses?.summaryExpenseLines) {
        draft.authorizationDetails[draft.currentAuthId].authExpenses.summaryExpenseLines.push(action.response);
      }

      if (draft.authorizationDetails[draft.currentAuthId]?.authExpenses?.expenseLines) {
        draft.authorizationDetails[draft.currentAuthId].authExpenses.expenseLines.push(action.response);
      }

      break;

    case UPDATE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS: {
      const customTaxes = draft.authorizationDetails[draft.currentAuthId]?.authExpenses.customTaxes;
      if (customTaxes) {
        const indexToUpdate = customTaxes.findIndex(s => s.customTaxId === action.response?.customTaxId);
        if (indexToUpdate !== -1) {
          customTaxes[indexToUpdate] = action.response;
          draft.authorizationDetails[draft.currentAuthId].authExpenses.customTaxes = [...customTaxes];
        }
      }
      break;
    }

    case POST_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS: {
      const customTaxes = draft.authorizationDetails[draft.currentAuthId]?.authExpenses.customTaxes ?? [];
      draft.authorizationDetails[draft.currentAuthId].authExpenses.customTaxes = [...customTaxes, action.response];
      break;
    }

    case DELETE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS: {
      const customTaxes = draft.authorizationDetails[draft.currentAuthId]?.authExpenses.customTaxes;
      if (customTaxes) {
        const indexToUpdate = customTaxes.findIndex(customTax => customTax.customTaxId === action.response?.customTaxId);
        if (indexToUpdate !== -1) {
          customTaxes.splice(indexToUpdate, 1);
          draft.authorizationDetails[draft.currentAuthId].authExpenses.customTaxes = [...customTaxes];
        }
      }
      break;
    }

    case GET_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS: {
      const currentAuth = draft.authorizationDetails[draft.currentAuthId];
      const customTaxes = currentAuth?.authExpenses.customTaxes ?? [];
    
      if (!action.response || action.response.length === 0) {
        currentAuth.authExpenses.customTaxes = [];
      } else {
        currentAuth.authExpenses.customTaxes = customTaxes.filter(
          tax => !action.response.some(
            newTax => newTax.expenseLineId === tax.expenseLineId
          )
        );

        currentAuth.authExpenses.customTaxes = [
          ...currentAuth.authExpenses.customTaxes,
          ...action.response
        ];
      }
      break;
    }
    
    

    default:
      break;
  }
  return draft;
};

export default handleAuthExpensesReducer;
