/* eslint-disable no-useless-computed-key */
import { MenuItem, TextField, withStyles } from '@material-ui/core';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Fragment, PureComponent } from 'react';
import memoize from 'memoize-one';

//import { TOAST_MESSAGE_SEVERITY_ERROR } from '../layout/types';
import { getTestApiV2, setPageTitle, showToast } from 'modules/layout/layout.actions';
import Log from 'utilities/log';

import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import { getVendors } from 'modules/authorizations/store/actions/dataManagement.actions';
import { getAuthorizationSummaries } from 'modules/authorizations/store/actions/authorizations.actions';
import { GET_VENDORS_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import { GET_AUTHORIZATIONS_FAILURE } from 'modules/authorizations/store/types/authorizationTypes';
import { GET_SURVEY_QUESTIONS_FAILURE } from '../surveys.types';

import SurveyQuestionResponsesGrid from './surveyQuestionResponsesGrid.component';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { getSurveyQuestionResponses, getSurveyQuestions } from '../surveys.actions';
import { GET_SURVEY_QUESTION_RESPONSES_FAILURE } from '../surveys.types';
import { chain } from 'lodash';

const styles = (theme) => ({
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 100,
  },
});

class SurveyQuestionResponsesView extends PureComponent {
    state = {
        isLoading: false,
        selectedStatuses: -1,
        selectedStatusName: '',
        selectedType: -1,
        selectedTypeName: null,
        selectedStatus: 1,
        selectedClient: -1,
        surveyQuestionResponses: this.props.surveyQuestionResponses,
      };

  async componentDidMount() {
    this.props.setPageTitle('Survey Question Responses');

    this.setState({ isLoading: true });

    if (!this.props.surveyQuestionResponses) {
      let { type, response } = await this.props.getSurveyQuestionResponses();
      if (type === GET_SURVEY_QUESTION_RESPONSES_FAILURE) {
        this.props.showToast('Failed to retrieve survey question responses, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        this.setState({surveyQuestionResponses: response });
      }
    }

    if (!this.props.surveyQuestions) {
      let { type } = await this.props.getSurveyQuestions();
      if (type === GET_SURVEY_QUESTIONS_FAILURE) {
        this.props.showToast('Failed to retrieve survey questions, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.vendors) {
      let { type } = await this.props.getVendors();
      if (type === GET_VENDORS_FAILURE) {
        this.props.showToast('Failed to retrieve vednors, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.authorizationSummaries) {
      let { type } = await this.props.getAuthorizationSummaries();
      if (type === GET_AUTHORIZATIONS_FAILURE) {
        this.props.showToast('Failed to retrieve authorizations, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    this.setState({ isLoading: false });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleVendorTypeChange = (vendorTypes) => (event) => {
    let type = vendorTypes.filter((s) => s.id === event.target.value);
    this.setState({
      selectedType: type[0].id,
      selectedTypeName: type[0].title,
    });
  };

  filterSummaries = memoize((vendors, selectedType, selectedTypeName, selectedStatuses, selectedStatusName) => {
    return vendors.filter((summary) => {
      return (selectedType === -1 || (selectedTypeName.length !== 0 && selectedTypeName.includes(summary.vendorType))) &&
        (selectedStatuses === -1 || (summary.status !== null && summary.status === selectedStatusName));
    });
  });

  getClientList = memoize((clients) => {
      if (!clients) {
        return [];
      }
      return chain(clients)
        .values()
        .uniqWith(clients, (a, b) => a.description === b.description)
        .value()
        .sort((a, b) => String(a.description).localeCompare(b.description))
        .filter((instance) => instance.description);
  });

  render() {
    Log.trace('RENDER', 'VendorsView');
    const { surveyQuestionResponses, selectedClient } = this.state;
    const { classes, history, isLoading, clients } = this.props;
    const sortedClients = this.getClientList(clients);

    return (
      <Fragment>
        <div className={classes.headerContainer}>
          <TextField
            select
            label="Clients"
            className={classes.textField}
            value={selectedClient}
            onChange={(e) => this.setState({ selectedClient: e.target.value, surveyQuestionResponses: e.target.value !== -1 ? this.props.surveyQuestionResponses.filter((sq) => sq.clientId === e.target.value) : this.props.surveyQuestionResponses }) }
            SelectProps={{
              autoWidth: true,
            }}
            variant="outlined"
          >
            <MenuItem value={-1}>
              <em>All Clients</em>
            </MenuItem>
            {sortedClients.map((option) => {
                return (
                  <MenuItem key={option.moveTrackClientId} value={option.moveTrackClientId}>
                    {option.description}
                  </MenuItem>
                )
            })}
          </TextField>
        </div>
        {/* <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Box sx={{padding: '10px', display: 'flex', flexDirection: 'row'}}>

            </Box>
          <Button color="secondary" variant="contained" disabled={true} sx={{ maxHeight: '40px', marginRight: '10px', marginTop: '8px', backgroundColor: '#5D9878', '&:hover': { backgroundColor: '#528569' } }} onClick={() => console.log('Create Question Clicked')}> Create Question </Button>
        </Box> */}
        { surveyQuestionResponses && 
          <>
            {(this.state.isLoading || !sortedClients || (sortedClients && sortedClients.length === 0)) && <FullscreenSpinner/>}
            {!this.state.isLoading && sortedClients && sortedClients.length > 0 && (
              <div className="px-2">
                <SurveyQuestionResponsesGrid
                  surveyQuestionResponses={surveyQuestionResponses ?? []}
                  isLoading={isLoading}
                  history={history}
                  sortedClients={sortedClients}
                />
              </div>
            )}
            </>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    authorizations: { authorizationSummaries, vendors },
    surveys: { surveyQuestionResponses, surveyQuestions, isLoading },
    clients: { clients },
  } = state;
  return { surveyQuestionResponses, surveyQuestions, isLoading, clients, authorizationSummaries, vendors };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
    showToast,
    getTestApiV2,
    getAuthorizationSummaries,
    getVendors,
    getSurveyQuestions,
    getSurveyQuestionResponses,
  }),
)(SurveyQuestionResponsesView);