import {
  POST_DEPARTURE_ADDRESS_FAILURE,
  POST_DESTINATION_ADDRESS_FAILURE,
  UPDATE_DEPARTURE_ADDRESS_FAILURE,
  UPDATE_DESTINATION_ADDRESS_FAILURE
} from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  postDepartureAddress,
  postDestinationAddress,
  updateDepartureAddress,
  updateDestinationAddress
} from '../store/actions/authorizations.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const AddressCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { departureAddress, destinationAddress, hideEdit } = props;

  let addressStatusOptions = ['Rentership', 'Ownership'];

  const tabs = [
    { header: 'Departure Address', order: 1, iconPath: ['fas', 'truck-moving'] },
    { header: 'Destination Address', order: 2, iconPath: ['fas', 'house'] }
  ];

  const formatStreetAddress = address => {
    if (!address) return '';

    return [address.addressLineOne, address.addressLineTwo, address.addressLineThree].filter(Boolean).join(', ');
  };

  const summaries = [
    {
      header: 'Rent/Own',
      accessorKey: 'addressType',
      type: 'dropdown',
      options: addressStatusOptions,
      required: false,
      value: departureAddress ? departureAddress.addressType : '',
      tab: 1
    },
    {
      header: 'Address',
      type: 'text',
      value: formatStreetAddress(departureAddress),
      tab: 1,
      hideInModal: true
    },
    {
      header: 'Street Address',
      accessorKey: 'addressLineOne',
      type: 'text',
      required: false,
      value: departureAddress ? departureAddress.addressLineOne : '',
      tab: 1,
      hideInTable: true
    },
    {
      header: 'Apartment/Suite/Floor',
      accessorKey: 'addressLineTwo',
      type: 'text',
      required: false,
      value: departureAddress ? departureAddress.addressLineTwo : '',
      tab: 1,
      hideInTable: true
    },
    {
      header: 'Additional Address Info',
      accessorKey: 'addressLineThree',
      type: 'text',
      required: false,
      value: departureAddress ? departureAddress.addressLineThree : '',
      tab: 1,
      hideInTable: true
    },
    { header: 'City', accessorKey: 'city', type: 'text', required: false, value: departureAddress ? departureAddress.city : '', tab: 1 },
    {
      header: 'State/Province',
      accessorKey: 'state',
      type: 'text',
      required: false,
      value: departureAddress ? departureAddress.state : '',
      tab: 1
    },
    {
      header: 'Country',
      accessorKey: 'country',
      type: 'text',
      required: false,
      value: departureAddress ? departureAddress.country : '',
      tab: 1
    },
    {
      header: 'County',
      accessorKey: 'county',
      type: 'text',
      required: false,
      value: departureAddress ? departureAddress.county : '',
      tab: 1
    },
    {
      header: 'Zip/Postal',
      accessorKey: 'postalCode',
      type: 'text',
      required: false,
      value: departureAddress ? departureAddress.postalCode : '',
      tab: 1
    },
    {
      header: 'Inventory',
      accessorKey: 'inventory',
      type: 'text',
      lineBelow: true,
      required: false,
      value: departureAddress ? departureAddress.inventory : '',
      tab: 1
    },

    {
      header: 'Status',
      accessorKey: 'addressType',
      type: 'dropdown',
      options: addressStatusOptions,
      lineBelow: true,
      required: false,
      value: destinationAddress ? destinationAddress.addressType : '',
      tab: 2
    },
    {
      header: 'Address',
      type: 'text',
      value: formatStreetAddress(destinationAddress),
      tab: 2,
      hideInModal: true
    },
    {
      header: 'Street Address',
      accessorKey: 'addressLineOne',
      type: 'text',
      required: false,
      value: destinationAddress ? destinationAddress.addressLineOne : '',
      tab: 2,
      hideInTable: true
    },
    {
      header: 'Apartment/Suite/Floor',
      accessorKey: 'addressLineTwo',
      type: 'text',
      required: false,
      value: destinationAddress ? destinationAddress.addressLineTwo : '',
      tab: 2,
      hideInTable: true
    },
    {
      header: 'Additional Address Info',
      accessorKey: 'addressLineThree',
      type: 'text',
      required: false,
      value: destinationAddress ? destinationAddress.addressLineThree : '',
      tab: 2,
      hideInTable: true
    },
    {
      header: 'City',
      accessorKey: 'city',
      type: 'text',
      required: false,
      value: destinationAddress ? destinationAddress.city : '',
      tab: 2
    },
    { emptyField: true, tab: 2 },
    {
      header: 'State/Province',
      accessorKey: 'state',
      type: 'text',
      required: false,
      value: destinationAddress ? destinationAddress.state : '',
      tab: 2
    },
    {
      header: 'Country',
      accessorKey: 'country',
      type: 'text',
      required: false,
      value: destinationAddress ? destinationAddress.country : '',
      tab: 2
    },
    {
      header: 'County',
      accessorKey: 'county',
      type: 'text',
      required: false,
      value: destinationAddress ? destinationAddress.county : '',
      tab: 2
    },
    {
      header: 'Zip/Postal',
      accessorKey: 'postalCode',
      type: 'text',
      required: false,
      value: destinationAddress ? destinationAddress.postalCode : '',
      tab: 2
    }
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    values.authorizationId = props.id;

    if (tab === 1) {
      if (departureAddress && departureAddress.length !== 0) {
        const resp = await props.updateDepartureAddress(values);
        if (resp.type === UPDATE_DEPARTURE_ADDRESS_FAILURE) {
          props.showToast('Failed to update departure info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else {
          setIsLoading(false);
          return true;
        }
      } else {
        const resp = await props.postDepartureAddress(values);
        if (resp.type === POST_DEPARTURE_ADDRESS_FAILURE) {
          props.showToast('Failed to update departure info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else {
          setIsLoading(false);
          return true;
        }
      }
    } else {
      if (destinationAddress && destinationAddress.length !== 0) {
        const resp = await props.updateDestinationAddress(values);
        if (resp.type === UPDATE_DESTINATION_ADDRESS_FAILURE) {
          props.showToast('Failed to update destination info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else {
          setIsLoading(false);
          return true;
        }
      } else {
        const resp = await props.postDestinationAddress(values);
        if (resp.type === POST_DESTINATION_ADDRESS_FAILURE) {
          props.showToast('Failed to update destination info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else {
          setIsLoading(false);
          return true;
        }
      }
    }
  };

  return (
    <EditableCard tabs={tabs} summaries={summaries} numColumns={3} updateFields={updateFields} isLoading={isLoading} hideEdit={hideEdit} />
  );
};

export default compose(
  connect(null, {
    postDepartureAddress,
    postDestinationAddress,
    updateDepartureAddress,
    updateDestinationAddress,
    showToast
  })
)(AddressCard);
