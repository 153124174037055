import {
    Typography,
    withStyles,
    withTheme,
  } from '@material-ui/core';
      
  import { FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
  import { Grid, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
  import {
    IntegratedSorting,
    SortingState,
  } from '@devexpress/dx-react-grid';
      
  import { compose } from 'recompose';
  import React, { PureComponent } from 'react';
  import classNames from 'classnames';
      
  import { formatDateGeneric } from 'utilities/common';
  import Log from 'utilities/log';
  import Spinner from 'modules/common/spinner.component';
      
  const styles = (theme) => {
    const intakeRecordBorder = `1px solid ${theme.palette.grey[500]}`;
    return {
      table: {
        borderSpacing: theme.spacing(0, 2),
      },
      tableHead: {
        height: 32,
      },
      cell: {
        borderTop: intakeRecordBorder,
        borderBottom: intakeRecordBorder,
        borderLeft: 0,
        borderRight: 0,
        '&:first-child': {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
          borderLeft: intakeRecordBorder,
        },
        '&:last-child': {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
          borderRight: intakeRecordBorder,
        },
      },
      urgencyMenuItem: {
        justifyContent: 'center',
      },
      selectInput: {
        lineHeight: 1.75,
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(0.5),
      },
      row: {
        backgroundColor: theme.palette.common.white,
      },
      noDataCell: {
        paddingBottom: theme.spacing(12),
        paddingTop: theme.spacing(12),
        textAlign: 'center',
      },
    };
  };
      
  const FILTER_DISABLED_COLUMNS = ['organization'];
      
  class SurveyQuestionResponsesGrid extends PureComponent {
        static defaultProps = {
          isLoading: false,
        };

        constructor(props) {
          super(props);
                
          this.state = {
            columns: [
              { name: 'responseId', title: 'Response ID' },
              { name: 'surveyQuestionId', title: 'Question ID' },
              { name: 'authorizationId', title: 'Authorization ID' },
              { name: 'clientId', title: 'Client', getCellValue: (record) => <Typography>{this.props.sortedClients ? this.props.sortedClients.find((o) => o.moveTrackClientId === record.clientId)?.description : '-'}</Typography> },
              { name: 'result', title: 'Result' },
              { name: 'completionDate', title: 'Completion Date', getCellValue: this.formatDate('completionDate') },
              { name: 'comment', title: 'Comment' },
              { name: 'linkedVendorId', title: 'Vendor ID' },
              { name: 'linkedCounselorId', title: 'Counselor ID' },
              { name: 'surveyQuestionVariationId', title: 'Variation Id' },
              { name: 'surveyQuestionVariationName', title: 'Variation Name' }
            //   { name: 'status', title: 'Status', getCellValue: (record) => <Typography>{statusOptions.find((o) => o.id === record.status)?.title}</Typography> },
            ],
            filters: [],
            sorting: [{ columnName: 'surveyQuestionId', direction: 'desc' }],
            tableColumnExtensions: [
                { columnName: 'clientId', width: 225 },
                { columnName: 'comment', width: 225 },
                { columnName: 'result', width: 80 },
                { columnName: 'responseId', width: 110 },
                { columnName: 'surveyQuestionId', width: 110 },
                { columnName: 'linkedVendorId', width: 100 },
                { columnName: 'linkedCounselorId', width: 110 },
                { columnName: 'surveyQuestionVariationId', width: 100 },
            ],
            integratedFilteringColumnExtensions: [
              { columnName: 'details', predicate: this.keyPredicate },
            ],
          };
    
          this.changeSorting = (sorting) => this.setState({ sorting });
    
        }
  
        keyPredicate = (value, filter) => {
          const valueLower = (value.key || '').toLowerCase();
          const filterLower = (filter.value || '').toLowerCase();
          return valueLower.indexOf(filterLower) > -1;
        };
  
        formatDate = (key) => (record) => {
          return formatDateGeneric(record[key]);
        };
    
        cellClicked = (id) => {
          this.props.history.push(`/vnedors/${id}`);
        }

        getNumExpenseLines = (record) => {
            const { expenseLines } = this.props;
            const lines = expenseLines.filter(instance => instance.expenseId === record.expenseId);
            return lines ? lines.length : 0;
        };
      
        changeFilters = (filters) => this.setState({ filters });
      
        filterCell = (props) => {
          return props.column.title && !FILTER_DISABLED_COLUMNS.includes(props.column.name) ?
            <TableFilterRow.Cell {...props} /> : null;
        };
      
        headerCell = (props) => {
          return props.column.title ? <TableHeaderRow.Cell {...props} /> : null;
        };
      
        headerRowComponent = (props) => {
          return <TableHeaderRow.Row className={this.props.classes.tableHead} {...props} />;
        };
      
        rowComponent = (props) => {
          return <Table.Row className={this.props.classes.row} {...props} />;
        };
      
        cellComponent = (props) => {
          let clickHandler = () => this.props.history.push(`/surveyQuestionResponses/${props.row.responseId}`); // Add handler for when a client is clicked
          let pointerClass = 'handles-click';
          const className = classNames(this.props.classes.cell, pointerClass);
          return <Table.Cell onClick={clickHandler} className={className} {...props} />;
        };
      
        tableComponent = (props) => {
          return <Table.Table className={this.props.classes.table} {...props} />;
        };
      
        noDataCellComponent = (props) => {
          const content = this.props.isLoading ? <Spinner logo /> : 'No Records were returned.  Check the filters above.';
          // need to remove the getMessage function to avoid exception
          // eslint-disable-next-line no-unused-vars
          const { getMessage, ...rest } = props;
          return (
            <Table.Cell {...rest} className={this.props.classes.noDataCell}>
              <Typography>{content}</Typography>
            </Table.Cell>
          );
        };
      
        
        render() {
          Log.trace('RENDER', 'surveyQuestionResponsesGrid');
          const { surveyQuestionResponses } = this.props;
          const {
            columns,
            filters,
            tableColumnExtensions,
            integratedFilteringColumnExtensions,
            sorting,
          } = this.state;

          return (
            <Grid
              rows={surveyQuestionResponses}
              columns={columns}
            >
              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
                
              <FilteringState
                filters={filters}
                onFiltersChange={this.changeFilters}
              />
              <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
              <IntegratedSorting />
              <Table
                columnExtensions={tableColumnExtensions}
                rowComponent={this.rowComponent}
                tableComponent={this.tableComponent}
                cellComponent={this.cellComponent}
                noDataCellComponent={this.noDataCellComponent}
              />
              <TableHeaderRow rowComponent={this.headerRowComponent} cellComponent={this.headerCell} showSortingControls />
              <TableFilterRow cellComponent={this.filterCell} />
            </Grid>
          );
        }
  }
      
  export default compose(
    withStyles(styles),
    withTheme,
  )(SurveyQuestionResponsesGrid);
      