import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import Spinner from 'modules/common/spinner.component';
import {
  getSurveyQuestionVariations,
  postSurveyQuestionVariation,
  updateSurveyQuestionVariation,
  deleteSurveyQuestionVariation
} from '../surveys.actions';
import { Clients } from './surveyQuestionConstants';

import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Button,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  FormControlLabel,
  Box,
  Tooltip,
  Typography,
  IconButton,
  Modal
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import isEqual from 'lodash/isEqual';

const taggedItemOptions = [
  { label: 'N/a', value: -1 },
  { label: 'RC on File', value: 1 },
  { label: 'HHG Estimate Vendor', value: 2 },
  { label: 'Dest Service Buyer Vendor', value: 3 },
  { label: 'Dest Service Renter Vendor', value: 4 },
  { label: 'TL Vendor', value: 5 }
];

const RowModal = ({ open, onClose, onSave, initialValues }) => {
  const [rowValues, setRowValues] = useState(initialValues || {});

  useEffect(() => {
    setRowValues(initialValues || {});
  }, [initialValues]);

  const handleChange = (value, field) => {
    setRowValues(prev => ({ ...prev, [field]: value }));
  };

  const handleSave = () => {
    onSave(rowValues);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          padding: 4,
          boxShadow: 24,
          width: 800
        }}
      >
        <Typography variant="h6" mb={1}>
          Question Variation
        </Typography>
        <Typography variant="body1" color="text.secondary" mb={2}>
          Write your question here, using <strong>@</strong> as the wildcard.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              label="Question"
              value={rowValues.variationName || ''}
              onChange={e => handleChange(e.target.value, 'variationName')}
              multiline
              minRows={3}
              sx={{
                textarea: {
                  fontSize: '1rem',
                  padding: '12px'
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body1" color="text.secondary" whiteSpace="nowrap">
                <strong>@ =</strong>
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="tagged-item-label">Tagged Item</InputLabel>
                <Select
                  labelId="tagged-item-label"
                  id="tagged-item"
                  label="Tagged Item"
                  value={rowValues.taggedItemId || ''}
                  onChange={e => handleChange(e.target.value, 'taggedItemId')}
                >
                  {taggedItemOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button onClick={onClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const QuestionVariationForm = ({
  formValues = [],
  saveFunction,
  formFields,
  createNewRowFunc,
  addRowLabel,
  isDisabled = false,
  header,
  headerCheckbox
}) => {
  const [formValuesState, setFormValuesState] = useState(formValues);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setFormValuesState([...formValues]);
    setHasChanges(false);
  }, [formValues]);

  const checkForChanges = updatedValues => {
    setHasChanges(!isEqual(updatedValues, formValues));
  };

  const handleModalOpen = (row = null, index = null) => {
    setEditingRowIndex(index);
    setModalOpen(true);
  };

  const handleChange = (value, field, index) => {
    setFormValuesState(prev => {
      const updated = [...prev];
      if (field === 'isPrimaryVariation') {
        if (value === true) {
          updated.forEach((item, i) => {
            updated[i] = { ...item, isPrimaryVariation: i === index };
          });
        } else {
          updated[index] = { ...updated[index], isPrimaryVariation: false };
        }
      } else {
        updated[index] = { ...updated[index], [field]: value };
      }

      checkForChanges(updated);
      return updated;
    });
  };

  const handleModalClose = () => {
    setEditingRowIndex(null);
    setModalOpen(false);
  };

  const handleModalSave = async newRow => {
    let updatedValues;
    if (editingRowIndex !== null) {
      updatedValues = [...formValuesState];
      updatedValues[editingRowIndex] = newRow;
    } else {
      updatedValues = [...formValuesState, newRow];
    }
    setFormValuesState(updatedValues);
    await saveFunction(updatedValues);
    handleModalClose();
  };

  const removeFormRow = index => {
    const updatedValues = formValuesState.filter((_, i) => i !== index);
    setFormValuesState(updatedValues);
    checkForChanges(updatedValues);
  };

  const saveForm = async () => {
    if (saveFunction) {
      setIsSubmitting(true);
      await saveFunction(formValuesState);
      setIsSubmitting(false);
      setHasChanges(false);
    }
  };

  const renderHeader = () => {
    if (!header) return null;
    return (
      <Box display="flex" alignItems="center">
        {header.iconPath && (
          <Tooltip title={header.title}>
            <FontAwesomeIcon icon={header.iconPath} size="2x" />
          </Tooltip>
        )}
        <Typography variant="h5" fontWeight="bold" sx={{ ml: 2 }}>
          {header.title}
        </Typography>
        {headerCheckbox && headerCheckbox()}
      </Box>
    );
  };

  return (
    <Grid item xs={12}>
      <Paper className="card-root" sx={{ p: 2 }}>
        <Grid container justifyContent="space-between" alignItems="center" mb={2}>
          {renderHeader()}
          <Button disabled={isSubmitting || isDisabled} onClick={() => handleModalOpen(createNewRowFunc(), null)}>
            {addRowLabel}
          </Button>
        </Grid>
        {formValuesState.map((row, index) => (
          <Box key={index} mb={2}>
            <Grid container alignItems="center" spacing={3}>
              {formFields
                .filter(f => f.type !== 'hidden')
                .map(field => (
                  <React.Fragment key={field.name}>
                    {field.type === 'checkbox' ? (
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={row[field.name] || false}
                              onChange={e => handleChange(e.target.checked, field.name, index)}
                              disabled={isDisabled}
                            />
                          }
                          label={field.label}
                        />
                      </Grid>
                    ) : field.type === 'select' ? (
                      <Grid item xs={3}>
                        <FormControl fullWidth>
                          <InputLabel id={`select-label-${field.name}`}>{field.label}</InputLabel>
                          <Select
                            labelId={`select-label-${field.name}`}
                            id={`select-${field.name}`}
                            label={field.label}
                            value={row[field.name] || ''}
                            onChange={e => handleChange(e.target.value, field.name, index)}
                          >
                            {field.options.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : (
                      <Grid item xs={4}>
                        <TextField
                          label={field.label}
                          value={row[field.name]}
                          fullWidth
                          disabled
                          inputProps={{
                            style: {
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }
                          }}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              <Grid item xs="auto">
                <IconButton onClick={() => handleModalOpen(row, index)} disabled={isDisabled}>
                  <FontAwesomeIcon icon={faEdit} />
                </IconButton>
                <IconButton onClick={() => removeFormRow(index)} disabled={isDisabled}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ))}
        <Grid container justifyContent="flex-end" mt={2}>
          <Button color="secondary" variant="contained" onClick={saveForm} disabled={!hasChanges || isSubmitting || isDisabled}>
            Save
          </Button>
        </Grid>
        <RowModal
          open={modalOpen}
          onClose={handleModalClose}
          onSave={handleModalSave}
          formFields={formFields}
          initialValues={editingRowIndex !== null ? formValuesState[editingRowIndex] : createNewRowFunc()}
        />
      </Paper>
    </Grid>
  );
};

const SurveyQuestionSetupCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { surveyQuestionVariations, surveyQuestionId, isReadOnly } = props;

  const formFields = [
    {
      name: 'surveyQuestionVariationId',
      type: 'hidden'
    },
    {
      name: 'surveyQuestionId',
      type: 'hidden'
    },
    { name: 'isPrimaryVariation', type: 'checkbox', label: 'Is Primary Variation' },
    { name: 'variationName', type: 'text', label: 'Name' },
    { name: 'variationClientSet', type: 'select', options: Clients, label: 'Clients' }
  ];
  const formLabel = 'variations';
  const addRowLabel = 'Add New Variation';
  const header = { title: 'Question Setup', iconPath: ['fas', 'gears'] };

  const createNewRowFunc = () => {
    return {
      surveyQuestionVariationId: 0,
      surveyQuestionId: surveyQuestionId,
      isPrimaryVariation: false,
      variationName: '',
      variationClientSet: 'All Clients',
      taggedItemId: null
    };
  };

  const saveForm = async formValues => {
    setIsLoading(true);

    formValues = formValues ?? [];
    const newVariation = formValues.filter(formValue => formValue.surveyQuestionVariationId === 0);

    const deletedVariations = surveyQuestionVariations.filter(
      variation => !formValues.some(formValue => formValue.surveyQuestionVariationId === variation.surveyQuestionVariationId)
    );

    const updatedVariations = formValues.filter(
      formValue =>
        formValue.surveyQuestionVariationId !== 0 &&
        surveyQuestionVariations.some(
          variation =>
            variation.surveyQuestionVariationId === formValue.surveyQuestionVariationId &&
            Object.keys(formValue).some(key => variation[key] !== formValue[key])
        )
    );

    try {
      if (deletedVariations.length > 0) {
        for (const variation of deletedVariations) {
          await props.deleteSurveyQuestionVariation(variation.surveyQuestionVariationId);
        }
      }
      if (newVariation.length > 0) {
        for (const variation of newVariation) {
          await props.postSurveyQuestionVariation(variation);
        }
      }
      if (updatedVariations.length > 0) {
        for (const variation of updatedVariations) {
          await props.updateSurveyQuestionVariation(variation);
        }
      }
      await props.getSurveyQuestionVariations(surveyQuestionId);
    } catch (error) {
      console.error('Error saving form:', error);
      props.showToast('Failed to update survey question variations, please try again later', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <QuestionVariationForm
          formValues={
            surveyQuestionVariations && surveyQuestionVariations.length > 0 ? surveyQuestionVariations.map(item => ({ ...item })) : []
          }
          saveFunction={saveForm}
          formFields={formFields}
          createNewRowFunc={createNewRowFunc}
          formLabel={formLabel}
          addRowLabel={addRowLabel}
          isDisabled={isReadOnly}
          header={header}
        />
      )}
    </>
  );
};

export default compose(
  connect(null, {
    showToast,
    getSurveyQuestionVariations,
    postSurveyQuestionVariation,
    updateSurveyQuestionVariation,
    deleteSurveyQuestionVariation
  })
)(SurveyQuestionSetupCard);
