/* eslint-disable react-hooks/exhaustive-deps */

import { withStyles } from '@material-ui/core';
import { Box, Grid, Button } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import SegmentInfoCard from './segmentInfoCard.component';
import StorageInfoCard from './storageInfoCard.component';
import EstimateMovingDates from './estimateMovingDates.component';
import HhgAddressCard from './hhgAddressCard.component';
import CarShipmentVehiclesComponent from './carShipmentVehicles.component';

const styles = theme => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  }
});

const PrimaryHhgInfo = props => {
  const { details, id, isLoadingMovingDates, segmentId, location, isReadOnly } = props;

  const segmentInfo = details?.authHouseHoldGoods?.hhgSegments
    ? details?.authHouseHoldGoods?.hhgSegments.find(s => s.segmentId === segmentId)
    : null;

  let vehicles = details?.authHouseHoldGoods?.hhgSegmentVehicles
    ? details?.authHouseHoldGoods?.hhgSegmentVehicles.filter(v => v.hhgSegmentId === segmentId)
    : [];

  return (
    <Box>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xl={8} rowSpacing={2} sx={{ marginTop: '-3px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 10px'
                }}
              >
                <Button
                  onClick={() => alert('Vendor Letter Sent Successfully!')}
                  color="secondary"
                  variant="contained"
                  sx={{ maxHeight: '40px', marginLeft: '12px' }}
                >
                  Send Vendor Letter
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} xl={8} rowSpacing={2}>
          <SegmentInfoCard
            segmentInfo={segmentInfo}
            hideEdit={location.pathname.includes('expenses') || isReadOnly}
            id={id}
            isReadOnly={isReadOnly}
          />
          <Box sx={{ paddingTop: '16px' }}>
            <EstimateMovingDates
              segmentInfo={segmentInfo}
              estimates={details?.authHouseHoldGoods?.hhgEstimates}
              hideEdit={true}
              id={id}
              isLoadingMovingDates={isLoadingMovingDates}
              isReadOnly={isReadOnly}
            />
          </Box>
          <Box sx={{ paddingTop: '16px' }}>
            <CarShipmentVehiclesComponent
              hhgSegmentVehicles={vehicles}
              segmentId={segmentId}
              authorizationId={id}
              isReadOnly={isReadOnly}
              segmentInfo={segmentInfo}
            />
          </Box>
        </Grid>
        <Grid item xs={12} xl={4} rowSpacing={2}>
          <StorageInfoCard
            segmentInfo={segmentInfo}
            hideEdit={location.pathname.includes('expenses') || isReadOnly}
            id={id}
            isReadOnly={isReadOnly}
          />
          <Box sx={{ paddingTop: '16px' }}>
            <HhgAddressCard
              segmentInfo={segmentInfo}
              hideEdit={location.pathname.includes('expenses') || isReadOnly}
              id={id}
              addresses={details?.authDetails?.addresses}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

PrimaryHhgInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default compose(withStyles(styles), connect(mapStateToProps, {}))(PrimaryHhgInfo);
