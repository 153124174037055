import { INTAKE_API_BASE_URL } from 'middleware/intakeV2Api';

//Transform file meta data into file object to be used in the editable card.
export const createFormFileObject = metadata => {
  return metadata
    ? {
        id: metadata?.attachmentId || null,
        file: new File([], metadata.fileName, { type: metadata.contentType })
      }
    : null;
};

//Transforms formFile into FileAttatchment
export const createSubmissionFileObject = async formFile => {
  if (!formFile || !formFile.file) {
    return null;
  }

  return {
    attachmentId: formFile.id || 0,
    fileName: formFile.file.name,
    base64FileData: await fileToBase64(formFile.file),
    contentType: formFile.file.type
  };
};

const fileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
    reader.readAsDataURL(file);
  });
};

export const openFile = fileData => {
  if (fileData && fileData.base64FileData) {
    const binaryData = atob(fileData.base64FileData);
    const byteArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    const fileBlob = new Blob([byteArray], { type: fileData.contentType });
    const fileURL = URL.createObjectURL(fileBlob);

    const newTab = window.open(fileURL, '_blank');

    if (newTab) {
      newTab.onload = () => {
        newTab.document.title = fileData.fileName || 'Downloaded File';
      };
    }
  } else {
    alert('File is empty.');
  }
};

export const getAndOpenFile = (fileId, fileName) => {
  let url = `${INTAKE_API_BASE_URL}api/files/${fileId}/view`;
  if (fileName) url = url.concat(`?fileName=${fileName}`);

  const newTab = window.open(url, '_blank');

  if (newTab && fileName) {
    newTab.onload = () => {
      newTab.document.title = fileName;
    };
  }
};

export const getAndDownloadFile = fileId => {
  window.open(`${INTAKE_API_BASE_URL}api/files/${fileId}/download`, '_blank');
};
