/* eslint-disable array-callback-return */
import { Box, Card, CardContent, Grid, Tooltip, Typography } from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { showToast } from 'modules/layout/layout.actions';
import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import { formatDateForInput } from 'utilities/common';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chipClickable: {
    cursor: 'pointer'
  },
  iconOverlayImg: {
    position: 'absolute'
  },
  spacingX: {
    marginRight: theme.spacing(1)
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2)
  },
  mb2: {
    marginBottom: '6.5px',
    marginTop: '6.5px',
    marginRight: '16px'
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important'
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important'
  },
  editModalInput: {
    minWidth: '332px'
  },
  dialogContentContainer: {
    margin: '15px'
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0
  },
  initiatedStatusBox: {
    border: '1px solid #67a783',
    borderRadius: '5px',
    padding: '5px',
    textAlign: 'center',
    minHeight: 30,
    background: '#67a783'
  },
  notInitiatedStatusBox: {
    border: '1px solid #fd6120',
    borderRadius: '5px',
    padding: '5px',
    textAlign: 'center',
    minHeight: 30,
    background: '#fd6120'
  },
  expensesStatusBox: {
    border: '1px solid #868686',
    borderRadius: '5px',
    padding: '5px',
    textAlign: 'center',
    minHeight: 30,
    background: '#868686'
  }
}));

const NotesAndCommunicationEditableCard = props => {
  const { comments, pinnedComments, authorizationId } = props;
  const classes = useStyles();

  let showExceptionsLink = Array.isArray(comments) ? comments.some(s => s.regardingId === 23) : false;

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip placement="right">
                  <FontAwesomeIcon icon={['fas', 'comment']} size="2x" />
                </Tooltip>
              </div>
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={{ xs: 1, md: 1 }}>
              <Grid item md={12}>
                <Box>
                  {pinnedComments &&
                    pinnedComments.length > 0 &&
                    [...pinnedComments]
                      .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)) 
                      .map((comment, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            gap: '10px',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                            paddingBottom: '4px',
                            marginBottom: '6px',
                            padding: '6px 0'
                          }}
                        >
                          <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                            <b>{formatDateForInput(comment.createdDate)}</b> - {comment.commentContent}
                          </Typography>
                        </Box>
                      ))}
                </Box>
              </Grid>
              <Grid item md="3">
                <div className={classes.mb2}>
                  <Typography variant="subtitle2" sx={{ a: { color: '#36939B' } }}>
                    <Link to={`/authorizations/${authorizationId}/comments`}>See All Comments</Link>
                  </Typography>
                </div>
              </Grid>
              {showExceptionsLink && (
                <Grid item md="2">
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ a: { color: '#ff0000' } }}>
                      <Link to={`/authorizations/${authorizationId}/comments?regardingOptionId=23`}>See Exceptions</Link>
                    </Typography>
                  </div>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Box>
      </Card>
    </ThemeProvider>
  );
};
NotesAndCommunicationEditableCard.propTypes = {
  showToast: PropTypes.func.isRequired
};
export default connect(null, { showToast })(NotesAndCommunicationEditableCard);
