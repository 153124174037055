import React, { useCallback, useState } from 'react';
import { Typography, withStyles, withTheme } from '@material-ui/core';
import { FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { Grid, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import classNames from 'classnames';
import Spinner from 'modules/common/spinner.component';
import { recurringPayableStatusName } from './recurringPayables.component';
import { MenuItem, Select } from '@mui/material';

const styles = theme => {
  const intakeRecordBorder = `1px solid ${theme.palette.grey[500]}`;
  return {
    table: {
      borderSpacing: theme.spacing(0, 2)
    },
    tableHead: {
      height: 32
    },
    cell: {
      borderTop: intakeRecordBorder,
      borderBottom: intakeRecordBorder,
      borderLeft: 0,
      borderRight: 0,
      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderLeft: intakeRecordBorder
      },
      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        borderRight: intakeRecordBorder
      }
    },
    urgencyMenuItem: {
      justifyContent: 'center'
    },
    selectInput: {
      lineHeight: 1.75,
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(0.5)
    },
    row: {
      backgroundColor: theme.palette.common.white
    },
    noDataCell: {
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(12),
      textAlign: 'center'
    }
  };
};

const StatusFilterCell = props => {
  const { filter, onFilter } = props;
  const statusOptions = [
    { value: '', label: 'All' },
    { value: 'Active', label: 'Active' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'Expired', label: 'Expired' }
  ];

  return (
    <TableFilterRow.Cell {...props}>
      <Select
        value={filter ? filter.value : statusOptions[0].value}
        onChange={event => onFilter(event.target.value ? { value: event.target.value } : null)}
        displayEmpty
        fullWidth
        variant="standard"
        sx={{ fontSize: '12px' }}
      >
        {statusOptions.map(option => (
          <MenuItem key={option.value} value={option.value} style={{ fontSize: '12px', padding: '5px 10px' }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </TableFilterRow.Cell>
  );
};

const CustomFilterCell = props => {
  switch (props.column.name) {
    case 'recurringPayableStatus':
      return <StatusFilterCell {...props} />;
    default:
      return <TableFilterRow.Cell {...props} />;
  }
};

const getPayeeName = (payTo, authorizationId, vendorId, vendors, authorizations) => {
  if (payTo && vendors && Array.isArray(vendors)) {
    return vendors?.find(v => v.vendorId === vendorId)?.companyName || '--';
  }

  if (!payTo && authorizations && Array.isArray(authorizations)) {
    return authorizations?.find(v => v.authorizationId === authorizationId)?.transfereeName || '--';
  }
};

const RecurringPayablesGrid = ({ recurringPayables, history, isLoading, classes, vendors, authorizations }) => {
  const [sorting, setSorting] = useState([{ columnName: 'startDate', direction: 'desc' }]);
  const [filters, setFilters] = useState([]);
  const cellClicked = id => history.push(`/recurringPayables/${id}`);
  const changeSorting = sorting => setSorting(sorting);
  const changeFilters = useCallback(newFilters => {
    setFilters(prevFilters => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        return newFilters;
      }
      return prevFilters;
    });
  }, []);

  const rowComponent = props => <Table.Row className={classes.row} {...props} />;
  const headerRowComponent = props => <TableHeaderRow.Row className={classes.tableHead} {...props} />;

  const cellComponent = props => (
    <Table.Cell
      onClick={() => cellClicked(props.row.recurringPayableId)}
      className={classNames(classes.cell, 'handles-click')}
      {...props}
    />
  );

  const noDataCellComponent = props => (
    <Table.Cell {...props} className={classes.noDataCell}>
      <Typography>{isLoading ? <Spinner logo /> : 'No Records were returned. Check the filters above.'}</Typography>
    </Table.Cell>
  );

  const tableComponent = useCallback(
    props => {
      return <Table.Table className={classes.table} {...props} />;
    },
    [classes.table]
  );

  const transformedRows =
    recurringPayables && Array.isArray(recurringPayables)
      ? recurringPayables.map(record => ({
          ...record,
          recurringPayableStatus: recurringPayableStatusName(record.recurringPayableStatus),
          payToChoice: record.payToVendor ? 'Vendor' : 'EE',
          payeeId: getPayeeName(record.payToVendor, record.authorizationId, record.vendorId, vendors, authorizations),
          amount: record.amount ? record.amount.toString() : '-',
          startDate: record.startDate ? record.startDate.split('T')[0] : '-',
          endDate: record.endDate ? record.endDate.split('T')[0] : '-'
        }))
      : [];

  return (
    <Grid
      rows={transformedRows || []}
      columns={[
        { name: 'recurringPayableId', title: 'Id' },
        {
          name: 'recurringPayableStatus',
          title: 'Status'
        },
        {
          name: 'payToChoice',
          title: 'Pay To'
        },
        {
          name: 'payeeId',
          title: 'Payee'
        },
        { name: 'amount', title: 'Amount' },
        { name: 'startDate', title: 'Start Date' },
        { name: 'endDate', title: 'End Date' }
      ]}
    >
      <SortingState sorting={sorting} onSortingChange={changeSorting} />
      <FilteringState filters={filters} onFiltersChange={changeFilters} />
      <IntegratedFiltering />
      <IntegratedSorting />
      <Table
        cellComponent={cellComponent}
        rowComponent={rowComponent}
        noDataCellComponent={noDataCellComponent}
        tableComponent={tableComponent}
      />
      <TableHeaderRow rowComponent={headerRowComponent} showSortingControls />
      <TableFilterRow cellComponent={CustomFilterCell} />
    </Grid>
  );
};

RecurringPayablesGrid.defaultProps = {
  isLoading: false
};

export default withTheme(withStyles(styles)(RecurringPayablesGrid));
