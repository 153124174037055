import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';
import { GET_SURVEY_QUESTION_BY_ID_FAILURE, UPDATE_SURVEY_QUESTION_FAILURE } from '../surveys.types';
import { updateSurveyQuestion, getSurveyQuestionById } from '../surveys.actions';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  Switch,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chipClickable: {
    cursor: 'pointer'
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important'
  },
  spacingX: {
    marginRight: theme.spacing(1)
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2)
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px'
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important'
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important'
  },
  editModalInput: {
    minWidth: '332px'
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px'
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0
  }
}));


const SurveyQuestionAnswerCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { surveyQuestionDetails, isReadOnly } = props;

  const [standardScoring, setStandardScoring] = useState(surveyQuestionDetails && surveyQuestionDetails.hasStandardScoring !== null ? surveyQuestionDetails.hasStandardScoring : true);
  const [comment, setComment] = useState(surveyQuestionDetails && surveyQuestionDetails.hasComment !== null ? surveyQuestionDetails.hasComment : true);
  const [followUp, setFollowUp] = useState(surveyQuestionDetails && surveyQuestionDetails.hasFollowUpRequest !== null ? surveyQuestionDetails.hasFollowUpRequest : true);

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

    const handleUpdate = async () => {
      setIsLoading(true);
      
      let newVals = { ...surveyQuestionDetails };
      newVals.hasStandardScoring = standardScoring;
      newVals.hasComment = comment;
      newVals.hasFollowUpRequest = followUp;

      let resp = await props.updateSurveyQuestion(newVals);
      if (resp.type === UPDATE_SURVEY_QUESTION_FAILURE) {
        props.showToast('Failed to update survey question, please try again later', TOAST_MESSAGE_SEVERITY_ERROR);
        setIsLoading(false);
      }
      else {
        let getResp = await props.getSurveyQuestionById(newVals.surveyQuestionId);
        if(getResp.type === GET_SURVEY_QUESTION_BY_ID_FAILURE) {
            props.showToast('Failed to get survey question, please try again later', TOAST_MESSAGE_SEVERITY_ERROR);
            setIsLoading(false);
        }
        else {
            props.showToast(`Successfully updated survey Question ${newVals.surveyQuestionId}.`, {severity: TOAST_MESSAGE_SEVERITY_SUCCESS});
            setStandardScoring(getResp.response.hasStandardScoring);
            setComment(getResp.response.hasComment);
            setFollowUp(getResp.response.hasFollowUpRequest);
            setIsLoading(false);
        }
      }
    };

  return (
     <ThemeProvider theme={theme}>
        { surveyQuestionDetails &&
        <>
        <Card className={classes.card}>
            <Box>
              <CardContent className={classes.cardContainer}>
                <div className="row justify-space-between align-center">
                  <div className={classes.iconRow}>
                    <Tooltip title="Estimates" key="Estimates" placement="right">
                      <FontAwesomeIcon icon={['fas', 'thumbs-up']} size="2x" />
                    </Tooltip>
                    <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                      Answer Formats
                    </Typography>
                  </div>
                </div>
              </CardContent>
              <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
                <Grid container spacing={2} columns={{ xs: 4, sm: 4, md: 4, xl: 4 }}>
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                        <div className="row justify-space-between align-center">
                            <div className={classes.iconRow}>
                                <Switch
                                    checked={standardScoring}
                                    disabled={isReadOnly}
                                    onChange={() => setStandardScoring(!standardScoring)}
                                    value={standardScoring}
                                />
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 'bold', fontSize: '1rem', paddingLeft: '20px', paddingTop: '6px' }}>
                                    Score 1 - 6
                                </Typography>
                            </div>
                        </div>
                      </Grid>
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                        <div className="row justify-space-between align-center">
                            <div className={classes.iconRow}>
                                <Switch
                                    checked={comment}
                                    disabled={isReadOnly}
                                    onChange={() => setComment(!comment)}
                                    value={comment}
                                />
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 'bold', fontSize: '1rem', paddingLeft: '20px', paddingTop: '6px' }}>
                                    Comment
                                </Typography>
                            </div>
                        </div>
                      </Grid>
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                        <div className="row justify-space-between align-center">
                            <div className={classes.iconRow}>
                                <Switch
                                    checked={followUp}
                                    disabled={isReadOnly}
                                    onChange={() => setFollowUp(!followUp)}
                                    value={followUp}
                                />
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 'bold', fontSize: '1rem', paddingLeft: '20px', paddingTop: '6px' }}>
                                    Ask Follow-up if Score is Below 5 (or this is default if score)
                                </Typography>
                            </div>
                        </div>
                      </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" style={{ marginTop: '1rem' }}>
                    <Button
                    color="secondary"
                    variant="contained"
                    sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                    onClick={() => handleUpdate()}
                    disabled={isLoading}
                    >
                    Save
                    </Button>
                </Grid>
              </CardContent>
            </Box>
          </Card>
        </>

        }
          
        </ThemeProvider>
      );
};

export default compose(
  connect(null, {
    updateSurveyQuestion,
    getSurveyQuestionById,
    showToast
  })
)(SurveyQuestionAnswerCard);
