import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { POST_AUTH_DESTINATION_SERVICES_FAILURE, UPDATE_AUTH_DESTINATION_SERVICES_FAILURE } from 'modules/authorizations/store/types/destinationServiceTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postAuthDestinationServices, updateAuthDestinationServices } from 'modules/authorizations/store/actions/destinationService.actions';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';

import {
  Box, Button, Card, CardContent, Checkbox, Dialog, DialogActions, Fab, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography, Divider 
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { formatDateForInput } from 'utilities/common';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));


const EditCardModal = (props) => {
  const { open, onSubmit, isLoading, onClose, destinationServices } = props;
  const classes = useStyles();

  const currencyOptions = ['$ USD', '£ British Pound', '$ CAD', '¥ Chinese Yuan', '€ Euros', '₹ Indian Rupee', '¥ Japanese Yen', '₣ Swiss Franc' ];
  const typeOfHomeOptions = ['Condo', 'CO-OP', 'Mobile Home', 'Multi Family', 'PUD', 'Single', 'Townhouse'];
  const ageOfHomeOptions = ['New', '1-5', '5-10', '10+', 'VINTAG'];
  const propertyTypeOptions = ['Single Family', 'Multi-Family', 'Condominium', 'Co-Op', 'Mobile Home', 'Other'];
  const familiarAreaOptions = ['Yes', 'No', 'Somewhat'];
  const basicPreferenceOptions = ['Yes', 'No', 'No Preference'];

  const [buyerCurrency, setBuyerCurrency] = useState(destinationServices ? destinationServices.buyerCurrency : '$ USD');
  const [renterPriceRangeMin, setRenterPriceRangeMin] = useState(destinationServices ? destinationServices.renterPriceRangeMin : '');
  const [buyerPriceRangeMin, setBuyerPriceRangeMin] = useState(destinationServices ? destinationServices.buyerPriceRangeMin : '');
  const [renterPriceRangeMax, setRenterPriceRangeMax] = useState(destinationServices ? destinationServices.renterPriceRangeMax : '');
  const [buyerPriceRangeMax, setBuyerPriceRangeMax] = useState(destinationServices ? destinationServices.buyerPriceRangeMax : '');
  const [renterNumBedrooms, setRenterNumBedrooms] = useState(destinationServices ? destinationServices.renterNumBedrooms : 0);
  const [buyerNumBedrooms, setBuyerNumBedrooms] = useState(destinationServices ? destinationServices.buyerNumBedrooms : 0);
  const [renterNumBathrooms, setRenterNumBathrooms] = useState(destinationServices ? destinationServices.renterNumBathrooms : 0);
  const [buyerNumBathrooms, setBuyerNumBathrooms] = useState(destinationServices ? destinationServices.buyerNumBathrooms : 0);
  const [leaseStartDate, setLeaseStartDate] = useState(destinationServices ? destinationServices.leaseStartDate : null);
  const [leaseEndDate] = useState(destinationServices ? destinationServices.leaseEndDate : null);
  const [leaseAmount] = useState(destinationServices ? destinationServices.leaseAmount : '');
  const [pentaltyDepositAmount, setPenaltyDepositAmount] = useState(destinationServices ? destinationServices.pentaltyDepositAmount : '');
  const [areaTour, setAreaTour] = useState(destinationServices ? destinationServices.areaTour : false);
  const [areaTourInitiationDate, setAreaTourInitiationDate] = useState(destinationServices ? destinationServices.areaTourInitiationDate : null);
  const [areaTourCanceledDate, setAreaTourCanceledDate] = useState(destinationServices ? destinationServices.areaTourCanceledDate : null);
  const [areaTourCompletedDate, setAreaTourCompletedDate] = useState(destinationServices ? destinationServices.areaTourCompletedDate : null);
  const [typeOfHome, setTypeOfHome] = useState(destinationServices ? destinationServices.typeOfHome : '');
  const [ageOfHome, setAgeOfHome] = useState(destinationServices ? destinationServices.ageOfHome : '');
  const [specialNeeds, setSpecialNeeds] = useState(destinationServices?.specialNeeds ?? '');

  const [renterDateNeeded, setRenterDateNeeded] = useState(destinationServices ? destinationServices.renterDateNeeded : null);
  const [renterAreaTourNote, setRenterAreaTourNote] = useState(destinationServices ? destinationServices.renterAreaTourNote : '');
  const [renterSchoolRequest, setRenterSchoolRequest] = useState(destinationServices ? destinationServices.renterSchoolRequest : false);
  const [renterSchoolRequestNote, setRenterSchoolRequestNote] = useState(destinationServices ? destinationServices.renterSchoolRequestNote : null);
  const [rentalSecured, setRentalSecured] = useState(destinationServices ? destinationServices.rentalSecured : false);
  const [renterCurrency, setRenterCurrency] = useState(destinationServices ? destinationServices.renterCurrency : '$ USD');

  const [buyerSquareFeet, setBuyerSquareFeet] = useState(destinationServices ? destinationServices.buyerSquareFeet : 0);
  const [buyerPropertyType, setBuyerPropertyType] = useState(destinationServices ? destinationServices.buyerPropertyType : '');
  const [buyerFamiliarArea, setBuyerFamiliarArea] = useState(destinationServices ? destinationServices.buyerFamiliarArea : '');
  const [buyerFireplacePreference, setBuyerFireplacePreference] = useState(destinationServices ? destinationServices.buyerFireplacePreference : '');
  const [buyerGaragePreference, setBuyerGaragePreference] = useState(destinationServices ? destinationServices.buyerGaragePreference : '');
  const [buyerBasementPreference, setBuyerBasementPreference] = useState(destinationServices ? destinationServices.buyerBasementPreference : '');
  const [buyerPreferredAgent, setBuyerPreferredAgent] = useState(destinationServices ? destinationServices.buyerPreferredAgent : '');
  const [buyerPreferredAgency, setBuyerPreferredAgency] = useState(destinationServices ? destinationServices.buyerPreferredAgency : '');
  const [buyerAgencyEmail, setBuyerAgencyEmail] = useState(destinationServices ? destinationServices.buyerAgencyEmail : '');
  const [buyerAgencyPhone, setBuyerAgencyPhone] = useState(destinationServices ? destinationServices.buyerAgencyPhone : '');

  const onSubmitModal = (e) => {
    e.preventDefault();

    const values = {
      buyerCurrency,
      renterPriceRangeMin,
      buyerPriceRangeMin,
      renterPriceRangeMax,
      buyerPriceRangeMax,
      renterNumBedrooms,
      buyerNumBedrooms,
      renterNumBathrooms,
      buyerNumBathrooms,
      leaseStartDate,
      leaseEndDate,
      leaseAmount,
      pentaltyDepositAmount,
      areaTour,
      areaTourInitiationDate,
      areaTourCanceledDate,
      areaTourCompletedDate,
      typeOfHome,
      ageOfHome,
      specialNeeds,
      renterDateNeeded,
      renterAreaTourNote,
      renterSchoolRequest,
      renterSchoolRequestNote,
      rentalSecured,
      renterCurrency,
      buyerSquareFeet,
      buyerPropertyType,
      buyerFamiliarArea,
      buyerFireplacePreference,
      buyerGaragePreference,
      buyerBasementPreference,
      buyerPreferredAgent,
      buyerPreferredAgency,
      buyerAgencyEmail,
      buyerAgencyPhone
    };
    onSubmit(values);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={onSubmitModal} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Tooltip title="Estimates" key="Estimates" placement="right">
              <FontAwesomeIcon icon={['fas', 'fa-truck-moving']} size="3x" />
            </Tooltip>
            <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
              {destinationServices.type === 'Renter' ? 'Renter Preferences' : 'Buyer Preferences'}
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem',
            }}
          >
            <Grid container spacing={{ xs: 2, md: 3, lg: 5 }} sx={{ padding: '30px', paddingTop: '0px' }}>
              { destinationServices.type === 'Renter' &&
                <>
                  <Grid item >
                    <div>
                      <TextField
                        key={'renterDateNeeded'}
                        label={'Date Needed'}
                        name={'renterDateNeeded'}
                        required={false}
                        disabled={false}
                        value={formatDateForInput(renterDateNeeded)}
                        sx={{ maxWidth: '360px' }}
                        margin="dense"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setRenterDateNeeded(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                        <FormControlLabel
                          key={'areaTour'}
                          name={'areaTour'}
                          label={'Area Tour'}
                          labelPlacement="start"
                          control={<Checkbox />}
                          required={false}
                          disabled={false}
                          checked={areaTour}
                          variant="standard"
                          margin="dense"
                          sx={{ marginTop: '6px', minWidth: '120px', maxWidth: '360px' }}
                          onClick={(e) => setAreaTour(e.target.checked ? true : false)}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '6px' }}>
                        <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'Service Type'}>
                          Currency
                        </InputLabel>
                        <Select
                          labelId={'Currency'}
                          key={'renterCurrency'}
                          label={'Currency'}
                          name={'renterCurrency'}
                          required={false}
                          disabled={false}
                          value={renterCurrency}
                          margin="dense"
                          variant="standard"
                          sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                          onChange={(e) => setRenterCurrency(e.target.value)}
                        >
                          {currencyOptions.map((opt) => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                              {opt}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item >
                    <Box>
                      <div className={props.classes.mb2}>
                        <Typography variant="subtitle2">
                          Min Price
                        </Typography>
                      </div>
                      <TextField
                        key={'renterPriceRangeMin'}
                        label={''}
                        name={'renterPriceRangeMin'}
                        required={false}
                        disabled={false}
                        value={renterPriceRangeMin}
                        sx={{ maxWidth: '360px' }}
                        type="text"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setRenterPriceRangeMin(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item >
                    <Box>
                    <div className={props.classes.mb2}>
                        <Typography variant="subtitle2">
                          Max Price
                        </Typography>
                      </div>
                      <TextField
                        key={'renterPriceRangeMax'}
                        label={''}
                        name={'renterPriceRangeMax'}
                        required={false}
                        disabled={false}
                        value={renterPriceRangeMax}
                        sx={{ maxWidth: '360px' }}
                        type="text"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setRenterPriceRangeMax(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <div>
                      <TextField
                        key={'renterNumBedrooms'}
                        label={'Bedroom Preference'}
                        name={'renterNumBedrooms'}
                        required={false}
                        value={renterNumBedrooms}
                        margin="dense"
                        disabled={false}
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        onChange={(e) => setRenterNumBedrooms(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <TextField
                        key={'renterNumBathrooms'}
                        label={'Bathroom Preference'}
                        name={'renterNumBathrooms'}
                        required={false}
                        value={renterNumBathrooms}
                        margin="dense"
                        disabled={false}
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        onChange={(e) => setRenterNumBathrooms(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                        <FormControlLabel
                          key={'renterSchoolRequest'}
                          name={'renterSchoolRequest'}
                          label={'School Request'}
                          labelPlacement="start"
                          control={<Checkbox />}
                          required={false}
                          disabled={false}
                          checked={renterSchoolRequest}
                          variant="standard"
                          margin="dense"
                          sx={{ marginTop: '6px', minWidth: '120px', maxWidth: '360px' }}
                          onClick={(e) => setRenterSchoolRequest(e.target.checked ? true : false)}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  {renterSchoolRequest && (
                    <Grid item >
                      <div>
                        <TextField
                          key={'renterSchoolRequestNote'}
                          label={'School Request Note'}
                          name={'renterSchoolRequestNote'}
                          required={false}
                          disabled={false}
                          value={renterSchoolRequestNote}
                          sx={{ maxWidth: '360px' }}
                          margin="dense"
                          variant="standard"
                          type="text"
                          onChange={(e) => setRenterSchoolRequestNote(e.target.value)}
                        />
                      </div>
                    </Grid>
                  )}
                  { areaTour &&
                    <>
                      <Box sx={{ width: '100%' }}>
                        <hr />
                      </Box>
                      <Typography variant="h5" sx={{ paddingLeft: '20px', marginTop: '10px', marginBottom: '10px' }}>
                        Area Tour
                      </Typography>
                      <Grid item >
                        <div>
                          <TextField
                            key={'areaTourInitiationDate'}
                            label={'Area Tour Initiation Date'}
                            name={'areaTourInitiationDate'}
                            required={false}
                            disabled={false}
                            value={formatDateForInput(areaTourInitiationDate)}
                            sx={{ maxWidth: '360px' }}
                            margin="dense"
                            variant="standard"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setAreaTourInitiationDate(e.target.value)}
                          />
                        </div>
                      </Grid>
                      <Grid item >
                        <div>
                          <TextField
                            key={'areaTourCanceledDate'}
                            label={'Area Tour Canceled Date'}
                            name={'areaTourInitiationDate'}
                            required={false}
                            disabled={false}
                            value={formatDateForInput(areaTourCanceledDate)}
                            sx={{ maxWidth: '360px' }}
                            margin="dense"
                            variant="standard"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setAreaTourCanceledDate(e.target.value)}
                          />
                        </div>
                      </Grid>
                      <Grid item >
                        <div>
                          <TextField
                            key={'areaTourCompletedDate'}
                            label={'Area Tour Completed Date'}
                            name={'areaTourCompletedDate'}
                            required={false}
                            disabled={false}
                            value={formatDateForInput(areaTourCompletedDate)}
                            sx={{ maxWidth: '360px' }}
                            margin="dense"
                            variant="standard"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setAreaTourCompletedDate(e.target.value)}
                          />
                        </div>
                      </Grid>
                      <Grid item >
                        <div>
                          <TextField
                            key={'renterAreaTourNote'}
                            label={'Area Tour Note'}
                            name={'renterAreaTourNote'}
                            required={false}
                            disabled={false}
                            value={renterAreaTourNote}
                            sx={{ maxWidth: '360px' }}
                            margin="dense"
                            variant="standard"
                            type="text"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setRenterAreaTourNote(e.target.value)}
                          />
                        </div>
                      </Grid>
                    </>
                  }
                  <Box sx={{ width: '100%', marginTop: '20px' }}>
                    <hr />
                  </Box>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                        <FormControlLabel
                          key={'rentalSecured'}
                          name={'rentalSecured'}
                          label={'Rental Secured'}
                          labelPlacement="start"
                          control={<Checkbox />}
                          required={false}
                          disabled={false}
                          checked={rentalSecured}
                          variant="standard"
                          margin="dense"
                          sx={{ marginTop: '6px', minWidth: '120px', maxWidth: '360px' }}
                          onClick={(e) => setRentalSecured(e.target.checked ? true : false)}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  {rentalSecured && (
                    <>
                      <Grid item >
                        <div>
                          <TextField
                            key={'leaseStartDate'}
                            label={'Lease Start Date'}
                            name={'leaseStartDate'}
                            required={false}
                            disabled={false}
                            value={formatDateForInput(leaseStartDate)}
                            sx={{ maxWidth: '360px' }}
                            margin="dense"
                            variant="standard"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setLeaseStartDate(e.target.value)}
                          />
                        </div>
                      </Grid>
                      <Grid item>
                        <div>
                          <TextField
                            key={'pentaltyDepositAmount'}
                            label={'Deposit'}
                            name={'pentaltyDepositAmount'}
                            required={false}
                            value={pentaltyDepositAmount}
                            disabled={false}
                            margin="dense"
                            variant="standard"
                            sx={{ maxWidth: '360px' }}
                            onChange={(e) => setPenaltyDepositAmount(e.target.value)}
                          />
                        </div>
                      </Grid>
                    </>
                  )}
                </>
              }
              { destinationServices.type === 'Buyer' &&
                <>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '6px' }}>
                        <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'Service Type'}>
                          Currency
                        </InputLabel>
                        <Select
                          labelId={'Currency'}
                          key={'buyerCurrency'}
                          label={'Currency'}
                          name={'buyerCurrency'}
                          required={false}
                          disabled={false}
                          value={buyerCurrency}
                          margin="dense"
                          variant="standard"
                          sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                          onChange={(e) => setBuyerCurrency(e.target.value)}
                        >
                          {currencyOptions.map((opt) => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                              {opt}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item >
                    <Box>
                      <div className={props.classes.mb2}>
                        <Typography variant="subtitle2">
                          Min Price
                        </Typography>
                      </div>
                      <TextField
                        key={'buyerPriceRangeMin'}
                        label={''}
                        name={'buyerPriceRangeMin'}
                        required={false}
                        disabled={false}
                        value={buyerPriceRangeMin}
                        sx={{ maxWidth: '360px' }}
                        type="text"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setBuyerPriceRangeMin(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item >
                    <Box>
                    <div className={props.classes.mb2}>
                        <Typography variant="subtitle2">
                          Max Price
                        </Typography>
                      </div>
                      <TextField
                        key={'buyerPriceRangeMax'}
                        label={''}
                        name={'buyerPriceRangeMax'}
                        required={false}
                        disabled={false}
                        value={buyerPriceRangeMax}
                        sx={{ maxWidth: '360px' }}
                        type="text"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setBuyerPriceRangeMax(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <div>
                      <TextField
                        key={'buyerNumBedrooms'}
                        label={'Bedroom Preference'}
                        name={'buyerNumBedrooms'}
                        required={false}
                        value={buyerNumBedrooms}
                        margin="dense"
                        disabled={false}
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        onChange={(e) => setBuyerNumBedrooms(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <TextField
                        key={'buyerNumBathrooms'}
                        label={'Bathroom Preference'}
                        name={'buyerNumBathrooms'}
                        required={false}
                        value={buyerNumBathrooms}
                        margin="dense"
                        disabled={false}
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        onChange={(e) => setBuyerNumBathrooms(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '6px' }}>
                        <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'Service Type'}>
                          Type of Home
                        </InputLabel>
                        <Select
                          labelId={'Type Of Home'}
                          key={'typeOfHome'}
                          label={'Type of Home'}
                          name={'typeOfHome'}
                          required={false}
                          disabled={false}
                          value={typeOfHome}
                          margin="dense"
                          variant="standard"
                          sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                          onChange={(e) => setTypeOfHome(e.target.value)}
                        >
                          {typeOfHomeOptions.map((opt) => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                              {opt}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '6px' }}>
                        <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'Service Type'}>
                          Age of Home
                        </InputLabel>
                        <Select
                          labelId={'Age of Home'}
                          key={'ageOfHome'}
                          label={'Age of Home'}
                          name={'ageOfHome'}
                          required={false}
                          disabled={false}
                          value={ageOfHome}
                          margin="dense"
                          variant="standard"
                          sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                          onChange={(e) => setAgeOfHome(e.target.value)}
                        >
                          {ageOfHomeOptions.map((opt) => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                              {opt}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <TextField
                        key={'buyerSquareFeet'}
                        label={'Square Feet'}
                        name={'buyerSquareFeet'}
                        required={false}
                        value={buyerSquareFeet}
                        margin="dense"
                        disabled={false}
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        onChange={(e) => setBuyerSquareFeet(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '6px' }}>
                        <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'Service Type'}>
                          Property Type
                        </InputLabel>
                        <Select
                          labelId={'buyerPropertyType'}
                          key={'buyerPropertyType'}
                          label={'Property Type'}
                          name={'buyerPropertyType'}
                          required={false}
                          disabled={false}
                          value={buyerPropertyType}
                          margin="dense"
                          variant="standard"
                          sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                          onChange={(e) => setBuyerPropertyType(e.target.value)}
                        >
                          {propertyTypeOptions.map((opt) => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                              {opt}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '6px' }}>
                        <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'Service Type'}>
                          Familiar with Area
                        </InputLabel>
                        <Select
                          labelId={'buyerFamiliarArea'}
                          key={'buyerFamiliarArea'}
                          label={'Property Type'}
                          name={'buyerFamiliarArea'}
                          required={false}
                          disabled={false}
                          value={buyerFamiliarArea}
                          margin="dense"
                          variant="standard"
                          sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                          onChange={(e) => setBuyerFamiliarArea(e.target.value)}
                        >
                          {familiarAreaOptions.map((opt) => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                              {opt}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '6px' }}>
                        <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'Service Type'}>
                          Fireplace Preference
                        </InputLabel>
                        <Select
                          labelId={'buyerFireplacePreference'}
                          key={'buyerFireplacePreference'}
                          label={'Fireplace Preference'}
                          name={'buyerFireplacePreference'}
                          required={false}
                          disabled={false}
                          value={buyerFireplacePreference}
                          margin="dense"
                          variant="standard"
                          sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                          onChange={(e) => setBuyerFireplacePreference(e.target.value)}
                        >
                          {basicPreferenceOptions.map((opt) => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                              {opt}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '6px' }}>
                        <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'Service Type'}>
                          Garage Preference
                        </InputLabel>
                        <Select
                          labelId={'buyerGaragePreference'}
                          key={'buyerGaragePreference'}
                          label={'Garage Preference'}
                          name={'buyerGaragePreference'}
                          required={false}
                          disabled={false}
                          value={buyerGaragePreference}
                          margin="dense"
                          variant="standard"
                          sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                          onChange={(e) => setBuyerGaragePreference(e.target.value)}
                        >
                          {basicPreferenceOptions.map((opt) => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                              {opt}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '6px' }}>
                        <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'Service Type'}>
                          Basement Preference
                        </InputLabel>
                        <Select
                          labelId={'buyerBasementPreference'}
                          key={'buyerBasementPreference'}
                          label={'Basement Preference'}
                          name={'buyerBasementPreference'}
                          required={false}
                          disabled={false}
                          value={buyerBasementPreference}
                          margin="dense"
                          variant="standard"
                          sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                          onChange={(e) => setBuyerBasementPreference(e.target.value)}
                        >
                          {basicPreferenceOptions.map((opt) => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                              {opt}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                        <FormControlLabel
                          key={'renterSchoolRequest'}
                          name={'renterSchoolRequest'}
                          label={'School Request'}
                          labelPlacement="start"
                          control={<Checkbox />}
                          required={false}
                          disabled={false}
                          checked={renterSchoolRequest}
                          variant="standard"
                          margin="dense"
                          sx={{ marginTop: '6px', minWidth: '120px', maxWidth: '360px' }}
                          onClick={(e) => setRenterSchoolRequest(e.target.checked ? true : false)}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  {renterSchoolRequest && (
                    <Grid item >
                      <div>
                        <TextField
                          key={'renterSchoolRequestNote'}
                          label={'School Request Note'}
                          name={'renterSchoolRequestNote'}
                          required={false}
                          disabled={false}
                          value={renterSchoolRequestNote}
                          sx={{ maxWidth: '360px' }}
                          margin="dense"
                          variant="standard"
                          type="text"
                          onChange={(e) => setRenterSchoolRequestNote(e.target.value)}
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item>
                      <div>  
                        <TextField
                          key={'specialNeeds'}
                          label={'Special Needs and Important Features'}
                          name={'specialNeeds'}
                          required={false}
                          disabled={false}
                          value={specialNeeds}
                          type="text"
                          margin="dense"
                          multiline
                          variant="standard"
                          rows={2}
                          sx={{ width: '550px' }}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => setSpecialNeeds(e.target.value)}
                        />
                      </div>
                    </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ margin: '20px 0' }} />
                  </Grid>
                  <Grid item>
                    <div>
                      <TextField
                        key={'buyerPreferredAgent'}
                        label={'Preferred Agent Name'}
                        name={'buyerPreferredAgent'}
                        required={false}
                        value={buyerPreferredAgent}
                        margin="dense"
                        disabled={false}
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="text"
                        onChange={(e) => setBuyerPreferredAgent(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <TextField
                        key={'buyerPreferredAgency'}
                        label={'Preferred Agency'}
                        name={'buyerPreferredAgency'}
                        required={false}
                        value={buyerPreferredAgency}
                        margin="dense"
                        disabled={false}
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="text"
                        onChange={(e) => setBuyerPreferredAgency(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <TextField
                        key={'buyerAgencyEmail'}
                        label={'Agency Email'}
                        name={'buyerAgencyEmail'}
                        required={false}
                        value={buyerAgencyEmail}
                        margin="dense"
                        disabled={false}
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="text"
                        onChange={(e) => setBuyerAgencyEmail(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <TextField
                        key={'buyerAgencyPhone'}
                        label={'Agency Phone'}
                        name={'buyerAgencyPhone'}
                        required={false}
                        value={buyerAgencyPhone}
                        margin="dense"
                        disabled={false}
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="text"
                        onChange={(e) => setBuyerAgencyPhone(e.target.value)}
                      />
                    </div>
                  </Grid>
                </>
              }
            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained">Save</Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

const DestinationServicesMainEditableCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [editingModalOpen, setEditingModalOpen] = useState(false);

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const { authId, destinationServices } = props;

  const updateFields = async (values) => {
    setIsLoading(true);

    let newVals = { ...destinationServices };
    newVals.authorizationId = authId;
    newVals.buyerCurrency = values.buyerCurrency;
    newVals.renterPriceRangeMin = values.renterPriceRangeMin;
    newVals.buyerPriceRangeMin = values.buyerPriceRangeMin;
    newVals.renterPriceRangeMax = values.renterPriceRangeMax;
    newVals.buyerPriceRangeMax = values.buyerPriceRangeMax;
    newVals.renterNumBedrooms = values.renterNumBedrooms;
    newVals.buyerNumBedrooms = values.buyerNumBedrooms;
    newVals.renterNumBathrooms = values.renterNumBathrooms;
    newVals.buyerNumBathrooms = values.buyerNumBathrooms;
    newVals.pentaltyDepositAmount = values.pentaltyDepositAmount;
    newVals.leaseStartDate = values.leaseStartDate;
    newVals.leaseEndDate = values.leaseEndDate; 
    newVals.leaseAmount =  values.leaseAmount;
    newVals.areaTour = values.areaTour;
    newVals.areaTourInitiationDate = values.areaTourInitiationDate;
    newVals.areaTourCanceledDate = values.areaTourCanceledDate;
    newVals.areaTourCompletedDate = values.areaTourCompletedDate;
    newVals.typeOfHome = values.typeOfHome;
    newVals.specialNeeds = values.specialNeeds;
    newVals.ageOfHome = values.ageOfHome;

    newVals.renterDateNeeded = values.renterDateNeeded;
    newVals.renterAreaTourNote = values.renterAreaTourNote;
    newVals.renterSchoolRequest = values.renterSchoolRequest;
    newVals.renterSchoolRequestNote = values.renterSchoolRequestNote;
    newVals.rentalSecured = values.rentalSecured;
    newVals.renterCurrency = values.renterCurrency;

    newVals.buyerSquareFeet = values.buyerSquareFeet;
    newVals.buyerPropertyType = values.buyerPropertyType;
    newVals.buyerFamiliarArea = values.buyerFamiliarArea;
    newVals.buyerFireplacePreference = values.buyerFireplacePreference;
    newVals.buyerGaragePreference = values.buyerGaragePreference;
    newVals.buyerBasementPreference = values.buyerBasementPreference;
    newVals.buyerPreferredAgent = values.buyerPreferredAgent;
    newVals.buyerPreferredAgency = values.buyerPreferredAgency;
    newVals.buyerAgencyEmail = values.buyerAgencyEmail;
    newVals.buyerAgencyPhone = values.buyerAgencyPhone;

    if (destinationServices && destinationServices?.length !== 0) {
      const resp = await props.updateAuthDestinationServices(newVals);
      if (resp.type === UPDATE_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update the employee destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true;
      }
    } else {
      const resp = await props.postAuthDestinationServices(newVals);
      if (resp.type === POST_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update employee destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const handleSaveEdits = async (values) => {
    // Send update call to parent to update and refresh values.
    const resp = await updateFields(values);
    if (!resp) {
      showToast('Failed to update card data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    } 
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ marginTop: '16px' }}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Estimates" key="Estimates" placement="right">
                  <FontAwesomeIcon icon={['fas', 'fa-truck-moving']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  {(destinationServices && destinationServices.type === 'Renter') ? 'Renter Preferences' : 'Buyer Preferences'}
                </Typography>
              </div>
              {!props.hideEdit &&
              <>
                <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                  <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                </Fab>
              </>
              }

            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={2} columns={{ xs: 4, sm: 4, md: 4, xl: 4 }}>
              { destinationServices.type === 'Renter' &&
                <>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Date Needed
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.renterDateNeeded ? new Date(destinationServices.renterDateNeeded).toLocaleDateString('en-us') : <div>&mdash;</div> }
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Area Tour
                        </Typography>
                      </div>
                      <div>
                        <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                          <FormControlLabel
                            key={'destinationServices.areaTour'}
                            name={'destinationServices.areaTour'}
                            control={<Checkbox />}
                            checked={destinationServices.areaTour}
                            disabled={true}
                            variant="standard"
                            margin="dense"
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Currency
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.renterCurrency ? destinationServices.renterCurrency : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Min Price
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.renterPriceRangeMin ? `$${destinationServices.renterPriceRangeMin}` : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div sx={{ marginLeft: '-50px', marginTop: '40px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Max Price
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.renterPriceRangeMax ? `$${destinationServices.renterPriceRangeMax}` : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Bedroom Preference
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.renterNumBedrooms ? destinationServices.renterNumBedrooms : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Bathroom Preference
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.renterNumBathrooms ? destinationServices.renterNumBathrooms : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          School Request
                        </Typography>
                      </div>
                      <div>
                        <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                          <FormControlLabel
                            key={'destinationServices.renterSchoolRequest'}
                            name={'destinationServices.renterSchoolRequest'}
                            control={<Checkbox />}
                            checked={destinationServices.renterSchoolRequest}
                            disabled={true}
                            variant="standard"
                            margin="dense"
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </Grid>
                  {destinationServices.renterSchoolRequest && (
                    <Grid item xs={2} sm={2} md={1} xl={1}>
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                            School Request Note
                          </Typography>
                        </div>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                            { destinationServices && destinationServices.renterSchoolRequestNote ? destinationServices.renterSchoolRequestNote : <div>&mdash;</div>}
                          </Typography>
                        </div>
                      </Box>
                    </Grid>
                  )}

                  { destinationServices.areaTour &&
                    <>
                      <Grid item xs={12}>
                        <Divider sx={{ margin: '20px 0' }} />
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Area Tour Initiation Date
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { destinationServices && destinationServices.areaTourInitiationDate ? new Date(destinationServices.areaTourInitiationDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Area Tour Canceled Date
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { destinationServices && destinationServices.areaTourCanceledDate ? new Date(destinationServices.areaTourCanceledDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Area Tour Completed Date
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { destinationServices && destinationServices.areaTourCompletedDate ? new Date(destinationServices.areaTourCompletedDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Area Tour Note
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { destinationServices && destinationServices.renterAreaTourNote ? destinationServices.renterAreaTourNote : <div>&mdash;</div>}
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                    </>
                  }
                  <Grid item xs={12}>
                    <Divider sx={{ margin: '20px 0' }} />
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Rental Secured
                        </Typography>
                      </div>
                      <div>
                        <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                          <FormControlLabel
                            key={'destinationServices.rentalSecured'}
                            name={'destinationServices.rentalSecured'}
                            control={<Checkbox />}
                            checked={destinationServices.rentalSecured}
                            disabled={true}
                            variant="standard"
                            margin="dense"
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </Grid>
                  {destinationServices.rentalSecured && (
                    <>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Lease Start Date
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { destinationServices && destinationServices.leaseStartDate ? new Date(destinationServices.leaseStartDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Deposit
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { destinationServices && destinationServices.pentaltyDepositAmount ? `$${destinationServices.pentaltyDepositAmount}` : <div>&mdash;</div>}
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                    </>
                  )}
                </>
              }
              { destinationServices.type && destinationServices.type === 'Buyer' &&
                <>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Currency
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerCurrency ? destinationServices.buyerCurrency : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Min Price
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerPriceRangeMin ? `$${destinationServices.buyerPriceRangeMin}` : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div sx={{ marginLeft: '-50px', marginTop: '40px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Max Price
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerPriceRangeMax ? `$${destinationServices.buyerPriceRangeMax}` : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Bedroom Preference
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerNumBedrooms ? destinationServices.buyerNumBedrooms : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Bathroom Preference
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerNumBathrooms ? destinationServices.buyerNumBathrooms : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Type of Home
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.typeOfHome ? destinationServices.typeOfHome : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Age of Home
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.ageOfHome ? destinationServices.ageOfHome : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Square Feet
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerSquareFeet ? destinationServices.buyerSquareFeet : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Property Type
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerPropertyType ? destinationServices.buyerPropertyType : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Familiar with Area
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerFamiliarArea ? destinationServices.buyerFamiliarArea : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Fireplace Preference
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerFireplacePreference ? destinationServices.buyerFireplacePreference : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Garage Preference
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerGaragePreference ? destinationServices.buyerGaragePreference : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Basement Preference
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerBasementPreference ? destinationServices.buyerBasementPreference : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          School Request
                        </Typography>
                      </div>
                      <div>
                        <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                          <FormControlLabel
                            key={'destinationServices.renterSchoolRequest'}
                            name={'destinationServices.renterSchoolRequest'}
                            control={<Checkbox />}
                            checked={destinationServices.renterSchoolRequest}
                            disabled={true}
                            variant="standard"
                            margin="dense"
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </Grid>
                  {destinationServices.renterSchoolRequest && (
                    <Grid item xs={2} sm={2} md={1} xl={1}>
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                            School Request Note
                          </Typography>
                        </div>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                            { destinationServices && destinationServices.renterSchoolRequestNote ? destinationServices.renterSchoolRequestNote : <div>&mdash;</div>}
                          </Typography>
                        </div>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Special Needs and Important Features
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.specialNeeds ? destinationServices.specialNeeds : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ margin: '20px 0' }} />
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Preferred Agent Name
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerPreferredAgent ? destinationServices.buyerPreferredAgent : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Preferred Agency
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerPreferredAgency ? destinationServices.buyerPreferredAgency : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Agency Email
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerAgencyEmail ? destinationServices.buyerAgencyEmail : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Agency Phone
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { destinationServices && destinationServices.buyerAgencyPhone ? destinationServices.buyerAgencyPhone : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                </>
              }
            </Grid>

            {destinationServices.type && destinationServices.type === 'Renter' && (
              <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginTop: '20px' }} disabled={!destinationServices.renterPriceRangeMin || !destinationServices.renterPriceRangeMax || !destinationServices.renterNumBedrooms || !destinationServices.renterNumBathrooms}> Send Rental Referral Letter </Button>
            )}

            {destinationServices.type && destinationServices.type === 'Buyer' && (
              <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginTop: '20px' }} disabled={false}> Send Agent List</Button>
            )}
          </CardContent>
        </Box>
      </Card>
      {editingModalOpen && (
        <EditCardModal
          classes={classes}
          tab={0}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={() => setEditingModalOpen(false)}
          onSubmit={handleSaveEdits}
          destinationServices={destinationServices}
        />
      )}
    </ThemeProvider>
  );

};

export default compose(
  connect(null, {
    updateAuthDestinationServices,
    postAuthDestinationServices,
    showToast,
  }),
)(DestinationServicesMainEditableCard);