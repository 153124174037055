import {
  DELETE_RECURRING_PAYABLES_SUCCESS,
  GET_RECURRING_PAYABLES_REQUEST,
  GET_RECURRING_PAYABLES_SUCCESS,
  POST_RECURRING_PAYABLES_SUCCESS,
  UPDATE_RECURRING_PAYABLES_SUCCESS
} from './recurringPayables.types';

import produce from 'immer';

const initialState = {
  recurringPayables: [],
  isLoadingRecurringPayables: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_RECURRING_PAYABLES_REQUEST:
      draft.isLoadingRecurringPayables = true;
      break;

    case GET_RECURRING_PAYABLES_SUCCESS:
      draft.isLoadingRecurringPayables = false;
      draft.recurringPayables = action.response;
      break;

    case POST_RECURRING_PAYABLES_SUCCESS:
      if (action.response) {
        draft.recurringPayables.push(action.response);
      }
      break;

    case UPDATE_RECURRING_PAYABLES_SUCCESS:
      if (action.response) {
        const index = draft.recurringPayables.findIndex(item => item.recurringPayableId === action.response.recurringPayableId);
        if (index !== -1) {
          draft.recurringPayables[index] = { ...action.response };
        }
      }
      break;

    case DELETE_RECURRING_PAYABLES_SUCCESS:
      if (action.response) {
        draft.recurringPayables = draft.recurringPayables.filter(item => item.recurringPayableId !== action.response.recurringPayableId);
      }
      break;

    default:
      break;
  }

  return draft;
}, initialState);
