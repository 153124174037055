/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@material-ui/core';
import { Grid } from '@mui/material';
import { selectDefaultAssignment } from './Utils/tempLivingUtils';
import { showToast } from 'modules/layout/layout.actions';
import { connect } from 'react-redux';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import React, { Fragment, useEffect, useState } from 'react';
import TempLivingHeader from './Components/tempLivingHeader.component';
import TempLivingStatusStepper from './Components/tempLivingStatusStepper.component';
import { useFetchDataHandler } from './useFormHandler';
import { compose } from 'recompose';
import TempLivingEditableCardForms from './Components/tempLivingEditableCardForms.component';
import { TemporaryLivingFormIds } from './TempLivingConstants';
import EditableCard from 'modules/common/editableCard.component';
import { calculateDaysRequested } from './Utils/tempLivingFormUtils';

const TempLivingView = props => {
  const { match, isReadOnly } = props;
  const details = props.authorizationDetails ? props.authorizationDetails[props.match.params.id] : null;
  const isLoading = props.isLoading;
  const temporaryLivingAssignments = details?.authTemporaryLiving?.temporaryLivingAssignments;
  const family = details?.authDetails?.family;
  const pets = details?.authDetails?.familyPets;

  const handleFetchData = useFetchDataHandler(showToast);
  const authId = match.params.id;
  const [isLoadingIconForForm, setIsLoadingIconForForm] = useState(false);
  const [selectedTempLivingAssignment, setSelectedTempLivingAssignment] = useState(selectDefaultAssignment(temporaryLivingAssignments));
  const [showComment, setShowComment] = useState(false);
  //Fetch Temp Living assignments.
  useEffect(() => {
    const fetchData = async () => {
      if (!temporaryLivingAssignments) {
        await handleFetchData(authId);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const hasAssignments = temporaryLivingAssignments?.length > 0;

    if (!hasAssignments && selectedTempLivingAssignment) {
      // If no assignments exist but one is selected (just deleted the last one), set selected to null
      setSelectedTempLivingAssignment(null);
    } else if (hasAssignments) {
      // Find the selected assignment or set the default if none is selected.
      // This is to force the Editable Card to update fields after an api call.
      const selectedAssignment =
        temporaryLivingAssignments.find(item => item?.temporaryLivingId === selectedTempLivingAssignment?.temporaryLivingId) ||
        selectDefaultAssignment(temporaryLivingAssignments);

      setSelectedTempLivingAssignment(selectedAssignment);
    }
  }, [temporaryLivingAssignments, selectedTempLivingAssignment]);

  const calculateStaticValue = fieldName => {
    if (!temporaryLivingAssignments || !Array.isArray(temporaryLivingAssignments)) return 0;
    return temporaryLivingAssignments.reduce((sum, item) => sum + (item[fieldName] || 0), 0);
  };

  const calculateDaysUsedValue = () => {
    if (!temporaryLivingAssignments || !Array.isArray(temporaryLivingAssignments)) return 0;
    return temporaryLivingAssignments.reduce((sum, item) => sum + (calculateDaysRequested(item.movedInDate, item.movedOutDate) || 0), 0);
  };

  const tempLivingSummaries = [
    { header: 'Days Allowed', type: 'number', value: calculateStaticValue('daysAllowed'), tab: 1 },
    { header: 'Total Days Used', type: 'number', value: calculateDaysUsedValue(), tab: 1 },
    { header: 'Total Exception Days', type: 'number', value: calculateStaticValue('exceptionDays'), tab: 1 }
  ];

  return (
    <>
      {isLoading ? (
        <FullscreenSpinner />
      ) : (
        <>
          {showComment && (
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1200,
                pointerEvents: 'none'
              }}
            />
          )}
          <TempLivingHeader
            match={match}
            showToast={showToast}
            setIsStatusCompleteLoading={setIsLoadingIconForForm}
            isReadOnly={isReadOnly}
            selectedTempLivingAssignment={selectedTempLivingAssignment}
            setSelectedTempLivingAssignment={setSelectedTempLivingAssignment}
            authId={authId}
            temporaryLivingAssignments={temporaryLivingAssignments}
            showComment={showComment}
            setShowComment={setShowComment}
            family={family}
            pets={pets}
          />
          {temporaryLivingAssignments && temporaryLivingAssignments.length > 0 ? (
            <Fragment>
              <TempLivingStatusStepper match={match} selectedTempLivingAssignment={selectedTempLivingAssignment} />
              <Grid container spacing={2} sx={{ padding: '20px' }}>
                {isLoadingIconForForm ? (
                  <>
                    <Grid item sm={12} md={8}>
                      <FullscreenSpinner />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item sm={12} md={12} lg={9.5} xl={9.5} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <TempLivingEditableCardForms
                        showToast={showToast}
                        isReadOnly={isReadOnly}
                        selectedTempLivingAssignment={selectedTempLivingAssignment}
                        setSelectedTempLivingAssignment={setSelectedTempLivingAssignment}
                        authId={authId}
                        formId={TemporaryLivingFormIds.Initiated}
                        pets={pets}
                        family={family}
                      />
                    </Grid>
                    <Grid item sm={12} md={12} lg={2.5} xl={2.5} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <EditableCard
                        tabs={[{ header: 'Temp Living Summary', order: 1 }]}
                        summaries={tempLivingSummaries}
                        numColumns={2}
                        hideEdit={true}
                      />
                    </Grid>
                    <Grid item sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <TempLivingEditableCardForms
                        showToast={showToast}
                        isReadOnly={isReadOnly}
                        selectedTempLivingAssignment={selectedTempLivingAssignment}
                        setSelectedTempLivingAssignment={setSelectedTempLivingAssignment}
                        authId={authId}
                        formId={TemporaryLivingFormIds.Property}
                      />
                    </Grid>
                    <Grid item sm={12} md={12} lg={6} xl={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <TempLivingEditableCardForms
                        showToast={showToast}
                        isReadOnly={isReadOnly}
                        selectedTempLivingAssignment={selectedTempLivingAssignment}
                        setSelectedTempLivingAssignment={setSelectedTempLivingAssignment}
                        authId={authId}
                        formId={TemporaryLivingFormIds.MovedInMovedOut}
                        pets={pets}
                        family={family}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Fragment>
          ) : (
            <div style={{ padding: '20px' }} />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const { authorizationDetails, isLoading } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    unmappedClients,
    isLoading
  };
};

export default compose(connect(mapStateToProps, {}))(TempLivingView);
