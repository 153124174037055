import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const SegmentInfoCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { segmentInfo } = props;

  const movePreferenceTabs = [{ header: 'Move Preferences', order: 1, iconPath: ['fas', 'suitcase'] }];

  const billToOptions = [
    { title: 'Transferee', id: 1 },
    { title: 'Client', id: 2 }
  ];

  const movePreferenceSummaries = [
    {
      header: 'Bill To',
      accessorKey: 'billTo',
      type: 'dropdown-v2',
      required: false,
      value: segmentInfo ? segmentInfo.billTo : '',
      options: billToOptions,
      tab: 1
    },
    { header: 'Shipment', accessorKey: 'shipment', type: 'text', required: false, value: segmentInfo ? segmentInfo.shipment : '', tab: 1 },
    {
      header: 'Monetary Cap',
      accessorKey: 'monetaryCap',
      type: 'checkbox',
      required: false,
      value: segmentInfo ? segmentInfo.monetaryCap : '',
      tab: 1
    },
    {
      header: 'Monetary Cap',
      accessorKey: 'monetaryCapAmount',
      type: 'money',
      required: false,
      value: segmentInfo ? segmentInfo.monetaryCapAmount : '',
      tab: 1
    },
    {
      header: 'Weight Cap',
      accessorKey: 'weightCap',
      type: 'number',
      required: false,
      value: segmentInfo ? segmentInfo.weightCap : '',
      tab: 1
    },
    {
      header: 'Pref Survey On',
      accessorKey: 'perfSurveyOn',
      type: 'date',
      required: false,
      value: segmentInfo ? segmentInfo.perfSurveyOn : '',
      tab: 1
    },
    {
      header: 'Pref Pack/Load',
      accessorKey: 'prefSurveyPackLoad',
      type: 'date',
      required: false,
      value: segmentInfo ? segmentInfo.prefSurveyPackLoad : '',
      tab: 1
    },
    {
      header: 'Delivery Goal',
      accessorKey: 'deliveryGoal',
      type: 'date',
      required: false,
      value: segmentInfo ? segmentInfo.deliveryGoal : '',
      tab: 1
    },
    {
      header: 'Client Disc Rt',
      accessorKey: 'clientDiscRt',
      type: 'text',
      required: false,
      value: segmentInfo ? segmentInfo.clientDiscRt : '',
      tab: 1
    },
    {
      header: 'Insured Amount',
      accessorKey: 'insuredAmount',
      type: 'money',
      required: false,
      value: segmentInfo ? segmentInfo.insuredAmount : '',
      tab: 1
    },
    {
      header: '# Vehicles',
      accessorKey: 'numberOfAuthorizedVehicles',
      type: 'number',
      required: false,
      value: segmentInfo ? segmentInfo.numberOfAuthorizedVehicles : '',
      tab: 1
    },
    {
      header: 'Large/Bulky Items',
      accessorKey: 'largeBulkyItems',
      type: 'notes',
      required: false,
      value: segmentInfo ? segmentInfo.largeBulkyItems : '',
      tab: 1
    },
    {
      header: 'Vendor Instructions',
      accessorKey: 'vendorInstructions',
      type: 'notes',
      required: false,
      value: segmentInfo ? segmentInfo.vendorInstructions : '',
      tab: 1
    }
  ];

  const selfMoveSummaries = [
    {
      header: 'Bill To',
      accessorKey: 'billTo',
      type: 'dropdown-v2',
      required: false,
      value: segmentInfo ? segmentInfo.billTo : '',
      options: billToOptions,
      tab: 1
    },
    {
      header: 'Monetary Cap',
      accessorKey: 'monetaryCap',
      type: 'checkbox',
      required: false,
      value: segmentInfo ? segmentInfo.monetaryCap : '',
      tab: 1
    },
    {
      header: 'Monetary Cap',
      accessorKey: 'monetaryCapAmount',
      type: 'money',
      required: false,
      value: segmentInfo ? segmentInfo.monetaryCapAmount : '',
      tab: 1
    },

    {
      header: 'Delivery Goal',
      accessorKey: 'deliveryGoal',
      type: 'date',
      required: false,
      value: segmentInfo ? segmentInfo.deliveryGoal : '',
      tab: 1
    }
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...segmentInfo };

    newValues.billTo = values.billTo;
    newValues.monetaryCap = values.monetaryCap;
    newValues.monetaryCapAmount = values.monetaryCapAmount;
    newValues.deliveryGoal = values.deliveryGoal;
    if (segmentInfo.type !== 0) {
      newValues.shipment = values.shipment;
      newValues.weightCap = values.weightCap;
      newValues.perfSurveyOn = values.perfSurveyOn;
      newValues.prefSurveyPackLoad = values.prefSurveyPackLoad;
      newValues.clientDiscRt = values.clientDiscRt;
      newValues.insuredAmount = values.insuredAmount;
      newValues.numberOfAuthorizedVehicles = values.numberOfAuthorizedVehicles;
      newValues.largeBulkyItems = values.largeBulkyItems;
      newValues.vendorInstructions = values.vendorInstructions;
    }

    if (segmentInfo && segmentInfo.length !== 0) {
      const resp = await props.updateHhgSegment(newValues);
      if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
        props.showToast('Failed to update the segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  return (
    <EditableCard
      tabs={movePreferenceTabs}
      summaries={segmentInfo && segmentInfo.type === 0 ? selfMoveSummaries : movePreferenceSummaries}
      numColumns={5}
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={props.hideEdit}
    />
  );
};

export default compose(
  connect(null, {
    updateHhgSegment,
    showToast
  })
)(SegmentInfoCard);
