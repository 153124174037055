/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Grid, Paper, Typography } from '@mui/material';
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS } from 'styles/theme';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React from 'react';
import classNames from 'classnames';
import { showToast } from 'modules/layout/layout.actions';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import { withStyles } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';

import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import { formatDateForInput } from 'utilities/common';


const styles = theme => ({
  container: {
    height: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`
    }
  },
  headerFixed: {
    zIndex: 4
  },
  estimateHeaderFixed: {
    paddingTop: '115px',
    zIndex: 2
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
});

const SurveyQuestionResponseDetails = props => {
  const { surveyQuestion, responseDetails, authorization, vendor, variation } = props;
        
      const theme = createTheme({
        palette: {
          primary: COLOR_PRIMARY,
          secondary: COLOR_SECONDARY,
          warning: COLOR_WARNING,
        },
         
      });
  
    const StyledRating = styled(Rating)(({ theme }) => ({
      '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
      },
    }));
    
    const customIcons = {
      1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" />,
        label: 'Very Dissatisfied',
      },
      2: {
        icon: <SentimentDissatisfiedIcon color="error" />,
        label: 'Dissatisfied',
      },
      3: {
        icon: <SentimentSatisfiedIcon color="warning" />,
        label: 'Neutral',
      },
      4: {
        icon: <SentimentSatisfiedAltIcon color="warning" />,
        label: 'Mostly Satisfied',
      },
      5: {
        icon: <TagFacesIcon color="success" />,
        label: 'Satisfied',
      },
      6: {
        icon: <SentimentVerySatisfiedIcon color="success" />,
        label: 'Very Satisfied',
      },
    };
    
    function IconContainer(props) {
      const { value, ...other } = props;
      return <span {...other}>{customIcons[value].icon}</span>;
    }
    let questionName = surveyQuestion ? surveyQuestion.questionName : '';
    let questionValue = responseDetails && responseDetails.result ? responseDetails.result : 3;

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box>
        { responseDetails &&
          <>
          <Paper square className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', width: '100%' }}>
              <Box sx={{ paddingLeft: '100px', paddingRight: '100px' }}>&nbsp;</Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '10px' }}>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                            Question Id
                        </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                            {responseDetails?.surveyQuestionId}
                        </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ paddingLeft: '10px' }}>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                            Authorization Id
                        </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                            {responseDetails?.authorizationId ? responseDetails.authorizationId : '' }
                        </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ paddingLeft: '10px' }}>
                    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                            Transferee Name
                        </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                            {responseDetails?.authorizationId && authorization ? authorization.transfereeName : '' }
                        </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                            Client Id
                        </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                            {responseDetails?.clientId ? responseDetails.clientId : ''}
                        </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                            Client
                        </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                            {responseDetails?.clientId && authorization ? authorization.clientName : ''}
                        </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                            Vendor Id
                        </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                            {responseDetails?.linkedVendorId ? responseDetails.linkedVendorId : ''}
                        </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                            Vendor
                        </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                            {responseDetails?.linkedVendorId && vendor ? vendor.companyName : ''}
                        </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                            Counselor Id
                        </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                            {responseDetails?.linkedCounselorId}
                        </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                            Counselor
                        </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                            
                        </Typography>
                        </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>
            <Box sx={{ padding: '16px', paddingTop: '120px' }}>
            <Paper square className={classNames(props.classes.container)} sx={{marginTop: '16px'}}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={12} xl={8} rowSpacing={2}>
                    <Grid item sx={{marginLeft: '100px'}}>
                        <div className={props.classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 'bold', fontSize: '1rem', paddingBottom: '6px' }}>
                            {questionName}
                            </Typography>
                        </div>
                        <div className={props.classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', paddingBottom: '6px' }}>
                            {variation && variation.variationName ? variation.variationName : ''}
                            </Typography>
                        </div>
                            <StyledRating
                            name="highlight-selected-only"
                            defaultValue={questionValue}
                            readOnly={true}
                            IconContainerComponent={IconContainer}
                            getLabelText={(value) => customIcons[value].label}
                            highlightSelectedOnly
                            max={6}
                            />
                        <div className={props.classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 'bold', fontSize: '1rem' }}>
                            Comments
                            </Typography>
                        </div>
                        <div className={props.classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', paddingBottom: '16px' }}>
                            {responseDetails ? responseDetails.comment : ''}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} xl={4} rowSpacing={2}>
                    <Grid item>
                    <div className={props.classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 'bold', fontSize: '1rem' }}>
                            Response Completion Date
                            </Typography>
                        </div>
                        <div className={props.classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', paddingBottom: '6px' }}>
                            {responseDetails ? formatDateForInput(responseDetails.completionDate) : <div>&mdash;</div> }
                            </Typography>
                        </div>
                        <div className={props.classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 'bold', fontSize: '1rem' }}>
                            Variation
                            </Typography>
                        </div>
                        <div className={props.classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', paddingBottom: '6px' }}>
                            {responseDetails ? responseDetails.surveyQuestionVariationName : <div>&mdash;</div> }
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                </Grid>
                </Paper>
            </Box>
            </>  
            }
      </Box>
      </ThemeProvider>
    </>
  );
};

const mapStateToProps = state => ({});

export default compose(withStyles(styles), connect(mapStateToProps, { showToast}))(SurveyQuestionResponseDetails);