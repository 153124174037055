/* eslint-disable react-hooks/exhaustive-deps */
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useEffect, useState } from 'react';

import { Box, Card, CardContent, Grid, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import { updateVoucherSummary } from 'modules/expenses/expenses.actions';
import { formatDateForInput } from 'utilities/common';
import { formatDateForField } from './utils';
import { FrequencyOptions, IsLeapYear, VoucherPaymentStatus } from 'modules/recurringPayables/recurringPayableConstants';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chipClickable: {
    cursor: 'pointer'
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important'
  },
  spacingX: {
    marginRight: theme.spacing(1)
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2)
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px'
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important'
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important'
  },
  editModalInput: {
    minWidth: '332px'
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px'
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0
  }
}));

const createDefaultRecurringPayment = dueDate => {
  return {
    voucherId: 0,
    dueDate: dueDate,
    paidStatusId: VoucherPaymentStatus.Pending
  };
};

const parseLocalDateString = dateInput => {
  if (!dateInput) return null;

  if (typeof dateInput === 'string') {
    const dateOnly = dateInput.includes('T') ? dateInput.split('T')[0] : dateInput;
    const match = dateOnly.match(/^(\d{4})-(\d{2})-(\d{2})$/);
    if (match) {
      const [, year, month, day] = match;
      return new Date(Number(year), Number(month) - 1, Number(day));
    }

    return new Date(dateInput);
  }

  if (dateInput instanceof Date) {
    return new Date(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDate());
  }

  return null;
};

const RecurringPaymentCard = props => {
  const { voucherInfo: recurringPayableInfo, isReadOnly, updateVoucherInfo, history } = props;

  const voucherDetails = recurringPayableInfo.voucherDetails || [];
  const [calculatedEnd, setCalculatedEnd] = useState(null);
  const [paymentDates, setPaymentDates] = useState(voucherDetails || []);
  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const createPaymentDates = () => {
    if (
      recurringPayableInfo &&
      recurringPayableInfo.frequencyId &&
      recurringPayableInfo.startDate &&
      recurringPayableInfo.numberOfPayments
    ) {
      let tempPaymentDates = Array.isArray(voucherDetails) ? [...voucherDetails] : [];

      const periodsPerYear = FrequencyOptions.find(fo => fo.id === recurringPayableInfo.frequencyId)?.periodsPerYear || 12;
      const numberOfDays = IsLeapYear(new Date().getFullYear()) ? 366 : 365;
      const daysPerPeriod = Math.floor(numberOfDays / periodsPerYear);

      let lastProcessedDate =
        tempPaymentDates.length > 0
          ? tempPaymentDates.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))[tempPaymentDates.length - 1].dueDate
          : recurringPayableInfo.startDate;

      let runningLastDate = new Date(parseLocalDateString(lastProcessedDate));

      const totalPaymentsNeeded = Math.max(0, parseInt(recurringPayableInfo.numberOfPayments) - tempPaymentDates.length);

      for (let i = 0; i < totalPaymentsNeeded; i++) {
        // If this isn't the very first payment, move the date forward first
        if (tempPaymentDates.length > 0 || i > 0) {
          runningLastDate.setDate(runningLastDate.getDate() + daysPerPeriod);
        }

        tempPaymentDates.push(createDefaultRecurringPayment(new Date(runningLastDate)));
      }

      tempPaymentDates.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));

      if (tempPaymentDates.length > 0) {
        const lastPayment = tempPaymentDates[tempPaymentDates.length - 1];
        if (lastPayment?.dueDate) {
          setCalculatedEnd(formatDateForField(new Date(lastPayment.dueDate)));
          updateVoucherInfo({ ...recurringPayableInfo, endDate: new Date(lastPayment.dueDate) });
        }

        setPaymentDates(tempPaymentDates);
      }
    }
  };

  useEffect(() => {
    createPaymentDates();
  }, [recurringPayableInfo.frequencyId, recurringPayableInfo.startDate, recurringPayableInfo.numberOfPayments]);

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ marginTop: '10px', marginRight: '10px' }}>
        <Box>
          <CardContent sx={{ padding: '10px', marginLeft: '10px', paddingTop: '0px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
                <Box sx={{ marginRight: '20px' }}>
                  <Box>
                    <Typography variant="body2">Frequency</Typography>
                  </Box>
                  <Box>
                    <Select
                      labelId={'frequencyOption'}
                      key={'frequencyOption'}
                      label={''}
                      name={'frequencyOption'}
                      value={recurringPayableInfo.frequencyId}
                      sx={{ maxWidth: '350px', minWidth: '180px' }}
                      disabled={isReadOnly}
                      onChange={e => updateVoucherInfo({ ...recurringPayableInfo, frequencyId: e.target.value })}
                    >
                      {FrequencyOptions.map(opt => (
                        <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                          {opt.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                  <Box>
                    <Typography variant="body2">Start Date</Typography>
                  </Box>
                  <TextField
                    key={'startDate'}
                    label={''}
                    name={'startDate'}
                    required={false}
                    disabled={false}
                    value={formatDateForInput(recurringPayableInfo.startDate)}
                    sx={{ maxWidth: '360px' }}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    onChange={e =>
                      updateVoucherInfo({
                        ...recurringPayableInfo,
                        startDate: e.target.value,
                        entryDate: e.target.value,
                        invoiceDate: e.target.value,
                        dueDate: e.target.value,
                        payrollDate: e.target.value
                      })
                    }
                  />
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                  <Box>
                    <Typography variant="body2">Number of Payments</Typography>
                  </Box>
                  <TextField
                    label=""
                    name="numberOfPayments"
                    required={true}
                    value={recurringPayableInfo.numberOfPayments}
                    sx={{ maxWidth: '170px' }}
                    type="number"
                    onChange={e => updateVoucherInfo({ ...recurringPayableInfo, numberOfPayments: e.target.value })}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
                <Box>
                  <Box>
                    <Typography variant="body2">End Date</Typography>
                  </Box>
                  <TextField
                    key={'endDate'}
                    label={''}
                    name={'endDate'}
                    required={false}
                    disabled={true}
                    value={calculatedEnd}
                    sx={{ maxWidth: '360px' }}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Box>
              <Box sx={{ paddingTop: '20px' }}>
                <Paper style={{ minWidth: '600px', maxWidth: '600px', maxHeight: '100px', overflowY: 'scroll' }}>
                  <Grid container>
                    {(() => {
                      if (!paymentDates || !Array.isArray(paymentDates)) return;

                      let sortedPayments = [...paymentDates].sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));

                      let lastProcessedIndex = sortedPayments
                        .map((p, index) =>
                          p.paidStatusId === VoucherPaymentStatus.Failed || p.paidStatusId === VoucherPaymentStatus.Paid ? index : -1
                        )
                        .filter(index => index !== -1)
                        .pop();

                      let nextPaymentIndex = sortedPayments.findIndex(
                        (p, index) =>
                          p.paidStatusId === VoucherPaymentStatus.Pending &&
                          (lastProcessedIndex === undefined || index > lastProcessedIndex)
                      );

                      return sortedPayments.map((payment, index) => {
                        let statusText =
                          payment.paidStatusId === VoucherPaymentStatus.Paid
                            ? '(Paid)'
                            : payment.paidStatusId === VoucherPaymentStatus.Failed
                            ? '(Failed)'
                            : nextPaymentIndex === index
                            ? '(Next)'
                            : '(Pending)';

                        return (
                          <Grid item md={6} key={index}>
                            <Box
                              sx={{
                                padding: '10px',
                                color:
                                  payment.paidStatusId === VoucherPaymentStatus.Paid
                                    ? '#41B7C0'
                                    : payment.paidStatusId === VoucherPaymentStatus.Failed
                                    ? 'red'
                                    : nextPaymentIndex === index
                                    ? 'green'
                                    : undefined,
                                minWidth: '35%',
                                cursor: payment.voucherId !== 0 ? 'pointer' : 'default',
                                '&:hover': {
                                  textDecoration: payment.voucherId !== 0 ? 'underline' : 'none'
                                }
                              }}
                              onClick={() => {
                                if (payment.voucherId !== 0) {
                                  history.push(`/vouchers/${payment.voucherId}`);
                                }
                              }}
                            >
                              {`Payment ${new Date(payment.dueDate).toLocaleDateString()} ${statusText}`}
                            </Box>
                          </Grid>
                        );
                      });
                    })()}
                  </Grid>
                </Paper>
              </Box>
            </Box>
          </CardContent>
        </Box>
      </Card>
    </ThemeProvider>
  );
};

export default compose(
  connect(null, {
    updateVoucherSummary,
    showToast
  })
)(RecurringPaymentCard);
