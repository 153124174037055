/* eslint-disable no-case-declarations */
import { GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_SUCCESS } from 'modules/surveys/surveys.types';

const handleSurveyResponsesReducer = (draft, action) => {
  if (draft.currentAuthId && !draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId] = {};
  if (draft.currentAuthId && !draft.authorizationDetails[draft.currentAuthId].surveyQuestionResponses)
    draft.authorizationDetails[draft.currentAuthId].surveyQuestionResponses = [];

  switch (action.type) {
    case GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].surveyQuestionResponses = action.response;
      break;

    default:
      break;
  }
  return draft;
};

export default handleSurveyResponsesReducer;
