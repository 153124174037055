/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import HouseholdGoods from './householdGoods.component';

import {
  getAddress,
  getAuthClientPolicies,
  getAuthDateSummary,
  getAuthorizationSummary,
  getCommunicationInfo,
  getDepartureAddress,
  getDestinationAddress,
  getEmployeeInfo,
  getFamilyMembers,
  getFamilyPets,
  getFinanceAndTax,
  getServices,
  getTeam,
  getUserSettings,
  setAuthId,
  setLoadingComplete
} from '../../store/actions/authorizations.actions';

import { getHhgSegments, getHhgVehicles, postHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';

import { getVendorContacts, getVendors } from 'modules/authorizations/store/actions/dataManagement.actions';

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import {
  fetchAuthDetails,
  fetchAuthHouseholdGoodsDetails,
  fetchVendorContacts,
  fetchVendors
} from 'modules/authorizations/utils/fetchDataDetailsService';
import { showToast } from 'modules/layout/layout.actions';

const styles = theme => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  }
});

const HouseholdGoodsView = props => {
  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const details = props.authorizationDetails ? props.authorizationDetails[props.match.params.id] : null;
  const clientInfo =
    details && details?.authDetails?.summary && props.unmappedClients
      ? props.unmappedClients.find(e => e.description === details?.authDetails?.summary.clientName)
      : null;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const id = props.match.params.id;

      window.scrollTo(0, 0);
      props.setAuthId(id);
      setIsLoading(true);

      await fetchAuthDetails(details, id, props);
      await fetchVendors(props?.vendors, props);
      await fetchVendorContacts(props?.vendorContacts, props);
      await fetchAuthHouseholdGoodsDetails(details, id, props);

      setIsLoading(false);
    }

    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && details && clientInfo && (
          <HouseholdGoods
            details={details}
            clientInfo={clientInfo}
            id={props.match.params.id}
            history={props.history}
            location={props.location}
          />
        )}
        {isLoading && <FullscreenSpinner />}
      </Box>
    </ThemeProvider>
  );
};

HouseholdGoodsView.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { authorizationDetails, isLoading, vendors, vendorContacts } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    unmappedClients,
    isLoading,
    vendors,
    vendorContacts
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getEmployeeInfo,
    getCommunicationInfo,
    getAuthClientPolicies,
    setAuthId,
    setLoadingComplete,
    getAuthDateSummary,
    getDepartureAddress,
    getDestinationAddress,
    getTeam,
    getServices,
    getUserSettings,
    getHhgSegments,
    getVendors,
    getVendorContacts,
    getFamilyMembers,
    getFamilyPets,
    postHhgSegment,
    getHhgVehicles,
    showToast,
    getAddress,
    getFinanceAndTax
  })
)(HouseholdGoodsView);
