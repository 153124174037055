/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import React, { useEffect, useState } from 'react';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { getSurveyQuestions, setSurveyLoadingComplete, getSurveyQuestionTriggers, getSurveyQuestionVariations, setQuestionId } from '../surveys.actions';
import { GET_SURVEY_QUESTIONS_FAILURE, GET_SURVEY_QUESTION_TRIGGERS_FAILURE, GET_SURVEY_QUESTION_VARIATIONS_FAILURE } from '../surveys.types';
import SurveyQuestionDetails from './surveyQuestionDetailsView.component';

const styles = theme => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  }
});

const SurveyQuestionDetailView = props => {
  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const questionDetails = props.surveyQuestions ? props.surveyQuestions.find(sq => sq.surveyQuestionId === parseInt(props.match.params.surveyQuestionId)) : null;
  const triggers = props.surveyQuestionTriggers ? props.surveyQuestionTriggers : null;
  const variations = props.surveyQuestionVariations ? props.surveyQuestionVariations : null;

  const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
     async function fetchData () {
      setIsLoading(true);
      const id = props.match.params.surveyQuestionId;

      props.setQuestionId(id);

       if (!props.surveyQuestions) {
         let { type } = await props.getSurveyQuestions();
         if (type === GET_SURVEY_QUESTIONS_FAILURE) {
             this.props.showToast('Failed to retrieve survey questions, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
         }
       }
       if (!props.surveyQuestionTriggers) {
        let { triggersType } = await props.getSurveyQuestionTriggers(id);
        if (triggersType === GET_SURVEY_QUESTION_TRIGGERS_FAILURE) {
            this.props.showToast('Failed to retrieve survey question triggers, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }
      if (!props.surveyQuestionVariations) {
        let { variationsType } = await props.getSurveyQuestionVariations(id);
        if (variationsType === GET_SURVEY_QUESTION_VARIATIONS_FAILURE) {
            this.props.showToast('Failed to retrieve survey question variations, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

       setIsLoading(false);
     }

     fetchData();

   }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && questionDetails && <Box sx={{ padding: '30px' }}><SurveyQuestionDetails surveyQuestionDetails={questionDetails} surveyQuestionTriggers={triggers} surveyQuestionVariations={variations} /></Box>}
        {isLoading && <FullscreenSpinner />}
      </Box>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
    const {
        surveys: { surveyQuestions, surveyQuestionTriggers, surveyQuestionVariations, isLoading }
    } = state;
    return { surveyQuestions, surveyQuestionTriggers, surveyQuestionVariations, isLoading };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getSurveyQuestions,
    setSurveyLoadingComplete,
    getSurveyQuestionTriggers,
    getSurveyQuestionVariations,
    setQuestionId,
  })
)(SurveyQuestionDetailView);
