/* eslint-disable no-useless-computed-key */
import { Box, MenuItem, TextField, withStyles } from '@material-ui/core';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Fragment, PureComponent } from 'react';
import memoize from 'memoize-one';

//import { TOAST_MESSAGE_SEVERITY_ERROR } from '../layout/types';
import { getTestApiV2, setPageTitle, showToast } from 'modules/layout/layout.actions';
import Log from 'utilities/log';

import { TOAST_MESSAGE_SEVERITY_ERROR } from '../layout/types';
import { getVendors, postVendor } from 'modules/authorizations/store/actions/dataManagement.actions';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import { Button } from '@mui/material';
import { getAuthorizationSummaries } from 'modules/authorizations/store/actions/authorizations.actions';
import SurveyQuestionsGrid from './surveyQuestionsGrid.component';
import { getSurveyQuestions } from './surveys.actions';
import { GET_SURVEY_QUESTIONS_FAILURE } from './surveys.types';

const styles = (theme) => ({
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 100,
  },
});

class SurveyQuestionsView extends PureComponent {
    state = {
        isLoading: false,
        selectedStatuses: -1,
        selectedStatusName: '',
        selectedType: -1,
        selectedTypeName: null,
        selectedStatus: 1,
        selectedService: null,
        surveyQuestions: this.props.surveyQuestions,
      };

  async componentDidMount() {
    this.props.setPageTitle('Survey Questions');

    this.setState({ isLoading: true });

    if (!this.props.surveyQuestions) {
      let { type, response } = await this.props.getSurveyQuestions();
      if (type === GET_SURVEY_QUESTIONS_FAILURE) {
        this.props.showToast('Failed to retrieve survey questions, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        this.setState({surveyQuestions: response.filter((sq) => sq.status === this.state.selectedStatus) });
      }
    }

    this.setState({ isLoading: false });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleVendorTypeChange = (vendorTypes) => (event) => {
    let type = vendorTypes.filter((s) => s.id === event.target.value);
    this.setState({
      selectedType: type[0].id,
      selectedTypeName: type[0].title,
    });
  };

  filterSummaries = memoize((vendors, selectedType, selectedTypeName, selectedStatuses, selectedStatusName) => {
    return vendors.filter((summary) => {
      return (selectedType === -1 || (selectedTypeName.length !== 0 && selectedTypeName.includes(summary.vendorType))) &&
        (selectedStatuses === -1 || (summary.status !== null && summary.status === selectedStatusName));
    });
  });

  render() {
    Log.trace('RENDER', 'VendorsView');
    const { surveyQuestions, selectedStatus, selectedService } = this.state;
    const { classes, history, isLoading } = this.props;

    const statusOptions = [
        { title: 'Active', id: 1 },
        { title: 'Inactive', id: 2 },
    ];

    const serviceOptions = [
        { title: 'Household Goods', id: 1 },
        { title: 'Homesale', id: 2 },
        { title: 'Destination Services', id: 3 },
        { title: 'Candidate Services', id: 4 },
        { title: 'Global', id: 5 },
        { title: 'Temporary Living', id: 6 },
        { title: 'Tax Assistance', id: 7 },
    ];

    return (
      <Fragment>
        <div className={classes.headerContainer}>
          <TextField
            select
            label="Status"
            className={classes.textField}
            value={selectedStatus}
            onChange={(e) => this.setState({ selectedStatus: e.target.value, surveyQuestions: this.props.surveyQuestions.filter((sq) => sq.status === e.target.value) }) }
            SelectProps={{
              autoWidth: true,
            }}
            variant="outlined"
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Service"
            className={classes.textField}
            value={selectedService}
            onChange={(e) => this.setState({ selectedService: e.target.value, surveyQuestions: e.target.value !== -1 ? this.props.surveyQuestions.filter((sq) => sq.primaryTriggerService === e.target.value) : this.props.surveyQuestions.filter((sq) => sq.status === selectedStatus) })}
            SelectProps={{
              autoWidth: true,
            }}
            variant="outlined"
          >
            <MenuItem value={-1}>
              <em>All Services</em>
            </MenuItem>
            {serviceOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Box sx={{padding: '10px', display: 'flex', flexDirection: 'row'}}>

            </Box>
          <Button color="secondary" variant="contained" disabled={true} sx={{ maxHeight: '40px', marginRight: '10px', marginTop: '8px', backgroundColor: '#5D9878', '&:hover': { backgroundColor: '#528569' } }} onClick={() => console.log('Create Question Clicked')}> Create Question </Button>
        </Box>
        { surveyQuestions && 
          <>
            {this.state.isLoading && <FullscreenSpinner/>}
            {!this.state.isLoading && (
              <div className="px-2">
                <SurveyQuestionsGrid
                  surveyQuestions={surveyQuestions ?? []}
                  isLoading={isLoading}
                  history={history}
                />
              </div>
            )}
            </>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    surveys: { surveyQuestions, isLoading }
  } = state;
  return { surveyQuestions, isLoading };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
    showToast,
    getTestApiV2,
    getAuthorizationSummaries,
    getVendors,
    postVendor,
    getSurveyQuestions
  }),
)(SurveyQuestionsView);