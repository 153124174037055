/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import image1 from 'assets/images/receipt-template-us-modern-red-750px.png';
import image2 from 'assets/images/receipt-template-us-mono-black-750px.png';
import image3 from 'assets/images/receipt-template-us-neat-750px.png';

import { Box, Button, Typography, Modal } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AttachmentModal = props => {
  const { displayText, isReadOnly, displayButton } = props;
  const [open, setOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const images = [image1, image2, image3];

  const handleImageClick = index => {
    if (isReadOnly) {
      return;
    }
    setSelectedImages(prevSelectedImages => {
      if (prevSelectedImages.includes(index)) {
        return prevSelectedImages.filter(i => i !== index);
      } else {
        return [...prevSelectedImages, index];
      }
    });
  };

  return (
    <div>
      { !displayButton &&
      <>
      <Typography
        variant="body2"
        component="span"
        style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
        onClick={handleOpen}
      >
        {displayText}
      </Typography>
      </>
      }
      { displayButton &&
        <Button
          onClick={handleOpen}
          color="secondary"
          variant="contained"
          sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
        >
          Attachments
        </Button>

      }
      <Modal open={open} onClose={handleClose} aria-labelledby="attachment-modal-title" aria-describedby="attachment-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            height: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
            <FontAwesomeIcon icon={['fas', 'file-contract']} size="2x" />
            <Typography id="attachment-modal-title" variant="h6" component="h2">
              {!isReadOnly ? <div>Add Attatchments</div> : <div>Attatchments</div>}
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ mt: 1, mb: 2, color: 'text.secondary' }}>
            {!isReadOnly ? (
              <div>Select from the attachments below or upload a new one ({selectedImages.length} Selected) </div>
            ) : (
              <div>Images Selected</div>
            )}
          </Typography>

          <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {images.map((src, index) => (
              <Box
                key={index}
                onClick={() => handleImageClick(index)}
                sx={{
                  border: selectedImages.includes(index) ? '2px solid blue' : '2px solid transparent',
                  borderRadius: '4px',
                  padding: '4px',
                  cursor: isReadOnly ? 'default' : 'pointer'
                }}
              >
                <img src={src} alt={`attachment-${index}`} style={{ width: '100px', height: '100px' }} />
              </Box>
            ))}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              variant="contained"
              onClick={() => {
                // Logic for adding attachment
              }}
              sx={{ marginRight: 1 }}
              disabled={isReadOnly}
            >
              Add Attachment
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                // Logic for saving selection
                handleClose();
              }}
              disabled={isReadOnly}
            >
              Save Selection
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AttachmentModal;
