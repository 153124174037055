export const ServiceTypes = [
    {label: 'Homesale', value: 0},
    {label: 'Global', value: 1},
    {label: 'Destination Services', value: 2},
    {label: 'Candidate Services', value: 3},
    {label: 'Temporary Living', value: 4},
    {label: 'Household Goods', value: 5},
];

export const FieldTypes = [
    {label: 'Initated Date', value: 'Initiated Date'},
    {label: 'Completed Date', value: 'Completed Date'},
    {label: 'Canceled Date', value: 'Canceled Date'},
];

export const ValueTypes = [
    {label: 'Contains Data', value: 'Contains Data'},
    {label: 'Enumerable', value: 'Enumerable'},
];

export const Clients = [
    {label: 'All Clients', value: 'All Clients'},
    {label: 'ACE Hardware', value: 'ACE Hardware'},
    {label: 'Allegheny Technologies, Inc.', value: 'Allegheny Technologies, Inc.'},
    {label: 'Barry Callebaut U.S.A. LLC.', value: 'Barry Callebaut U.S.A. LLC.'},
    {label: 'Calgon Carbon - A Kuraray Company', value: 'Calgon Carbon - A Kuraray Company'},
    {label: 'Cleaver Brooks Inc.', value: 'Cleaver Brooks Inc.'},
    {label: 'DSW Inc.', value: 'DSW Inc.'},
    {label: 'Brookfield Properties - Retail', value: 'Brookfield Properties - Retail'},
    {label: 'Gilbane Company', value: 'Gilbane Company'},
    {label: 'Komatsu Mining Corp', value: 'Komatsu Mining Corp'},
    {label: 'Johnson Outdoors', value: 'Johnson Outdoors'},
    {label: 'Koppers Inc.', value: 'Koppers Inc.'},
    {label: 'Metal Technologies Inc.', value: 'Metal Technologies Inc.'},
    {label: 'Old Republic Surety Company', value: 'Old Republic Surety Company'},
    {label: 'Paradies Lagardere', value: 'Paradies Lagardere'},
    {label: 'Sargento Foods Inc.', value: 'Sargento Foods Inc.'},
    {label: 'Southwire Company', value: 'Southwire Company'},
    {label: 'Black Hills Corporation', value: 'Black Hills Corporation'},
    {label: 'Excellus Inc.', value: 'Excellus Inc.'},
    {label: 'SPS Technologies', value: 'SPS Technologies'},
    {label: 'Inland Truck Parts Co.', value: 'Inland Truck Parts Co.'},
    {label: 'Crown Castle International', value: 'Crown Castle International'},
    {label: 'PennEngineering & Mfg Corp', value: 'PennEngineering & Mfg Corp.'},
    {label: 'United Steel Corp', value: 'United Steel Corp'},
    {label: 'Valero Energy Corporation', value: 'Valero Energy Corporation'},
    {label: 'Flexible Steel Lacing Company', value: 'Flexible Steel Lacing Company'},
    {label: 'Lincoln National Corporation', value: 'Lincoln National Corporation'},
    {label: 'Greenheck Fan Corporation', value: 'Greenheck Fan Corporation'},
    {label: 'Nustar Energy L.P.', value: 'Nustar Energy L.P.'},
    {label: 'Charter Manufacturing Co. Inc.', value: 'Charter Manufacturing Co. Inc.'},
    {label: 'DNRP', value: 'DNRP'},
    {label: 'Securitas Security Services USA Inc.', value: 'Securitas Security Services USA Inc.'},
    {label: 'GSA-FAS Cont Mgt (9FQ)', value: 'GSA-FAS Cont Mgt (9FQ)'},
    {label: 'Plexus Corp', value: 'Plexus Corp'},
    {label: 'Cambrex Corporation', value: 'Cambrex Corporation'},
    {label: 'US Venture Inc.', value: 'US Venture Inc.'},
    {label: 'Sandia National Laboratories', value: 'Sandia National Laboratories'},
    {label: 'Dematic Corp.', value: 'Dematic Corp.'},
    {label: 'Value Source', value: 'Value Source'},
    {label: 'Thomson Reuters Holdings Inc', value: 'Thomson Reuters Holdings Inc'},
    {label: 'Louis Vuitton North America Inc', value: 'Louis Vuitton North America Inc'},
    {label: 'Critical Mass Inc', value: 'Critical Mass Inc'},
    {label: 'Ellsworth Adhesives', value: 'Ellsworth Adhesives'},
    {label: 'KLA Corporation', value: 'KLA Corporation'},
    {label: 'Vitamin Shoppe Industries Inc.', value: 'Vitamin Shoppe Industries Inc.'},
    {label: 'PPM America', value: 'PPM America'},
    {label: 'Stearns Lending Inc', value: 'Sterns Lending Inc'},
    {label: 'Fincantieri Marine Group LLC', value: 'Fincantieri Marine Group LLC'},
    {label: 'Petro Harvester Operating Company', value: 'Petro Harvester Operating Company'},
    {label: 'Acushnet Company', value: 'Acushnet Company'},
    {label: 'Greystar', value: 'Greystar'},
    {label: 'Spirax-Sarco', value: 'Spirax-Sarco'},
    {label: 'Armacell LLC', value: 'Armacell LLC'},
    {label: 'TrisMas Corporation', value: 'TrisMas Corporation'},
    {label: 'International Capital & Management Co. LLC', value: 'International Capital & Management Co. LLC'},
    {label: 'High Liner Foods', value: 'High Liner Foods'},
    {label: 'Aceto Corporation', value: 'Aceto Corporation'},
    {label: 'TRCGS Referral Tracking', value: 'TRCGS Referral Tracking'},
    {label: 'SiteOne Landscape Supply LLC', value: 'SiteOne Landscape Supply LLC'},
    {label: 'Cracker Barrel Old Country Store, Inc.', value: 'Cracker Barrel Old Country Store, Inc.'},
    {label: 'Sensient Technologies Corporation', value: 'Sensient Technologies Corporation'},
    {label: 'Oak Ridge National Laboratory', value: 'Oak Ridge National Laboratory'},
    {label: 'Briggs & Stratton', value: 'Briggs & Stratton'},
    {label: 'ABC Supply Company', value: 'ABC Supply Company'},
    {label: 'Generac Power Systems', value: 'Generac Power Systems'},


    {label: 'TRC Internal', value: 'TRC Internal'},
];