import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowRight,
  faArrowsSplitUpAndLeft,
  faBan,
  faBars,
  faBook,
  faCalendarDays,
  faCamera,
  faCartFlatbedSuitcase,
  faCheckCircle,
  faChevronDown,
  faCircle,
  faClipboard,
  faComment,
  faConciergeBell,
  faDatabase,
  faDollarSign,
  faExclamationCircle,
  faExclamationTriangle,
  faFileContract,
  faFileDownload,
  faFileImport,
  faFileInvoice,
  faGear,
  faHistory,
  faHotel,
  faHouse,
  faHouseFlag,
  faHouseUser,
  faIdCard,
  faKey,
  faLandmark,
  faMagnifyingGlass,
  faMoneyBill,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
  faMoneyBills,
  faMoneyCheckAlt,
  faMoneyCheckDollar,
  faPassport,
  faPen,
  faPenSquare,
  faPeopleArrows,
  faPerson,
  faPersonWalkingLuggage,
  faPhone,
  faPlane,
  faPlaneDeparture,
  faPowerOff,
  faQuestionCircle,
  faRightToBracket,
  faSackDollar,
  faSuitcase,
  faSuitcaseRolling,
  faSyringe,
  faTruckMoving,
  faWeightScale,
  faXmark,
  faAddressBook,
  faTrash,
  faCopy,
  faCircleInfo,
  faComments,
  faCircleXmark,
  faCircleDown,
  faEarthOceania,
  faCircleArrowRight,
  faStroopwafel,
  faFileInvoiceDollar,
  faFlag,
  faGears,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import { faBallot, faCar, faDog, faExternalLink, faSpinnerThird, faUser, faUserFriends } from '@fortawesome/pro-solid-svg-icons';
import { faIdBadge } from '@fortawesome/free-regular-svg-icons';
import { faLightbulbOn, faPaperPlane, faPlus, faPlusCircle, faStickyNote, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

const buildIconLibrary = () => {
  library.add(
    faIdBadge,
    faPlus,
    faPlusCircle,
    faPowerOff,
    faCircle,
    faCheckCircle,
    faExclamationCircle,
    faExclamationTriangle,
    faSpinnerThird,
    faTrashAlt,
    faKey,
    faLightbulbOn,
    faFileImport,
    faStickyNote,
    faFileDownload,
    faCamera,
    faTruckMoving,
    faBallot,
    faPenSquare,
    faPen,
    faChevronDown,
    faHistory,
    faConciergeBell,
    faPaperPlane,
    faQuestionCircle,
    faUser,
    faUserFriends,
    faExternalLink,
    faRightToBracket,
    faDatabase,
    faHouse,
    faGear,
    faClipboard,
    faComment,
    faArrowRight,
    faFileContract,
    faPeopleArrows,
    faPhone,
    faBars,
    faMagnifyingGlass,
    faMoneyBills,
    faXmark,
    faBan,
    faCalendarDays,
    faSuitcase,
    faCartFlatbedSuitcase,
    faHouseUser,
    faMoneyCheckDollar,
    faPerson,
    faPersonWalkingLuggage,
    faCar,
    faBook,
    faWeightScale,
    faArrowsSplitUpAndLeft,
    faPassport,
    faSyringe,
    faPlane,
    faSuitcaseRolling,
    faHouseFlag,
    faFileInvoice,
    faMoneyBillTransfer,
    faDog,
    faMoneyBillTrendUp,
    faMoneyBill,
    faSackDollar,
    faLandmark,
    faDollarSign,
    faIdCard,
    faPlaneDeparture,
    faHotel,
    faMoneyCheckAlt,
    faAddressBook,
    faTrash,
    faCopy,
    faCircleInfo,
    faComments,
    faCircleXmark,
    faCircleDown,
    faEarthOceania,
    faCircleArrowRight,
    faStroopwafel,
    faFileInvoiceDollar,
    faFlag,
    faGears,
    faThumbsUp,
  );
};

export default buildIconLibrary;