import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';

import { POST_CANDIDATE_SERVICES_FAILURE, UPDATE_CANDIDATE_SERVICES_FAILURE } from 'modules/authorizations/store/types/canidateTripTypes';
import { postCandidateServices, updateCandidateServices } from 'modules/authorizations/store/actions/canidateTrip.actions';
import {
  Box, Button, Card, CardContent, Checkbox, Dialog, DialogActions, Fab, FormControl, FormControlLabel, Grid, Stack, TextField, Tooltip, Typography, Divider 
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { formatDateForInput } from 'utilities/common';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';
import AdvancedSearch from 'modules/common/advancedSearch.component';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));


const EditCardModal = (props) => {
  const { open, onSubmit, isLoading, onClose, candidateServices, brokerAgents } = props;
  const classes = useStyles();

  const [initiationDate, setInitiationDate] = useState(candidateServices ? candidateServices.initiationDate : null);
  const [interviewStartDate, setInterviewStartDate] = useState(candidateServices ? candidateServices.interviewStartDate : null);
  const [interviewEndDate, setInterviewEndDate] = useState(candidateServices ? candidateServices.interviewEndDate : null);
  const [areaTour, setAreaTour] = useState(candidateServices ? candidateServices.areaTour : false);
  const [areaTourInitiationDate, setAreaTourInitiationDate] = useState(candidateServices ? candidateServices.areaTourInitiatedDate : null);
  const [areaTourCanceledDate, setAreaTourCanceledDate] = useState(candidateServices ? candidateServices.areaTourCanceledDate : null);
  const [areaTourCompletedDate, setAreaTourCompletedDate] = useState(candidateServices ? candidateServices.areaTourCompletedDate : null);
  const [areaTourAgentName, setAreaTourAgentName] = useState(candidateServices ? candidateServices.areaTourAgentName : null);
  const [areaTourAgentId, setAreaTourAgentId] = useState(candidateServices ? candidateServices.areaTourAgentId : 0);
  const [notes, setNotes] = useState(candidateServices ? candidateServices.notes : '');

  const brokerAgentSearchInfo = {
    searchingColumns: [{ name: 'typeOfAgent', title: 'Type of Agent' }, { name: 'agentName', title: 'Agent Name' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }],
    searchingColumnExtensions: [{ columnName: 'typeOfAgent', width: 150 }, { columnName: 'agentName', width: 200 }, { columnName: 'companyName', width: 300 }, { columnName: 'city', width: 150 }, { columnName: 'state', width: 150 }],
    searchingRows: brokerAgents,
    nameKey: 'agentName',
    idKey: 'brokerId',
  };

  const onSubmitModal = (e) => {
    e.preventDefault();

    const values = {
      initiationDate,
      interviewStartDate,
      interviewEndDate,
      areaTour,
      areaTourInitiationDate,
      areaTourCanceledDate,
      areaTourCompletedDate,
      areaTourAgentName,
      areaTourAgentId,
      notes
    };
    onSubmit(values);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={onSubmitModal} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Tooltip title="Estimates" key="Estimates" placement="right">
              <FontAwesomeIcon icon={['fas', 'fa-id-card']} size="3x" />
            </Tooltip>
            <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
              Candidate Services Overview
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem',
            }}
          >
            <Grid container spacing={{ xs: 2, md: 3, lg: 5 }} sx={{ padding: '30px', paddingTop: '0px' }}>
                  <Grid item >
                    <div>
                      <TextField
                        key={'initiationDate'}
                        label={'Initiation Date'}
                        name={'initiationDate'}
                        required={false}
                        disabled={false}
                        value={formatDateForInput(initiationDate)}
                        sx={{ maxWidth: '360px' }}
                        margin="dense"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setInitiationDate(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item >
                    <div>
                      <TextField
                        key={'interviewStartDate'}
                        label={'Interview Start Date'}
                        name={'interviewStartDate'}
                        required={false}
                        disabled={false}
                        value={formatDateForInput(interviewStartDate)}
                        sx={{ maxWidth: '360px' }}
                        margin="dense"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setInterviewStartDate(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item >
                    <div>
                      <TextField
                        key={'interviewEndDate'}
                        label={'Interview End Date'}
                        name={'interviewEndDate'}
                        required={false}
                        disabled={false}
                        value={formatDateForInput(interviewEndDate)}
                        sx={{ maxWidth: '360px' }}
                        margin="dense"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setInterviewEndDate(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                        <FormControlLabel
                          key={'areaTour'}
                          name={'areaTour'}
                          label={'Area Tour'}
                          labelPlacement="start"
                          control={<Checkbox />}
                          required={false}
                          disabled={false}
                          checked={areaTour}
                          variant="standard"
                          margin="dense"
                          sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                          onClick={(e) => setAreaTour(e.target.checked ? true : false)}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  { areaTour &&
                    <>
                      <Box sx={{ width: '100%' }}>
                        <hr />
                      </Box>
                      <Typography variant="h5" sx={{ paddingLeft: '20px', marginTop: '10px', marginBottom: '10px' }}>
                        Area Tour
                      </Typography>
                      <Grid item >
                        <div>
                          <TextField
                            key={'areaTourInitiationDate'}
                            label={'Area Tour Initiation Date'}
                            name={'areaTourInitiationDate'}
                            required={false}
                            disabled={false}
                            value={formatDateForInput(areaTourInitiationDate)}
                            sx={{ maxWidth: '360px' }}
                            margin="dense"
                            variant="standard"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setAreaTourInitiationDate(e.target.value)}
                          />
                        </div>
                      </Grid>
                      <Grid item >
                        <div>
                          <TextField
                            key={'areaTourCanceledDate'}
                            label={'Area Tour Canceled Date'}
                            name={'areaTourInitiationDate'}
                            required={false}
                            disabled={false}
                            value={formatDateForInput(areaTourCanceledDate)}
                            sx={{ maxWidth: '360px' }}
                            margin="dense"
                            variant="standard"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setAreaTourCanceledDate(e.target.value)}
                          />
                        </div>
                      </Grid>
                      <Grid item >
                        <div>
                          <TextField
                            key={'areaTourCompletedDate'}
                            label={'Area Tour Completed Date'}
                            name={'areaTourCompletedDate'}
                            required={false}
                            disabled={false}
                            value={formatDateForInput(areaTourCompletedDate)}
                            sx={{ maxWidth: '360px' }}
                            margin="dense"
                            variant="standard"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setAreaTourCompletedDate(e.target.value)}
                          />
                        </div>
                      </Grid>
                    <Grid item >
                        <div>
                            <AdvancedSearch 
                                labelText={'Area Tour Agent'} 
                                displayValueAccessorKey={'agentName'} 
                                saveIdValueAccessorKey={'brokerId'} 
                                searchingCriteria={brokerAgentSearchInfo.searchingColumns} 
                                searchingCriteriaExtensions={brokerAgentSearchInfo.searchingColumnExtensions} 
                                searchingRows={brokerAgents} 
                                value={areaTourAgentName} 
                                id={areaTourAgentId}
                                setIdValue={(id, val) => { setAreaTourAgentName(val); setAreaTourAgentId(id);}}
                            />
                        </div>
                      </Grid>
                    </>
                  }
                  <Grid item>
                      <div>  
                        <TextField
                          key={'notes'}
                          label={'Notes'}
                          name={'notes'}
                          required={false}
                          disabled={false}
                          value={notes}
                          type="text"
                          margin="dense"
                          multiline
                          variant="standard"
                          rows={2}
                          sx={{ width: '550px' }}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => setNotes(e.target.value)}
                        />
                      </div>
                    </Grid>

            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained">Save</Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

const CandidateServicesOverviewEditableCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [editingModalOpen, setEditingModalOpen] = useState(false);

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const { authId, candidateServices, brokerAgents } = props;

  const updateFields = async (values) => {
    setIsLoading(true);

    let newVals = { ...candidateServices };
    newVals.authorizationId = authId;
    newVals.initiationDate = values.initiationDate;
    newVals.interviewStartDate = values.interviewStartDate;
    newVals.interviewEndDate = values.interviewEndDate;
    newVals.areaTour = values.areaTour;
    newVals.areaTourInitiatedDate = values.areaTourInitiationDate;
    newVals.areaTourCanceledDate = values.areaTourCanceledDate;
    newVals.areaTourCompletedDate = values.areaTourCompletedDate;
    newVals.areaTourAgentId = values.areaTourAgentId;
    newVals.areaTourAgentName = values.areaTourAgentName;
    newVals.notes = values.notes;

    if (candidateServices && candidateServices?.length !== 0) {
      const resp = await props.updateCandidateServices(newVals);
      if (resp.type === UPDATE_CANDIDATE_SERVICES_FAILURE) {
        props.showToast('Failed to update the employee candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true;
      }
    } else {
      const resp = await props.postCandidateServices(newVals);
      if (resp.type === POST_CANDIDATE_SERVICES_FAILURE ) {
        props.showToast('Failed to update employee candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const handleSaveEdits = async (values) => {
    // Send update call to parent to update and refresh values.
    const resp = await updateFields(values);
    if (!resp) {
      showToast('Failed to update card data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    } 
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ marginTop: '16px' }}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Estimates" key="Estimates" placement="right">
                  <FontAwesomeIcon icon={['fas', 'fa-id-card']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Candidate Services Overview
                </Typography>
              </div>
              {!props.hideEdit &&
              <>
                <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                  <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                </Fab>
              </>
              }

            </div>
          </CardContent>
          { candidateServices &&
          <>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={2} columns={{ xs: 4, sm: 4, md: 4, xl: 4 }}>
                <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Initiation Date
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { candidateServices && candidateServices.initiationDate ? new Date(candidateServices.initiationDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Interview Start Date
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { candidateServices && candidateServices.interviewStartDate ? new Date(candidateServices.interviewStartDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Interview End Date
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { candidateServices && candidateServices.interviewEndDate ? new Date(candidateServices.interviewEndDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Area Tour
                        </Typography>
                      </div>
                      <div>
                        <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                          <FormControlLabel
                            key={'candidateServices.areaTour'}
                            name={'candidateServices.areaTour'}
                            control={<Checkbox />}
                            checked={candidateServices.areaTour ? candidateServices.areaTour : false }
                            disabled={true}
                            variant="standard"
                            margin="dense"
                          />
                        </FormControl>
                      </div>
                    </Box>
                  </Grid>
                  { candidateServices.areaTour &&
                    <>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Area Tour Initiation Date
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { candidateServices && candidateServices.areaTourInitiatedDate ? new Date(candidateServices.areaTourInitiatedDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Area Tour Canceled Date
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { candidateServices && candidateServices.areaTourCanceledDate ? new Date(candidateServices.areaTourCanceledDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                          <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Area Tour Completed Date
                            </Typography>
                          </div>
                          <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { candidateServices && candidateServices.areaTourCompletedDate ? new Date(candidateServices.areaTourCompletedDate).toLocaleDateString('en-us') : <div>&mdash;</div> }
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                        <Box>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Area Tour Agent
                            </Typography>
                        </div>
                        <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                            { candidateServices && candidateServices.areaTourAgentName ? candidateServices.areaTourAgentName : <div>&mdash;</div>}
                            </Typography>
                        </div>
                        </Box>
                    </Grid>
                    </>
                  }
                  <Grid item xs={12}>
                    <Divider sx={{ margin: '20px 0' }} />
                  </Grid>
                  
                  <Grid item xs={2} sm={2} md={1} xl={1}>
                    <Box>
                      <div className={classes.mb2}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                          Notes
                        </Typography>
                      </div>
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                          { candidateServices && candidateServices.notes ? candidateServices.notes : <div>&mdash;</div>}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
            </Grid>
          </CardContent>
          </>
          }
        </Box>
      </Card>
      {editingModalOpen && (
        <EditCardModal
          classes={classes}
          tab={0}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={() => setEditingModalOpen(false)}
          onSubmit={handleSaveEdits}
          candidateServices={candidateServices}
          brokerAgents={brokerAgents}
        />
      )}
    </ThemeProvider>
  );

};

export default compose(
  connect(null, {
    updateCandidateServices,
    postCandidateServices,
    showToast,
  }),
)(CandidateServicesOverviewEditableCard);