/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box, Button, Checkbox, Dialog, DialogActions, FormControl, FormControlLabel, Grid, MenuItem, Select, TextField, Typography,
} from '@mui/material';
import {
  Card,
  CardContent,
  Fab,
  Stack,
  Tooltip,
} from '@mui/material';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { calculateTripDuration } from 'modules/authorizations/utils';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getTravelBookingRequestsById, getTravelBookingRequestsByTrip, postTravelBookingRequest, updateTravelBookingRequest, deleteTravelBookingRequest, updateCandidateTrip } from 'modules/authorizations/store/actions/canidateTrip.actions';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';
import { UPDATE_CANDIDATE_TRIP_FAILURE, UPDATE_TRAVEL_BOOKING_REQUESTS_FAILURE } from 'modules/authorizations/store/types/canidateTripTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { formatDateForInput } from 'utilities/common';
import { makeStyles } from '@material-ui/styles';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import Spinner from 'modules/common/spinner.component';
import AttachmentModal from 'modules/common/AttatchmentModal.component';
  
const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));

  const EditCardModal = (props) => {
    
    const { open, onSubmit, isLoading, onClose, tripInfo, isRoundTrip } = props;
    const classes = useStyles();

    const rentalCarClassOptions = ['Economy', 'Compact Sedan', 'Midsize Sedan', 'Full Size Sedan', 'Compact SUV', 'Full Size SUV', 'Other'];

    const [airTransport, setAirTransport] = useState(tripInfo ? tripInfo.airTransport : false);
    const [airTransportDetpartureDate, setAirTransportDepartureDate] = useState(tripInfo ? tripInfo.airTransportDetpartureDate : null);
    const [airTransportArrivalDate, setAirTransportArrivalDate] = useState(tripInfo ? tripInfo.airTransportArrivalDate : null);
    const [airTransportReturnDepartureDate, setAirTransportReturnDepartureDate] = useState(tripInfo ? tripInfo.airTransportReturnDepartureDate : null);
    const [airTransportReturnArrivalDate, setAirTransportReturnArrivalDate] = useState(tripInfo ? tripInfo.airTransportReturnArrivalDate : null);

    const [hotelBooking, setHotelBooking] = useState(tripInfo ? tripInfo.hotelBooking : false);
    const [hotelName, setHotelName] = useState(tripInfo ? tripInfo.hotelName : null);
    const [hotelAddress, setHotelAddress] = useState(tripInfo ? tripInfo.hotelAddress : null);
    const [hotelCheckIn, setHotelCheckIn] = useState(tripInfo ? tripInfo.hotelCheckIn : null);
    const [hotelCheckOut, setHotelCheckOut] = useState(tripInfo ? tripInfo.hotelCheckOut : null);
    const [hotelDurationOfStay, setHotelDurationOfStay] = useState(tripInfo ? tripInfo.hotelDurationOfStay : null);

    const [rentalCar, setRentalCar] = useState(tripInfo ? tripInfo.rentalCarBooking : false);
    const [otherRentalCar, setOtherRentalCar] = useState(tripInfo ? tripInfo.otherRentalCar : '');
    const [rentalCarClass, setRentalCarClass] = useState(tripInfo ? tripInfo.rentalCarClass : null);
    const [rentalCarStartDate, setRentalCarStartDate] = useState(tripInfo ? tripInfo.rentalCarStartDate : null);
    const [rentalCarEndDate, setRentalCarEndDate] = useState(tripInfo ? tripInfo.rentalCarEndDate : null);
    const [rentalCarDuration, setRentalCarDuration] = useState(tripInfo ? tripInfo.rentalCarDuration : null);
  
    const onSubmitModal = (e) => {
      e.preventDefault();
  
      const values = {
        airTransport,
        airTransportDetpartureDate,
        airTransportArrivalDate,
        airTransportReturnDepartureDate,
        airTransportReturnArrivalDate,
        hotelBooking,
        hotelName,
        hotelAddress,
        hotelCheckIn,
        hotelCheckOut,
        hotelDurationOfStay,
        rentalCar,
        rentalCarClass,
        otherRentalCar,
        rentalCarStartDate,
        rentalCarEndDate,
        rentalCarDuration,
      };
      onSubmit(values);
    };


    return (
        <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
        <form onSubmit={onSubmitModal} fullScreen>
          <div className="row justify-space-between align-center">
            <div className={classes.dialogIconRow}>
              <Tooltip title="Travel Request" key="tripInfo" placement="right">
                <FontAwesomeIcon icon={['fas', 'fa-truck-moving']} size="3x" />
              </Tooltip>
              <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
                Booking Details
              </Typography>
            </div>
          </div>
          <div className={classes.dialogContentContainer}>
            <Stack
              sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
                gap: '1.5rem',
              }}
            >
              <Grid container spacing={{ xs: 2, md: 3, lg: 5 }} sx={{ padding: '30px', paddingTop: '0px' }}>
                    <Grid item >
                      <Box>
                      <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                        <FormControlLabel
                          key={'airTransport'}
                          name={'airTransport'}
                          label={'Air Transport'}
                          labelPlacement="start"
                          control={<Checkbox />}
                          required={false}
                          disabled={false}
                          checked={airTransport}
                          variant="bold"
                          margin="dense"
                          sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px', marginRight: '20px' }}
                          onClick={(e) => setAirTransport(e.target.checked ? true : false)}
                        />
                      </FormControl>
                      </Box>
                    </Grid>
                    { airTransport &&
                        <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                        <Grid item >
                            <Box>
                            <div className={props.classes.mb2}>
                                <Typography variant="subtitle2">
                                    Departure Date
                                </Typography>
                                </div>
                                <TextField
                                key={'airTransportDetpartureDate'}
                                label={''}
                                name={'airTransportDetpartureDate'}
                                required={false}
                                disabled={false}
                                value={formatDateForInput(airTransportDetpartureDate)}
                                sx={{ maxWidth: '360px', marginRight: '20px' }}
                                type="date"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => setAirTransportDepartureDate(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item >
                            <Box>
                            <div className={props.classes.mb2}>
                                <Typography variant="subtitle2">
                                    Arrival Date
                                </Typography>
                                </div>
                                <TextField
                                key={'airTransportArrivalDate'}
                                label={''}
                                name={'airTransportArrivalDate'}
                                required={false}
                                disabled={false}
                                value={formatDateForInput(airTransportArrivalDate)}
                                sx={{ maxWidth: '360px', marginRight: '20px' }}
                                type="date"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => setAirTransportArrivalDate(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        { isRoundTrip &&
                        <>
                          <Grid item >
                            <Box>
                            <div className={props.classes.mb2}>
                                <Typography variant="subtitle2">
                                    Return Departure Date
                                </Typography>
                                </div>
                                <TextField
                                key={'airTransportReturnDepartureDate'}
                                label={''}
                                name={'airTransportReturnDepartureDate'}
                                required={false}
                                disabled={false}
                                value={formatDateForInput(airTransportReturnDepartureDate)}
                                sx={{ maxWidth: '360px', marginRight: '20px' }}
                                type="date"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => setAirTransportReturnDepartureDate(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item >
                            <Box>
                            <div className={props.classes.mb2}>
                                <Typography variant="subtitle2">
                                    Return Arrival Date
                                </Typography>
                                </div>
                                <TextField
                                key={'airTransportReturnArrivalDate'}
                                label={''}
                                name={'airTransportReturnArrivalDate'}
                                required={false}
                                disabled={false}
                                value={formatDateForInput(airTransportReturnArrivalDate)}
                                sx={{ maxWidth: '360px', marginRight: '20px' }}
                                type="date"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => setAirTransportReturnArrivalDate(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        </>}
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <hr />
                        </Box>
                        </>
                    }
                    <Grid item >
                      <Box>
                      <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                        <FormControlLabel
                          key={'hotelBooking'}
                          name={'hotelBooking'}
                          label={'Hotel Booking'}
                          labelPlacement="start"
                          control={<Checkbox />}
                          required={false}
                          disabled={false}
                          checked={hotelBooking}
                          variant="bold"
                          margin="dense"
                          sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px', marginRight: '20px' }}
                          onClick={(e) => setHotelBooking(e.target.checked ? true : false)}
                        />
                      </FormControl>
                      </Box>
                    </Grid>
                    { hotelBooking &&
                        <>
                          <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                            <Grid item>
                                <div>
                                    <TextField
                                    key={'hotelName'}
                                    label={'Hotel Name'}
                                    name={'hotelName'}
                                    required={false}
                                    value={hotelName}
                                    margin="dense"
                                    disabled={false}
                                    sx={{ maxWidth: '360px', marginRight: '20px' }}
                                    variant="standard"
                                    type="text"
                                    onChange={(e) => setHotelName(e.target.value)}
                                    />
                                </div>
                            </Grid>
                            <Grid item>
                                <div>
                                    <TextField
                                    key={'hotelAddress'}
                                    label={'Hotel Address'}
                                    name={'hotelAddress'}
                                    required={false}
                                    value={hotelAddress}
                                    margin="dense"
                                    disabled={false}
                                    sx={{ maxWidth: '360px', marginRight: '20px' }}
                                    variant="standard"
                                    type="text"
                                    onChange={(e) => setHotelAddress(e.target.value)}
                                    />
                                </div>
                            </Grid>
                            <Grid item >
                                <Box>
                                <div className={props.classes.mb2}>
                                    <Typography variant="subtitle2">
                                        Hotel Check-In Date
                                    </Typography>
                                    </div>
                                    <TextField
                                    key={'hotelCheckIn'}
                                    label={''}
                                    name={'hotelCheckIn'}
                                    required={false}
                                    disabled={false}
                                    value={formatDateForInput(hotelCheckIn)}
                                    sx={{ maxWidth: '360px', marginRight: '20px' }}
                                    type="date"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setHotelCheckIn(e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item >
                                <Box>
                                <div className={props.classes.mb2}>
                                    <Typography variant="subtitle2">
                                        Hotel Check-Out Date
                                    </Typography>
                                    </div>
                                    <TextField
                                    key={'hotelCheckOut'}
                                    label={''}
                                    name={'hotelCheckOut'}
                                    required={false}
                                    disabled={false}
                                    value={formatDateForInput(hotelCheckOut)}
                                    sx={{ maxWidth: '360px', marginRight: '20px' }}
                                    type="date"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setHotelCheckOut(e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item>
                                <div>
                                    <TextField
                                    key={'hotelDurationOfStay'}
                                    label={'Duration Of Stay'}
                                    name={'hotelDurationOfStay'}
                                    required={false}
                                    value={hotelDurationOfStay ? hotelDurationOfStay : (calculateTripDuration(hotelCheckIn, hotelCheckOut) > 0 ? calculateTripDuration(hotelCheckIn, hotelCheckOut) : 0)}
                                    margin="dense"
                                    disabled={false}
                                    sx={{ maxWidth: '360px', marginRight: '20px' }}
                                    variant="standard"
                                    type="number"
                                    onChange={(e) => setHotelDurationOfStay(e.target.value)}
                                    />
                                </div>
                            </Grid>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                              <hr />
                            </Box>
                        </>
                    }
                    <Grid item >
                      <Box>
                      <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                        <FormControlLabel
                          key={'rentalCar'}
                          name={'rentalCar'}
                          label={'Rental Car'}
                          labelPlacement="start"
                          control={<Checkbox />}
                          required={false}
                          disabled={false}
                          checked={rentalCar}
                          variant="bold"
                          margin="dense"
                          sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px', marginRight: '20px' }}
                          onClick={(e) => setRentalCar(e.target.checked ? true : false)}
                        />
                      </FormControl>
                      </Box>
                    </Grid>
                    { rentalCar &&
                      <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px'}}>
                          <Grid item>
                              <div>
                              <div className={props.classes.mb2}>
                                <Typography variant="subtitle2">
                                    Rental Car Class
                                </Typography>
                                </div>
                              <Select
                                  key={'rentalCarClass'}
                                  label={'Rental Car Class'}
                                  name={'payPeriodProcessing'}
                                  value={rentalCarClass}
                                  defaultValue={rentalCarClassOptions[0]}
                                  sx={{ maxWidth: '360px', minWidth: '180px', marginRight: '20px' }}
                                  onChange={(e) =>
                                  setRentalCarClass(e.target.value)
                                  }
                              >
                                  {rentalCarClassOptions.map((opt) => (
                                  <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                                      {opt}
                                  </MenuItem>
                                  ))}
                              </Select>
                              </div>
                          </Grid>
                          { rentalCarClass === 'Other' &&
                          <>
                            <Grid item >
                              <Box>
                              <div className={props.classes.mb2}>
                                  <Typography variant="subtitle2">
                                      Please Specify Rental Car Class
                                  </Typography>
                                  </div>
                                  <TextField
                                  key={'otherRentalCar'}
                                  label={''}
                                  name={'otherRentalCar'}
                                  required={false}
                                  disabled={false}
                                  value={otherRentalCar}
                                  sx={{ maxWidth: '360px',  marginRight: '20px'  }}
                                  type="text"
                                  variant="standard"
                                  InputLabelProps={{ shrink: true }}
                                  onChange={(e) => setOtherRentalCar(e.target.value)}
                                  />
                              </Box>
                          </Grid>
                          </>
                          }
                          <Grid item >
                              <Box>
                              <div className={props.classes.mb2}>
                                  <Typography variant="subtitle2">
                                      Rental Car Start Date
                                  </Typography>
                                  </div>
                                  <TextField
                                  key={'rentalCarStartDate'}
                                  label={''}
                                  name={'rentalCarStartDate'}
                                  required={false}
                                  disabled={false}
                                  value={formatDateForInput(rentalCarStartDate)}
                                  sx={{ maxWidth: '360px',  marginRight: '20px'  }}
                                  type="date"
                                  variant="standard"
                                  InputLabelProps={{ shrink: true }}
                                  onChange={(e) => setRentalCarStartDate(e.target.value)}
                                  />
                              </Box>
                          </Grid>
                          <Grid item >
                              <Box>
                              <div className={props.classes.mb2}>
                                  <Typography variant="subtitle2">
                                      Rental Car End Date
                                  </Typography>
                                  </div>
                                  <TextField
                                  key={'rentalCarEndDate'}
                                  label={''}
                                  name={'rentalCarEndDate'}
                                  required={false}
                                  disabled={false}
                                  value={formatDateForInput(rentalCarEndDate)}
                                  sx={{ maxWidth: '360px',  marginRight: '20px'  }}
                                  type="date"
                                  variant="standard"
                                  InputLabelProps={{ shrink: true }}
                                  onChange={(e) => setRentalCarEndDate(e.target.value)}
                                  />
                              </Box>
                          </Grid>
                          <Grid item>
                              <div>
                                  <TextField
                                  key={'rentalCarDuration'}
                                  label={'Duration Of Rental'}
                                  name={'rentalCarDuration'}
                                  required={false}
                                  value={rentalCarDuration ? rentalCarDuration : (calculateTripDuration(rentalCarStartDate, rentalCarEndDate) > 0 ? calculateTripDuration(rentalCarStartDate, rentalCarEndDate) : 0)}
                                  margin="dense"
                                  disabled={false}
                                  sx={{ maxWidth: '360px',  marginRight: '20px'  }}
                                  variant="standard"
                                  type="number"
                                  onChange={(e) => setRentalCarDuration(e.target.value)}
                                  />
                              </div>
                          </Grid>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <hr />
                          </Box>
                      </>
                    }
                    <Grid item md={2}>
                      <AttachmentModal displayText={'View Attachment'} />
                    </Grid> 
              </Grid>
            </Stack>
          </div>
          {isLoading && (
            <DialogActions sx={{ p: '1.25rem' }}>
              <Spinner />
            </DialogActions>
          )}
          {!isLoading && (
            <DialogActions sx={{ p: '1.25rem' }}>
              <Button onClick={onClose}>Cancel</Button>
              <Button color="primary" type="submit" variant="contained">Save</Button>
            </DialogActions>
          )}
        </form>
      </Dialog>  
    );
    
  };

  const TravelRequestCard = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [editingModalOpen, setEditingModalOpen] = useState(false);

      const classes = useStyles();
    
      const theme = createTheme({
        palette: {
          primary: COLOR_PRIMARY,
          secondary: COLOR_SECONDARY,
          warning: COLOR_WARNING,
        },
      });
    
    const { authId, tripInfo, tripId, isIntern, roundTrip, updateData } = props;

  const updateFields = async (values) => {
    setIsLoading(true);

    let newVals = {...tripInfo};
    newVals.authorizationId = authId;
    newVals.tripId = tripId;
    newVals.airTransport = values.airTransport;
    newVals.airTransportDetpartureDate = values.airTransportDetpartureDate;
    newVals.airTransportArrivalDate = values.airTransportArrivalDate;
    newVals.airTransportReturnDepartureDate = values.airTransportReturnDepartureDate;
    newVals.airTransportReturnArrivalDate = values.airTransportReturnArrivalDate;
    newVals.hotelBooking = values.hotelBooking;
    newVals.hotelName = values.hotelName;
    newVals.hotelAddress = values.hotelAddress;
    newVals.hotelCheckIn = values.hotelCheckIn;
    newVals.hotelCheckOut = values.hotelCheckOut;
    newVals.hotelDurationOfStay = values.hotelDurationOfStay;
    newVals.rentalCarBooking = values.rentalCar;
    newVals.otherRentalCar = values.otherRentalCar;
    newVals.rentalCarClass = values.rentalCarClass; 
    newVals.rentalCarStartDate =  values.rentalCarStartDate;
    newVals.rentalCarEndDate = values.rentalCarEndDate;
    newVals.rentalCarDuration = values.rentalCarDuration;

    if (tripInfo && !isIntern) {
      const resp = await props.updateCandidateTrip(newVals);
      if (resp.type === UPDATE_CANDIDATE_TRIP_FAILURE) {
        props.showToast('Failed to update candidate trip info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else if (tripInfo && isIntern) {
      const resp = await props.updateTravelBookingRequest(newVals);
      if (resp.type === UPDATE_TRAVEL_BOOKING_REQUESTS_FAILURE) {
        props.showToast('Failed to update intern trip info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        updateData(resp.response);
        setIsLoading(false);
        return true; 
      }
    }
  };

    const handleSaveEdits = async (values) => {
      // Send update call to parent to update and refresh values.
      const resp = await updateFields(values);
      if (!resp) {
        showToast('Failed to update card data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      } 
      setEditingModalOpen(false); //required to exit editing mode and close modal
    };   

    return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ marginTop: '16px' }}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Booking Details" key="TravelRequest" placement="right">
                  <FontAwesomeIcon icon={['fas', 'fa-truck-moving']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Booking Details
                </Typography>
              </div>
                <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                  <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                </Fab>
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            {tripInfo &&
                <>
                  <Grid container spacing={2} columns={{ xs: 4, sm: 4, md: 4, xl: 4 }}>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                      <Box>
                          <div className={classes.mb2}>
                          <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Air Transport
                          </Typography>
                          </div>
                          <div className={classes.mb2}>
                          <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                          <FormControlLabel
                              key={'airTransport'}
                              name={'airTransport'}
                              control={<Checkbox />}
                              checked={tripInfo && tripInfo?.airTransport ? tripInfo.airTransport : false}
                              disabled={true}
                              variant="standard"
                              margin="dense"
                          />
                          </FormControl>
                          </div>
                      </Box>
                      </Grid>
                      { tripInfo.airTransport &&
                      <>
                          <Grid item xs={2} sm={2} md={1} xl={1}>
                              <Box>
                              <div sx={{ marginLeft: '-50px', marginTop: '40px' }}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Departure Date
                                  </Typography>
                              </div>
                              <div className={classes.mb2}>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                  { tripInfo && tripInfo.airTransportDetpartureDate ? formatDateForInput(tripInfo.airTransportDetpartureDate) : <div>&mdash;</div>}
                                  </Typography>
                              </div>
                              </Box>
                          </Grid>
                          <Grid item xs={2} sm={2} md={1} xl={1}>
                              <Box>
                              <div sx={{ marginLeft: '-50px', marginTop: '40px' }}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Arrival Date
                                  </Typography>
                              </div>
                              <div className={classes.mb2}>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                  { tripInfo && tripInfo.airTransportArrivalDate ? formatDateForInput(tripInfo.airTransportArrivalDate) : <div>&mdash;</div>}
                                  </Typography>
                              </div>
                              </Box>
                          </Grid>
                          { roundTrip &&
                          <>
                            <Grid item xs={2} sm={2} md={1} xl={1}>
                              <Box>
                              <div sx={{ marginLeft: '-50px', marginTop: '40px' }}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Return Departure Date
                                  </Typography>
                              </div>
                              <div className={classes.mb2}>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                  { tripInfo && tripInfo.airTransportReturnDepartureDate ? formatDateForInput(tripInfo.airTransportReturnDepartureDate) : <div>&mdash;</div>}
                                  </Typography>
                              </div>
                              </Box>
                          </Grid>
                          <Grid item xs={2} sm={2} md={1} xl={1}>
                              <Box>
                              <div sx={{ marginLeft: '-50px', marginTop: '40px' }}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Return Arrival Date
                                  </Typography>
                              </div>
                              <div className={classes.mb2}>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                  { tripInfo && tripInfo.airTransportReturnArrivalDate ? formatDateForInput(tripInfo.airTransportReturnArrivalDate) : <div>&mdash;</div>}
                                  </Typography>
                              </div>
                              </Box>
                          </Grid>
                          </>
                          }
                          <Box sx={{ width: '100%' }}>
                            <hr />
                          </Box>
                      </>
                      }
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                      <Box>
                          <div className={classes.mb2}>
                          <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Hotel Booking
                          </Typography>
                          </div>
                          <div className={classes.mb2}>
                          <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                          <FormControlLabel
                              key={'hotelBooking'}
                              name={'hotelBooking'}
                              control={<Checkbox />}
                              checked={tripInfo && tripInfo.hotelBooking ? tripInfo.hotelBooking : false}
                              disabled={true}
                              variant="standard"
                              margin="dense"
                          />
                          </FormControl>
                          </div>
                      </Box>
                      </Grid>
                      { tripInfo.hotelBooking &&
                      <>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                          <Box>
                          <div className={classes.mb2}>
                              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Hotel Name
                              </Typography>
                          </div>
                          <div className={classes.mb2}>
                              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { tripInfo && tripInfo.hotelName ? tripInfo.hotelName : <div>&mdash;</div>}
                              </Typography>
                          </div>
                          </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                          <Box>
                          <div className={classes.mb2}>
                              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Hotel Address
                              </Typography>
                          </div>
                          <div className={classes.mb2}>
                              <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                              { tripInfo && tripInfo.hotelAddress ? tripInfo.hotelAddress : <div>&mdash;</div>}
                              </Typography>
                          </div>
                          </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                              <Box>
                              <div sx={{ marginLeft: '-50px', marginTop: '40px' }}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Hotel Check-In Date
                                  </Typography>
                              </div>
                              <div className={classes.mb2}>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                  { tripInfo && tripInfo.hotelCheckIn ? formatDateForInput(tripInfo.hotelCheckIn) : <div>&mdash;</div>}
                                  </Typography>
                              </div>
                              </Box>
                          </Grid>
                          <Grid item xs={2} sm={2} md={1} xl={1}>
                              <Box>
                              <div sx={{ marginLeft: '-50px', marginTop: '40px' }}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Hotel Check-Out Date
                                  </Typography>
                              </div>
                              <div className={classes.mb2}>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                  { tripInfo && tripInfo.hotelCheckOut ? formatDateForInput(tripInfo.hotelCheckOut) : <div>&mdash;</div>}
                                  </Typography>
                              </div>
                              </Box>
                          </Grid>
                          <Grid item xs={2} sm={2} md={1} xl={1}>
                              <Box>
                              <div sx={{ marginLeft: '-50px', marginTop: '40px' }}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Hotel Duration of Stay
                                  </Typography>
                              </div>
                              <div className={classes.mb2}>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                  { calculateTripDuration(tripInfo.hotelCheckIn, tripInfo.hotelCheckOut) > 0 ? calculateTripDuration(tripInfo.hotelCheckIn, tripInfo.hotelCheckOut) : <div>&mdash;</div>}
                                  </Typography>
                              </div>
                              </Box>
                          </Grid>
                          <Box sx={{ width: '100%' }}>
                            <hr />
                          </Box>
                      </>
                      }
                      <Grid item xs={2} sm={2} md={1} xl={1}>
                      <Box>
                          <div className={classes.mb2}>
                          <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                              Rental Car
                          </Typography>
                          </div>
                          <div className={classes.mb2}>
                          <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                          <FormControlLabel
                              key={'rentalCar'}
                              name={'rentalCar'}
                              control={<Checkbox />}
                              checked={tripInfo && tripInfo.rentalCarBooking ? tripInfo.rentalCarBooking : false}
                              disabled={true}
                              variant="standard"
                              margin="dense"
                          />
                          </FormControl>
                          </div>
                      </Box>
                      </Grid>
                      { tripInfo.rentalCarBooking &&
                      <>
                          <Grid item xs={2} sm={2} md={1} xl={1}>
                              <Box>
                              <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Rental Car Class
                                  </Typography>
                              </div>
                              <div className={classes.mb2}>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                  { tripInfo && tripInfo.rentalCarClass ? tripInfo.rentalCarClass : <div>&mdash;</div>}
                                  </Typography>
                              </div>
                              </Box>
                          </Grid>
                          { tripInfo.rentalCarClass === 'Other' &&
                          <>
                            <Grid item xs={2} sm={2} md={1} xl={1}>
                              <Box>
                              <div className={classes.mb2}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Please Specify Rental Car Class
                                  </Typography>
                              </div>
                              <div className={classes.mb2}>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                  { tripInfo && tripInfo.otherRentalCar ? tripInfo.otherRentalCar : <div>&mdash;</div>}
                                  </Typography>
                              </div>
                              </Box>
                          </Grid>
                          </>
                          }
                          <Grid item xs={2} sm={2} md={1} xl={1}>
                              <Box>
                              <div sx={{ marginLeft: '-50px', marginTop: '40px' }}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Rental Start Date
                                  </Typography>
                              </div>
                              <div className={classes.mb2}>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                  { tripInfo && tripInfo.rentalCarStartDate ? formatDateForInput(tripInfo.rentalCarStartDate) : <div>&mdash;</div>}
                                  </Typography>
                              </div>
                              </Box>
                          </Grid>
                          <Grid item xs={2} sm={2} md={1} xl={1}>
                              <Box>
                              <div sx={{ marginLeft: '-50px', marginTop: '40px' }}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Rental End Date
                                  </Typography>
                              </div>
                              <div className={classes.mb2}>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                  { tripInfo && tripInfo.rentalCarEndDate ? formatDateForInput(tripInfo.rentalCarEndDate) : <div>&mdash;</div>}
                                  </Typography>
                              </div>
                              </Box>
                          </Grid>
                          <Grid item xs={2} sm={2} md={1} xl={1}>
                              <Box>
                              <div sx={{ marginLeft: '-50px', marginTop: '40px' }}>
                                  <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Rental Duration
                                  </Typography>
                              </div>
                              <div className={classes.mb2}>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                  { calculateTripDuration(tripInfo.rentalCarStartDate, tripInfo.rentalCarEndDate) > 0 ? calculateTripDuration(tripInfo.rentalCarStartDate, tripInfo.rentalCarEndDate) : <div>&mdash;</div> }
                                  </Typography>
                              </div>
                              </Box>
                          </Grid>
                          <Box sx={{ width: '100%' }}>
                            <hr />
                          </Box>
                      </>
                      }
                  </Grid>
              </>
            }
          </CardContent>
        </Box>
      </Card>
      {editingModalOpen && (
        <EditCardModal
          classes={classes}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={() => setEditingModalOpen(false)}
          onSubmit={handleSaveEdits}
          tripInfo={tripInfo}
          isRoundTrip={roundTrip}
        />
      )}
    </ThemeProvider>
  );
  };

    
  const mapStateToProps = (state) => {
    return {
    };
  };
    
  export default compose(
    connect(mapStateToProps, {
      postTravelBookingRequest,
      updateTravelBookingRequest,
      getTravelBookingRequestsById,
      getTravelBookingRequestsByTrip,
      deleteTravelBookingRequest,
      updateCandidateTrip,
      showToast,
    }),
  )(TravelRequestCard);