/* eslint-disable react-hooks/exhaustive-deps */

import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import  { withStyles } from '@material-ui/core';

import {
  Box, Button, Dialog, DialogActions, Grid, Paper, TextField, Typography,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { GET_INTERN_SERVICES_FAILURE, UPDATE_INTERN_SERVICES_FAILURE, GET_TRAVEL_BOOKING_REQUESTS_BY_AUTH_FAILURE, POST_TRAVEL_BOOKING_REQUESTS_FAILURE, DELETE_TRAVEL_BOOKING_REQUESTS_FAILURE, UPDATE_TRAVEL_BOOKING_REQUESTS_FAILURE } from 'modules/authorizations/store/types/canidateTripTypes';
import { getAuthDateSummary, getAuthorizationSummary, setAuthId, setLoadingComplete } from '../../store/actions/authorizations.actions';
import { getBrokerAgents, getBrokerAgentsById } from 'modules/authorizations/store/actions/dataManagement.actions';
import { getInternServices, postInternServices, updateInternServices, getTravelBookingRequestsByAuth, postTravelBookingRequest, deleteTravelBookingRequest, updateTravelBookingRequest } from 'modules/authorizations/store/actions/canidateTrip.actions';

import { calculateTripDuration } from 'modules/authorizations/utils';
import { showToast } from 'modules/layout/layout.actions';
import CostEstimatesCard from './costEstimatesCard.component';
import EditableCard from 'modules/common/editableCard.component';
import TravelRequestCard from './travelRequestCard.component';
import InternSummaryCard from './internSummaryCard.component';

import ServiceTriggeredComment from '../commentsAndNotes/serviceTriggeredComment.component';
import SlidingCommentsAndNotes from '../commentsAndNotes/commentsAndNotesSlidingPanel.component';
import SpinnerComponent from 'modules/common/spinner.component';
import { formatDateForInput } from 'utilities/common';

const styles = (theme) => ({
  wideControl: {
    minWidth: 174,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  selfServicePolicySelect:{
    minWidth: 300,
  },
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
});

const InternServices = (props) => {    

  const { brokerAgents, id, details, location, isReadOnly } = props;

  const [currentStatus, setCurrentStatus] = useState(details && details?.authCanidateTrips?.candidateServices ? details?.authCanidateTrips?.candidateServices.status : 'Initiated');
  const [isLoading, setIsLoading] = useState(false);
  // default to false, no travel booking created for a trip unless we can find them
  const [tripTravelRequests, setTripTravelRequests] = useState([]);
  
  const [currentTripId, setCurrentTripId] = useState(tripTravelRequests && tripTravelRequests.length > 0 ? tripTravelRequests[0].requestId : null);
  const [currentTripInfo, setCurrentTripInfo] = useState(tripTravelRequests ? (currentTripId ? tripTravelRequests.find((ttr) => ttr.requestId === currentTripId) : tripTravelRequests[0]) : null);
  const [showAddTrip, setShowAddTrip] = useState(false);

  const [newTitle, setNewTitle] = useState('');
  const [newDepartureDate, setNewDepartureDate] = useState(null);
  const [newArrivalDate, setNewArrivalDate] = useState(null);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isLoadingBooking, setIsLoadingBooking] = useState(false);

  const [showTriggeredComment, setShowTriggeredComment] = useState(false);
  const [showComment, setShowComment] = useState(false);

  const brokerAgentSearchInfo = {
    searchingColumns: [{ name: 'typeOfAgent', title: 'Type of Agent' }, { name: 'agentName', title: 'Agent Name' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }],
    searchingColumnExtensions: [{ columnName: 'typeOfAgent', width: 150 }, { columnName: 'agentName', width: 200 }, { columnName: 'companyName', width: 300 }, { columnName: 'city', width: 150 }, { columnName: 'state', width: 150 }],
    searchingRows: brokerAgents,
    nameKey: 'agentName',
    idKey: 'brokerId',
  };

  const internTripToCardTabs = [
    { header: 'Trip Summary', order: 1, iconPath: ['fas', 'fa-plane-departure'] }
  ];

  const tripToSummaries = [
    { header: 'Departure Date', accessorKey: 'tripToDepartureDate', type: 'date', required: false, value: currentTripInfo ? currentTripInfo.tripToDepartureDate : null, tab: 1 },
    { header: 'Arrival Date', accessorKey: 'tripToArrivalDate', type: 'date', required: false, value: currentTripInfo ? currentTripInfo.tripToArrivalDate : null, tab: 1 },
    { header: 'Trip Duration', accessorKey: 'tripToTravelDuration', type: 'number', required: false, value: currentTripInfo && (currentTripInfo.tripToDepartureDate && currentTripInfo.tripToArrivalDate) ? calculateTripDuration(currentTripInfo.tripToDepartureDate, currentTripInfo.tripToArrivalDate, currentTripInfo.tripToTravelDuration) : 0, tab: 1 },
    { header: 'Special Needs', accessorKey: 'tripToSpecialNeeds', type: 'notes', required: false, value: currentTripInfo ? currentTripInfo.tripToSpecialNeeds : '', tab: 1 },
    { header: 'Notes', accessorKey: 'tripToNotes', type: 'notes', required: false, value: currentTripInfo ? currentTripInfo.tripToNotes : '', tab: 1 },
  ];

      const getBookingRequestData = async () => {
        const bookingResp = await props.getTravelBookingRequestsByAuth(id);
        if (bookingResp.type === GET_TRAVEL_BOOKING_REQUESTS_BY_AUTH_FAILURE) {
          props.showToast('Failed to get the candidate trip booking request info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        } else {
            setTripTravelRequests(bookingResp.response);
            if (!currentTripId) setCurrentTripId(bookingResp.response && bookingResp.response.length > 0 ? bookingResp.response[0].requestId : null);
          }
          setIsLoading(false);
      };
    
      useEffect(() => {
        getBookingRequestData();
      }, []);

      useEffect(() => {
        if (tripTravelRequests && tripTravelRequests.length > 0 && currentTripId != null) {
          setCurrentTripInfo(tripTravelRequests ? tripTravelRequests.find((ttr) => ttr.requestId === currentTripId) : null);
        }
      }, [currentTripId]);
  
      const createBooking = async () => {
          setIsLoadingBooking(true);
          
          let newValues = {...currentTripInfo}
  
          newValues.hasBookingDetails = true;
  
          const resp = await props.updateTravelBookingRequest(newValues);
          if (resp.type === UPDATE_TRAVEL_BOOKING_REQUESTS_FAILURE) {
            props.showToast('Failed to update intern trip info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
            setIsLoadingBooking(false);
            return false;
          } else { 
            setCurrentTripInfo(resp.response);
            setIsLoadingBooking(false);
            return true; 
          }
        };
  
    const BookTravelButtons = ({ ...rest }) => {
      return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 10px',
                }}
              >
                  {!isLoadingBooking && currentTripInfo && !currentTripInfo.hasBookingDetails && (
                    <Button
                      onClick={createBooking}
                      color="secondary"
                      variant="contained"
                      sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                    >
                      Add Booking Details
                    </Button>
                  )}
                  {isLoadingBooking && <SpinnerComponent/>}
              </Box>
            </Box>
          </Box>
        )
    };

    
  const showButtonComponentFunc = (currentTab) => {
    return currentTab === 1;
  };

  const updateTripToFields = async (tab, values) => {
    setIsLoading(true);
    let newVals = { ...currentTripInfo };

    newVals.tripToDepartureDate = values.tripToDepartureDate;
    newVals.tripToArrivalDate = values.tripToArrivalDate;
    newVals.tripToTravelDuration = values.tripToTravelDuration;
    newVals.tripToSpecialNeeds = values.tripToSpecialNeeds;
    newVals.tripToNotes = values.tripToNotes;
        
    if (currentTripInfo) {
      const resp = await props.updateTravelBookingRequest(newVals);
      if (resp.type === UPDATE_TRAVEL_BOOKING_REQUESTS_FAILURE) {
        props.showToast('Failed to update the intern services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        getBookingRequestData();
        setCurrentTripInfo(resp.response);
        return true;
      }
    } 
  };

  const cancelService = async () => {
    setIsLoading(true);
    setCurrentStatus('Canceled');
    let newVals = { ...details?.authCanidateTrips?.candidateServices };
    newVals.authorizationId = props.id;
    newVals.status = currentStatus;

    if (details?.authCanidateTrips?.candidateServices && details?.authCanidateTrips?.candidateServices.length !== 0) {
      const resp = await props.updateCandidateServices(newVals);
      if (resp.type === UPDATE_INTERN_SERVICES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getCandidateServices(props.id);
        if (getResp.type === GET_INTERN_SERVICES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true; 
        }
      }
    }
  };

  const completeService = async () => {
    setIsLoading(true);
    setCurrentStatus('Complete');
    let newVals = { ...details?.authCanidateTrips?.candidateServices };
    newVals.authorizationId = props.id;
    newVals.status = currentStatus;
    setShowTriggeredComment(true);

    if (details?.authCanidateTrips?.candidateServices && details?.authCanidateTrips?.candidateServices.length !== 0) {
      const resp = await props.updateCandidateServices(newVals);
      if (resp.type === UPDATE_INTERN_SERVICES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getCandidateServices(props.id);
        if (getResp.type === GET_INTERN_SERVICES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true; 
        }
      }
    }
  };

  const deleteTrip = async () => {
      const deleteTripResp = await props.deleteTravelBookingRequest(currentTripId);
      if (deleteTripResp.type === DELETE_TRAVEL_BOOKING_REQUESTS_FAILURE) {
        props.showToast('Failed to delete the current intern trip info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Trip deleted successfully.', { userMustDismiss: false, severity: 'success' });
        const allTripsResp = await props.getTravelBookingRequestsByAuth(id);
        if (allTripsResp.type === GET_TRAVEL_BOOKING_REQUESTS_BY_AUTH_FAILURE) {
          props.showToast('Failed to get the intern trips info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        } else {
          setTripTravelRequests(allTripsResp.response);
        }     
      }
  };

  const createTrip = async () => {
      const authId = details?.authDetails?.summary.authorizationId;
      setIsLoadingModal(true);
  
      const resp = await props.postTravelBookingRequest({
        authorizationId: authId,
        tripName: newTitle,
        tripToDepartureDate: newDepartureDate,
        tripToArrivalDate: newArrivalDate
      });
  
      if (resp.type === POST_TRAVEL_BOOKING_REQUESTS_FAILURE) {
        props.showToast('Failed to update the intern trips info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setShowAddTrip(false);
      } else {
        const allTripsResp = await props.getTravelBookingRequestsByAuth(authId);
        if (allTripsResp.type === GET_TRAVEL_BOOKING_REQUESTS_BY_AUTH_FAILURE) {
          props.showToast('Failed to fetch intern trips, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        } else {
          props.showToast('New trip created successfully.', { userMustDismiss: false, severity: 'success' });
          setCurrentTripId(resp.response.requestId);
          setTripTravelRequests(allTripsResp.response);
          setCurrentTripInfo(resp.response);
          setShowAddTrip(false);
        }
        setIsLoadingModal(false);
      }
  };

  return (
    <Box>
      {showComment && (
              <Box
                sx={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 1200, 
                  pointerEvents: 'none',
                }}
              />
            )}
      <Box sx={{ padding: '20px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Paper
            square
            className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)}
            sx={{
              position: 'sticky',
              paddingRight: '10px',
              paddingLeft: '10px',
              marginBottom: '15px',
              minWidth: '100%'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px', marginBottom: '10px' }}>
              <Box />
              <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                <Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                      Move Type
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                      {details?.authDetails?.summary.moveType}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                      {details?.authDetails?.summary.moveType ? details?.authDetails?.summary.moveType : ''} Status
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                      {currentStatus}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                      Policy
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                      {details?.authDetails?.authClientPolicies?.clientPolicyCode}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
              {location && !location.pathname.includes('expenses') && !isReadOnly && (
                <>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        maxHeight: '40px',
                        marginLeft: '12px',
                        marginTop: '8px',
                        backgroundColor: '#868686',
                        '&:hover': { backgroundColor: '#474747' }
                      }}
                      onClick={cancelService}
                    >
                      {' '}
                      Cancel Service{' '}
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        maxHeight: '40px',
                        marginLeft: '12px',
                        marginTop: '8px',
                        backgroundColor: '#f0b11d',
                        '&:hover': { backgroundColor: '#ba8a00' }
                      }}
                    >
                      {' '}
                      Set On Hold{' '}
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                      onClick={completeService}
                    >
                      {' '}
                      Complete Service{' '}
                    </Button>
                </>
              )}
              <Button
                  onClick={() => setShowComment(true)}
                  variant="contained"
                  sx={{
                    maxHeight: '40px',
                    marginLeft: '12px',
                    marginTop: '8px',
                    backgroundColor: '#41B7C0',
                    color: 'white',
                    '&:hover': { backgroundColor: '#3d959c' }
                  }}
                >
                  Comments
                </Button>
                </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} xl={8} rowSpacing={2}>
            <Grid item sx={{ marginBottom: '10px' }}>
              <Paper
                  square
                  className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)}
                  sx={{
                    position: 'sticky',
                    paddingRight: '10px',
                    paddingLeft: '10px',
                    marginBottom: '15px',
                    minWidth: '100%'
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                    <Box>
                      <div className={props.classes.mb2}>
                        <Typography variant="subtitle2">Trip</Typography>
                      </div>
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box>
                          <Paper style={{ minWidth: '300px', maxWidth: '600px', maxHeight: '125px', minHeight: '100px', overflowY: 'scroll' }}>
                            {tripTravelRequests && tripTravelRequests.map(opt => (
                              <Box sx={{padding: '10px', backgroundColor: currentTripId === opt.requestId ? '#c5e3d3' : undefined, cursor: 'pointer', '&:hover': { backgroundColor: currentTripId === opt.requestId ? '#5D9878' : 'lightgrey' }}} onClick={() => { setCurrentTripId(opt.requestId); setCurrentTripInfo(tripTravelRequests ? tripTravelRequests.find((ttr) => ttr.requestId === opt.requestId) : null); }} >
                                {opt.tripName} ({new Date(opt.tripToDepartureDate).toLocaleDateString()} - {new Date(opt.tripToArrivalDate).toLocaleDateString()})
                              </Box>
                            ))}
                          </Paper>
                        </Box>
                        {location && !location.pathname.includes('expenses') && !isReadOnly && (
                          <>
                            <Button
                              color="secondary"
                              type="submit"
                              variant="contained"
                              sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                              onClick={() => setShowAddTrip(true)}
                            >
                              {' '}
                              New Trip{' '}
                            </Button>
                            <Button
                              color="secondary"
                              type="submit"
                              variant="contained"
                              sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#868686' }}
                              onClick={deleteTrip}
                            >
                              {' '}
                              Delete Trip{' '}
                            </Button>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Paper>
            </Grid>
            {currentTripId && (
              <>
                <Grid item sx={{ marginBottom: '10px' }}>
                  <EditableCard
                    tabs={internTripToCardTabs}
                    summaries={tripToSummaries}
                    numColumns={5}
                    updateFields={updateTripToFields}
                    isLoading={isLoading}
                    hideEdit={location.pathname.includes('expenses') || isReadOnly}
                    ButtonComponent={BookTravelButtons}
                    showButtonComponentFunc={showButtonComponentFunc}
                  />
                </Grid> 
                {currentTripInfo && currentTripInfo.hasBookingDetails && (
                  <Grid item>
                    <TravelRequestCard tripInfo={currentTripInfo} isIntern={true} travelRequests={tripTravelRequests} authId={id} tripId={currentTripId} roundTrip={false} updateData={(data) => { setCurrentTripInfo(data); getBookingRequestData(); }}/>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} xl={4} rowSpacing={2}>
            <Grid item sx={{ marginBottom: '10px' }}>
              <InternSummaryCard authId={props.id} internServices={details?.authCanidateTrips?.internServices} brokerAgentSearchInfo={brokerAgentSearchInfo} hideEdit={props.hideEdit} status={currentStatus}/>
            </Grid>
            <Grid item sx={{ marginBottom: '10px' }}>
              <CostEstimatesCard
                costEstimate={details?.authCanidateTrips?.costEstimate}
                hideEdit={location.pathname.includes('expenses') || isReadOnly}
              />
            </Grid>
          </Grid>
        </Grid>
        <Dialog open={showAddTrip} fullWidth={false} maxWidth={'lg'}>
          <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
            <Box sx={{ marginRight: '20px' }}>
              <TextField
                key={'newTripTitle'}
                label={'Trip Title'}
                name={'newTripTitle'}
                required={true}
                disabled={false}
                value={newTitle}
                sx={{ maxWidth: '360px' }}
                margin="dense"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                onChange={e => setNewTitle(e.target.value)}
              />
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </Box>
            <Box sx={{ marginRight: '20px' }}>
              <TextField
                key={'newTripStartDate'}
                label={'Departure Date'}
                name={'newTripStartDate'}
                required={true}
                disabled={false}
                value={formatDateForInput(newDepartureDate)}
                sx={{ maxWidth: '360px' }}
                margin="dense"
                variant="standard"
                type="date"
                InputLabelProps={{ shrink: true }}
                onChange={e => setNewDepartureDate(e.target.value)}
              />
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </Box>
            <Box sx={{ marginRight: '20px' }}>
              <TextField
                key={'newTripEndDate'}
                label={'Arrival Date'}
                name={'newTripEndDate'}
                required={true}
                disabled={false}
                value={formatDateForInput(newArrivalDate)}
                sx={{ maxWidth: '360px' }}
                margin="dense"
                variant="standard"
                type="date"
                InputLabelProps={{ shrink: true }}
                onChange={e => setNewArrivalDate(e.target.value)}
              />
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </Box>
          </Box>
          <DialogActions sx={{ p: '1.25rem' }}>
            {isLoadingModal && <SpinnerComponent />}
            {!isLoadingModal && (
              <>
                <Button onClick={() => setShowAddTrip(false)}>Cancel</Button>
                <Button
                  onClick={createTrip}
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={!(newTitle && newTitle.length > 0)}
                >
                  Create Trip
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
        {showTriggeredComment && (
            <ServiceTriggeredComment
              authorizationId={id}
              serviceOptionId={5}
              displayText={'Candidate - Service Completed Comment'}
              setShowTriggeredComment={setShowTriggeredComment} 
            />
          )}
        {showComment && (
            <SlidingCommentsAndNotes
              authorizationId={id}
              serviceOptionId={0}
              setShowComment={setShowComment}
              isOpen={true}
            />
          )}
      </Box>
    </Box>
  );

};

InternServices.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getAuthDateSummary,
    getBrokerAgents,
    setAuthId,
    setLoadingComplete,
    getBrokerAgentsById,
    getInternServices,
    updateInternServices,
    postInternServices,
    getTravelBookingRequestsByAuth,
    postTravelBookingRequest,
    showToast,
    deleteTravelBookingRequest,
    updateTravelBookingRequest,
  }),
)(InternServices);