export const GET_HHG_SEGMENTS_FAILURE = 'GET_HHG_SEGMENTS_FAILURE';
export const GET_HHG_SEGMENTS_REQUEST = 'GET_HHG_SEGMENTS_REQUEST';
export const GET_HHG_SEGMENTS_SUCCESS = 'GET_HHG_SEGMENTS_SUCCESS';

export const POST_HHG_SEGMENT_FAILURE = 'POST_HHG_SEGMENT_FAILURE';
export const POST_HHG_SEGMENT_REQUEST = 'POST_HHG_SEGMENT_REQUEST';
export const POST_HHG_SEGMENT_SUCCESS = 'POST_HHG_SEGMENT_SUCCESS';

export const UPDATE_HHG_SEGMENT_FAILURE = 'UPDATE_BROKER_AGENTS_FAILURE';
export const UPDATE_HHG_SEGMENT_REQUEST = 'UPDATE_BROKER_AGENTS_REQUEST';
export const UPDATE_HHG_SEGMENT_SUCCESS = 'UPDATE_HHG_SEGMENT_SUCCESS';

export const GET_HHG_MOVING_DATE_FAILURE = 'GET_HHG_MOVING_DATE_FAILURE';
export const GET_HHG_MOVING_DATE_REQUEST = 'GET_HHG_MOVING_DATE_REQUEST';
export const GET_HHG_MOVING_DATE_SUCCESS = 'GET_HHG_MOVING_DATE_SUCCESS';

export const POST_HHG_MOVING_DATE_FAILURE = 'POST_HHG_MOVING_DATE_FAILURE';
export const POST_HHG_MOVING_DATE_REQUEST = 'POST_HHG_MOVING_DATE_REQUEST';
export const POST_HHG_MOVING_DATE_SUCCESS = 'POST_HHG_MOVING_DATE_SUCCESS';

export const UPDATE_HHG_MOVING_DATE_FAILURE = 'UPDATE_HHG_MOVING_DATE_FAILURE';
export const UPDATE_HHG_MOVING_DATE_REQUEST = 'UPDATE_HHG_MOVING_DATE_REQUEST';
export const UPDATE_HHG_MOVING_DATE_SUCCESS = 'UPDATE_HHG_MOVING_DATE_SUCCESS';

export const DELETE_HHG_MOVING_DATE_FAILURE = 'DELETE_HHG_MOVING_DATE_FAILURE';
export const DELETE_HHG_MOVING_DATE_REQUEST = 'DELETE_HHG_MOVING_DATE_REQUEST';
export const DELETE_HHG_MOVING_DATE_SUCCESS = 'DELETE_HHG_MOVING_DATE_SUCCESS';

export const GET_HHG_ITEMS_FAILURE = 'GET_HHG_ITEMS_FAILURE';
export const GET_HHG_ITEMS_REQUEST = 'GET_HHG_ITEMS_REQUEST';
export const GET_HHG_ITEMS_SUCCESS = 'GET_HHG_ITEMS_SUCCESS';

export const POST_HHG_ITEMS_FAILURE = 'POST_HHG_ITEMS_FAILURE';
export const POST_HHG_ITEMS_REQUEST = 'POST_HHG_ITEMS_REQUEST';
export const POST_HHG_ITEMS_SUCCESS = 'POST_HHG_ITEMS_SUCCESS';

export const UPDATE_HHG_ITEMS_FAILURE = 'UPDATE_HHG_ITEMS_FAILURE';
export const UPDATE_HHG_ITEMS_REQUEST = 'UPDATE_HHG_ITEMS_REQUEST';
export const UPDATE_HHG_ITEMS_SUCCESS = 'UPDATE_HHG_ITEMS_SUCCESS';

export const DELETE_HHG_ITEMS_FAILURE = 'DELETE_HHG_ITEMS_FAILURE';
export const DELETE_HHG_ITEMS_REQUEST = 'DELETE_HHG_ITEMS_REQUEST';
export const DELETE_HHG_ITEMS_SUCCESS = 'DELETE_HHG_ITEMS_SUCCESS';

export const GET_HHG_ESTIMATES_FAILURE = 'GET_HHG_ESTIMATES_FAILURE';
export const GET_HHG_ESTIMATES_REQUEST = 'GET_HHG_ESTIMATES_REQUEST';
export const GET_HHG_ESTIMATES_SUCCESS = 'GET_HHG_ESTIMATES_SUCCESS';

export const POST_HHG_ESTIMATE_FAILURE = 'POST_HHG_ESTIMATE_FAILURE';
export const POST_HHG_ESTIMATE_REQUEST = 'POST_HHG_ESTIMATE_REQUEST';
export const POST_HHG_ESTIMATE_SUCCESS = 'POST_HHG_ESTIMATE_SUCCESS';

export const UPDATE_HHG_ESTIMATE_FAILURE = 'UPDATE_HHG_ESTIMATE_FAILURE';
export const UPDATE_HHG_ESTIMATE_REQUEST = 'UPDATE_HHG_ESTIMATE_REQUEST';
export const UPDATE_HHG_ESTIMATE_SUCCESS = 'UPDATE_HHG_ESTIMATE_SUCCESS';


export const DELETE_HHG_VEHICLES_FAILURE = 'DELETE_HHG_VEHICLES_FAILURE';
export const DELETE_HHG_VEHICLES_REQUEST = 'DELETE_HHG_VEHICLES_REQUEST';
export const DELETE_HHG_VEHICLES_SUCCESS = 'DELETE_HHG_VEHICLES_SUCCESS';

export const GET_HHG_VEHICLES_FAILURE = 'GET_HHG_VEHICLES_FAILURE';
export const GET_HHG_VEHICLES_REQUEST = 'GET_HHG_VEHICLES_REQUEST';
export const GET_HHG_VEHICLES_SUCCESS = 'GET_HHG_VEHICLES_SUCCESS';

export const POST_HHG_VEHICLES_FAILURE = 'POST_HHG_VEHICLES_FAILURE';
export const POST_HHG_VEHICLES_REQUEST = 'POST_HHG_VEHICLES_REQUEST';
export const POST_HHG_VEHICLES_SUCCESS = 'POST_HHG_VEHICLES_SUCCESS';

export const UPDATE_HHG_VEHICLES_FAILURE = 'UPDATE_HHG_VEHICLES_FAILURE';
export const UPDATE_HHG_VEHICLES_REQUEST = 'UPDATE_HHG_VEHICLES_REQUEST';
export const UPDATE_HHG_VEHICLES_SUCCESS = 'UPDATE_HHG_VEHICLES_SUCCESS';

export const GET_HHG_ESTIMATE_MOVING_DATES_FAILURE = 'GET_HHG_ESTIMATE_MOVING_DATES_FAILURE';
export const GET_HHG_ESTIMATE_MOVING_DATES_REQUEST = 'GET_HHG_ESTIMATE_MOVING_DATES_REQUEST';
export const GET_HHG_ESTIMATE_MOVING_DATES_SUCCESS = 'GET_HHG_ESTIMATE_MOVING_DATES_SUCCESS';

export const POST_HHG_ESTIMATE_MOVING_DATES_FAILURE = 'POST_HHG_ESTIMATE_MOVING_DATES_FAILURE';
export const POST_HHG_ESTIMATE_MOVING_DATES_REQUEST = 'POST_HHG_ESTIMATE_MOVING_DATES_REQUEST';
export const POST_HHG_ESTIMATE_MOVING_DATES_SUCCESS = 'POST_HHG_ESTIMATE_MOVING_DATES_SUCCESS';

export const UPDATE_HHG_ESTIMATE_MOVING_DATES_FAILURE = 'UPDATE_HHG_ESTIMATE_MOVING_DATES_FAILURE';
export const UPDATE_HHG_ESTIMATE_MOVING_DATES_REQUEST = 'UPDATE_HHG_ESTIMATE_MOVING_DATES_REQUEST';
export const UPDATE_HHG_ESTIMATE_MOVING_DATES_SUCCESS = 'UPDATE_HHG_ESTIMATE_MOVING_DATES_SUCCESS';