/* eslint-disable react-hooks/exhaustive-deps */

import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import  { withStyles } from '@material-ui/core';

import {
  Box, Button, Dialog, DialogActions, Grid, Paper, TextField, Typography,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { DELETE_CANDIDATE_TRIP_FAILURE, GET_ALL_CANDIDATE_TRIPS_FAILURE, GET_CANDIDATE_SERVICES_FAILURE, GET_CANDIDATE_TRIP_FAILURE, POST_CANDIDATE_SERVICES_FAILURE, POST_CANDIDATE_TRIP_FAILURE, UPDATE_CANDIDATE_SERVICES_FAILURE } from 'modules/authorizations/store/types/canidateTripTypes';
import { deleteCandidateTrip, getCandidateServices, getCandidateTripById, getCandidateTrips, postCandidateServices, postCandidateTrip, updateCandidateServices } from 'modules/authorizations/store/actions/canidateTrip.actions';
import { getAuthDateSummary, getAuthorizationSummary, setAuthId, setLoadingComplete } from '../../store/actions/authorizations.actions';
import { getBrokerAgents, getBrokerAgentsById } from 'modules/authorizations/store/actions/dataManagement.actions';

import { calculateTripDuration } from 'modules/authorizations/utils';
import { formatDateForInput } from 'utilities/common';
import { showToast } from 'modules/layout/layout.actions';
import Spinner from 'modules/common/spinner.component';
import CandidateOverviewCard from './candidateOverviewCard.component';
import CandidateTripSummaryCard from './candidateTripSummary.component';
import ServiceTriggeredComment from '../commentsAndNotes/serviceTriggeredComment.component';
import SlidingCommentsAndNotes from '../commentsAndNotes/commentsAndNotesSlidingPanel.component';

const styles = (theme) => ({
  wideControl: {
    minWidth: 174,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  selfServicePolicySelect:{
    minWidth: 300,
  },
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
});

const CandidateServices = (props) => {    

  const { brokerAgents, id, details, location, isReadOnly } = props;

  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(details && details?.authCanidateTrips?.candidateServices ? details?.authCanidateTrips?.candidateServices.status : 'Initiated');
  const [currentTripId, setCurrentTripId] = useState(details?.authCanidateTrips?.candidateTrips && details?.authCanidateTrips?.candidateTrips[0] ? details?.authCanidateTrips?.candidateTrips[0].tripId : null);
  const [newTripTitle, setNewTripTitle] = useState('');
  const [newTripStartDate, setNewTripStartDate] = useState(null);
  const [newTripEndDate, setNewTripEndDate] = useState(null);
  const [newTripDuration, setNewTripDuration] = useState(0);
  const [showAddTrip, setShowAddTrip] = useState(false);

  const [showTriggeredComment, setShowTriggeredComment] = useState(false);
  const [showComment, setShowComment] = useState(false);

  const cancelService = async () => {
    setCurrentStatus('Canceled');
    let newVals = { ...details?.authCanidateTrips?.candidateServices };
    newVals.authorizationId = props.id;
    newVals.status = currentStatus;

    if (details?.authCanidateTrips?.candidateServices && details?.authCanidateTrips?.candidateServices.length !== 0) {
      const resp = await props.updateCandidateServices(newVals);
      if (resp.type === UPDATE_CANDIDATE_SERVICES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      } else { 
        const getResp = await props.getCandidateServices(props.id);
        if (getResp.type === GET_CANDIDATE_SERVICES_FAILURE) {
          return false;
        } else {
          return true; 
        }
      }
    } else {
      const resp = await props.postCandidateServices(newVals);
      if (resp.type === POST_CANDIDATE_SERVICES_FAILURE) {
        props.showToast('Failed to update candidate service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      } else { 
        return true; 
      }
    }
  };

  const completeService = async () => {
    setCurrentStatus('Complete');
    let newVals = { ...details?.authCanidateTrips?.candidateServices };
    newVals.authorizationId = props.id;
    newVals.status = currentStatus;
    setShowTriggeredComment(true);

    if (details?.authCanidateTrips?.candidateServices && details?.authCanidateTrips?.candidateServices.length !== 0) {
      const resp = await props.updateCandidateServices(newVals);
      if (resp.type === UPDATE_CANDIDATE_SERVICES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      } else { 
        const getResp = await props.getCandidateServices(props.id);
        if (getResp.type === GET_CANDIDATE_SERVICES_FAILURE) {
          return false;
        } else {
          return true; 
        }
      }
    } else {
      const resp = await props.postCandidateServices(newVals);
      if (resp.type === POST_CANDIDATE_SERVICES_FAILURE) {
        props.showToast('Failed to update candidate service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return false;
      } else { 
        return true; 
      }
    }
  };

  const trip = details?.authCanidateTrips?.candidateTrips ? details?.authCanidateTrips?.candidateTrips.find((s) => s.tripId === currentTripId) : null;

  const createTrip = async () => {
    const authId = details?.authDetails?.summary.authorizationId;
    setIsLoadingModal(true);

    const resp = await props.postCandidateTrip({
      authorizationId: authId,
      tripName: newTripTitle,
      tripStartDate: newTripStartDate,
      tripEndDate: newTripEndDate,
      tripDuration: newTripDuration,
    });

    if (resp.type === POST_CANDIDATE_TRIP_FAILURE) {
      props.showToast('Failed to update the candidate trips info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setShowAddTrip(false);
    } else {
      const allTripsResp = await props.getCandidateTrips(authId);
      if (allTripsResp.type === GET_ALL_CANDIDATE_TRIPS_FAILURE) {
        props.showToast('Failed to fetch candidate trips, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('New Offer created successfully.', { userMustDismiss: false, severity: 'success' });
        setCurrentTripId(resp.response.tripId);
        setShowAddTrip(false);
      }
      setIsLoadingModal(false);
    }
  };

  const deleteTrip = async () => {
    const deleteTripResp = await props.deleteCandidateTrip(currentTripId);
    if (deleteTripResp.type === DELETE_CANDIDATE_TRIP_FAILURE) {
      props.showToast('Failed to delete the current candidate trip info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      props.showToast('Trip deleted successfully.', { userMustDismiss: false, severity: 'success' });
      setCurrentTripId(details?.authCanidateTrips?.candidateTrips[0].tripId);
      const allTripsResp = await props.getCandidateTrips(id);
      if (allTripsResp.type === GET_ALL_CANDIDATE_TRIPS_FAILURE) {
        props.showToast('Failed to get the candidate trips info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }     
    }
  };

  const getTripData = async () => {
    const tripResp = await props.getCandidateTripById(currentTripId);
    if (tripResp.type === GET_CANDIDATE_TRIP_FAILURE) {
      props.showToast('Failed to get the candidate trip info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } 
  };

  const createAddressName = address => {
    const addressParts = [address.addressLineOne, address.city, address.state].filter(Boolean);
    return addressParts.length > 0 ? addressParts.join(', ') : '';
  };

  let addressesOptions = Array.isArray(details?.authDetails?.addresses)
  ? details?.authDetails?.addresses
      .map(s => ({
        id: s.addressId,
        title: createAddressName(s)
      }))
      .filter(option => option.title)
  : [];

  useEffect(() => {
    if (currentTripId) {
      getTripData();
    }
  }, [currentTripId]);

  return (
    <Box>
      {showComment && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1200, 
            pointerEvents: 'none',
          }}
        />
      )}
      <Box sx={{ padding: '20px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Paper
            square
            className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)}
            sx={{
              position: 'sticky',
              paddingRight: '10px',
              paddingLeft: '10px',
              marginBottom: '5px',
              minWidth: '100%'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px', marginBottom: '10px' }}>
              <Box />
              <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                <Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                      Move Type
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                      {details?.authDetails?.summary.moveType}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                      {details?.authDetails?.summary.moveType ? details?.authDetails?.summary.moveType : ''} Status
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                      {currentStatus}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                      Policy
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                      {details?.authDetails?.authClientPolicies?.clientPolicyCode}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
              {location && !location.pathname.includes('expenses') && !isReadOnly && (
                <>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        maxHeight: '40px',
                        marginLeft: '12px',
                        marginTop: '8px',
                        backgroundColor: '#868686',
                        '&:hover': { backgroundColor: '#474747' }
                      }}
                      onClick={cancelService}
                    >
                      {' '}
                      Cancel Service{' '}
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        maxHeight: '40px',
                        marginLeft: '12px',
                        marginTop: '8px',
                        backgroundColor: '#f0b11d',
                        '&:hover': { backgroundColor: '#ba8a00' }
                      }}
                    >
                      {' '}
                      Set On Hold{' '}
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                      onClick={completeService}
                    >
                      {' '}
                      Complete Service{' '}
                    </Button>
                </>
              )}
              <Button
                onClick={() => setShowComment(true)}
                variant="contained"
                sx={{
                  maxHeight: '40px',
                  marginLeft: '12px',
                  marginTop: '8px',
                  backgroundColor: '#41B7C0',
                  color: 'white',
                  '&:hover': { backgroundColor: '#3d959c' }
                }}
              >
                Comments
              </Button>
              </Box>              
            </Box>
          </Paper>
        </Grid>
        
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={12} xl={8} rowSpacing={2}>
            <Grid item sx={{ marginBottom: '10px' }}>
              <Paper
                  square
                  className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)}
                  sx={{
                    position: 'sticky',
                    paddingRight: '10px',
                    paddingLeft: '10px',
                    marginBottom: '15px',
                    minWidth: '100%'
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                    <Box>
                      <div className={props.classes.mb2}>
                        <Typography variant="subtitle2">Trip</Typography>
                      </div>
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box>
                          <Paper style={{ minWidth: '300px', maxWidth: '600px', maxHeight: '125px', minHeight: '100px', overflowY: 'scroll' }}>
                            {details &&
                            details?.authCanidateTrips?.candidateTrips &&
                            details?.authCanidateTrips?.candidateTrips.map(opt => (
                              <Box sx={{padding: '10px', backgroundColor: currentTripId === opt.tripId ? '#c5e3d3' : undefined, cursor: 'pointer', '&:hover': { backgroundColor: currentTripId === opt.tripId ? '#5D9878' : 'lightgrey' }}} onClick={() => setCurrentTripId(opt.tripId)} >
                                {opt.tripName}, {opt.tripCategory}, ({new Date(opt.tripStartDate).toLocaleDateString()}), {addressesOptions && addressesOptions.length !== 0 && opt.destinationAddressId ? addressesOptions.find(o => o.id === opt.destinationAddressId).title : '' }
                              </Box>
                            ))}
                          </Paper>
                        </Box>
                        {location && !location.pathname.includes('expenses') && !isReadOnly && (
                          <>
                            <Button
                              color="secondary"
                              type="submit"
                              variant="contained"
                              sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                              onClick={() => setShowAddTrip(true)}
                            >
                              {' '}
                              New Trip{' '}
                            </Button>
                            <Button
                              color="secondary"
                              type="submit"
                              variant="contained"
                              sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#868686' }}
                              onClick={deleteTrip}
                            >
                              {' '}
                              Delete Trip{' '}
                            </Button>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Paper>
            </Grid>
            {currentTripId && (
              <>
                <Grid item >
                  <CandidateTripSummaryCard trip={trip} familyMembers={details?.authDetails?.family} travelRequests={details?.authCanidateTrips?.travelRequests} hideEdit={location.pathname.includes('expenses') || isReadOnly} id={id} addresses={addressesOptions} />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12} xl={4} rowSpacing={2}>
            <Grid item sx={{ marginBottom: '10px' }}>
              <CandidateOverviewCard 
                authId={props.id}
                candidateServices={details?.authCanidateTrips?.candidateServices}
                brokerAgents={brokerAgents}
                hideEdit={location.pathname.includes('expenses') || isReadOnly} 
              />
          </Grid>
          </Grid>
          <Dialog open={showAddTrip} fullWidth={false} maxWidth={'lg'}>
            <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
              <Box sx={{ marginRight: '20px' }}>
                <TextField
                  key={'newTripTitle'}
                  label={'Trip Title'}
                  name={'newTripTitle'}
                  required={true}
                  disabled={false}
                  value={newTripTitle}
                  sx={{ maxWidth: '360px' }}
                  margin="dense"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setNewTripTitle(e.target.value)}
                />
                <Typography variant="caption" display="block" color="primary">
                  Required
                </Typography>
              </Box>
              <Box sx={{ marginRight: '20px' }}>
                <TextField
                  key={'newTripStartDate'}
                  label={'Start Date'}
                  name={'newTripStartDate'}
                  required={true}
                  disabled={false}
                  value={formatDateForInput(newTripStartDate)}
                  sx={{ maxWidth: '360px' }}
                  margin="dense"
                  variant="standard"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setNewTripStartDate(e.target.value)}
                />
                <Typography variant="caption" display="block" color="primary">
                  Required
                </Typography>
              </Box>
              <Box sx={{ marginRight: '20px' }}>
                <TextField
                  key={'newTripEndDate'}
                  label={'End Date'}
                  name={'newTripEndDate'}
                  required={true}
                  disabled={false}
                  value={formatDateForInput(newTripEndDate)}
                  sx={{ maxWidth: '360px' }}
                  margin="dense"
                  variant="standard"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setNewTripEndDate(e.target.value)}
                />
                <Typography variant="caption" display="block" color="primary">
                  Required
                </Typography>
              </Box>
              <Box sx={{ marginRight: '20px' }}>
                <TextField
                  key={'newTripDuration'}
                  label={'Trip Duration'}
                  name={'newTripDuration'}
                  required={true}
                  disabled={false}
                  value={newTripStartDate && newTripEndDate ? calculateTripDuration(newTripStartDate, newTripEndDate) : newTripDuration}
                  sx={{ maxWidth: '360px' }}
                  margin="dense"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setNewTripDuration(e.target.value)}
                />
              </Box>
            </Box>
            <DialogActions sx={{ p: '1.25rem' }}>
              {isLoadingModal && <Spinner />}
              {!isLoadingModal && (
                <>
                  <Button onClick={() => setShowAddTrip(false)}>Cancel</Button>
                  <Button
                    onClick={createTrip}
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={!(newTripTitle && newTripTitle.length > 0)}
                  >
                    Create Trip
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
      {showTriggeredComment && (
      <ServiceTriggeredComment
        authorizationId={id}
        serviceOptionId={5}
        displayText={'Candidate - Service Completed Comment'}
        setShowTriggeredComment={setShowTriggeredComment} 
      />
    )}
  {showComment && (
      <SlidingCommentsAndNotes
        authorizationId={id}
        serviceOptionId={0}
        setShowComment={setShowComment}
        isOpen={true}
      />
    )}
    </Box>
  );
};

CandidateServices.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getAuthDateSummary,
    getBrokerAgents,
    setAuthId,
    setLoadingComplete,
    getBrokerAgentsById,
    getCandidateServices,
    updateCandidateServices,
    postCandidateServices,
    getCandidateTripById,
    getCandidateTrips,
    postCandidateTrip,
    deleteCandidateTrip,
    showToast,
  }),
)(CandidateServices);